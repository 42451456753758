.ce-module {
  .ce-img-compare {
    position: relative;
    .img-compare-back {
      width: 100%;
      height: auto;
      img {
        width: 100%;
        height: auto;
      }
    }
    .img-compare-front {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
    }
    &.compare-x .img-compare-front {
      width: 50%;
      height: 100%;
      img {
        height: 100%;
        width: auto;
      }
    }
    &.compare-y .img-compare-front {
      width: 100%;
      height: 50%;
      img {
        height: auto;
        width: 100%;
      }
    }
    .indicator,
    img {
      pointer-events: none;
    }
    img {
      -webkit-user-select: none;
      user-select: none;
    }
    .indicator {
      display: block;
      background-color: white;
      position: absolute;

      .grabber {
        width: 50px;
        height: 50px;
        border-radius: 9999px;
        background-color: $cool_white;
        position: absolute;
        @include centerer(50%, 50%);
      }
    }
    $lineSize: 2px;
    &.compare-x .indicator {
      width: $lineSize;
      height: 100%;
      top: 0;
      left: calc(50% - 1px);
    }

    &.compare-y .indicator {
      width: 100%;
      height: $lineSize;
      top: calc(50% - 1px);
      left: 0;
    }
  }
}