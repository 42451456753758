.ce-module {
  .gift-projects {
    margin-top: $mod_child_padding_v_mobile;
    @include media-breakpoint-up(lg) {
      margin-top: $mod_child_padding_v;
    }
  }

  #gift-details-trigger {
    margin-top: 1rem;
  }

  .gift-pack-projects-list {
    ul {
      padding-left: 0;
      list-style: none;

      li {
        margin-bottom: .7rem;
      }
    }
  }

  &.calculator-hero {
    .calculator-col,
    .image-col {
      padding-bottom: $mod_child_padding_v_mobile;
    }
    .image-col {
      .bg-img {
        padding-top: 55%;
        width: 100%;
        height: 100%;
        background-position: center;
      }
    }
    &.calculator-hero-alt {
      .image-col {
        display: none;
      }
      .calculator-col {
        padding-top: $mod_child_padding_v_mobile;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &.calculator-hero {
      .calculator-col {
        padding-bottom: $mod_card_content_padding_lg;
      }
      .image-col {
        padding-bottom: 0;
        .bg-img {
          padding-top: 0;
        }
      }
      &.calculator-hero-alt {
        .image-col {
          display: block;
        }
        .calculator-col {
          padding-top: 40px;
        }
      }
    }
  }
}
