.ce-module {
  .ce-slick-slider {
    .slick-list, .slick-track {
      height: 100%;
    }
    .ce-slick-slider-slide {
      background-position: center;
      background-size: cover;
      &:focus {
        outline: none;
      }
    }
  }
}