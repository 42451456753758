.ce-module {
  .range {
    $thumbSize: 36px;
    display: block;
    position: relative;
    height: $thumbSize;
    .slider-background {
      width: 100%;
      height: 2px;
      display: block;
      position: absolute;
      left: 0;
      top: 50%;
      transform: translateY(-50%);
      .slider-progress {
        width: 100%;
        height: 100%;
        display: flex;
        justify-content: space-between;
        position: absolute;
        top: 0;
        left: 0;
        .tick {
          width: 12px;
          height: 12px;
          border-radius: 9999px;
          margin-top: -5px;
        }
      }
      .slider-progress {
        .tick {
          background-color: $cool_gray;
        }
        &.progress-1 {
          background: linear-gradient(to right, $cool_gray 0, $cool_gray 100%);
        }
        &.progress-2 {
          .tick:nth-child(1),
          .tick:nth-child(2) {
            background-color: $cool_blue;
          }
        }
        &.progress-3 {
          .tick:nth-child(1),
          .tick:nth-child(2),
          .tick:nth-child(3) {
            background-color: $cool_blue;
          }
        }
        &.progress-4 {
          .tick:nth-child(1),
          .tick:nth-child(2),
          .tick:nth-child(3),
          .tick:nth-child(4){
            background-color: $cool_blue;
          }
        }
        &.progress-5 {
          .tick {
            background-color: $cool_blue;
          }
        }
      }
    }

    &.range-max-4 {
      .slider-background .slider-progress {
        &.progress-2 {
          background: linear-gradient(to right, $cool_blue 0, $cool_blue percentage(1/3), $cool_gray percentage(1/3), $cool_gray 100%);
        }
        &.progress-3 {
          background: linear-gradient(to right, $cool_blue 0, $cool_blue percentage(2/3), $cool_gray percentage(2/3), $cool_gray 100%);
        }
        &.progress-4 {
          background: linear-gradient(to right, $cool_blue 0, $cool_blue 100%);
        }
      }
    }
    &.range-max-5 {
      .slider-background .slider-progress {
        &.progress-2 {
          background: linear-gradient(to right, $cool_blue 0, $cool_blue 25%, $cool_gray 25%, $cool_gray 100%);
        }
        &.progress-3 {
          background: linear-gradient(to right, $cool_blue 0, $cool_blue 50%, $cool_gray 50%, $cool_gray 100%);
        }
        &.progress-4 {
          background: linear-gradient(to right, $cool_blue 0, $cool_blue 75%, $cool_gray 75%, $cool_gray 100%);
        }
        &.progress-5 {
          background: linear-gradient(to right, $cool_blue 0, $cool_blue 100%);
        }
      }
    }

    input[type=range] {
      width: calc(100% + 24px);
      margin: 0 -12px;
      -webkit-appearance: none;
      height: $thumbSize;
      background: transparent;
      position: relative;

      &::-webkit-slider-thumb {
        -webkit-appearance: none;
      }
      &:focus {
        outline: none;
      }
      &::-ms-track {
        width: 100%;
        height: $thumbSize;
        cursor: pointer;
        /* Hides the slider so custom styles can be added */
        background: transparent;
        border-color: transparent;
        color: transparent;
        //border-width: 18px 0;
        //margin-top: -($thumbSize/2);
      }
      &::-ms-fill-lower {
        background: transparent;
      }
      &::-ms-fill-upper {
        background: transparent;
      }
      &::-ms-tooltip {
        display: none;
      }
      &::-moz-range-track {
        width: 100%;
        height: 0;
        background: transparent;
        border: none;
      }
      &::-moz-focus-outer {
        border: 0;
      }
      // style thumb
      &::-webkit-slider-thumb {
        -webkit-appearance: none;
        height: $thumbSize;
        width: $thumbSize;
        border-radius: 9999px;
        background: $cool_blue;
        background-color: $cool_blue;
        cursor: pointer;
        border: 0 !important;
        box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.25);
      }
      /* All the same stuff for Firefox */
      &::-moz-range-thumb {
        box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.25);
        height: $thumbSize;
        width: $thumbSize;
        border-radius: 9999px;
        background: $cool_blue;
        border: 0 !important;
        cursor: pointer;
      }
      /* All the same stuff for IE */
      &::-ms-thumb {
        box-shadow: 0px 2px 6px 0px rgba(0,0,0,0.25);
        height: $thumbSize;
        width: $thumbSize;
        border-radius: 9999px;
        background: $cool_blue;
        border: 0 !important;
        cursor: pointer;
      }
    }
  }
  //
  .range-tick-labels {
    height: 24px;
    margin: 16px 6px 0 6px;
    position: relative;
    .tick-label {
      white-space: nowrap;
      position: absolute;
      top: 0;
      transform: translateX(-50%);
      &:nth-child(1) {
        left: 0;
      }
    }
    &.range-max-4 .tick-label {
      &:nth-child(2) {
        left: percentage(1/3);
      }
      &:nth-child(3) {
        left: percentage(2/3);
      }
      &:nth-child(4) {
        left: 100%;
      }
    }
    &.range-max-5 .tick-label {
      &:nth-child(2) {
        left: 25%;
      }
      &:nth-child(3) {
        left: 50%;
      }
      &:nth-child(4) {
        left: 75%;
      }
      &:nth-child(5) {
        left: 100%;
      }
    }
  }
}

@media all and (-ms-high-contrast:none)
{
  *::-ms-backdrop, .ce-module .range input[type=range] {
    height: auto;
    margin-top: -18px;
  } /* IE11 */
}
