.ce-banner-content-overlay {
  align-items: center;
  box-sizing: border-box;
  display: flex;
  flex-direction: column;
  justify-content: center;
  padding: $page_padding;
  text-align: center;

  &:not([class*="theme-"]) {
    // Side padding unnecessary if the element's background is transparent
    padding-left: 0;
    padding-right: 0;
  }
  &:not([class*="col"]) {
    width: 100%; // Prevent style from overriding widths set by column classes
  }
  .module-header {
    margin: 0;
    padding: 0;
  }
  h1, h2, h3, h4 {
    margin-bottom: 0;
  }
}