.ce-module .travel-tabs {
	.nav-tabs {
		margin-right: 0;
		margin-left: 0;

		.nav-item {
			@media(max-width:767px) {
				margin-bottom: 20px;
			}

			.nav-link::before {
				content: '';
				display: block;
				width: 80px;
				height: 80px;
				margin-bottom: 15px;
				background-color: #432973;
				background-repeat: no-repeat;
				background-size: 40px auto;
				background-position: center;
				border-radius: 50%;
				border: 2px solid #432973;
				transition: all .5s ease;
			}

			.nav-link:hover::before,
			.nav-link.active::before {
				background-color: transparent;
				border: 2px solid #003B8D;
			}

			#tab-air-travel::before {
				background-image: url('/src/img/static/ce-icon-travel-air.svg');
			}
			#tab-air-travel:hover::before,
			#tab-air-travel.active::before {
				background-image: url('/src/img/static/ce-icon-travel-air-blue.svg');
			}
			#tab-car-commuting::before {
				background-image: url('/src/img/static/ce-icon-travel-car.svg');
			}
			#tab-car-commuting:hover::before,
			#tab-car-commuting.active::before {
				background-image: url('/src/img/static/ce-icon-travel-car-blue.svg');
			}
			#tab-hotel-stay::before {
				background-image: url('/src/img/static/ce-icon-home.svg');
			}
			#tab-hotel-stay:hover::before,
			#tab-hotel-stay.active::before {
				background-image: url('/src/img/static/ce-icon-home-blue.svg');
			}
			#tab-cruise::before {
				background-image: url('/src/img/static/ce-icon-boat.svg');
			}
			#tab-cruise:hover::before,
			#tab-cruise.active::before {
				background-image: url('/src/img/static/ce-icon-boat-blue.svg');
			}
		}
	}
}
