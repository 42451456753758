.ce-collapsible-trigger {
  cursor: pointer;
  .indicator {
    display: inline-block;
    //transform: rotate(90deg);
    transition: transform 250ms;
  }
  &.hide .indicator {
    //transform: rotate(0deg);
  }

  .indicator.ce-icon-chevron-right {
    transform: rotate(-90deg);
  }

  &.hide .indicator.ce-icon-chevron-right {
    transform: rotate(90deg);
  }

  &.ce-collapsible-trigger-link {
    color: $cool_yellow;
    text-transform: uppercase;
    font-weight: $cool_font-bold-weight;

    .ce-collapsible-trigger-onshow,
    .ce-collapsible-trigger-onhide {
      display: flex;
      align-items: center;
      &:after {
        display: block;
        font-family: 'cooleffect';
        font-weight: normal;
        margin-left: $mod_space_xs;
      }
    }

    .ce-collapsible-trigger-onhide {
      &:after {
        @extend .ce-icon-plus-outline:before;
      }
    }

    .ce-collapsible-trigger-onshow {
      &:after {
        @extend .ce-icon-minus-outline:before;
      }
    }

    &.hide {
      .ce-collapsible-trigger-onshow {
        display: none;
      }
    }

    &:not(.hide) {
      .ce-collapsible-trigger-onhide {
        display: none;
      }
    }

    & + .ce-collapsible-target {
      margin-top: ( $mod_space_md / 2 );
    }
  }
}

.ce-collapsible-target.hide {
  display: none;
}

.ce-collapsible-target-wrapper {
  position: relative;
  > * {
    position: absolute;
    overflow: hidden;
    top: 0;
    left: 0;
    width: 100%;
  }
}