@mixin form-base() {
    form
    {
        //display:                                inline-block;
        max-width:                              400px;
        margin:                                 0 auto;
        text-align:                             left;

        &.auto-width
        {
            max-width:                          inherit;
        }
        &.full-width
        {
            width:                              100%;
        }

        @content;

        .space {
            margin-top:10px;
        }
    }
}

@mixin form-base-fields() {
    fieldset > span
    {
        display:                            block;
        padding:                            0.75em 0.75em 0 0.75em;
    }

    label
    {
        font-weight:                        $cool_font-semi-bold-weight;
        //text-transform:                     uppercase;
      font-size: 13px;
      line-height: 1.5;
      margin-bottom: 6px;

        &.has-static
        {
            padding-bottom:                 0;
        }
    }

    hr
    {
        margin:                             0;
    }

    .alert
    {
        hr
        {
            margin-bottom:                  0.75em;
        }
    }

    .form-control-static
    {
        min-height:                         inherit;
        padding:                            0 0.75em 0.75em 0.75em;
        line-height:                        16px;
        font-size:                          14px;
    }

    .form-control, .input-group-addon
    {
        border-radius:                      inherit;
      &.switch {
        border:                             0;
        background-color:                   $cool_light_gray;
        padding: 0;
      }

        &:-webkit-autofill
        {
            -webkit-box-shadow:             0 0 0 2em $cool_light_gray inset;
            -webkit-text-fill-color:        $cool_medium_gray;
        }

        &.has-danger
        {
            box-shadow:                     inset 0 0 0 1px $cool_danger;
        }
    }
    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="number"],
    input[type="password"] {
      &.form-control {
        border: 1px solid $cool_gray;
        background-color: $cool_white;
        padding: 7px 10px;
        color: $cool_dark_gray;
        box-shadow: none;
        &:focus,
        &:hover {
          outline: none;
          border-color: $cool_purple;
        }
      }
    }
}
