// Parent of slides and nav
@mixin create_ce-thumbnail-slider_base() {
	.ce-thumbnail-slider-slides,
	.ce-thumbnail-slider-nav {
		// Height will be set by applying "padding-top" to
		// slides and nav
		height: auto; // !important
		width: 100%; // !important

		.ce-slick-slider-slide.slick-slide {
			background-repeat: no-repeat;
			outline: 0;
			&:focus {
				outline: 0;
			}
		}
	}

	.ce-thumbnail-slider-nav {
		margin-top: $mod_space_sm;
		
		.slick-list {
			padding: 0 !important;
		}
		.slick-track {
			display: flex;
			margin-left: ( -1 * $mod_space_sm );
			transform: none !important; // Prevent nav from being cut off when re-sizing window
			width: 100% !important;
		}
		.ce-slick-slider-slide.slick-slide {
			cursor: pointer;
			border: 2px solid transparent;
			margin-left: $mod_space_sm;
			transition: outline-color 0.3s ease;
			// width: 100% !important;

			&.slick-current {
				border-color: $cool_purple;
			}
		}
	}
}

.ce-thumbnail-slider-parent {
	@include create_ce-thumbnail-slider_base();

	.ce-thumbnail-slider-responsive {
		&.ce-thumbnail-slider-slides {
			.ce-slick-slider-slide {
				//padding-top: 25%;
			}
		}
		&.ce-thumbnail-slider-nav {
			.ce-slick-slider-slide {
				//padding-top: 15%;
			}
		}
	}
}

.ce-thumbnail-slider-overlay {
	@include create_ce-thumbnail-slider_base();

	.ce-thumbnail-slider-overlay-wrapper {
		position: relative;
        width:540px;
        height:auto;

		.ce-thumbnail-slider-overlay-image {
			position: absolute;
			right: 0;
			top: 50%;
			transform: translateY( -50% );
			z-index: z-layer( "content" );
			@include media-breakpoint-down( xs ) {
				max-width: 30vw;
			}
		}
	}
	.ce-thumbnail-slider-slides {
		.ce-slick-slider-slide.slick-slide {
			background-position: left center;
			background-size: contain;
		}
	}
	.ce-thumbnail-slider-nav {
		.slick-track {
			height: 50px;
			justify-content: center;
			@include media-breakpoint-up(sm) {
				height: 60px;
			}
			@include media-breakpoint-up(lg) {
				height: 60px;
				justify-content: flex-start;
			}
		}
		.ce-slick-slider-slide {
			max-width: 80px;
			@include media-breakpoint-up(lg) {
				max-width: 75px;
			}
		}
	}

	> .ce-caption-small {
		margin-top: $mod_space_sm;
	}
}
