.ce-header-main.partner {
  .ce-module.header-nav-module {
    nav.navbar {
      .partner-logo {
        position: relative;
        display: block;
        width: 100%;
        max-width: 210px;
        background-repeat: no-repeat;
        background-size: contain;
        background-position: center right;
      }
      &.mobile {
        justify-content: flex-end;
        .partner-logo {
          position: fixed;
          right: 20px;
          top: 10px;
          height: 50px;
        }
      }
    }
  }
  // desktop
  @include media-breakpoint-up(lg) {
    .ce-module.header-nav-module {
      nav.navbar {
        .partner-logo {
          max-width: 350px;
          height: 70px;
        }
      }
    }
  }
}
