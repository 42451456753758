.ce-module.rising-carbon {
  overflow: hidden;
  .frame {
    width: 0;
    overflow: hidden;
    img {
      display: block;
      position: relative;
      width: 100%;
      height: auto;
    }
    &:first-child {
      width: 100%;
    }
    &:not(:first-child) {
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      padding: inherit;
      img {
        width: auto;
        height: 100%;
      }
    }
  }
  .in-first-view {
    .frame {
      $duration: 1.25; // seconds
      $initalDelay: 0; // seconds
      @for $i from 2 through 5 {
        &:nth-child(#{$i}) {
          width: 100%;
          $delay: #{$i - $duration + $initalDelay}s;
          transition: width #{$duration}s linear $delay;
        }
      }

    }
  }
  .in-view {
    .frame {
      width: 100%;
    }
  }
  .disclaimer {
    line-height: 1;
    @include media-breakpoint-up(md) {
      margin-top: -56px;
      text-align: right;
    }
    color: $cool_gray;
  }
}
