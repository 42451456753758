.ce-module {
	.checkout-subnav {
		display: flex;
		justify-content: center;
		.item {
			border: none;
			padding: 0 $grid-gutter-width-md;
			position: relative;
			cursor: pointer;
			@include media-breakpoint-up(sm) {
				padding: 0 $grid-gutter-width;
			}
			&:disabled {
				cursor: default;
			}
			// This sets up the gradient underline for the active state - J.D.P.
			&::after {
				background: transparent;
				bottom: 0;
				content: "";
				display: block;
				height: 3px;
				left: 0;
				position: absolute;
				width: 100%;
			}
			background: none;
			&.hidden {
				display: none;
			}
			.content {
				display: flex;
				align-items: center;
				height: 70px;
				.checkout-subnav-label {
					line-height: 1;
					margin-bottom: 0;
					color: $cool_gray;
				}
				.indicator {
					display: none;
				}
				@include media-breakpoint-up( md ) {
					height: 100px;
				}
			}
			&.done {
				.indicator {
					display: flex;
					justify-content: center;
					align-items: center;
					background-color: $cool_purple;
					color: $cool_white;
					border-radius: 9999px;
					margin-right: 10px;
					font-size: 10px;
					width: 23px;
					height: 23px;
					@include media-breakpoint-up( md ) {
						width: 30px;
						height: 30px;
						font-size: 12px;
					}
				}
			}
			&.active {
				&::after {
					background: linear-gradient(to right, $cool_red 0, $cool_blue 100%);
				}
			}
			&.done,
			&.active {
				.checkout-subnav-label {
					color: $cool_dark_gray;
				}
			}
		}
	}
}
