.profiled-impact {
  overflow: hidden;
  .grid-item {
    overflow: hidden;
    > a {
      display: block;
      position: relative;
      width: 100%;
      height: 100%;
      overflow: hidden;
      &:hover {
        > .overlay {
          transform: translateY(101%);
        }
      }
      > .overlay {
        position: absolute;
        top: 0;
        left: 0;
        transform: translateY(0);
        width: 100%;
        height: 100%;
        display: none;
        align-items: flex-end;
        color: $cool_white;
        padding: $mod_space_md $mod_space_lg;
        font-size: $mod_font_size_h3_lg;
        font-weight: $cool_font-semi-bold-weight;
        transition: transform .75s ease;
        @include media-breakpoint-up(lg) {
          display: flex;
        }
        > *:last-child:after {
          @extend .ce-icon-arrow-right:before;
          font-family: 'cooleffect';
          font-size: 18px;
          font-weight: normal;
          margin-left: 10px;
        }
      }
      > img {
        width: 100%;
        height: auto;
      }
    }
  }
  .ce-slick-slider {
    .slick-list {
      padding: 0 15% !important;
      .slick-track {
        display: flex;
      }
    }
    .ce-slick-slider-slide {
      padding: 0 $grid-gutter-width/2;
      width: 75%;
      a > img {
        width: 100%;
        height: auto;
      }
    }
  }
  .grid-item,
  .ce-slick-slider-slide {
    &.overview {
      > * {
        background-color: $cool_blue;
        position: relative;
        > * {
          position: relative;
        }
        &:before {
          display: block;
          position: absolute;
          content: '';
          width: 100%;
          height: 100%;
          top: 0;
          left: 0;
          background: linear-gradient(to bottom right, rgba(0,0,0, 0 ), rgba(0,0,0, .4 ));
        }
        @extend .theme-transparent-dark;
        height: 100%;
        padding: $mod_space_xl;

        @include media-breakpoint-only(xs) {
          h3 {
            font-size: $font-size-root;
          }
          p {
            font-size: 15px;
          }
          padding: $mod_space_lg;
        }
        @include media-breakpoint-only(md) {
          h3 {
            font-size: $mod_font_size_h4;
          }
          padding: $mod_space_lg;
        }
      }
    }
  }
  .ce-slick-slider-slide.overview {
    height: auto;
    min-height: 100%;
  }
}