// I hate doing this. Come up with a better solution. - AE
.basic-col .vc_single_image-wrapper {
  text-align: center;
}
.basic-col .vc_single_image-wrapper > img {
	height: auto !important;
	max-width: 100%;
}
// To target h1 element in full-width module.
.img-banner .text-lg>h1 {
	font-size: 3.5em;
}