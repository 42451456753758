.ce-module.header-nav-module.stillin {
  nav.navbar {
    .logos {
      display: flex;
      height: 100%;
      align-items: center;
      .logo {
        width: auto;
        height: 100%;
        color: $stillin_color_black;
        &.separator {
          margin: 0 18px;
          font-size: 15px;
          font-weight: $cool_font-bold-weight;
          font-family: $font-family-base;
        }
        &.stillin-logo {
          font-size: 17px;
          font-family: $stillin-font-family_display;
          font-weight: $stillin_font_weight_display_extra_bold;
          letter-spacing: 1px;
          &:after {
            content: '';
            display: block;
            position: absolute;
            bottom: 0;
            width: 100%;
            height: 8px;
            background-color: $stillin_color_red;
          }
        }
        &.ce-logo {
          font-size: 17px;
          font-family: $font-family-base;
        }
      }
    }
  }
  .nav-menu-cart-count {
    background-color: $stillin_color_red;
    position: relative;
    font-size: 10px;
    font-family: $stillin_font_family_display;
    font-weight: $stillin_font_weight_display_semibold;
    line-height: 0;
    height: 18px;
    min-height: 18px;
    min-width: 18px;
    top: 0;
    left: 0;
    letter-spacing: 0;

  }
}