.ce-module {
  .ce-partner-primary {
    .image {
      padding: $mod_card_content_padding_featured 0;
      .ce-image-list-item {
        display: flex;
        justify-content: center;
        align-items: center;
      }
    }
    .text {
      padding: $mod_card_content_padding_featured;
      padding-top: 0;
      display: flex;
      align-items: center;
    }
    @include media-breakpoint-up(lg) {
      .text {
        padding-left: $mod_card_content_padding_featured - $grid-gutter-width;
        padding-top: $mod_card_content_padding_featured;
      }
    }
  }
  .ce-partner-list-item {
    .inner {
      .ce-image-list-item {
        background-color: $cool_very_light_gray;
        position: relative;
        padding-top: 66%;
        a {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
        img {
          position: absolute;
          top: 50%;
          left: 50%;
          transform: translate(-50%, -50%);
        }
      }
    }
  }
  .ce-grid .ce-partner-list-item {
    padding-bottom: $mod_card_padding_v;
  }

  .ce-partner-name-list {
    columns: 1;
    list-style: none;
    padding-left: 0;
    margin-bottom: -$mod_space_xs;
    text-align: center;
    li {
      font-weight: $cool_font-semi-bold-weight;
      font-size: $mod_font_size_h4;
      margin-bottom: $mod_space_xs;
    }
  }
  @include media-breakpoint-up(md) {
    .ce-partner-name-list {
      columns: 2;
    }
  }
}

.ce-module.featured-partners {
  @include media-breakpoint-up(lg) {
    background-color: transparent;
  }
}
.ce-module {
  .simple.ce-partner-list-item {
    .inner {
      .ce-image-list-item {
        padding-top: 100%;
      }
    }
  }
  .ce-partner-list-item {
    .inner {
      .description {
        margin-top: $mod_space_sm;
      }
    }
  }
  .featured-partner.ce-partner-list-item {
    .inner {
      .ce-image-list-item {
        //padding-top: 66%;
      }
    }
  }
  .featured-partner.ce-faceted-card {
    $triggerSize: 22px;
    $tiggerOffset: 20px;

    .ce-collapsible-trigger {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      color: $cool_purple;
      text-align: right;
      .indicator {
        position: relative;
        padding-top: calc(66% - 42px);
        margin-bottom: $tiggerOffset;
        margin-right: $tiggerOffset;
        @include media-breakpoint-only(lg) {
          padding-top: calc(100% - 42px);
        }
      }
    }
    .card-facet + .card-facet {
      padding-top: 20px;
      p {
        line-height: 1.1;
      }
      .ce-textblock > *:last-child {
        margin-bottom: 0;
      }
      blockquote,
      .ce-text-featured {
        font-size: $mod_font_size_h4;
        line-height: 1.3;
      }
      blockquote {
        &:before {
          content: open-quote;
        }
        &:after {
          content: close-quote;
        }
      }
    }
    .ce-partner-list-item {
      padding-bottom: 0;
      .inner {
        .ce-image-list-item {
          background-color: $cool_very_light_gray;
          //padding-top: 66%;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      > .inner {
        padding-top: 66%;
        @include media-breakpoint-only(lg) {
          padding-top: 100%;
        }
      }
      .ce-collapsible-trigger {
        bottom: $mod_card_padding_v;
        right: $mod_card_padding_v;
        color: $cool_white;
      }
      .ce-collapsible-trigger.hide {
        color: $cool_purple;
      }
      .card-facet {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
      .card-facet + .card-facet {
        color: $cool_white;
        background-color: $cool_purple;
        padding: $mod_child_padding_v;
        width: 100%;
        height: 100%;
        blockquote,
        .ce-text-featured {
          font-size: $mod_font_size_h4_lg;
        }
        small {
          font-size: 100%;
        }
      }
      .ce-partner-list-item {
        height: 100%;
        .inner {
          height: 100%;
          background-color: $cool_very_light_gray;
          .ce-image-list-item {
            padding-top: 0;
            height: 100%;
          }
        }
      }
    }
  }
  &.partners {
    padding-top: $mod_padding_v_mobile;
    #partners-more {
      @extend .ce-collapsible-target;
      margin-bottom: - $mod_card_padding_v;
    }
    #partners-more-trigger {
      display: none;
      margin-top: $grid-gutter-width;
      width: 100%;
      &.hide {
        display: block;
      }
    }
    @include media-breakpoint-up(lg) {
      padding-top: 0;
      #partners-more {
        display: block !important;
      }
      #partners-more-trigger,
      #partners-more-trigger.hide {
        display: none;
      }
    }
  }
}
