.ce-module,
.ce-modal {
  hr.banner-rule {
    border-width: 2px;
    margin: 0;
    &.bottom {
      margin-top: 10px;
    }
  }
}
