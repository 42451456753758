@import url('https://fonts.googleapis.com/css?family=Cookie&display=swap');
.ce-module .ce-certificate.ce-certificate-origami-gift.cuckoo-combo {
  .content {
    .outer-frame {
      .inner-frame {
        .copy {
          padding-top: 3.1in;
          justify-content: flex-start;
          .recipient-label {
            font-size: 10pt;
            text-transform: uppercase;
          }
          .recipient-name {
            font-size: 30pt;
            font-family: 'Cookie', cursive;
          }
          .quantity-label {
            font-size: 10pt;
            text-transform: uppercase;
            margin-bottom: 5pt;
          }
          .quantity {
            font-size: 22pt;
            font-weight: $cool_font-regular-weight;
            margin-bottom: 8pt;
          }
          .about {
            font-size: 9pt;
            margin-bottom: 12pt;
          }
        }
      }
    }
  }
}
