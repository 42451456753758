/********************************** CONST begin *********************************/

$cardWidth: 600px;
$cardHeight: 460px;
$cardOpenWidth: 840px;
$cardImgHeight: 335px;

$cardWidthDifferenceHalf: ($cardOpenWidth - $cardWidth)/2;

$numberOfCards: 17;

$coverMargin: 40px;
$coverTextWidth: 313px;

$cardLeftColumnWidth: ( ($coverMargin * 2) + $coverTextWidth ) + $cardWidthDifferenceHalf;
$cardRightColumnWidth: $cardOpenWidth - $cardLeftColumnWidth;

$cardCoverBottomContentHeight:125px;

$cardGutter: 40px;
$spaceBetweenCards: 15px;

$mobileHeroHeight: $cardImgHeight;

$cardsStartScrollTime: 0.5s;

$desktop: lg;
$mobile: md;
/********************************** CONST end ***********************************/


.timeline__viewport {
  display:block;
  min-height: 699px;
  @include media-breakpoint-up($desktop) {
    overflow: hidden;
    height: calc( 100vh - 553px );
    background: darkolivegreen no-repeat center url("/app/img/static/outandabout/landscape.jpg");
    background-size: cover;
  }
  @include media-breakpoint-down($mobile) {
    position:relative;
  }
}

.timeline_header {
  text-align: center;
  padding-top: 35px;
  padding-bottom: 35px;
  &,
  h1 {
    color: $cool_white;
  }
  .timeline_header-body :last-child {
    margin-bottom: 0;
  }
}

.timeline_carousel {
  @include media-breakpoint-up($desktop) {
    transition-delay: 0.25s;
    transition-duration: $cardsStartScrollTime;
    transition-property: margin-left;
  }
  @include media-breakpoint-down($mobile) {
    position:relative;
    left:0;
    background-color:white;
  }
}

.timeline_carousel--park {
  @include media-breakpoint-up($desktop) {
    margin-left: 100vw;
  }
}


/********************************** CARDSET begin *********************************/
.timeline__cardSet {
  @include media-breakpoint-up($desktop) {
    display: block;
    width: 1px;
    margin-left: auto;
    margin-right: auto;
    position:relative;
  }
}


.timeline__cardSlider {
  display: flex;
  @include media-breakpoint-up($desktop) {
    margin: 0 0 0 0;

    width: 100%;
    transition-duration: 1s;
    transition-property: margin-left;
  }
  @include media-breakpoint-down($mobile) {
    flex-direction: column;
  }
}

/*********************
 *                   *
 *    POSITIONS      *
 *                   *
 *********************/

@for $i from 0 to $numberOfCards {
  .timeline__cardSlider--pos#{$i} {
    @include media-breakpoint-up($desktop) {
      margin-left: -$cardWidth/2 -$cardWidth * ($i)  -$spaceBetweenCards * (($i*2) + 1);
    }
  }

  .timeline__cardSlider--posOpen#{$i} {
    @include media-breakpoint-up($desktop) {
      margin-left: -$cardWidth/2 -$cardWidth * ($i)  -$spaceBetweenCards * (($i*2) + 1) - ($cardOpenWidth - $cardWidth)/2;
    }
  }

  .timeline__cardSlider--speed#{$i} {
    // Class is still referenced in `outAndAboutCtrl.js`
  }
}

.timeline__cardSlider--pos00 {
  @extend .timeline__cardSlider--pos1;
  @include media-breakpoint-up($desktop) {
    margin-left: -$cardWidth - $spaceBetweenCards;
  }
}

.timeline__card {
  @include media-breakpoint-up($desktop) {
    height: $cardHeight;
    width: $cardWidth;

    transition-property: transform, height, width, filter;
    transition-duration: 1s;
    overflow: hidden;

    margin: 0 $spaceBetweenCards 0 $spaceBetweenCards;
    flex-shrink: 0;
    position: relative;

    cursor: pointer;
    cursor: hand;
  }
}

.splash__card,
.rich__card {
  width: 100%;
  height: 100%;
}

.timeline_header {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @include media-breakpoint-down($mobile) {
    width:100%;
    height:$mobileHeroHeight;
    background: darkolivegreen no-repeat center url("/app/img/static/outandabout/landscape.jpg") ; //all this background stuff shoudl be conflated into one background =D
    background-size: cover;
    background-position-y: 0;
  }

  @include media-breakpoint-down(xs) {
    height: 200px;
  }
}

.rich__card {
  background:white;
  box-shadow: 10px 10px 10px rgba(0, 0, 0, .1);
}

.timeline__card:hover {
  @include media-breakpoint-up($desktop) {
  }
}


.timeline__card--embiggen {
  @include media-breakpoint-up($desktop) {
    width: $cardOpenWidth;
  }
}

.timeline__card--unSelected {
  @include media-breakpoint-up($desktop) {
    transition-duration: 1s;
    filter: grayscale(100%) sepia(50%);
  }
}

.timeline__card--unSelected:hover {
  @include media-breakpoint-up($desktop) {
    filter: grayscale(00%) sepia(00%);
  }
}

.timeline__cardHitButton {
  @include media-breakpoint-up($desktop) {
    position: absolute;
    top: 0;
    left: 0;
    height: $cardHeight;
    width: $cardOpenWidth;
    z-index: 4;
    opacity: 1;
  }
}



/********************************** CARDSET end *********************************/

/********************************** CARDCOVER begin *********************************/

.timeline_cardCover {
  background-position-y: 0;
  @include media-breakpoint-up($desktop) {
    height:$cardHeight;
    width: $cardOpenWidth;
    position: absolute;
    z-index: 3;
    display: flex;
    flex-wrap: wrap;
    transition-duration: 1s;
    transition-property: left, opacity;
    left: -$cardWidthDifferenceHalf;
    opacity: 1;
  }
  @include media-breakpoint-down($mobile) {
    height:100%;
  }
}

.timeline_cardCover--fadeout {
  left: 0;
  opacity: 0;
}


.timeline_cardCoverImage {
  height:$cardImgHeight;
  width:$cardOpenWidth;
  width:100%;
  @include media-breakpoint-down(xs) {
    height: 200px;
  }
}


.timeline_cardCoverLeftContent {
  min-height:$cardCoverBottomContentHeight;
  @include media-breakpoint-up($desktop) {
    padding: 15px 0 0 160px;
    width: $cardLeftColumnWidth;
  }
  @include media-breakpoint-down($mobile) {
    padding: 30px $cardGutter 60px $cardGutter;
  }
}


.timeline_cardCoverLeftContent h1 {
  font-size: 21px;
  color: #432675;
  letter-spacing: 0px;
  line-height: 27px;
  @include media-breakpoint-down($mobile) {
    margin: 0 0 20px 0;
  }
}


.timeline_cardCoverHyphenDate {
  font-size: 16px;
  color: #606060;
  letter-spacing: 0px;
  line-height: 24px;
}

.timeline_cardCoverCopy {
  @include media-breakpoint-up($desktop) {
    display:none;
  }
}

.timeline_cardCoverRightContent {
  @include media-breakpoint-up($desktop) {
    padding: 49px 39px 0 0;
    width: $cardRightColumnWidth;
  }
}

.timeline_cardCoverRightContent button {
  @include media-breakpoint-down($mobile) {
    display:none;
  }
}

/********************************** CARDCOVER end ***********************************/

/********************************** CARDCONTENT begin *********************************/

.timeline_cardContent {
  width: $cardOpenWidth;
  position: relative;
  transition-duration: 1s;
  transition-property: left, opacity;

  left: -$cardWidthDifferenceHalf;
  opacity: .5;
  z-index: 2;
  padding: 80px 140px 0 143px;
}

.timeline_cardContent--fadein {
  opacity: 1;
  left: 0;
}

.timeline_cardContentClose {
  position: absolute;
  top:10px;
  right:10px;
  width: 12px;
  height: 12px;
  background: url('data:image/svg+xml;utf8,
                            <svg width="13px" height="13px" viewBox="1109 252 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <desc>Created with Sketch.</desc>
                                <defs></defs>
                                <g id="cardContentClose" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(1110.000000, 253.000000)">
                                    <path d="M0,0 L10.8019803,10.8019803" id="Path-3" stroke="#432675" stroke-width="2"></path>
                                    <path d="M0,10.8019803 L10.8019803,0" id="Path-3-Copy" stroke="#432675" stroke-width="2"></path>
                                </g>
                            </svg>') no-repeat;

}

.timeline_cardContentClose:hover {
  background: url('data:image/svg+xml;utf8,
                            <svg width="13px" height="13px" viewBox="1109 252 13 13" version="1.1" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink">
                                <desc>Created with Sketch.</desc>
                                <defs></defs>
                                <g id="cardContentClose" stroke="none" stroke-width="1" fill="none" fill-rule="evenodd" transform="translate(1110.000000, 253.000000)">
                                    <path d="M0,0 L10.8019803,10.8019803" id="Path-3" stroke="#FFAA19" stroke-width="2"></path>
                                    <path d="M0,10.8019803 L10.8019803,0" id="Path-3-Copy" stroke="#FFAA19" stroke-width="2"></path>
                                </g>
                            </svg>') no-repeat;
}



.timeline_cardDate {
  font-size: 16px;
  color: #606060;
  letter-spacing: 0px;
  line-height: 34px;
  margin: 0 0 0 0;
}

.timeline__card .collapseTrigger {
  margin-bottom: 0;
  @include media-breakpoint-up($desktop) {
    display: none;
  }
}

.timeline_cardContentTitle {
  font-size: 24px;
  color: #432675;
  letter-spacing: 0px;
  line-height: 32px;
  margin: 0 0 31px 0;
}

.timeline_cardContentCopy {
  font-size: 16px;
  color: #606060;
  letter-spacing: 0px;
  line-height: 24px;
}

/********************************** CARDCONTENT end ***********************************/




/********************************** NAVIGATION begin *********************************/

.timeline__nav {
  width: 50%;
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-flow: row wrap;
  margin: 40px auto 60px auto;
  transition-timing-function: ease-in;
  transition-delay: 0.5s;
  transition-duration: 0.5s;
  transition-property: opacity;
  @include media-breakpoint-down($mobile) {
    display:none;
  }
}

.timeline__nav--park {
  opacity: 0;
}

.timeline__navButtons {
  cursor: pointer;
  width: 20px;
  height: 20px;
  min-width: 20px;
  margin: 0;
  background: url("/app/img/static/outandabout/navButton.svg") no-repeat center;
  transition: background .25s ease-in-out;
  flex: 0 1; // This actually needs to be set as a function of the number of slides on the page.  -ae 04.26.17
}

.timeline__navButtons:hover {
  background: url("/app/img/static/outandabout/navButtonOver.svg") no-repeat center;
}

.timeline__navButtons--selected {
  background: url("/app/img/static/outandabout/navButtonSelected.svg") no-repeat center;
}

.timeline__navButtons--selected:hover {
  background: url("/app/img/static/outandabout/navButtonSelectedOver.svg") no-repeat center;
}

.timeline__line {
  margin: -10px 10px 10px 10px;
  width: 100%;
  border-top: 1px solid white;
}

/********************************** NAVIGATION end *********************************/
