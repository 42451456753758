@font-face {
  font-family: 'ToothNailAlt';
  src: url('/app/fonts/certificate/html_versions/Tooth-and-Nail-Alt.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
.ce-module .ce-certificate.ce-certificate-origami-gift.happy-heart {
  .content {
    .outer-frame {
      .inner-frame {
        .copy {
          padding-top: 1.25in;
          justify-content: flex-start;
          align-items: center;
          .recipient-label {
            font-size: 10pt;
            text-transform: uppercase;
            margin-bottom: -4pt;
          }
          .recipient-name {
            font-size: 39pt;
            font-family: 'ToothNailAlt';
          }
          .quantity-label {
            font-size: 9pt;
            text-transform: uppercase;
            margin-top: 1.61in;
          }
          .quantity {
            font-size: 24pt;
            font-weight: $cool_font-semi-bold-weight;
            margin-bottom: 6pt;
          }
          .about {
            font-size: 9pt;
          }
        }
      }
    }
  }
}
