.vertically-spaced {
  > .container > .row {
    > .counter {
      @include media-breakpoint-up(lg) {
        margin-bottom: 0;
      }
    }
  }
}

.tonnes-retired.ce-module {
  @extend .vertically-spaced;
  .counter,
  .info {
    @extend .col-12;
    @extend .col-lg-6;
  }
  .info {
    > *:last-child {
      margin-bottom: 0;
    }
  }
  .counter-digits {
    justify-content: center;
  }

  @include media-breakpoint-up(lg) {
    .counter-digits {
      justify-content: flex-end;
    }
  }
}
