.ce-card {
  position: relative;
  display: block;
  .ce-card-inner {
    position: relative;
    width: 100%;
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    justify-content: flex-end;
    > * {
      position: relative;
    }
  }
  .card-title {
    margin-bottom: 0;
  }

  figure {
    display: block;
    position: relative;
    padding-top: 66%;
    width: 100%;
    margin-bottom: 20px;
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    a {
      //position: absolute;
      //top: 0;
      //left: 0;
      width: 100%;
      height: 100%;
    }
    .bg-image {
      background-position: center center;
      background-repeat: no-repeat;
      background-size: cover;
      width: 100%;
      height: 100%;
    }
  }
  &.bg-pos-top figure .bg-image {
    background-position-y: top;
  }
  &.bg-pos-bottom figure .bg-image {
    background-position-y: bottom;
  }
  &.bg-pos-left figure .bg-image {
    background-position-x: left;
  }
  &.bg-pos-right figure .bg-image {
    background-position-x: right;
  }

  .ce-card-content {
    display: flex;
    flex-direction: column;
    flex: 1 0 auto;
    min-width: 1px;
    .ce-card-header {
      .pre-title {
        margin-bottom: 0.125rem;
      }
      .byline {
        margin-top: 0.5rem;
      }
      &:last-child,
      & > :last-child {
        margin-bottom: 0;
      }
      & > a:last-child {
        h1, h2, h3, h4, h5, h6 {
          margin-bottom: 0;
        }
      }
    }
    .ce-card-body {
      margin-top: $mod_space_xs;
      flex: 1 0 auto;
      @include media-breakpoint-up( lg ) {
        margin-top: initial;
      }
    }
    .ce-card-body:last-child {
      > *:last-child {
        margin-bottom: 0;
      }
    }
    .ce-card-header,
    .ce-card-footer {
      flex: 0 1 auto;
    }
    .ce-card-footer {
      margin-top: $mod_space_xs;
      @include media-breakpoint-up( lg ) {
        margin-top: initial;
      }
    }
    .ce-card-body + .ce-card-footer {
      margin-top: initial;
    }
  }
  &.layout-horizontal {
    //.ce-card-inner {
    //  .card-title {
    //    font-size: $mod_font_size_h3;
    //  }
    //}
    figure {
      padding-top: 0;
      a {
        .bg-image {
          padding-top: 66%;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      .ce-card-inner {
        flex-direction: row;
        figure {
          width: 40%;
          flex: 1 0 auto;
          margin-bottom: 0;
          margin-right: $mod_space_sm;
          padding-top: 0;
          a {
            .bg-image {
              padding-top: 100%;
            }
          }
        }
        .ce-card-content {
          flex: 0 1 auto;
          padding: $mod_space_xs 0;
          .ce-card-body {
            margin-top: $mod_space_xs;
          }
        }
        //.card-title {
        //  font-size: $mod_font_size_h3_md;
        //}
      }
    }
    //@include media-breakpoint-up(lg) {
    //  .ce-card-inner {
    //    .card-title {
    //      font-size: $mod_font_size_h3_lg;
    //    }
    //  }
    //}
  }
  &.theme-white-light {
    .ce-card-inner {
      background-color: $cool_white;
    }
  }
  &.layout-horizontal.theme-white-light {
    .ce-card-inner {
      padding: $mod_space_sm;
    }
  }
}

.stacked-card {
  @extend .ce-card;
  background-color: transparent;
  figure {
    margin-bottom: 0;
    padding-top: 0;
    .bg-image {
      top: 0;
      padding-top: 50%;
    }
  }
  .ce-card-content {
    padding-top: $mod_space_sm;
  }
  @include media-breakpoint-up(lg) {
    figure {
      .bg-image {
        padding-top: 66%;
      }
    }
    .ce-card-content {
      padding: $grid-gutter-width;
      background-color: $cool_white;
    }
  }
  &.theme-gray-light {
    @include media-breakpoint-up(lg) {
      .ce-card-content {
        background-color: $cool_very_light_gray;
      }
    }
  }
}

.ce-grid > .ce-card {
  padding-bottom: $mod_card_padding_v;
}
