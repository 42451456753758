.ce-module {
  &.donate-banner {
    > .container {
      padding-left: 20px;
      padding-right: 20px;
    }
  }
  &.donate-module {
    background-position: center center;
    &.donate-snowman-module {
      background-position-x: 0;
      background-position-y: 90%;
    }
    .module-header {
      width: 100%;
      max-width: 100%;
      margin-left: 0;
      flex: 1 0 auto;
    }
    .donate-options {
      padding: 0;
      .inner {
        padding: 40px;
        display: flex;
        flex-direction: column;
        justify-content: center;
        align-items: center;
      }
      h6 {
        color: $cool_dark_gray;
        text-transform: uppercase;
        margin-bottom: $mod_space_sm;
      }
      .text-block * {
        color: $color-base;
      }
      .avg-price {
        text-align: center;
        color: $color-base;
        font-size: 13px;
        line-height: 1.2;
      }
      .ce-radio-button-grid-wrapper {
        .ce-radio-button-grid {
          display: block;
          width: 100%;
        }
        .ce-textfield_wrapper {
          margin-top: 0;
        }
        .ce-radio-button_label {
          text-align: center;
        }
        .enter-price {
          display: flex;
          align-items: center;
        }
        .enter-price-label-wrapper {
          display: flex;
          align-items: center;
          justify-content: flex-end;
          .enter-price-label {
            color: $cool_dark_gray;
            font-size: $font-size-root;
            font-weight: $cool_font-bold-weight;
            margin-bottom: 0;
          }
        }
        .enter-price-input-wrapper {
          display: flex;
          align-items: center;
          justify-content: space-between;
          .enter-price-unit {
            color: $cool_dark_gray;
            font-size: $font-size-base;
            margin-bottom: 0;
            margin-right: $grid-gutter-width-sm;
          }
          input {
            font-weight: $cool_font-bold-weight;
            height: 40px;
            padding: 0 $grid-gutter-width-sm;
            width: 100%;
          }
        }
      }
    }
    @include media-breakpoint-up(sm) {
      .donate-options {
        .ce-radio-button-grid-wrapper {
          width: percentage(2/3);
        }
      }
    }
    @include media-breakpoint-up(lg) {
      .ce-banner-content-overlay {
        margin-bottom: 0;
        .module-header {
          display: flex;
          align-items: center;
        }
      }
    }
  }
}
