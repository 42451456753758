// legacy Salesforce
.travel-offset-card {
  @extend .project-preview-card;
  figure {
    height: 255px;
    background-color: $cool_very-light-gray;
    padding: 40px;
    .bg-image {
      background-size: contain;
      padding: 0;
      height: 100%;
    }
  }
  .ce-card-content {
    padding: $mod_space_md 0 0 0;
  }


  .ce-card-footer {
    .cta.price {
      margin-bottom: 0;
      .amount {
        margin-bottom: 15px;
      }
    }
  }

  @include media-breakpoint-down(md) {
    figure {
      height: 200px;
    }
    &:not(.is-catalog-item) {
      figure .bg-image {
        padding-top: 0;
      }
    }
  }
}

.travel-offset-calc {
  text-align: left;

  .ce-icon-calculator {
    font-size: 38px;
    position: absolute;
    top: 4px;
    left: 15px;
  }

  p {
    padding-left: 40px;
    margin-bottom: 0;
  }

  @include media-breakpoint-down(md) {
    p {
      padding-left: 60px;
    }
    .ce-icon-calculator {
      font-size: 56px;
    }
  }
}
