.ce-module.stillin {
  .ce-social-share {
    display: flex;
    justify-content: center;
  }
  .ce-social-share,
  .social-media-links {
    [share="twitter"] {
      background-color: #1DA1F2;
    }
    [share="facebook"] {
      background-color: #3b5998;
    }
    [share] {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 35px;
      height: 35px;
      color: $stillin_color_white;
    }
    [share] + [share] {
      margin-left: 15px;
    }
    [class^="ce-icon-"],
    [class*=" ce-icon-"] {
      font-size: 18px;
    }
    &.checkout {
      margin-top: .5rem;
      margin-bottom: .5rem;
      [share] {
        color: $stillin_color_black;
        background-color: transparent;
      }
      [share] + [share] {
        margin-left: 20px;
      }
      [share]:hover {
        color: $stillin_color_blue_gray;
      }
    }
    &.pos-bottom {
      position: absolute;
      width: 100%;
      left: 0;
      bottom: 20px;
      padding: inherit;
    }
  }

  @include media-breakpoint-up(lg) {
    .ce-social-share {
      justify-content: flex-end;
      [share] + [share] {
        margin-left: 0;
      }
    }
  }
}