// Press Page Intro
.pressIntro {
  margin: 64px 0;
}

// Group Header
.pressGroup-header {
  display: flex;
  justify-content: space-between;
  align-items: baseline;
  //margin-top: 32px;
  //margin-bottom: 24px;
  border-bottom: .1rem solid $cool_light_gray;
}

// Entries
.pressEntry-group {
  margin-bottom: 40px;
}
.pressEntry-group:last-child {
  margin-bottom: 64px;
}

.pressEntry {
  //display: block;
  //position: relative;
  margin-top: 32px;
  @include media-breakpoint-down(md){
    //margin: 0.5rem 0 1rem 0;
  }
}
@include media-breakpoint-up(md) {
  .pressEntry:first-child {
    margin-top: 0px;
  }
}
.pressEntry:last-child .pressEntry-content {
  border-bottom: none;
}

// Featured Style
// .featured .pressEntry-content {
//   border: 1px solid $cool_dark_yellow;
// }

.pressEntry-content {
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-flex-direction: row;
  -ms-flex-direction: row;
  flex-direction: row;
  -webkit-box-align: start;
  -webkit-align-items: flex-start;
  -ms-flex-align: start;
  align-items: flex-start;
  border-bottom: 1px solid $cool_light_gray;
  @include media-breakpoint-down(lg) {
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
  }

  .row {
    width: 100%; // IE bug
  }
}

.pressEntry-logo {
  position: relative;
  //padding: 0 16px;
  width: 33%;
  @include media-breakpoint-down(lg) {
    width: 100%;
    margin-bottom: 16px;
  }
}
.pressEntry-logo img {
  display: block;
  margin: 0 auto;
  max-height: 100px;
  @include media-breakpoint-down(lg) {
    margin: 0;
    max-height: 64px;
  }
  @include media-breakpoint-down(md) {
    margin: 0;
    max-height: 64px;
    max-width: 160px;
  }
}

.pressEntry-copy {
  font-size: 18px;
  color: $cool_dark_gray;
  //padding: 0 0 0 24px;
  width: 66%;
  @include media-breakpoint-down(lg) {
    width: 100%;
  }
}

.pressEntry-cta {
  display: block;
  margin-top: 24px;
  margin-bottom: 32px;
}

.pressEntry-date {
  font-size: 16px;
  font-weight: normal;
  color: $cool_medium_gray;
}

// Article Styling
.pressArticle {
  margin-top: 56px;
  margin-bottom: 64px;
}
.pressArticle .container {
  padding-left: 15px;
  padding-right: 15px;
}

.pressArticle-intro {
  border-bottom: .1rem solid $cool_light_gray;
  @include media-breakpoint-up(lg) {
    padding-bottom: 40px;
  }
  @include media-breakpoint-down(lg) {
    padding-bottom: 32px;
  }
}

.pressArticle-logo {
  margin-bottom: 32px;
}
.pressArticle-logo img {
  max-height: 100px;
  max-width: 200px;
}

.pressArticle-authorDate {
  margin-top: 24px;
}
.pressArticle-authorDate span {
  display: block;
  font-weight: 500;
}

.pressArticle-introLink {
  margin-top: 24px;
  display: block;
}


.pressArticle-copy {
  @include media-breakpoint-up(lg){
    margin-top: 40px;
    margin-bottom: 64px;
  }
  @include media-breakpoint-down(lg){
    margin-top: 32px;
    margin-bottom: 48px;
  }
}

.pressArticle-copy img {
  display: block;
  width: 100%;
  height: auto;
}

.pressArticle-copy img.size-small {
  max-width: 175px;
}

.pressArticle-copy h2 {
  margin-top: 40px;
  margin-bottom: 24px;
}

.pressArticle-copy p {
  margin-bottom: 16px;
}

.pressArticle-copy hr {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}
.pressArticle-copy .arve-wrapper,
.pressArticle-copy img {
  @include media-breakpoint-up(lg){
    margin-top: 48px;
    margin-bottom: 48px;
  }
  @include media-breakpoint-down(lg){
    margin-top: 40px;
    margin-bottom: 40px;
  }
}

.pressArticle-closeLink {
  margin-top: 24px;
  display: block;
  text-align: center;
}

.pressEntry-copy {
  .ce-button {
    font-size: 1rem;
  }
}

