.ce-module.header-nav-module,
.ce-module.header-topbar-module {
  .ce-dropdown-toggle {
    .indicator {
      @extend .ce-icon-chevron-down;
      font-family: 'cooleffect' !important;
      font-weight: normal;
      font-size: 7px;
      margin-left: 10px;
    }
  }
  .ce-dropdown-menu {
    display: block;
    visibility: hidden;
    opacity: 0;
    transition: all 0.3s ease;
    position: absolute;
    margin-top: 0;
    padding: 0 0 10px 0;
    border: 0;
    border-radius: 0;
    background-color: $cool_white;
    color: $cool_dark_gray;
    top: 100%;
    left: 0;
    width: 100%;
    min-width: 0;
    list-style: none;
    box-shadow: 0px 1px 5px 0px rgba(0, 0, 0, 0.1);
    z-index: z-layer('sticky-header');
    .nav-item {
      position: relative;
      justify-content: stretch;
      padding: 0;
      font-size: 14px;
      .item-action {
        padding: 8px 20px;
        width: 100%;
        color: $cool_dark_gray;
        &:hover,
        &:focus,
        &:active {
          color: $cool_dark_gray;
        }
        &:hover:after {
          display: none;
        }
      }
    }
  }
  .ce-dropdown-menu.show {
    visibility: visible;
    opacity: 1;
    transition: all 0s ease;
  }
}