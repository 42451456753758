.ce-module.stillin-promo {
  padding: 15px 0;
  background-color: $stillin_color_light_yellow;
  color: $stillin_color_black;
  .logo-group {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-wrap: wrap;
    .ce-icon-si-tilde {
      color: $stillin_color_red;
      font-size: 10px;
      display: flex;
      justify-content: center;
      width: 100%;
      margin-bottom: 10px;
    }
    .logo {
      font-size: 18px;
    }
    .stillin-logo {
      font-family: $stillin_font_family_display;
      font-weight: $stillin_font_weight_display_extra_bold;
    }
    .separator-logo {
      font-family: $stillin_font_family_display;
      font-weight: $stillin_font_weight_display_medium;
      margin: 0 10px;
    }
    .ce-logo {
      text-transform: uppercase;
      font-weight: $cool_font-semi-bold-weight;
    }
  }
  .tagline {
    font-family: $stillin_font_family_base;
    font-size: 14px;
    text-align: center;
  }
  .col-auto {
    max-width: 100%;
  }

  @include media-breakpoint-up(sm) {
    .logo-group {
      flex-wrap: nowrap;
      .ce-icon-si-tilde {
        width: auto;
        margin-right: 20px;
        margin-bottom: 0;
      }
      .logo {
        font-size: 19px;
      }
    }
  }
}