/*
 * Title: Preview Card
 * Type: Element
 * Dependencies: card.scss, overlay.scss, card-overlay.scss
*/

.ce-card.event-preview-card {
    @include ce-card_overlay(xs);
    .ce-card-inner {
        min-height: 170px;
        @include media-breakpoint-up(lg) {
            height: 300px;
        }
    }

    &:not(.ce-card-flavor) figure {
        .bg-image {
            @include ce-overlay_dark-bottom-gradient( 0, 0.85 );
            position: absolute;
            top: 0;
        }
    }

    .ce-card-content {
        pointer-events: none;
    }
}
