/*
  ** Placard
  * Small card-like component that functions as
  * button navigation for the website
*/

%ce-placard {
	border: 3px solid transparent; // On hover, this will turn a particular color
	transition: all 0.3s ease;
	& > * {
		transition: inherit;
	}
	._inner {
		display: flex;
		flex-wrap: wrap;
	}
	._thumb,
	._header,
	._subheader { // Make sure display is the same for sizing and layout purposes
		display: block;
	}
	._thumb {
		background-color: $cool_teal; // Set default background color in case no image is found
		background-position: center center;
		background-repeat: no-repeat;
		background-size: cover;
	}
	._body {
		padding: ( $mod_space_lg / 2 );
	}
	._header {
		font-size: $font-size-h3;
		margin: 0;
	}
	._header,
	._subheader {
		font-weight: 600;
	}
	._subheader {
		font-size: $font-size-sm;
	}
}

.ce-placard-prev-next {
    @extend %ce-placard;
    
	width: 100%;
	height: 100%;
  
	> a {
		height: 100%;
	}
	&:hover {
		border-color: $cool_purple;
		cursor: pointer;
	}
	._inner {
		flex-direction: row;
		background-color: $cool_white;
		height: 100%;
	}
	._thumb {
		height: auto;
		flex: 1;
		min-height: 120px;
		@include media-breakpoint-down(md) {
			display: none;
		}
	}
	._body {
		padding: $mod_card_content_padding;
		display: flex;
		justify-content: center;
		flex-direction: column;
		width: 100%;
		@include media-breakpoint-up(lg) {
			flex: 2;
		}
	}
	._subheader {
		color: $cool_medium_gray;
	}
	._header {
		color: $cool_purple;
	}
  }