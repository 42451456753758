.ce-module.hero-why-carbon {
  @include media-breakpoint-up(lg) {
    height: 55vw;
    max-height: 70vh;
    display: flex;
    align-items: center;
  }
  @include media-breakpoint-down(sm) {
    padding-top: 55%;
    padding-bottom: 0;
  }
}