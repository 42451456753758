.ce-module {
  .line-on-sides-heading {
    color: $cool_dark_blue;
    display: flex;
    align-items: center;
    justify-content: center;
    text-align: center;
    width: 100%;
    text-transform: uppercase;
    margin-bottom: 0;
  }

  .line-on-sides-heading:before,
  .line-on-sides-heading:after {
    display: block;
    content: '';
    border-top: 2px solid $cool_blue;
    min-width: 60px;
    flex: 1 0 auto;
  }
  .line-on-sides-heading:before {
    margin-right: 15px;
  }
  .line-on-sides-heading:after {
    margin-left: 15px;
  }
  .line-on-sides-quote {
    @extend .line-on-sides-heading;
    .quotation-marks {
      font-size: 38px;
    }
    &.quote-open {
      margin-bottom: 1.75rem;
    }
    &.quote-close {
      margin-top: 1.25rem;
    }
  }
  @include media-breakpoint-up(md) {
    .line-on-sides-heading:before {
      margin-right: 30px;
    }
    .line-on-sides-heading:after {
      margin-left: 30px;
    }
  }
}
