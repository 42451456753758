.ce-module {
  .cart-summary-column {
    display: flex;
    flex-direction: column;
  }
  .cart-summary-sticky {
    &.stuck-top {
      top: 105px;
    }
    &.stuck-align-bottom {

    }
    > :last-child {
      margin-bottom: 0;
      padding-bottom: 0;
    }
    @include media-breakpoint-down( md ) {
      // Force full width to override plugin default
      width: 100% !important;
      // Force summary to remain in place on mobile
      position: relative !important;
      // Prevent sticky re-positioning
      top: unset !important;
      bottom: unset !important
    }
  }
  .cart-summary {
    padding: $mod_space_xl 0 0 0;
    color: $cool_dark_gray;
    h5 {
      color: $cool_dark_gray;
      margin-bottom: 1rem;
    }
    .sub-total {
      border-bottom: 1px solid $cool_gray;
    }
    .sub-total + .sub-total {
      padding-top: $mod_space_sm;
    }
    .total {
      padding-top: $mod_space_sm;
    }
    .item {
      display: flex;
      justify-content: space-between;
      text-transform: initial;
      padding-bottom: $mod_space_sm;
      .name {
        font-weight: $cool_font-semi-bold-weight;
      }
      .details {
        text-transform: capitalize;
      }
    }
    .sub-total .item {
      line-height: 1.1;
    }
    .total .item {
      color: $cool_purple;
      font-weight: $cool_font-semi-bold-weight;
      font-size: $mod_font_size_h5;
      padding-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      padding: 1rem 0 0 0;
      .total .item {
        font-size: $mod_font_size_h5_lg;
      }
    }
  }
}
