.ce-module,
.ce-modal {
  form {
    &:not(.ce-radio-button-grid) {
      .row {
        margin-left: -$grid-gutter-width-sm/2;
        margin-right: -$grid-gutter-width-sm/2;
      }
      .col {
        padding-left: $grid-gutter-width-sm/2;
        padding-right: $grid-gutter-width-sm/2;
        input[type="text"],
        input[type="tel"],
        input[type="email"],
        input[type="password"],
        textarea,
        .braintree-input,
        .awesomplete,
        .awesomplete input {
          width: 100%;
        }
      }
    }
    .fieldset {
      display: flex;
      flex-wrap: wrap;
      width: 100%;
      &.radiogroup {
        flex-direction: column;
        .field + .field {
          margin-top: $mod_space_xs;
        }
      }
      &.radiogroup-inline {
        .field + .field {
          margin-left: $mod_space_lg;
        }
      }
    }
    label {
      font-size: $mod_font_size_small;
      font-weight: $cool_font-semi-bold-weight;
      margin-bottom: 0; // Override bottom margin from reboot.scss
      &.required:after {
        content: '*';
      }
      &.text-label {
        margin-bottom: 4px;
        display: flex;
        align-items: center;
        .tooltip-trigger {
          float: right;
          margin-left: 10px;
          color: $cool_blue;
          font-size: 18px;
        }
      }
    }
    .text-danger {
      margin-top: 4px;
      margin-bottom: 0;
      display: block;
      line-height: 1.2;
      @include media-breakpoint-down(sm) {
        font-size: 11px;
        margin-top: 2px;
      }
    }

    input[type="text"],
    input[type="tel"],
    input[type="email"],
    input[type="number"],
    input[type="password"],
    textarea,
    .input-group .prepend,
    .input-group .append,
    .braintree-input,
    .awesomplete input {
      &::-webkit-contacts-auto-fill-button {
        visibility: hidden;
        display: none !important;
        pointer-events: none;
        position: absolute;
        right: 0;
      }
      border: 2px solid $cool_gray;
      border-radius: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      padding: 7px 10px;
      color: $cool_dark_gray;
      & + .text-danger {
        //visibility: hidden;
      }
      &:focus,
      &:hover {
        outline: none;
        border-color: $cool_purple;
      }
      &.ng-invalid.ng-invalid-required.ng-touched,
      &.ng-invalid.ng-invalid-pattern.ng-touched,
      &.braintree-touched.braintree-invalid,
      &.braintree-hosted-fields-invalid {
        border-color: $cool_danger;
        & + .text-danger {
          visibility: visible;
        }
      }
      &.ng-invalid + .text-danger,
      &.braintree-hosted-fields-invalid + .text-danger {
        visibility: visible;
      }
      &.success {
        border-color: $cool_success;
      }
      &:disabled {
        color: $cool_gray;
        border-color: $cool_light_gray;
        &:hover,
        &:focus {
          border-color: $cool_gray;
        }
      }
    }
    input[type="text"] {
      &:disabled {
        text-overflow: ellipsis;
        white-space: nowrap;
        overflow: hidden;
      }
    }
    input[type="number"].no-stepper {
      -moz-appearance:textfield;
    }
    .ce-select.ng-invalid + .text-danger {
      visibility: visible;
    }
    // checkbox
    input[type="checkbox"].ce-checkbox {
      display: none;
    }
    input[type="checkbox"].ce-checkbox + label {
      display: flex;
      align-items: flex-start;
      position: relative;
      width: 100%;
      font-size: $font-size-base;
      font-weight: $cool_font-semi-bold-weight;
      color: $cool_dark_gray;
      cursor: pointer;
      &:before {
        content: '';
        display: inline-block;
        width: 20px;
        min-width: 20px;
        height: 20px;
        border: 2px solid $cool_gray;
        margin-right: 10px;
      }
      a {
        color: $cool_blue;
        &:hover, &:focus, &:active {
          color: $cool-dark-blue;
        }
      }
      span {
        line-height: 1;
        margin-top: 2px;
      }
    }
    input[type="checkbox"].ce-checkbox:checked + label {
      &:before {
        border-color: $cool_blue;
        background-color: $cool_blue;
      }
      &:after {
        font-family: 'cooleffect' !important;
        font-weight: normal;
        @extend .ce-icon-check:before;
        font-size: 11px;
        color: $cool_white;
        position: absolute;
        width: 20px;
        height: 20px;
        left: 0;
        top: 0;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    input[type="checkbox"].ce-checkbox:disabled + label {
      cursor: not-allowed;
      color: $cool_gray;
      &:before {
        border-color: $cool_gray;
        background-color: transparent;
      }
    }
    input[type="checkbox"].ce-checkbox:checked:disabled + label {
      &:before {
        background-color: $cool_gray;
      }
    }

    // radio
    input[type="radio"].ce-radio {
      display: none;
      align-items: center;
      position: relative;
      width: 100%;
      font-size: $mod_font_size_h4_lg;
      font-weight: $cool_font-semi-bold-weight;
      color: $cool_dark_gray;
      cursor: pointer;
    }
    input[type="radio"].ce-radio + label {
      display: flex;
      align-items: flex-start;
      position: relative;
      width: 100%;
      cursor: pointer;
      font-size: 1rem;

      &:before {
        content: '';
        display: inline-block;
        width: 20px;
        min-width: 20px;
        height: 20px;
        border: 2px solid $cool_gray;
        border-radius: 9999px;
        margin-right: .5rem;
        margin-top: 2px;

        @include media-breakpoint-up( lg ) {
          width: 22px;
          min-width: 22px;
          height: 22px;
          margin-top: 2px;
        }
      }

      &:disabled {

      }
    }

    input[type="radio"].ce-radio:checked + label {
      &:before {
        border-color: $cool_blue;
      }
      &:after {
        background-color: $cool_blue;
        border-radius: 9999px;
        content: '';
        display: block;
        height: 10px;
        top: 7px;
        left: 5px;
        min-width: 10px;
        position: absolute;
        width: 10px;
        @include media-breakpoint-up( lg ) {
          top: 8px;
          left: 6px;
          transform: unset;
        }
      }
    }

    input[type="radio"].ce-radio:disabled {
      + label {
        cursor: not-allowed;
        color: $cool_gray;
      }
      &:checked + label {
        &:before {
          border-color: $cool_gray;
        }
        &:after {
          background-color: $cool_gray;
        }
      }
    }
    // end radio
    .form-actions {
      margin-top: $mod_space_xs;
    }

    &.pw-protected-content {
      input[type=password] {
        max-width: 300px;
      }
      .form-actions {
        margin-top: 1rem;
      }
    }

    // input groups
    .input-group {
      display: flex;
      flex-wrap: nowrap;
      .prepend,
      .append {
        flex: 0 1 auto;
        background-color: $cool_light_gray;
        pointer-events: none;
      }
      .prepend {
        border-right: none;
      }
      .append {
        border-left: none;
      }
      &.disabled {
        .prepend,
        .append {
          border-color: $cool_light_gray;
          color: $cool_gray;
          background-color: $cool_very_light_gray;
        }
      }
    }
    ::placeholder { /* Chrome, Firefox, Opera, Safari 10.1+ */
      color: $cool_gray !important;
      opacity: 1; /* Firefox */
    }
    :-ms-input-placeholder { /* Internet Explorer 10-11 */
      color: $cool_gray !important;
    }
    ::-ms-input-placeholder { /* Microsoft Edge */
      color: $cool_gray !important;
    }
  }
  input[type=number]::-webkit-inner-spin-button,
  input[type=number]::-webkit-outer-spin-button {
    -webkit-appearance: none;
  }
  input[type=number] {
    -moz-appearance:textfield;
  }
  .braintree-input {
    height: 40px;
  }

  input[type=text],
  input[type=email],
  input[type=password] {
    border-radius: 0;
    background-clip: padding-box;
  }
}
