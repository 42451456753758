.ce-module {
  .event-offset-calculator {
    .ce-select {
      min-width: 90px;
    }
    .ce-select .selected,
    .ce-select .item label {
      padding-left: 10px;
    }
    @include media-breakpoint-down(md) {
      .option-box {
        //margin-bottom: 0;
      }
    }
  }
}
.ce-module #event-offset-info-modal {
  h3 {
    display: flex;
    align-items: center;
  }
  .ce-icon-circle {
    width: 40px;
    height: 40px;
    min-width: 40px;
    min-height: 40px;
    border-width: 1px;
    margin-right: 10px;
    color: $cool_dark_blue;
    border-color: $cool_dark_blue;
    .space {
      display: none;
    }
    &.ce-icon-person {
      font-size: 28px;
    }
    &.ce-icon-electricity {
      font-size: 24px;
    }
    &.ce-icon-travel-air {
      font-size: 17px;
    }
  }
  @include media-breakpoint-up(lg) {
    .ce-icon-circle {
      width: 40px;
      height: 40px;
      min-width: 40px;
      min-height: 40px;
      border-size: 1px;
    }
  }
}
