@mixin create_ce-banner-fixed-hero( $height, $height_lg ) {
    @include create_ce-banner_base;
    height: ( $height + 0px );
    width: 100%;

    @include media-breakpoint-up( lg ) {
        height: ( $height_lg + 0px );
    }
}

.ce-banner-fixed-hero {
    @include create_ce-banner-fixed-hero(300, 400);
}