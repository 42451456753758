.social-media-links {
  @extend .row;
  display: flex;
  align-items: flex-end;
  [class^="ce-icon-"],
  [class*=" ce-icon-"]
  {
    color: $cool_purple;

  }
  .ce-icon-facebook {
    font-size: 26px;
  }
  .btn {
    padding: 0 15px;
  }
}