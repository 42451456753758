.progress-spinner {
  $transitionDuration: 250ms;
  opacity: 1;
  visibility: visible;
  transition: opacity $transitionDuration linear, visibility $transitionDuration linear;
  &.inactive {
    opacity: 0;
    visibility: hidden;
    .indicator {
      display: none;
    }
  }
  position: absolute;
  top: 0;
  right: 0;
  bottom: 0;
  left: 0;
  z-index: 1050;
  overflow: hidden;
  background-color: rgba(0,0,0,0.5);
  color: $cool_white;
  display: flex;
  align-items: center;
  justify-content: center;

  .indicator {
    animation: rotate 1.5s linear infinite;
  }
}
