.transactBar {
  bottom: 0;
  width: 100%;
  padding: 24px;
  padding: 16px;
  background: $cool_dark_purple;
  color: rgb(255,255,255);
  z-index: 1001;

  @include media-breakpoint-down(lg) {
    padding: 16px 24px;
  }
}
.transactBar.is-fixed {
  position: fixed;
}
.transactBar h1,
.transactBar h2,
.transactBar h3, {
  color: rgb(255,255,255);
  text-transform: uppercase;
  margin-bottom: 0;
}
.transactBar .transactBar--copy h2 {
  font-size: 20px;
  @include media-breakpoint-up(lg) {
    font-size: 28px;
  }
  @include media-breakpoint-down(sm) {
    font-size: 18px;
  }
  @include media-breakpoint-down(xs) {
    font-size: 16px;
  }
}
.transactBar p {
  margin-bottom: 0;
}

.transactBar--content {
  display: flex;
  align-items: center;
  justify-content: space-between;
  flex-direction: row;
  margin: auto;
  @include media-breakpoint-up(lg) {
    max-width: 720px;
  }
  @include media-breakpoint-down(lg) {
    display: block;
    &:after {
      content: "";
      display: table;
      clear: both;
    }
  }
}

.transactBar--copy {
  float: left;
  @include media-breakpoint-up(lg) {
    width: auto;
  }
}
.transactBar .btn {
  float: right;
  @include media-breakpoint-down(lg) {
    padding: 6px 30px;
  }
}
.transactBar .why-link {
  color: rgba(255,255,255,0.65);
  @include media-breakpoint-down(lg) {
    clear: both;
  }
}