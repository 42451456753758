.ce-module {
  .awesomplete [hidden] {
    display: none;
  }

  .awesomplete .visually-hidden {
    position: absolute;
    clip: rect(0, 0, 0, 0);
  }

  .awesomplete {
    display: inline-block;
    position: relative;
  }

  .awesomplete > input {
    display: block;
  }

  .awesomplete > ul {
    position: absolute;
    left: 0;
    z-index: 1;
    min-width: 100%;
    box-sizing: border-box;
    list-style: none;
    padding: 0;
    margin: 0;
    background: #fff;
  }

  .awesomplete > ul:empty {
    display: none;
  }

  .awesomplete > ul {
    background: white;
    border: 2px solid $cool_gray;
  }

  @supports (transform: scale(0)) {
    .awesomplete > ul {
      transition: .3s cubic-bezier(.4, .2, .5, 1.4);
      transform-origin: 1.43em -.43em;
    }

    .awesomplete > ul[hidden],
    .awesomplete > ul:empty {
      opacity: 0;
      transform: scale(0);
      display: block;
      transition-timing-function: ease;
    }
  }

  .awesomplete > ul > li {
    position: relative;
    padding: .2em .5em;
    cursor: pointer;
  }

  .awesomplete > ul > li:hover {
    color: black;
    font-weight: $cool_font-semi-bold-weight;
  }

  .awesomplete > ul > li[aria-selected="true"] {
    background: $cool_blue;
    color: white;
  }

  .awesomplete mark {
    background: rgba(0, 0, 0, .35);
    color: $cool_white;
  }

  .awesomplete li:hover mark {
  }

  .awesomplete li[aria-selected="true"] mark {
    color: inherit;
  }

  .awesomplete .no-mark + ul mark {
    background: none;
  }
}
