.ce-module {
  &.sections {
    display: block;
  }
  .transaction-row-sticky {
    @include media-breakpoint-up(lg) {
      display: flex;
    }
  }
  .ce-textwrap_image.ce-img_country {
    display: none;
    @include media-breakpoint-up(lg) {
      display: block;
    }
  }
  .project-update-preview-grid {
    @include media-breakpoint-down(md) {
      .ce-card:nth-child(n+4) {
        display: none;
      }
    }
  }
  &.project-donate-footer-wrapper {
    height: 70px;
    @include media-breakpoint-up(lg) {
      display: none;
    }
    .project-donate-footer {
      height: 70px;
      width: 100%;
      > button {
        height: 70px;
      }
    }
    opacity: 1;
    visibility: visible;
    transition: all 0.4s ease;
  }
  .ce-source-list {
    li:last-of-type {
      margin-bottom: 0;
    }
  }
  .ce-section_article {
    padding-bottom: $mod_card_padding_v;
    &:last-of-type {
      padding-bottom: 0;
    }
    @include media-breakpoint-up( lg) {
      padding-bottom: $mod_child_padding_v;
      *:last-child:not(li:last-child) {
        margin-bottom: 0;
      }
    }
    .ce-adaptive-carousel_project-updates {
      padding: 0;
      margin-bottom: 0;
      .carousel-gradient-overlay {
        background: transparent;
      }
    }

    .section-content > * {
      margin-bottom: 1rem;
      &:last-child {
        margin-bottom: 0;
      }
    }
    .section-content {
      .ce-image-list {
        margin-bottom: $mod_space_lg - $grid-gutter-width/2;
      }
      .text-block {
        img {
          margin-bottom: 1rem;
        }
        > *:last-child {
          margin-bottom: 0;
        }
      }
      ul:not(.carousel-indicators) li,
      ol:not(.carousel-indicators) li {
        margin-bottom: 1rem;
      }
      .ce-textwrap > *:last-child {
        margin-bottom: 0;
      }
      .text-column {
        ul:first-of-type {
          margin-bottom: 0px;
        }
      }
    }
    h1, h2, h3, h4, h5, h6 {
      color: $cool_dark_gray;
    }
    .project-drawdown {
      margin-bottom: 2rem;
    }
  }
}

.ce-global-nav-expanded {
  .ce-module.project-donate-footer-wrapper {
    opacity: 0;
    visibility: hidden;
    transition: all 0.4s ease;
  }
}
