.sectionHeader {
  text-align: center;
  margin-bottom: 64px;
  @include media-breakpoint-down(lg) {
    margin-bottom: 40px;
  }
}
.sectionHeader h1,
.sectionHeader h2 {
  @include media-breakpoint-up(lg) {
    line-height: 37px;
  }
  margin-bottom: 8px;
}