.ce-module {
  .input-group {
    &.combo-box {
      .append {
        pointer-events: auto;
        display: flex;
        align-items: center;
        justify-content: center;
        width: 33px;

        .indicator {
          display: flex;
          align-items: center;
          justify-content: center;

          .ce-icon-chevron-down {
            font-size: 6px;
          }
        }

        &:hover {
          background-color: $cool_gray;
          border-color: $cool_gray;
        }
      }

      .awesomplete > ul {
        width: calc(100% + 32px)
      }
    }
  }
}
