/**
** Adaptive Carousel `.ce-adaptive-carousel`
** Functions as the base for all adaptive carousels
** that can be extended by individual classes
**/
@mixin make-carousel-columns($breakpoints: $grid-breakpoints) {
    @each $breakpoint in map-keys($breakpoints) {
        @for $i from 1 through 4 {
            $col: 12/$i;
            &.#{$breakpoint}-#{$i} .carousel-item {
                @include media-breakpoint-up($breakpoint, $breakpoints) {
                    @include make-col($col);
                }
            }
            &.adapt-at-#{$breakpoint} {
                @include media-breakpoint-up($breakpoint, $breakpoints) {
                    margin-bottom: -$grid-gutter-width;
                    .carousel-inner-wrapper {
                        border: none;
                        padding: 0;
                    }
                    .carousel-item {
                        display: block !important;
                        padding-bottom: $mod_card_padding_v;
                        margin-right: 0;
                    }
                    .ce-mobile-carousel-controls {
                        display: none;
                    }
                }
            }
        }
    }
}

.ce-adaptive-carousel {
    .ce-carousel-gradient::after {
        background: linear-gradient(transparent, rgba(0, 0, 0, .85));
        @include media-breakpoint-down( md ) {
            .ce-carousel-gradient::after {
                background: none;
            }
        }
    }
    .carousel-inner-wrapper {
        width: 100%;
        border: 5px solid $cool_light_gray;
        background-color: $cool_white;
        padding: $mod_card_content_padding;
    }
    .carousel-inner {
        width: auto;
    }

    .carousel-indicators {
        bottom: 0;
        margin-bottom: 1rem;
        > *,
        > .active {
            @include media-breakpoint-up( lg ) {
                margin: 3px 3px 0;
            }
        }
    }

    .carousel-item {
        padding-right: ($grid-gutter-width / 2);
        padding-left: ($grid-gutter-width / 2);
    }

    @include make-carousel-columns($grid-breakpoints);
}
