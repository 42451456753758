.ce-module.sm-container {
  position: relative;
  //flex: 1 0 auto;
  min-height: calc(100vh - #{$header-height-desktop});
  //background-color: red;
  overflow: hidden;
  .fullpage-sm-scene {
    padding: 0;
    position: relative;
    width: 100%;
    min-height: calc(100vh - #{$header-height-desktop});
    color: red;
    font-size: 72px;
    border: 2px solid;
    display: flex;
    flex-direction: column;
    .trigger-start,
    .trigger-end {
      //position: absolute;
      width: 100%;
    }
    .fullpage-sm-scene-content {
      flex: 1 0 auto;
    }
    //.trigger-start {
    //  top: 0;
    //}
    //.trigger-end {
    //  bottom: 0;
    //}
  }
  #slide-1 {
    background-color: gray;
    border-color: red;
  }
  #slide-2 {
    background-color: lightblue;
    border-color: green;
  }
  #slide-3 {
    background-color: lightgreen;
    border-color: blue;
  }
}