.ce-module {
  .carousel-control-next,
  .carousel-control-prev {
    &:hover,
    &:focus {
      opacity: 1;
    }
  }
  a.carousel-control-next,
  a.carousel-control-prev {
    color: $cool_white;
    transition: opacity 0.15s ease;

    &:hover {
      color: $cool_white;
    }
  } 
}