.ce-module.stillin {
  .ce-card.checkout-card {
    form#paymentInfo {
      .radio-tabs.payment-methods {
        input[type="radio"] + label {
          font-size: 16px;
        }
      }
      label {
        .ce-icon-question-outline {
          color: $stillin_color_dark_blue;
          &:hover {
            color: $cool_dark_blue;
          }
        }
      }
    }
  }
}