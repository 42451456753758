.gift-grid { 
	.ce-card.preview-card {
		.ce-card-inner {
			min-height: 300px;
			@include media-breakpoint-up(md) {
				min-height: 470px !important;
			}
		}

		figure {
		 	@include media-breakpoint-down(md) {
				display: block;
			    height: 100%;
			    padding-top: 0;
			    position: absolute;
			    top: 0;
			    width: 100%;
			}  

			.bg-image {
				@include ce-overlay_base();
				&:after {
					background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
					background-color: rgba(255, 255, 255, 0);
				}
			}
		}

		p {
			color: #fff;
		}

		h4 {
			font-size: $mod_font_size_h3;
			color: #fff;
			@include media-breakpoint-up(md) {
				font-size: $mod_font_size_h3_md;
			}
			@include media-breakpoint-up(lg) {
				font-size: $mod_font_size_h3_lg;
			}
		}

		.ce-card-content {
			flex: 0 1 auto;
			border-top: none;
			pointer-events: none;
			cursor: pointer;
			padding: $mod_space_xl $mod_space_sm;
			@include media-breakpoint-up(lg) {
				padding: $mod_card_content_padding_featured;
			}
			
		}

		.ce-card-footer {
			.btn {
				pointer-events: all;
			}
		}
	}
}