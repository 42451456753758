@font-face {
  font-family: 'quiet-nights';
  src: url('/app/fonts/certificate/html_versions/Quiet-Nights.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@font-face {
  font-family: 'ToothNailAlt';
  src: url('/app/fonts/certificate/html_versions/Tooth-and-Nail-Alt.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
@import url('https://fonts.googleapis.com/css?family=Montserrat:900&display=swap');
.ce-module .ce-certificate.ce-certificate-origami-gift.big-fan {
  .content {
    .outer-frame {
      .inner-frame {
        .copy {
          padding-top: 3.2in;
          justify-content: flex-start;
          .recipient-label {
            font-size: 16pt;
            margin-left: 11px;
            text-transform: uppercase;
            span {
              border-bottom: 1px solid black;
              padding-bottom: 2pt;
            }
          }
          .recipient-name {
            font-size: 42pt;
            font-family: 'quiet-nights', 'ToothNailAlt';
          }
          .quantity-label {
            font-size: 13pt;
            text-transform: uppercase;
            margin-bottom: 6pt;
            span {
              border-top: 1px solid black;
              padding-top: 2pt;
            }
          }
          .quantity {
            font-size: 24pt;
            font-family: 'Montserrat', sans-serif;
            font-weight: 900;
            margin-bottom: 15pt;
            span {
              background-color: black;
              color: $cool_white;
              padding: 2pt 40pt;
              -webkit-print-color-adjust: exact;
            }
          }
          .about {
            font-size: 9pt;
          }
        }
      }
    }
  }
}
