.ce-module .ce-purchase-inline {
  .purchase-sections {
    #paymentInfo {
      padding-bottom: 0;
      .checkout-section {
        &:last-of-type {
          padding-bottom: 0;
        }
      }
    }
    .checkout-section {
      padding-bottom: $mod_card_content_padding_featured;
      .section-header {
        margin-bottom: $mod_space_xs;
      }
      .checkout-actions {
        margin-top: $mod_space_sm;
      }
      &:last-of-type {
        padding-bottom: 0;
      }
    }
  }
}

.donate-banner-container {
  display: block;
  padding: 0;
  .main-bg-image {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
  }
  .donate-col {
    padding-bottom: $grid-gutter-width;
    .donate-box {
      background-color: $cool_white;
      padding: $mod_card_content_padding $mod_space_md;
      border: 2px solid $cool_dark_blue;
      border-radius: 8px;
      .donation-heading {
        margin-bottom: $mod_space_md;
      }
    }
  }
  .donation-heading {
    color: $cool_dark_blue;
    &:after {
      display: block;
      content: '';
      width: 100px;
      height: 8px;
      background: linear-gradient( to right, $cool_red 0, $cool_blue 100% );
      border-radius: 9999px;
      margin: $mod_space_lg auto 0 auto;
    }
  }
  @include media-breakpoint-up(md) {
    .donate-col {
      .donate-box {
        padding: $mod_card_content_padding_featured;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .main-bg-image {
      height: 500px;
    }
    .donate-col {
      padding-bottom: 0;
    }
    .info-col {
      margin-top: 500px - $mod_padding_v + $grid-gutter-width;
    }
  }
}
