.ce-module {
	.ce-grid.cruise-offset {
		form .row.inputs .type {
			justify-content: center;

			& > label.text-label { width: 180px; }
		}
	}
	.ce-grid.calculator-new {
		form .row.inputs .text-label,
		form .row.inputs .type,
		form .row.inputs .travelers {
			@media(max-width:767px) {
				justify-content: center;
			}
		}

		.inputs input[type="number"] { width: 100px; }

		.ce-modal.ce-cart {
			background-color: rgba(0,0,0,.5);
		}
		.ce-modal.ce-cart.show {
			display: block;
		}
		.ce-modal.ce-error.show {
			background-color: rgba(0,0,0,.5);
			display: block;
		}
		.ce-modal.ce-error.show h2 {
			color: red;
		}
	}
}
