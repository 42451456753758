.ce-module.stillin,
.ce-modal.stillin {
  .ce-button {
    border-radius: 9999px;
    font-family: $stillin_font_family_display;
    font-weight: $stillin_font_weight_display_bold;
    font-size: 14px;
  }
  .ce-button-primary {
    padding: 13px 30px;
  }

  .ce-button-secondary,
  .ce-button-secondary-alt {
    padding: 11px 30px;
  }

  .ce-button-primary {
    background: linear-gradient(to right, $stillin_color_blue 0%, $stillin_color_dark_blue 100%);
    &:after {
      background: linear-gradient( to right, transparent 0%, $stillin_color_blue_gray 50% );
      bottom: 0;
      width: 200%;
      height: 100%;
      left: 100%;
      transition: left 0.75s ease;
    }
    &:hover:after {
      bottom: 0;
      left: -100%;
    }
  }
  .ce-button-secondary {
    border-color: $stillin_color_dark_blue;
    color: $stillin_color_dark_blue;

    &:hover {
      color: $stillin_color_blue_gray;
      border-color: $stillin_color_blue_gray;
    }
  }
  .ce-button-tertiary {
    color: $stillin_color_dark_blue;
    padding: 0;
    &:hover {
      color: $stillin_color_blue_gray;
    }
  }
  .grid-show-more {
    .indicator {
      border-color: $stillin_color_dark_blue;
      color: $stillin_color_dark_blue;
    }
    &:hover {
      .indicator {
        border-color: $stillin_color_blue_gray;
        color: $stillin_color_blue_gray;
      }
    }
  }
}