.halves-carousel .carousel-content {
  @include media-breakpoint-up(md) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
}
// Carousel Controls
.carousel-ctrl {
  border: 1px solid $cool_yellow;
  border-radius: 9999px;
  color: $cool_yellow;
  padding: 0px 18px;
  position: relative;
  transition: all .125s ease;
  -webkit-transition: all .125s ease;

  display: flex;
  align-items: center;
  justify-content: center;
  width: 50px;
  height: 50px;

  .ce-icon-chevron-right {
    font-size: 18px;
  }
  &.left {
    .ce-icon-chevron-right {
      transform: scaleX(-1);
    }
  }

  //Commented out until swipe resolved -ae 11.22.16
  @include media-breakpoint-down(sm) {
    display: none;
  }
}
// Dated Mobile Controls
.carousel-ctrl--mobileContainer {
  position: relative;
  @include media-breakpoint-up(md) {
    display: none;
  }
}
.carousel-ctrl.is-mobile {
  @extend .carousel-ctrl;
  z-index: 98;
  @include media-breakpoint-up(sm) {
    display: none;
  }
  @include media-breakpoint-down(sm) {
    display: flex;
  }
}
.left.carousel-ctrl.is-mobile {
  position: absolute;
  top: -15px;
  left: 15px;
}
.right.carousel-ctrl.is-mobile {
  position: absolute;
  top: -15px;
  right: 15px;
}
.carousel-ctrl:hover{
  background-color: $cool_yellow;
  color: rgb(255,255,255);
}
.left.carousel-ctrl {
  float: left;
}
.right.carousel-ctrl {
  float: right;
}

// Carousel Content
.halves-carousel .carousel-inner {
  width: 80%;
  margin: auto;
  @include media-breakpoint-down(sm) {
    width: 100%;
  }
}
.halves-carousel .halves {
  @include media-breakpoint-up(lg){
    max-height: 400px;
  }
  @include media-breakpoint-up(xl){
    max-height: 300px;
  }
}
.halves-carousel .halves .image-half {
  @include media-breakpoint-up(xl){
    max-height: 300px;
  }
  @include media-breakpoint-down(lg){
    max-height: 400px;
  }
}
.halves-carousel .halves .text-half {
  padding: 30px;
}
// Carousel Indicators
.halves-carousel .carousel-indicators {
  position: relative;
  margin: 20px auto;
  bottom: 0;
  left: 0;
  width: auto;
}
.halves-carousel .carousel-indicators li {
  border: 1px solid $cool_light_gray;
}
.halves-carousel .carousel-indicators .active {
  background-color: $cool_yellow;
  border: 1px solid $cool_yellow;
}