.ce-module.one-percent-info {
  .circle-col,
  .copy-col {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  .circle-col {
    flex: 0 1 auto;
    width: 100%;
  }
  .ce-icon-circle {
    overflow: hidden;
    width: 150px;
    height: 150px;
    border-color: $cool_dark_blue;
    img {
      width: auto;
      height: 55%;
    }
  }
  .copy {
    font-size: 18px;
    font-weight: $cool_font-semi-bold-weight;
    color: $cool_dark_blue;
    max-width: 100%;
  }

  @include media-breakpoint-up(lg) {
    .circle-col {
      width: auto;
    }
    .ce-icon-circle {
      width: 200px;
      height: 200px;
    }
  }
}
