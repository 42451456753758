.ce-module .ce-goal-tracker {
  @extend .row;
  $tickSize: 24px;
  $progressSize: 10px;
  $progressBgColor: $cool_gray;
  $goalWidth: 160px;
  position: relative;

  flex-direction: column;

  .details,
  .summary {
    @extend .col;
    flex: 1 0 auto;
  }
  .details {
    display: flex;
    justify-content: center;
    height: 430px;
  }
  .summary {
    text-align: center;
    color: $cool_blue;
    margin-bottom: 30px;
    img {
      margin-bottom: .5rem;
    }
    .amt,
    .desc {
      font-weight: $cool_font-semi-bold-weight;
    }
    .amt {
      font-size: 50px;
      line-height: 1;
      margin-bottom: 0;
    }
    .desc {
      &:last-child {
        margin-bottom: 0;
      }
    }
  }

  // TO DO: create function to generate 2-8?
  &.goals-4 {
    $numGoals: 4;
    // bug with interpolation, so weird math
    $goalSize: percentage(100/($numGoals+1)/100);
    .goal-progress-wrapper {
      margin-left: ($tickSize - $progressSize)/2;
      margin-bottom: $tickSize/2;
    }
    .goals .goal {
      height: $goalSize;
      &:nth-child(1) {
        height: $tickSize;
      }
    }
  }

  .goal-progress-wrapper {
    position: relative;
    display: flex;
    width: $progressSize;
    align-self: stretch;
    margin-top: $tickSize/2;
    .goal-progress-bg {
      position: relative;
      width: 100%;
      //height: 100%;
      background-color: $progressBgColor;
      border-radius: 9999px;
    }
    .goal-progress {
      position: absolute;
      top: auto;
      bottom: 0;
      left: 0;
      width: 100%;
      height: auto;
      border-radius: 9999px;
      background: linear-gradient( to top, $cool_red 0, $cool_blue 100% );
    }
  }
  .goals {
    display: flex;
    flex-direction: column-reverse;
    justify-content: space-between;
    position: relative;
    margin-left: -($tickSize + $progressSize)/2;
    height: auto;
    .goal {
      display: flex;
      flex-direction: row;
      align-items: flex-start;
      .tick {
        display: flex;
        flex-direction: row;
        align-items: center;
        .circle {
          width: $tickSize;
          height: $tickSize;
          border-radius: 9999px;
          background-color: $progressBgColor;
          position: relative;
          .check {
            display: none;
          }
        }
        .line {
          width: 60px;
          height: 2px;
          background-color: $progressBgColor;
        }
      }
      .label {
        text-align: left;
        max-width: 160px;
        padding-left: .5rem;
        .goal-title,
        .goal-body {
          color: $progressBgColor;
          font-weight: $cool_font-semi-bold-weight;
        }
        .goal-title {
          margin-bottom: 0;
        }
        .goal-body {
          font-size: $font-size-sm;
          > *:last-child {
            margin-bottom: 0;
          }
        }
      }
      &:first-child {
        .tick .circle {
          background-color: $cool_red;
        }
      }
      &.met {
        .tick .circle {
          background-color: $cool_dark_gray;
          display: flex;
          align-items: center;
          justify-content: center;
          .check {
            display: block;
            color: $cool_white;
            font-size: 9px;
          }
        }
        .goal-title {
          color: $cool_blue;
        }
        .goal-body {
          color: $cool_medium_gray;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    flex-direction: row;
    .details {
      display: block;
      height: auto;
      max-width: 80%;
      flex: 1 0;
      order: 0;
    }
    .summary {
      flex: 0 1 auto;
      width: auto;
      order: 1;
      margin-top: -120px;
      margin-bottom: 0;
    }
    .goal-progress-wrapper {
      width: auto;
      height: $progressSize;
      margin-top: ($tickSize - $progressSize)/2;
      .goal-progress {
        left: 0;
        width: auto;
        height: 100%;
        background: linear-gradient( to right, $cool_red 0, $cool_blue 100% );
      }
    }
    .goals {
      flex-direction: row;
      margin-left: 0;
      margin-top: -($tickSize + $progressSize)/2;
      height: auto;
      .goal {
        flex-direction: column;
        align-items: center;
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
        .tick {
          flex-direction: column;
          .line {
            width: 2px;
            height: 20px;
            background-color: $progressBgColor;
          }
        }
        .label {
          max-width: 100%;
          padding-top: .5rem;
          padding-left: 0;
          text-align: center;
        }
      }
    }
    &.goals-4 {
      $numGoals: 4;
      // bug with interpolation, so weird math
      $goalSize: percentage(100/($numGoals+1)/100);
      .goal-progress-wrapper {
        margin-left: $goalSize/2;
        margin-right: $goalSize/2;
        margin-bottom: 0;
      }
      .goals .goal {
        width: $goalSize;
        height: auto;
      }
    }
  }
}