.ce-module {
  .travel-offset-gift-calculator {
    .tonnes-calculated {
      margin-bottom: $mod_space_sm;
    }
    .radiogroup {
      margin-bottom: $mod_space_sm;
    }
    .col-purchase {
      .purchase {
        height: 100%;
        padding: $mod_space_lg;
      }
    }
    .gift-option {
      label {
        text-align: left;
        span {
          font-weight: $cool_font-regular-weight;
        }
      }
      margin-bottom: $mod_space_sm;
    }
    @include media-breakpoint-up(lg) {
      .col-purchase {
        .purchase {
          .per {
            margin-bottom: 4rem;
          }
        }
      }
    }
  }
}
