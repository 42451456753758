// For typographic concerns beyond the scope of Bootstrap
sup, sub {
  font-size: 50%;
  vertical-align: baseline;
  position: relative;
  top: -0.4em;
}
sub {
  top: 0em; // This seems like a bad call, but it was requested by design -ae 03.28.17
}

// Custom <hr> styles to support TOR design requests
hr.hr-centerWhite {
  border: 1px solid rgb(255,255,255);
  border-radius: 15px;
  width: 50px;
}
hr.hr-centerPurple {
  @extend hr.hr-centerWhite;
  border: 1px solid $cool_purple;
}
hr.hr-inlineWhite {
  @extend hr.hr-centerWhite;
  @include media-breakpoint-up(xl) {
    margin: 1rem 0;
  }
}
hr.hr-inlinePurple {
  @extend hr.hr-inlineWhite;
  border: 1px solid $cool_purple;
}
