//* Utility *//
@mixin ce_center_element ($axis: "absolute") {
  @if $axis == "absolute" or $axis == "vertical" or $axis == "horizontal" {
    position: absolute;
  
    @if $axis == "absolute" or $axis == "vertical" {
      top: 50%;
    }
    @if $axis == "absolute" or $axis == "horizontal" {
      left: 50%;
    }
    @if $axis == "vertical" {
      transform: translateY(-50%);
    }
    @if $axis == "horizontal" {
      transform: translateX(-50%);
    }
    @if $axis == "absolute" {
      transform: translate(-50%, -50%);
    }
  }
}

/* Timeline Slider */
.ce-timeline-slider {
  height: 30vw !important;
  min-height: 300px;
  width: 100%;
}

/* Timeline Slider - Slide */
.ce-timeline-slider-slide {
  position: relative;

  &:after {
    background: linear-gradient(transparent 0%, transparent 20%, rgba(0, 0, 0, 0.5) 55%, rgba(0, 0, 0, 0.65) 75%, rgba(0, 0, 0, 0.8) 100%);
    position: absolute;
    content:" ";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: z-layer( "content" );
  }
  @include media-breakpoint-up(sm) {
    background: linear-gradient(transparent 0%, rgba(0, 0, 0, 0.3) 55%, rgba(0, 0, 0, 0.5) 70%, rgba(0, 0, 0, 0.6) 85%, rgba(0, 0, 0, 0.8) 100%);
  }
}

/* Timeline Slider - Slide (Content) */
.ce-timeline-slider-slide-content {
  bottom: 60px;
  display: block;
  width: calc( 100% - 80px );
  z-index: z-layer( "content-upper" );
  @include ce_center_element("horizontal");
  
  @include media-breakpoint-up( md ) {
    width: 77.5%;
  }
  @include media-breakpoint-up( lg ) {
    width: 50%;
  }
  h1, h2, p, .preheading {
    color: $cool_white;
    display: block;
    line-height: 1;
    margin: 0 0 $mod_space_xs;
    padding: 0;
    &:last-child {
      margin-bottom: 0;
    }
  }
  p {
    display: none;
  }
  .preheading {
    font-weight: $cool_font-semi-bold-weight;
  }
  @include media-breakpoint-up(sm) {
    bottom: 80px;
    p {
      display: block;
    }
  }
}

/* Timeline Slider - Navigation */
.ce-timeline-slider .slick-dots {
  bottom: 0;
  display: flex;
  justify-content: space-between;
  list-style: none;
  margin: $mod_space_sm 0 $mod_space_md;
  padding: 0;
  width: calc( 100% - 80px );
  z-index: z-layer( "content-upper" );
  @include ce_center_element("horizontal");
  
  @include media-breakpoint-up( md ) {
    width: 75%;
  }
  @include media-breakpoint-up( lg ) {
    width: 50%;
  }
  &:after {
    content: "";
    background: $cool_white;
    display: block;
    height: 1px;
    width: 100%;
    @include ce_center_element();
  }
}

.ce-timeline-slider .slick-dots li {
  border: 1px solid transparent;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  height: 20px;
  position: relative;
  transition: all 0.3s ease;
  width: 20px;
  z-index: 2;
  
  &:first-child {
    margin-left: (-1 * $mod_space_xs);
  }
  &:last-child {
    margin-right: (-1 * $mod_space_xs);
  }
  &.slick-active,
  &.slick-active:hover {
    border-color: $cool_yellow;
  }
  &:hover {
    border-color: $cool_white;
  }
}

.ce-timeline-slider .slick-dots li button {
  background: $cool_white;
  border: 0;
  border-radius: 100%;
  cursor: pointer;
  display: block;
  font-size: 0;
  height: 7px;
  line-height: 0;
  outline: none;
  padding: 0;
  width: 7px;
  @include ce_center_element();
}