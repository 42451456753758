.ce-module.stillin {
  $mod_stillin_padding_v:                 60px;
  $mod_stillin_padding_v_short:           $mod_stillin_padding_v/2;
  $mod_stillin_padding_v_mobile:          $mod_stillin_padding_v/2;
  $mod_stillin_padding_v_mobile_short:    $mod_stillin_padding_v/2;
  &.vertically-padded {
    padding-top: $mod_stillin_padding_v;
    padding-bottom: $mod_stillin_padding_v;
    @include media-breakpoint-down(sm) {
      padding-top: $mod_stillin_padding_v_mobile;
      padding-bottom: $mod_stillin_padding_v_mobile;
    }
  }

  &.vertically-padded-short {
    padding-top: $mod_stillin_padding_v_short;
    padding-bottom: $mod_stillin_padding_v_short;

    @include media-breakpoint-down(sm) {
      padding-top: $mod_stillin_padding_v_mobile_short;
      padding-bottom: $mod_stillin_padding_v_mobile_short;
    }
  }

  &.vertically-spaced {
    @extend .stillin.vertically-padded;
  }

  &.vertically-spaced-short {
    @extend .stillin.vertically-padded-short;
    &.ce-banner-container > .container,
    > .container > .row {
      > * {
        margin-bottom: $mod_child_padding_v;
      }
      > *:last-child {
        margin-bottom: 0;
      }
    }
    @include media-breakpoint-down(sm) {
      > .container > .row {
        > * {
          margin-bottom: $mod_stillin_padding_v_mobile_short;
        }
      }
    }
  }
}