.ce-module {
  .preview {
    height: auto;
    @extend .col-12;
    padding-top: 0;
    padding-bottom: 0;
    .bg-image {
      position: relative;
      top: 0;
      width: 100%;
      height: 200px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    .text {
      padding: 0;
      margin-top: 20px;
      background-color: transparent;
      height: auto;
      @extend .col-12;
      @extend .col-lg-4;
      @extend .offset-lg-7;
    }
    .preview-pre-title {
      @extend .pre-title;
    }
    .preview-body {
      *:last-child {
        margin-bottom: 0;
      }
    }
    .preview-action {
      text-transform: uppercase;
      margin-top: 1rem;
    }

    @include media-breakpoint-up(lg) {
      padding-top: $mod_space_xxl + $mod_space_sm;
      padding-bottom: $mod_space_xxl + $mod_space_sm;

      .text {
        margin-top: 0;
        padding: $mod_padding_v_mobile;
        height: 100%;
        background-color: $cool_white;
      }

      .bg-image {
        position: absolute;
        top: 0;
        width: calc(100% - 30px);
        height: 100%;
      }
    }
  }
  &.ce-preview-banner {
    .bg-image {
      position: relative;
      top: 0;
      width: 100%;
      height: 200px;
      background-size: cover;
      background-repeat: no-repeat;
      background-position: center center;
    }
    &.bg-pos-top > .bg-image {
      background-position-y: top;
    }
    &.bg-pos-bottom > .bg-image {
      background-position-y: bottom;
    }
    &.bg-pos-left > .bg-image {
      background-position-x: left;
    }
    &.bg-pos-right > .bg-image {
      background-position-x: right;
    }
    @include media-breakpoint-up(lg) {
      > .bg-image {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
      }
    }
  }
}

