.ce-card.ce-blurb-card {
  .ce-card-inner {
    min-height: 230px !important;
    @include media-breakpoint-up(lg) {
      min-height: 300px !important;
    }
  }

  figure {
    display: block;
    height: calc(100% - 30px);
    padding-top: 0;
    position: absolute;
    top: 0;
    width: calc(100% - 30px);
    .bg-image {
      position: absolute;
      top: 0;
      &:after {
        background: transparent;
        content: "";
        display: block;
        height: 100%;
        transition: background-color 0.3s ease;
      }
    }
  }
  .ce-card-content {
    color: $cool_white;
    border: none;
    height: 100%;
    justify-content: flex-end;
    padding: $mod_space_lg;
  }
  .ce-card-header {
    margin-bottom: 0;
    transition: all 0.4s ease;
    .card-title {
      color: $cool_white;
      font-size: $mod_font_size_h4_md;
    }
  }
  .ce-card-body {
    overflow: hidden;
    max-height: 0;
    transition: all 0.3s ease;
  }
  .ce-card-footer {
    height: 100%;
    left: 0;
    margin-top: 0 !important;
    position: absolute;
    top: 0;
    width: 100%;
    a {
      height: 100%;
      display: block;
      width: 100%;
      color: transparent;
    }
  }
  .ce-card-inner:hover {
    figure {
      .bg-image {
        @include ce-overlay_dark( 0.35 );
      }
    }
    @include media-breakpoint-up(lg) {
      .ce-card-header {
        margin-bottom: 0.5rem;
      }
      .ce-card-body {
        max-height: 100px;
      }
    }
  }
}