.engageBar-content {
  box-sizing: border-box;
  position: absolute;
  height: 70px;
  width: 100%;
  bottom: 0;
  background-color: $cool_purple;
  box-shadow: 0 0 1px rgba(0,0,0,.44);
  transform: translateY(100%);
  transition: all .3s;
}