.ce-module.inconvseq {
  position: relative;
  @extend .vertically-spaced;

  h2 {
    font-size: 32px;
    @include media-breakpoint-down(sm) {
      font-size: 24px;
    }
  }

  &.theme-brand-dark {
    h1, h2 {
      color: $cool_white;
    }
  }

  &.hero {
    background: $cool_light-gray;

    .container, .row {
      position: static;
    }

    .module-header {
      @extend .col-md-6;
      @extend .offset-md-0;
      text-align: left;
      padding-right: 140px;

      .header-title:before {
        margin-left: 0;
      }
      @include media-breakpoint-up(sm) {
        margin-bottom: 0 !important;
      }
    }

    .header-image {
      @extend .col-12;
      @extend .col-md-6;
      position: absolute;
      top: 0;
      left: 50%;
      width: 50%;
      max-width: none;
      height: 100%;
      text-align: center;
      background: radial-gradient(#fdfbfc, #c5d4e1);
    }
    .wpb_content_element {
      height: 100%;
      margin-bottom: 0;
      padding-top: 35px;
      padding-bottom: 35px;
    }
    .wpb_wrapper {
      height: 100%;
    }
    img {
      height: 100%;
      width: auto;
    }

    @include media-breakpoint-down(sm) {
      padding: 0;
      margin-left: 0;
      margin-right: 0;
      .container {
        margin-left: 0;
        margin-right: 0;
        padding-left: 0;
        padding-right: 0;
        max-width: unset;
      }
      > .container > .row {
        display: flex;
        flex-direction: column;
        align-items: center;
      }
      .module-header {
        order: 1;
        max-width: 567px;
        margin-left: auto;
        margin-right: auto;
        margin-bottom: $mod_padding_v_mobile !important;
        .header-title {
          text-align: center;
          &:before {
            margin-left: auto;
          }
        }
      }
      .header-image {
        position: static;
        width: 100%;
        height: 300px;
        padding: 20px 0;
        margin-bottom: $mod_padding_v_mobile !important;
      }
    }
  }

  &.hero .module-header {
    @include media-breakpoint-down(sm) {
      padding-left: $page_padding;
      padding-right: $page_padding;
    }
  }

  &.see-our-projects .header-title {
    color: $cool_purple;
  }
}
