ol.carousel-indicators li {
  $mod_font_size_small: 13px; // In case `_variables.scss` hasn't been loaded yet
  margin-bottom: 0;
  
  height: $mod_font_size_small;
  width: $mod_font_size_small;

  border-radius: 9999px;
  background-color: transparent;
  border: 2px solid $cool_white;

  cursor: pointer;

  &.active {
    height: $mod_font_size_small;
    width: $mod_font_size_small;
    margin: 1px;
    background-color: $cool_white;
  }
}