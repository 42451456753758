.ce-module.sei-contact-download-banner {
  .guide-description-content,
  .form-content {
    width: 100%;
    height: 100%;
  }
  .guide-description-content {
    @extend .theme-secondary-gradient-dark;
    padding: 40px;
  }
  .form-content {
    padding: 40px 0;
  }
  @include media-breakpoint-up(lg) {
    .guide-description-content {
      padding: 40px 60px;
    }
    .form-content {
      padding: 40px 0 40px 15px;
    }
  }
}
