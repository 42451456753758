.ce-module {
  .text-column {
    column-gap: $grid-gutter-width;
    h1, h2, h3, h4, h5, h5 {
      break-before: always;
    }
    ul, ol {
      display: inline-block; // Fix for uneven columns in Safari (Jira ticket CE-2889)
      break-inside: avoid;
      page-break-inside: avoid; // Fix for uneven columns in Firefox/Opera (Jira ticket CE-2889)
      -webkit-column-break-inside: avoid;
      
      @include media-breakpoint-up(md) {
        margin-bottom: 0px;
      }
    }
    h6 {
      display: inline-block; // Fix for uneven columns in IE 11, Edge
    }
    &.xs-1 {
      column-count: 1;
      -moz-column-count: 1;
    }
    &.xs-2 {
      column-count: 2;
      -moz-column-count: 2;
    }
    &.xs-3 {
      column-count: 3;
      -moz-column-count: 3;
    }
    &.xs-4 {
      column-count: 4;
      -moz-column-count: 4;
    }

    @include media-breakpoint-up(sm) {
      &.sm-1 {
        column-count: 1;
        -moz-column-count: 1;
      }
      &.sm-2 {
        column-count: 2;
        -moz-column-count: 2;
      }
      &.sm-3 {
        column-count: 3;
        -moz-column-count: 3;
      }
      &.sm-4 {
        column-count: 4;
        -moz-column-count: 4;
      }
    }

    @include media-breakpoint-up(md) {
      &.md-1 {
        column-count: 1;
        -moz-column-count: 1;
      }
      &.md-2 {
        column-count: 2;
        -moz-column-count: 2;
      }
      &.md-3 {
        column-count: 3;
        -moz-column-count: 3;
      }
      &.md-4 {
        column-count: 4;
        -moz-column-count: 4;
      }
    }

    @include media-breakpoint-up(lg) {
      &.lg-1 {
        column-count: 1;
        -moz-column-count: 1;
      }
      &.lg-2 {
        column-count: 2;
        -moz-column-count: 2;
      }
      &.lg-3 {
        column-count: 3;
        -moz-column-count: 3;
      }
      &.lg-4 {
        column-count: 4;
        -moz-column-count: 4;
      }
    }
  }
}