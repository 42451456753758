.ce-global-nav-expanded {
  @include media-breakpoint-down(md) {
    overflow: hidden;
    pointer-events: none;
    -ms-touch-action: none;
    touch-action: none;
    height: 100%;
    body {
      height: 100%;
      overflow: hidden;
    }
    .ce-modal {
      pointer-events: auto;
      -ms-touch-action: manipulation;
      touch-action: manipulation;
      -webkit-overflow-scrolling: touch;
    }
  }
  .ce-header-main:before {
    content: '';
    display: block;
    position: fixed;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: transparent;
    pointer-events: none;
  }
}
.ce-header-main {
  z-index: z-layer("header");
  position: relative;
  width: 100%;
  height: $header-height-mobile;

  @include media-breakpoint-up(lg) {
    height: $header-nav-height-desktop;
    &.show-topbar {
      height:  $header-nav-height-desktop + $header-topbar-height;
    }
    &.minimized {
      height: $header-nav-height-minimized-desktop;
    }
    &.minimized.show-topbar {
      height: $header-nav-height-minimized-desktop + $header-topbar-height;
    }
  }

  .ce-header-content {
    width: 100%;
    position: fixed;
    transform: translate3d(0,0,0);
    top: 0;
    transition-property: top;
    transition-duration: $heading-duration/2;
    transition-timing-function: ease-out;

    @include media-breakpoint-up(lg) {
      &.hide {
        //top: (-1 * (1 + $header-topbar-height + $header-nav-height-minimized-desktop));
        top: (-1 * (1 + $header-topbar-height + $header-nav-height-desktop));
        transition-property: top;
        transition-duration: $heading-duration/2;
        transition-timing-function: ease-out;
      }
    }

    .nav-menu-cart-count {
      color: $cool_white;
      background-color: $cool_yellow;
      border-radius: 9999px;
      font-size: 12px;
      font-weight: $cool-font-semi-bold-weight;
      line-height: 1;
      height: 16px;
      min-width: 16px;
      padding: 0 4px;
      position: absolute;
      display: flex;
      align-items: center;
      justify-content: center;
    }

    .nav-menu-cart-count {
      top: 0;
      left: 18px;
      letter-spacing: 1px;
    }

    .header-nav-module {
      z-index: z-layer('header') + 1;
    }
    .header-topbar-module {
      z-index: z-layer('header') + 2;
    }
  }

  &.logged-in {
    .logged-in-hide {
      display: none !important;
    }
  }
  &.logged-out {
    .logged-out-hide {
      display: none !important;
    }
  }
  .logo {
    color: $cool_purple;
    font-weight: $cool-font-semi-bold-weight;
    white-space: nowrap;
    &:hover,
    &:focus,
    &:active {
      color: $cool_purple;
    }
  }

  @media print {
    display: none;
  }
}
