.grecaptcha-badge {
  z-index: z-layer('overlay-bottom');
}
.donate-box {
  .recaptcha {
    max-width: 9999px !important;
  }
}

#modalRecaptcha {
  .ce-modal_body {
    padding: 0;
  }
  @include media-breakpoint-only(xs) {
    .modal-dialog {
      padding: 0;
    }
    .ce-modal_content {
      padding: 50px 30px;
    }
  }
}
#modalPurchaseInPlace,
#modalRecaptcha {
  .recaptcha {
    display: flex;
    justify-content: center;
  }
}
