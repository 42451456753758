.ce-module, .ce-modal {
  .alert {
    .alert-label-with-icon {
      display: flex;
      align-items: center;
      &:before {
        @extend .ce-icon-exclamation:before;
        font-family: 'cooleffect';
        font-size: 22px;
        margin-right: 1rem;
      }
    }
  }
}