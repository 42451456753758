.flir-do-more {
  overflow: hidden;
  .ce-icon-arrow-right {
    font-size: 12px;
    margin-left: 10px;
  }
  .ce-layered-compare {
    .compare-layer .overlay {
      padding-left: $mod_space_xxl;
      display: none;
      top: 130px;
      @include media-breakpoint-up(lg) {
        display: block;
      }
    }
  }
}