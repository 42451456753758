.page-row {
  height: 100%;
  .page-column {
    height: 100%;
  }
}

.ce-module {
  &.checkout-module {
    flex: 1 0 auto;
    padding-top: $mod_padding_v_mobile;
    padding-bottom: $mod_padding_v_mobile;
    @include media-breakpoint-up( lg) {
      padding-top: $mod_space_lg;
      padding-bottom: $mod_padding_v;
    }
    h1, h2, h3, h4, h5, h6 {
      color: $cool_dark_gray;
    }
    > .container > .row {
      display: flex;
      flex-wrap: wrap;
    }
    .checkout-main {
      margin-bottom: -$mod_card_padding_v;
      .ce-select .ce-collapsible-target {
        z-index: z-layer( "content" );
      }
      form input[type="radio"].ce-radio + label {
        font-size: $mod_font_size_h4;
        font-weight: $cool_font-semi-bold-weight;
        color: $cool_dark_gray;
        @include media-breakpoint-up( lg ) {
          font-size: $mod_font_size_h4_lg;
        }
      }
    }
    .cart-summary + .checkout-actions {
      margin-top: $mod_space_lg;
      @include media-breakpoint-up(lg) {
        margin-top: $mod_space_xl;
      }
    }
  }
  .checkout-actions {
    display: flex;
    flex-direction: column;
    align-items: center;
    margin-top: $mod_space_lg;
    > * {
      flex: 1 0 auto;
    }
    .row.small-gutters {
      margin-left: -$grid-gutter-width-sm/2;
      margin-right: -$grid-gutter-width-sm/2;
    }
    @include media-breakpoint-up( lg) {
      align-items: flex-end;
      margin-top: $mod_space_lg;
    }
    a, button {
      display: block;
    }
    a + button,
    button + button {
      margin-top: 10px;
    }
  }
  .ce-card.checkout-card {
    padding-bottom: $grid-gutter-width;
    .ce-card-inner {
      justify-content: flex-start;
    }
    .ce-card-header {
      padding-bottom: $mod_space_lg;
      margin-bottom: 0;
      border-bottom: 1px solid $cool_gray;
    }
    .card-title,
    .section-title {
      display: flex;
      align-items: center;
      color: $cool_dark_gray;
      margin-bottom: 0;
      line-height: 1;
      .indicator {
        margin-right: 10px;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .ce-card-content {
      background-color: $cool_white;
      padding: $mod_card_padding_v $mod_space_sm;
      @include media-breakpoint-up( lg) {
        padding: $mod_card_content_padding_lg;
      }
      .ce-card-header + .ce-card-body {
        padding-top: $mod_card_padding_v;
        @include media-breakpoint-up( lg) {
          padding-top: $mod_card_content_padding_lg;
        }
      }
      .ce-card-body {
        .col {
          padding-left: $grid-gutter-width-sm/2;
          padding-right: $grid-gutter-width-sm/2;
        }
        .checkout-actions {
          margin-top: $mod_card_padding_v; // Override for when element is in card
        }
      }
    }
    .quantity {
      font-weight: $cool_font-semi-bold-weight;
    }
    .price {
      font-weight: $cool_font-semi-bold-weight;
      font-size: $mod_font_size_h4;
      color: $cool_dark_gray;
      input {
        color: $cool_dark_gray;
        font-weight: $cool_font-semi-bold-weight;
        //border: none;
        width: 105px;
        height: 40px;
        padding: 0 10px;
      }
      @include media-breakpoint-up( lg) {
        font-size: $mod_font_size_h4_lg;
      }
    }
    .card-section:not(:last-of-type) {
      .ce-card-body {
        border-bottom: none;
      }
    }
    .card-section {
      padding-bottom: $mod_card_padding_v;
      &.collapsible {
        .section-title {
          color: $cool_gray;
        }
        .section-title.selectable {
          cursor: pointer;
        }
        .ce-card-header {
          @include media-breakpoint-up( lg ) {
            border-color: $cool_light_gray;
          }
        }
        .ce-card-body {
          display: none;
          padding-bottom: $mod_card_padding_v;
          border-bottom: 1px solid $cool_gray;
          @include media-breakpoint-up( lg ) {
            border-color: $cool_light_gray;
            padding-bottom: $mod_space_xxl;
          }
        }
        &.card-section-active {
          .ce-card-header {
            border-color: $cool_medium_gray;
            @include media-breakpoint-up( lg ) {
              border-color: $cool_gray;
            }
            .section-title.selectable {
              cursor: auto;
            }
          }
          &.ng-animate {
            .ce-card-body {
              display: block;
            }
          }
          .ce-card-body {
            display: block;
          }
        }
        &.card-section-active,
        &.done {
          .section-title {
            color: $cool_dark_gray;
          }
        }
      }
      &.card-section-alt {
        display: none;
        .ce-card-header {

        }
        .section-title {
          color: $cool_dark_gray;
        }
        .ce-card-body {
          display: block;
        }
        &.card-section-active {
          display: block;
          border-color: $cool_medium_gray;
          @include media-breakpoint-up( lg ) {
            border-color: $cool_gray;
          }
          &.ng-animate {
            display: block;
          }
        }
      }
      .section-title {
        .indicator {
          &.circle {
            border: 2px solid;
            border-radius: 9999px;
            height: 23px;
            // Setting a 'min' value prevents circle from deforming
            min-height: 23px;
            min-width: 23px;
            width: 23px;
            @include media-breakpoint-up( lg ) {
              height: 30px;
              min-height: 30px;
              min-width: 30px;
              width: 30px;
            }
          }
          .number {
            font-size: $font-size-root;
            line-height: 1.2;
            @include media-breakpoint-up( lg ) {
              font-size: 18px;
              line-height: 0;
            }
          }
          .ce-icon-check {
            display: none;
            font-size: 12px;
            color: $cool_white;
          }
        }
      }
      &.done {
        .section-title {
          .indicator {
            &.circle {
              border-color: $cool_blue;
              background-color: $cool_blue;
            }
            .number {
              // hide without removing from the dom, to maintain numbering
              visibility: hidden;
              position: absolute;
            }
            .ce-icon-check {
              display: block;
            }
          }
        }
      }
      &.complete {
        .section-title {
          .indicator {
            .number {
              display: none;
            }
            .ce-icon-check {
              display: block;
            }
          }
        }
      }
    }
    &.sectioned-card .ce-card-content {
      ol.section-list {
        margin-bottom: 0;
        padding-left: 0;
        counter-reset: item;
        list-style-type: none;
        li:before {
          content: counter(item) "  ";
          counter-increment: item;
        }
        ng-include:last-of-type .card-section:not( .active ),
        > .card-section:last-of-type:not( .active ) {
          .ce-card-header {
            border: none;
            padding-bottom: 0;
          }
        }
        ng-include:last-of-type .card-section,
        > .card-section:last-of-type {
          padding-bottom: 0;
          .ce-card-body {
            padding-bottom: 0;
            border: none;
          }
        }
      }
    }
  }
  form {
    textarea {
      resize: none;
    }
    .ce-radio-tabs {
      display: flex;
      flex-wrap: wrap;
      align-items: flex-end;
      width: 100%;
      .ce-radio-tab {
        flex: 1 1 0%;
        min-width: 1px;
        input[type="radio"] {
          display: none;
        }
        input[type="radio"] + label {
          width: 100%;
          text-transform: uppercase;
          padding: .5rem;
          color: $cool_dark_blue;
          font-size: 1rem;
          font-weight: $cool_font-semi-bold-weight;
          text-align: center;
          border-bottom: 8px solid $cool_gray;
          cursor: pointer;
          display: flex;
          align-items: center;
          justify-content: center;
          &:hover {
            border-color: $cool_dark_blue;
          }
        }
        input[type="radio"]:checked + label {
          border-color: $cool_dark_blue;
          color: $cool_dark_blue;
        }
        input[type="radio"]:disabled + label {
          color: $cool_gray;
          [class^="ce-icon-"],
          [class*=" ce-icon-"] {
            color: $cool_gray;
          }
          &:hover {
            border-color: $cool_gray;
            color: $cool_gray;
          }
          cursor: not-allowed;
        }
        input[type="radio"]:checked:disabled + label {
          border-color: $cool_gray;
        }
      }
    }
  }
}
