.ce-module.stillin {
  &.stillin-main-hero {
    @include media-breakpoint-down(xs) {
      .container {
        padding: 0;
      }
    }
    .ce-display-grid {
      display: flex;
      flex-direction: column;

      .bg-image {
        padding-top: 56%;
      }
      .copy-item {
        padding: 40px;
      }
      .title-item {
        background: $stillin_color_black;
        text-align: center;
        display: flex;
        align-items: center;
        justify-content: center;
        padding: 25px 40px;
        h1 {
          color: $stillin_color_red;
          font-weight: $stillin_font_weight_display_extra_bold;
          font-size: 30px;
          margin-bottom: .75rem;
        }
        p {
          color: $stillin_color_white;
          font-family: $stillin_font_family_display;
          font-weight: $stillin_font_weight_display_semibold;
        }
      }

      h2.primary {
        font-size: 26px;
      }

      .main-item {
        order: 0;
      }
      .title-item {
        order: 1;
      }
      .promise-item {
        order: 2;
      }
      .post-promise-item {
        order: 3;
        margin-top: 1rem;
      }
      .pledge-item {
        order: 4;
      }

      .ce-blockquote-source {
        font-size: 13px;
      }

      .ce-social-share.pos-bottom {
        position: relative;
        padding: 0;
        bottom: 0;
      }

      @include media-breakpoint-up(md) {
        .title-item {
          h1 {
            font-size: 44px;
          }
        }
      }

      @include media-breakpoint-up(lg) {
        display: grid;
        display: -ms-grid;
        grid-template-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
        -ms-grid-rows: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;

        .bg-image {
          padding-top: 0;
        }
        .copy-item {
          display: flex;
          align-items: center;
          padding: 60px;
        }
        .title-item {
          background: transparent;
          text-align: left;
          display: flex;
          align-items: center;
          justify-content: flex-start;
          padding: 60px;
          h1 {
            font-size: 58px;
            margin-bottom: 0;
          }
          p {
            color: $stillin_color_black;
          }
        }
        h2.primary {
          font-size: 28px;
        }
        .ce-social-share.pos-bottom {
          position: absolute;
          bottom: 20px;
        }
      }
      @include media-breakpoint-up(xl) {
        .main-item {
          //background-position-x: center;
        }
        .title-item {
          h1 {
            font-size: 70px;
          }
        }
        h2.primary {
          font-size: 36px;
        }
      }
    }
  }
}