.ce-module, .ce-modal {
  .ce-text-featured {
    font-size: $mod_font_size_h3_md;
    line-height: 1.4;
  }
  .ce-text-small {
    font-size: $mod_font_size_small;
    line-height: 1.3;
  }
}
