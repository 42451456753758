.ce-module.stillin,
.ce-modal.stillin {
  &.theme-transparent-dark {
    color: $stillin_color_white;
    h1, h2, h3, h4, h5, h6 {
      color: $stillin_color_white;
    }
  }
  &.theme-yellow-light,
  .theme-yellow-light {
    background-color: $stillin_color_light_yellow;
  }
}