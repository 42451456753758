.ce-module,
.ce-modal {
  .ce-flex {
    display: flex;
  }
  // direction
  .ce-flex-row {
    flex-direction: row;
    width: 100%;
  }
  .ce-flex-row-reverse {
    flex-direction: row-reverse;
    width: 100%;
  }
  .ce-flex-col {
    flex-direction: column;
  }
  .ce-flex-col-reverse {
    flex-direction: column-reverse;
  }
  // wrap
  .ce-flex-wrap {
    flex-wrap: wrap;
  }
  .ce-flex-wrap-no {
    flex-wrap: nowrap;
  }
  .ce-flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  // justify-content
  .ce-flex-justify-content-flex-start {
    justify-content: flex-start;
  }
  .ce-flex-justify-content-flex-end {
    justify-content: flex-end;
  }
  .ce-flex-justify-content-center {
    justify-content: center;
  }
  .ce-flex-justify-content-space-around {
    justify-content: space-around;
  }
  .ce-flex-justify-content-space-between {
    justify-content: space-between;
  }
  .ce-flex-justify-content-space-evenly {
    justify-content: space-evenly;
  }
  // align-items
  .ce-flex-align-items-flex-start {
    align-items: flex-start;
  }
  .ce-flex-align-items-flex-end {
    align-items: flex-end;
  }
  .ce-flex-align-items-center {
    align-items: center;
  }
  .ce-flex-align-items-stretch {
    align-items: stretch;
  }
  .ce-flex-align-items-baseline {
    align-items: baseline;
  }
  // align-content
  .ce-flex-align-content-flex-start {
    align-content: flex-start;
  }
  .ce-flex-align-content-flex-end {
    align-content: flex-end;
  }
  .ce-flex-align-content-center {
    align-content: center;
  }
  .ce-flex-align-content-space-around {
    align-content: space-around;
  }
  .ce-flex-align-content-space-between {
    align-content: space-between;
  }
  .ce-flex-align-content-stretch {
    align-content: stretch;
  }
  // grow
  @for $i from 0 through 6 {
    .ce-flex-grow-#{$i} {
      flex-grow: $i;
    }
  }
  // shrink
  @for $i from 0 through 6 {
    .ce-flex-shrink-#{$i} {
      flex-shrink: $i;
    }
  }
  // order
  @for $i from 0 through 6 {
    .ce-flex-order-#{$i} {
      order: $i;
    }
  }
  // basis
  .ce-flex-basis-0 {
    flex-basis: 0;
  }
  .ce-flex-basis-auto {
    flex-basis: auto;
  }
  .ce-flex-basis-100 {
    flex-basis: 100%;
  }
}
