.ce-module .ce-cta-row {
  .copy-col {
    margin-bottom: $mod_space_lg;
    > *:last-child {
      margin-bottom: 0;
    }
  }
  .action-col {
  }
  &.theme-light {
    background-color: $cool_white;
    padding: $mod_card_content_padding;
  }
  @include media-breakpoint-up(lg) {
    > .row {
      flex-wrap: nowrap;
    }
    .copy-col {
      padding-right: 80px;
      margin-bottom: 0;
      flex: 1 1 auto;
    }
    .action-col {
      display: flex;
      align-items: center;
      flex: 0 0 auto;
    }
    &.theme-light {
      padding: $mod_card_content_padding_lg;
    }
  }
}
