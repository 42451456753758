.ce-module.header-nav-module.stillin {
  .ce-dropdown-menu {
    background-color: $stillin_color_blue_gray;
    padding: 10px 0;
    .menu-item {
      padding: 10px 30px;
    }


  }
}