.info-page {
  @extend .container-fluid;

  h1 {
    color:                    $cool_purple;
  }

  .quote-row {
    background-color:         $cool_very_light_gray;
    .col-half-page {
      @extend .col-md-12;
      @extend .col-lg-6;
      @extend .col-xl-5;

      &:nth-of-type(1) {
        @include media-breakpoint-up(xl) {
          @extend .offset-xl-1;
        }
      }
    }

    img {
      width:                  100%;
    }

    blockquote {
      margin-left:            40px;
      margin-top:             40px;

      @include media-breakpoint-down(md) {
        margin-left:          0;
      }
    }
  }

  .founders-row {
    background-color:         $cool_dark_purple;
    color:                    $cool_white;
    padding-top:              60px;
    padding-bottom:           60px;
    h2 {
      color:                  $cool_white;
      margin-bottom:          20px;
      @extend .text-md-center;
    }
    .signature {
      font-weight:            $cool_font-semi-bold-weight;
    }
  }

  .info-row {
    padding-bottom:           45px;
    .item {
      padding-bottom:          30px;
      &:last-of-type {
        padding-bottom:        0px;
      }
      h3 {
        text-transform:       uppercase;
      }
      .btn {
        margin-top: 20px;
      }
    }
  }

  .img-row {
    padding-bottom:           70px;
    @include media-breakpoint-down(sm) {
      padding-bottom:           30px;
    }
  }

  .col-info {
    @extend .col-md-8;
    @extend .offset-md-2;

    @extend .col-12;
    float:                      none;
  }
}