.ce-module,
.ce-modal {
  @keyframes fadeIn {
    from {opacity: 0;}
    to {opacity: 1;}
  }
  [ce-lazy-img] {
    opacity: 0;
  }
  .ce-lazy-img-loaded {
    opacity: 1;
    animation-name: fadeIn;
    animation-duration: 400ms;
  }
}