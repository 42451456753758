.ce-module.stillin {
  .simple-portfolio-purchase {
    > .col {
      max-width: 180px;
    }
  }
  .custom-price-wrapper {
    background-color: $stillin_color_white;
    border-radius: 9999px;
    display: flex;
    font-family: $stillin_font_family_display;
    padding: 10px 20px;
    box-shadow: inset 0px 0px 25px 0px rgba(0,0,0,0.6);
    .custom-price-unit {
      color: $stillin_color_red;
      font-size: 16px;
      margin-bottom: 0;
    }
    .custom-price-input {
      text-align: center;
      font-weight: $stillin_font_weight_display_medium;
      font-size: 16px;
      width: 100%;
      border: none;
      background-color: transparent;
    }
  }
}