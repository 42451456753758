.ce-module {
	.ce-card.checkout-card {
		// Select specific instances to override scope
		.ce-card-content .card-section {
			&.gift-select,
			&.gift-edit {
				.ce-card-body .checkout-actions {
					margin-top: 0;
					width: 100%;
				}
			}
      &.collapsible {
        &.gift-select {
          padding-bottom: 0;
          .ce-card-body {
            border: none;
            padding-bottom: 0;
          }
        }
        &.gift-edit {
          padding-top: $mod_card_padding_v;
        }
      }
		}
		.gift-select-items {
			.item + .item {
				border-top: 1px solid $cool_light_gray;
				padding-top: $mod_space_lg;
				@include media-breakpoint-up( lg ) {
					padding-top: $mod_space_xl;
				}
			}
			.item {
				display: flex;
				flex-direction: column;
				padding-bottom: $mod_space_lg;
				width: 100%;
				@include media-breakpoint-up( lg ) {
					align-items: center;
					flex-direction: row;
					justify-content: space-between;
					padding-bottom: $mod_space_xl;
				}
				.field {
					align-self: flex-start;
					padding-bottom: $mod_space_xs;
					@include media-breakpoint-up( lg ) {
						align-self: unset;
						padding-bottom: 0;
					}
				}
				label {
					margin-bottom: 0;
				}
				.price {
					align-self: flex-end;
					@include media-breakpoint-up( lg ) {
						align-self: unset;
					}
				}
			}
			.item:last-of-type {
				padding-bottom: 0; // Bug: Does not always remove bottom padding
			}
			.cta {
				display: block;
			}
		}
		.gift-completed-items {
			list-style: none;
			padding-left: 0;
			margin-bottom: 0;
			> .item + .item {
				border-top: 1px solid $cool_light_gray;
				padding-top: $mod_space_lg;
				@include media-breakpoint-up( lg ) {
					padding-top: $mod_space_lg;
				}
			}
			&.row {
				padding-left: ( $grid-gutter-width / 2 );
				padding-right: ( $grid-gutter-width / 2 );
				@include media-breakpoint-up( lg ) {
					padding-left: 0;
					padding-right: 0;
				}
			}
			> .item {
				display: flex;
				flex-wrap: wrap;
				justify-content: space-between;
				padding-bottom: $mod_space_lg;
				width: 100%;
				@include media-breakpoint-up( lg ) {
					flex-wrap: nowrap;
					padding-bottom: $mod_space_lg;
				}
				> * {
					@include media-breakpoint-up( lg ) {
						padding: 0 ( $grid-gutter-width / 2 );
					}
				}
				.gift-summary {
					flex: 0 1 100%;
					@include media-breakpoint-up( lg ) {
						flex-basis: auto;
					}
					> * {
						padding-bottom: $mod_space_xs;
						@include media-breakpoint-up( lg ) {
							padding-bottom: 0;
						}
					}
					.recipient {
						display: flex;
						color: $cool_dark_gray;
						h3 {
							color: $cool_dark_gray;
							margin-bottom: 0;
						}
					}
					.details {
						color: $cool_dark_gray;
						font-size: $mod_font_size_h6;
						@include media-breakpoint-up( lg ) {
							color: $cool_medium_gray;
							font-size: $font-size-root;
							margin-left: 35px;
						}
						.item-name {
							font-weight: $cool_font-semi-bold-weight;
						}
					}
					.ce-icon-gift {
						font-size: 17px;
						width: 28px;
						line-height: 26px;
						@include media-breakpoint-up( lg ) {
							font-size: 22px;
							width: 35px;
							line-height: 33px;
						}
					}
				}
				.quantity,
				.price,
				.edit {
					line-height: 1;
				}
				.quantity,
				.price {
					flex: 1 0 50%;
					@include media-breakpoint-up( lg ) {
						flex: 0 1 auto;
					}
				}
				.price,
				.edit {
					text-align: right;
					@include media-breakpoint-up( lg ) {
						text-align: left;
					}
				}
				.quantity {
					color: $cool_dark_gray;
					font-size: $mod_font_size_h6;
          text-transform: capitalize;
					@include media-breakpoint-up( lg ) {
						color: $cool_medium_gray;
						font-size: $font-size-root;
					}
				}
				.edit {
					flex: 1 0 100%;
					padding-top: $mod_space_xs;
					@include media-breakpoint-up( lg ) {
						flex: 0 1 auto;
						padding-top: 0;
					}

				}
			}
			> .item:last-of-type {
				padding-bottom: 0;
			}
		} // edit section
		.gift-edit {
			label {
				width: 100%;
			}
			.delivery-methods {
				input[type="radio"] + label {
					flex-direction: column;
					font-size: $mod_font_size_small;
					line-height: 1.1;
					text-align: center;
					@include media-breakpoint-up( lg ) {
						font-size: 1rem;
						line-height: 1.5;
					}
					[class^="ce-icon-"],
					[class*=" ce-icon-"] {
						display: flex;
						align-items: flex-end;
						justify-content: center;
						margin-bottom: 5px;
						@include media-breakpoint-up( lg ) {
							height: 28px;
						}
					}
					.ce-icon-paper-plane {
						font-size: 14px;
						@include media-breakpoint-up( lg ) {
							font-size: 26px;
						}
					}
					.ce-icon-printer {
						font-size: 14px;
						@include media-breakpoint-up( lg ) {
							font-size: 28px;
						}
					}
					.ce-icon-envelope {
						font-size: 10px;
						@include media-breakpoint-up( lg ) {
							font-size: 20px;
						}
					}
				}
			}
      .delivery-methods-description {
        margin-top: 1rem;
      }
		}
	}
}
