.ce-module.holiday,
.ce-banner-container.holiday {
  &.promo {
    padding-top: $mod_space_md;
    padding-bottom: $mod_space_md;
    h4 {
      margin-bottom: .25rem;
      line-height: 1.2;
    }
    p {
      line-height: 1.2;
    }
    a {
      position: relative;
      &:after {
        content: '';
        display: block;
        position: absolute;
        left: 0;
        bottom: 0;
        width: 100%;
        height: 1px;
        background-color: $color-base;
      }
    }
    &.theme-dark {
      a {
        &:after {
          background-color: $cool_white;
        }
      }

    }
  }
  &.travel-offset-hero {
    display: flex;
    align-items: center;
    background-size: cover;

    min-height: 75vw;
    max-height: 70vh;

    @include media-breakpoint-up(sm) {
      min-height: 40vw;
    }
  }
  .ce-goal-tracker {
    .goals {
      //.goal:nth-child(2) {
      //  .tick {
      //    position: relative;
      //    @keyframes burst {
      //      0% {
      //        transform: rotate(0) scale(1,1);
      //      }
      //      50% {
      //        transform: rotate(45deg) scale(1.15, 1.15);
      //      }
      //      100% {
      //        transform: rotate(0) scale(1,1);
      //      }
      //    }
      //    &:before {
      //      display: block;
      //      width: 42px;
      //      height: 42px;
      //      position: absolute;
      //      top: -9px;
      //      left: -9px;
      //      content: '';
      //      background-image: url('https://externalassets.cooleffect.org/lib/content/wp-content/uploads/2018/11/30165531/icon-burst.png');
      //      background-size: cover;
      //      animation-name: burst;
      //      animation-duration: 2s;
      //      animation-iteration-count: infinite;
      //      animation-timing-function: ease-in-out;
      //    }
      //  }
      //}
    }
  }
  // breaking news hero
  &.breaking-news-module {
    padding-top: 80px;
    padding-bottom: 25px;
    background-size: cover;
    .ce-banner-content-overlay {
      &.donate-options {
        padding-left: $grid-gutter-width/2;
        padding-right: $grid-gutter-width/2;
      }
    }
    .info {
      margin-top: 30px;
    }
    .breaking-news {
      display: inline-block;
      background-color: $cool_red;
      color: $cool_white;
      font-size: 22px;
      font-weight: $cool_font-bold-weight;
      font-style: italic;
      text-transform: uppercase;
      letter-spacing: 1.5px;
      padding: .5rem $grid-gutter-width;
    }
    .headline,
    .reporter {
      display: flex;
      align-items: center;
    }
    .headline {
      flex: 1 0 auto;
      background-color: $cool_white;
      padding: .5rem $grid-gutter-width;
      h1 {
        color: $cool_dark_gray;
        font-size: 32px;
        font-weight: $cool_font_bold-weight;
        font-style: italic;
        letter-spacing: 1px;
        text-transform: uppercase;
      }
    }
    .reporter {
      flex: 0 1 auto;
      display: flex;
      justify-content: space-between;
      align-items: center;
      background-color: $cool_blue;
      color: $cool_white;
      font-weight: $cool_font_bold-weight;
      font-style: italic;
      letter-spacing: 1px;
      text-transform: uppercase;
      padding-left: $grid-gutter-width;

      .text-block {
        text-align: center;
        margin-right: 25px;
      }
    }
    .crawler {
      background-color: $cool_dark_gray;
      color: $cool_white;
      font-size: 20px;
      font-weight: $cool-font-bold-weight;
      font-style: italic;
      letter-spacing: 1px;
      padding: .7rem 0;
      overflow: hidden;
      display: flex;
      flex-wrap: nowrap;
      @keyframes crawl {
        from {transform: translate3D(0,0,0);}
        to {transform: translate3D(-100%,0,0);}
      }
      .crawler-item {
        white-space: nowrap;
        transform: translate3D(0,0,0);
        animation-name: crawl;
        animation-duration: 15s;
        animation-iteration-count: infinite;
        animation-timing-function: linear;
        .separator {
          padding: 0 20px;
        }
      }
    }
    @include media-breakpoint-only(xs) {
      background-size: 160% !important;
    }
    @include media-breakpoint-only(sm) {
      background-size: 160% !important;
    }
    @include media-breakpoint-only(md) {
      background-position: 15% top;
      .info {
        .headline {
          h1 {
            font-size: 26px;
          }
        }
      }
    }
    @include media-breakpoint-only(lg) {
      .info {
        .headline {
          h1 {
            font-size: 26px;
          }
        }
      }
    }
    @include media-breakpoint-only(xl) {
      .info {
        .headline {
          h1 {
            //font-size: 26px;
          }
        }
      }
    }
    @include media-breakpoint-down(sm) {
      background-position: top left !important;
      background-color: $cool_very_light_gray;
      padding: 0;

      > .container {
        max-width: 100%;
        padding: 0;
      }

      .info {
        margin-top: 0;
        .breaking-news {
          font-size: 1rem;
          padding: .5rem 24px;
          margin-bottom: 55%;
        }
        .headline {
          order: 1;
          padding-left: 24px;
          padding-right: 24px;
          h1 {
            font-size: 24px;
          }
        }
        .reporter {
          order: 0;
          position: relative;
          font-size: 13px;
          text-align: left;
          padding: .5rem 24px;
          img {
            position: absolute;
            top: 0;
            right: 0;
            height: 100%;
            width: auto;
          }
        }
        .crawler {
          font-size: 13px;
          padding: .5rem 0;
        }
        .row {
          > .col {
            flex-direction: column;
          }
        }
      }
    }
  }
  // end breaking news hero
  .sticker-promo-banner {
    .sticker {
      position: absolute;
      right: 40px;
      bottom: 0;
      margin-bottom: -30px;
      display: none;
      @include media-breakpoint-up(md) {
        display: block;
      }
    }
  }
  &.ce-preview-banner.sticker-pack {
    .sticker {
      display: none;
      position: absolute;
      bottom: 0;
      right: 0;
      margin-right: -70px;
      margin-bottom: -50px;
    }
    @include media-breakpoint-up(lg) {
      .sticker {
        display: block;
      }
    }
  }
}