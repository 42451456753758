@mixin make-grid-items($breakpoints: $grid-breakpoints) {
  @each $breakpoint in map-keys($breakpoints) {
    .ce-display-grid-item {
      @for $i from 1 through 13 {
        $infix: breakpoint-infix($breakpoint, $breakpoints);

        &.gr-start#{$infix}-#{$i} {
          @include media-breakpoint-up($breakpoint, $breakpoints) {
            grid-row-start: $i;
            -ms-grid-row: $i;
          }
        }
        &.gr-span#{$infix}-#{$i} {
          @include media-breakpoint-up($breakpoint, $breakpoints) {
            grid-row-end: span $i;
            -ms-grid-row-span: $i;
          }
        }
        &.gc-start#{$infix}-#{$i} {
          @include media-breakpoint-up($breakpoint, $breakpoints) {
            grid-column-start: $i;
            -ms-grid-column: $i;
          }
        }
        &.gc-span#{$infix}-#{$i} {
          @include media-breakpoint-up($breakpoint, $breakpoints) {
            grid-column-end: span $i;
            -ms-grid-column-span: $i;
          }
        }
      }
    }
  }
}

.ce-module {
  .ce-display-grid {
    position: relative;
    display: grid;
    display: -ms-grid;
    grid-template-columns: repeat(12, 1fr);
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    .ce-display-grid-item {
      position: relative;
    }
  }
  @include make-grid-items($grid-breakpoints);
}

