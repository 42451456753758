// I hate this file. Slated for clean-up. -ae 11.21.16
// bootstrap buttons / state overrides
.btn-primary,   .btn-primary-outline,
.btn-secondary, .btn-secondary-outline,
.btn-info,      .btn-info-outline,
.btn-success,   .btn-success-outline,
.btn-warning,   .btn-warning-outline,
.btn-danger,    .btn-danger-outline,
.btn-tertiary,  .btn-tertiary-outline,
.btn-purple-outline
{
  text-transform:         uppercase;
  font-weight: $cool_font-semi-bold-weight;
}

.btn-primary {
  padding: 10px 30px;
}

.btn-primary-outline {
  &:hover, &:active, &:focus {
    background-color: transparent;
    color: $cool_blue;
    border-color: $cool_blue;
  }
}

.btn-primary-outline,
.btn-secondary-outline,
.btn-info-outline,
.btn-success-outline,
.btn-warning-outline,
.btn-danger-outline,
.btn-tertiary-outline,
.btn-purple-outline
{
  padding: 8px 28px;
}

button,
.btn,
a {
  &:active,
  &:focus {
    outline:              none;
  }
}
.btn {
  font-size:              16px;
  &:active:focus {
    outline:              none;
  }
}
.btn-primary {
  border: none;
  overflow: hidden;
  position: relative;
  z-index: z-layer( "content" );
  &:after {
    content: "";
    display: block;
    position: absolute;
    z-index: z-layer( "content-lower" );

    /* This sets up the gradient animation */
    background-image: linear-gradient( to top, $cool_purple 10%, $cool_blue 50% );
    bottom: 100%;
    height: 200%;
    left: 0;
    right: 0;
    transition: bottom 0.75s ease;
  }
  &:hover:after {
    bottom: -100%;
  }

  &:disabled {
    background: $cool_gray !important;

    &:after {
      display: none;
    }
  }
}

.btn-primary-icon {
  @extend .btn-primary;
}
.btn-secondary-icon {
  @extend .btn-secondary;
}
.btn-tertiary-icon {
  @extend .btn-tertiary;
}

.btn-primary-icon,
.btn-secondary-icon,
.btn-tertiary-icon {
  position: relative;
  
  [class^="ce-icon-"] {
    left: 50%;
    position: absolute;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.btn-icon-sm {
  height: 30px;
  padding: 10px;
  width: 30px;

  [class^="ce-icon-"] {
    font-size: $font-size-base;
  }
}

.btn-tertiary {
  color:                $cool_purple;
  background-color:     transparent;
  border: none;
  padding: 0;
  line-height: 1;
  &:hover,
  &:active:focus {
    color:                $cool_blue;
    background-color:     transparent;
  }
}

.btn,
.btn-primary,
.btn-primary-outline,
.btn-primary-outline-alt {
  &.disabled,
  &:disabled {
    cursor: none;
    opacity: 1;
    color: $cool_white !important;
    box-shadow: none;
  }
}

.btn {
  &.disabled,
  &:disabled {
    color: $cool_white !important;
    background-color: $cool_gray !important;
    border-color: $cool_gray !important;
  }
}

.btn-primary {
  &.disabled,
  &:disabled {
    color: $cool_white !important;
    background-color: $cool_gray !important;
    border-color: $cool_gray !important;
  }
}

.btn-primary-outline {
  &.disabled,
  &:disabled {
    color: $cool_gray !important;
    background-color: $cool_white !important;
    border-color: $cool_gray !important;
  }
}

.btn-tertiary {
  &.disabled,
  &:disabled {
    color: $cool_gray !important;
    background-color: $cool_white !important;
    border-color: $cool_white !important;
  }
}

.btn-link {
  &.disabled,
  &:disabled {
    color: $cool_gray !important;
    background-color: transparent !important;
    border-color: transparent !important;
  }
}

.btn-primary-outline-alt {
  @extend .btn-primary-outline;
  color: $cool_medium_gray;
  &:hover,
  &:focus,
  &:active {
    color: $cool_medium_gray;
  }
}

.btn-secondary {
  &:hover,
  &:active:focus {
    color:                $cool_yellow;
    background-color:     $cool_white;
    border-color:         $cool_white;
  }
}

.btn-secondary-outline {
  &:hover,
  &:active:focus {
    color:                $cool_yellow;
  }
}

.btn-tertiary-outline {
  color: $cool_purple;
  background-color: $cool_white;
  border-color: $cool_purple;
  &:hover,
  &:active:focus {
    color:                $cool_white;
    background-color:     $cool_purple;
    border-color:         $cool_purple;
  }
}

.btn-danger {
  &:hover,
  &:active:focus {
    color:                $cool_white;
    background-color:     $cool_danger;
    border-color:         $cool_danger;
  }
}

// button sizes
.btn,
.btn-sm,
.btn-md,
.btn-lg {
  line-height:            1.5;
}
.btn-sm {
  min-width:              100px;
}

.btn-md {
  min-width:              225px;
}

.btn-lg {
  min-width:              304px;
  @media (max-width: 340px) {
    min-width: 0;
    width: 100% !important;
  }
}

.btn-block {
  padding: $btn-padding-y 0;
}

// Alternative button styles
.btn-alt {
  border-radius: 5px;
}

// Making CTAs less bad.
.btn-cta {
  min-width: auto;
  padding: $btn-padding-y 30px;
  @include media-breakpoint-down(sm) {
    padding: $btn-padding-y 30px;
  }
}
