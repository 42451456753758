
/**
 * media-min-responsive applies a series of min-width media queries for a provided property.
 * `root` refers to the property's base value, aside from media queries, and is optional.
 * @include responsive-property-min(
 *     [propertyName],
 *     root,         [value],
 *     [breakpoint], [value],
 *     [breakpoint], [value],
 *     [breakpoint], [value],
 *     [breakpoint], [value]
 * );
 */
//TODO is this being used?


@mixin media-min-responsive($propertyName, $breakValueList...) {
    @for $breakValueCount from 1 through length($breakValueList) {
        @if $breakValueCount % 2 != 0 {
            $breakpoint: nth($breakValueList, $breakValueCount);
            $breakpointValue: nth($breakValueList, $breakValueCount + 1);

            @if $breakpoint == root {
                 #{$propertyName}: $breakpointValue;
            } @else {
                @include media-breakpoint-up($breakpoint) {
                    #{$propertyName}: $breakpointValue;
                }
            }

        }
    }
}
