
.simple-portfolio-purchase-card {
	text-align: center;

	.ce-card-content {
		padding: $mod_card_content_padding;
		background-color: $cool_white;
		border: 3px solid transparent;

		&:hover {
  			border-image: linear-gradient(to bottom, $cool_red 0%, $cool_blue 100%);
  			border-image-slice: 1;
		}	
	}
	.card-price-input {
		margin-bottom: 1rem;
		@include media-breakpoint-up(lg) {
			margin-bottom: 0;
		}
	}
	.card-price {
		color: $cool_dark_gray;
		margin-bottom: 1rem;
	}
	p {
		max-width: 200px;
		margin-right: auto;
		margin-left: auto;
	}
	.enter-price-unit {
		font-size: 20px;
		color: $cool_purple;
		font-weight: $cool_font-semi-bold-weight;
		margin-right: 10px;
	}
	.custom-price {
		font-size: 20px;
		font-weight: $cool_font-semi-bold-weight;
    border: 2px solid $cool_gray;
		height: 40px;
		padding: 0 10px;
		max-width: 142px;
		&:focus,
    &:active,
    &:hover {
			outline: none;
      border-color: $cool_purple;
		}
	}

	&.custom-purchase-price-card {
		.ce-card-body {
			display: flex;
			flex-direction: column;
			justify-content: center;
		}
	}
}
