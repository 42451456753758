.popover {
  color: $color-base;
  z-index: z-layer( "content-upper" ) !important;
}
.popover.sticky-sidebar {
  z-index: z-layer('sticky-header') - 1 !important;
}
.popover-header {
  background-color: $cool_very_light_gray;
  padding: 10px 20px;
}
.popover-body {
  font-size: 13px;
  padding: 20px;
  > *:last-child {
    margin-bottom: 0;
  }
}
