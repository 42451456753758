.theme-dark {
  *, h1, h2, h3, h4, h4, h5, h6 {
    color: $cool_white;
  }
}

.theme-brand-dark {
  @extend .theme-dark;
  background-color: $cool_purple;
}

.theme-blue-dark {
  @extend .theme-dark;
  background-color: $cool_blue;
}

.theme-overlay-light,
.theme-overlay-medium,
.theme-overlay-dark {
  @extend .theme-dark;
  &:before {
    content: '';
    background-color: rgba(0,0,0,0.5);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.theme-overlay-light {
  &:before {
    background-color: rgba(0,0,0,0.15);
  }
}

.theme-overlay-medium {
  &:before {
    background-color: rgba(0,0,0,0.25);
  }
}

.theme-transparent-dark { // When copy is over a darker image without an overlay
  @extend .theme-dark;
}

.theme-transparent-outline-dark {
  @extend .theme-dark;
  border: 1px solid $cool_white;
}

.theme-background-red-blue {
  color: $cool_white;
  h1, h2, h3, h4, h4, h5, h6 {
    color: $cool_white;
  }
  &:before {
    content: '';
    background: linear-gradient($cool_blue, $cool_red);
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    z-index: -1;
  }
}

.theme-gray-light {
  background-color: $cool_very_light_gray;
}

.theme-gray-light_md {
  @include media-breakpoint-up( md ) {
    background-color: $cool_very_light_gray;
  }
}

.theme-gray-light_lg {
  @include media-breakpoint-up( lg ) {
    background-color: $cool_very_light_gray;
  }
}

.theme-gray-light_xl {
  @include media-breakpoint-up( xl ) {
    background-color: $cool_very_light_gray;
  }
}

.theme-light {
  background-color: $cool_white;
  color: $color-base;
  .module-header .header-title {
    color: $headings-color;
  }
  .module-header .header-body {
    color: $color-base;
  }
}

.theme-light_lg {
  @include media-breakpoint-up( lg ) {
    background-color: $cool_white;
    color: $color-base;
    .module-header .header-title {
      color: $headings-color;
    }
    .module-header .header-body {
      color: $color-base;
    }
  }
}

.theme-light_xl {
  @include media-breakpoint-up( xl ) {
    background-color: $cool_white;
    color: $color-base;
    .module-header .header-title {
      color: $headings-color;
    }
    .module-header .header-body {
      color: $color-base;
    }
  }
}

.theme-gray-dark {
  background-color: $cool_dark_gray;
  color: $cool_white;
  h1, h2, h3, h4, h4, h5, h6 {
    color: $cool_white;
  }
}

.theme-black-dark {
  @extend .theme-dark;
  background-color: #000000;
  .module-header .header-title {
    color: $cool_white;
  }
  .module-header .header-body {
    color: $cool_white;
  }
}

.theme-primary-gradient-dark {
  @extend .theme-dark;
  background: linear-gradient(to bottom right, $cool_dark_blue, $cool_purple);
}

.theme-secondary-gradient-dark {
  @extend .theme-dark;
  background: linear-gradient(to bottom right, $cool_blue, $cool_dark_blue);
}
