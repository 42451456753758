/*
 * Title: Promo Card
 * Type: Element
 * Dependencies: card.scss, overlay.scss, card-overlay.scss
*/

.ce-card.promo-card {
  @include ce-card_overlay();
  cursor: pointer;
  background-color: transparent;

  .ce-card-inner {
    min-height: 345px !important;
  }

  &.bg-pos-top .bg-image {
    background-position-y: top;
  }
  &.bg-pos-bottom .bg-image {
    background-position-y: bottom;
  }
  &.bg-pos-left .bg-image {
    background-position-x: left;
  }
  &.bg-pos-right .bg-image {
    background-position-x: right;
  }

  .ce-card-inner:hover {
    figure {
      .bg-image {
          transform: scale(1.05);
          transform-origin: 50%;
      }
    }
  }

  &.theme-overlay-dark,
  &.theme-transparent-dark,
  &.theme-gray-dark,
  &.theme-brand-dark {
    .ce-card-content {
      color: $cool_white;
      .card-title {
        color: $cool_white;
      }
    }
  }
  &.theme-overlay-light,
  &.theme-overlay-medium,
  &.theme-overlay-dark {
    &:before {
      display: none;
    }
    figure {
      background-color: $cool_dark_gray;
    }
  }

  &.theme-overlay-light {
    figure {
      .bg-image {
        @include ce-overlay_dark( 0.15 );
      }
    }
  }

  &.theme-overlay-medium {
    figure {
      .bg-image {
        @include ce-overlay_dark( 0.25 );
      }
    }
  }

  &.theme-overlay-dark {
    figure {
      .bg-image {
        @include ce-overlay_dark( 0.35 );
      }
    }
  }

  &.theme-transparent-dark {
    figure {
      background-color: $cool_dark_gray;
    }
    &:before {
      display: none;
    }
  }

  &.theme-gray-light {
    figure {
      background-color: $cool_very_light_gray;
    }
    .ce-card-content {
      color: $cool_dark_gray;
      .card-title {
        color: $cool_dark_gray;
      }
    }
  }
  &.theme-gray-dark {
    figure {
      background-color: $cool_dark_gray;
    }
  }
  &.theme-brand-dark {
    figure {
      background-color: $cool_purple;
    }
  }
  figure {
    $border-size: 4px;

    // Border is set using a pseudo class so
    // that it shows up in Safari
    &::after {
        background: linear-gradient( to right, $cool_red 0, $cool_blue 100% );
        content: "";
        display: block;
        height: $border-size;
        position: absolute;
        bottom: 0;
        width: 100%;
        z-index: z-layer( "content" );
    }

    .bg-image {
      transform: scale(1);
      transition: transform 0.75s;
    }
  }
  .ce-card-header {
    .card-title {
      margin-bottom: .25rem;
      &h1 {
        font-size: $mod_font_size_h1;
      }

      @include media-breakpoint-between( md, lg ) {
        &h1 {
          font-size: $mod_font_size_h1_md;
        }
      }
      @include media-breakpoint-up( xl ) {
        &h1 {
          font-size: $mod_font_size_h1_lg;
        }
      }
    }
  }

  .ce-card-body {
    font-size: $mod_font_size_h4_lg;
    font-weight: $cool_font-semi-bold-weight;
    p {
      line-height: 1.25;
    }

    @include media-breakpoint-between( md, lg ) {
      font-size: $mod_font_size_h4_md;
    }
  }

  &:not(.promo-card_video) {
    .ce-card-header {
      [class^="ce-icon-"],
      [class*="ce-icon-"] {
        font-size: $mod_font_size_h2;
        margin-bottom: ( $mod_card_padding_v * 0.5 );
      }
    }
    .ce-card-content {
      @include media-breakpoint-up( xl ) {
        max-width: 75%;
      }
    }
  }
  &.content-full {
    .ce-card-content {
      @include media-breakpoint-up( xl ) {
        max-width: 100%;
      }
    }
  }

  &.promo-card_video {
    justify-content: center;

    .ce-card-header {
      text-align: center;

      .playButton {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }
}