.u-noPointer {
  cursor: default !important;
}
.u-overflowHidden {
  overflow: hidden;
}

.u-displayNone {
  display: none;
}
.u-display-sm {
  @include media-breakpoint-up(md) {
    display: none;
  }
  display: block;
}
// Typography Utils // ------
.u-lightCopy {
  color: rgb(255,255,255);
}
.u-capitalize {
  text-transform: uppercase;
}
.u-textCenterXl {
  @include media-breakpoint-up(xl) {
    text-align: center;
  }
}
.u-textCenter {
  text-align: center;
}
.u-textLeft {
  text-align: left;
}
.u-textRight {
  text-align: right;
}
.u-letterSpacing1 {
  letter-spacing: 0.025rem;
}
.u-letterSpacing2 {
  letter-spacing: 0.05rem;
}
.u-letterSpacing3 {
  letter-spacing: 0.075rem;
}
// Layout Utils // ------
// Clearfix Fixes

.u-clearAfter--xs2>*:nth-child(2n+1) {
  @include media-breakpoint-down(lg) {
    clear: left;
  }
}


// Flex Layouts
.u-verticalAlign {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: row;
}
.u-verticalAlign--lgMin {
  @include media-breakpoint-up(lg) {
    display: flex;
    align-items: center;
    justify-content: flex-start;
    flex-direction: row;
  }
}
.u-verticalAlign--xlMin {
  @include media-breakpoint-up(xl) {
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: row;
  }
}
.u-verticalAlignCol {
  display: flex;
  align-items: center;
  justify-content: center;
  flex-direction: column;
}
.u-padding--noleft {
  padding-left: 0px !important;
}
.u-padding--noright {
  padding-right: 0px !important;
}
.u-marginCenter {
  margin: 0 auto;
}

// Padding Classes // ------
.u-noPadding--x {
  padding-left: 0px;
  padding-right: 0px;
}
.u-padding--1 {
  padding: 5px;
}
.u-padding--2 {
  padding: 10px;
}
.u-padding--3 {
  padding: 15px;
}
.u-padding--4 {
  padding: 30px;
}
.u-padding--5 {
  padding: 50px;
}
.u-padding--6 {
  padding: 70px;
}
.u-padding--7 {
  padding: 100px;
}
// Padding Left/Right // ---
.u-padding--1x {
  padding-left: 5px;
  padding-right: 5px;
}
.u-padding--2x {
  padding-left: 10px;
  padding-right: 10px;
}
.u-padding--3x {
  padding-left: 15px;
  padding-right: 15px;
}
.u-padding--4x {
  padding-left: 30px;
  padding-right: 30px;
}
.u-padding--5x {
  padding-left: 50px;
  padding-right: 50px;
}
.u-padding--6x {
  padding-left: 70px;
  padding-right: 70px;
}
.u-padding--7x {
  padding-left: 100px;
  padding-right: 100px;
}
// Padding Top Bottom // ---
.u-padding--1y {
  padding-top: 5px;
  padding-bottom: 5px;
}
.u-padding--2y {
  padding-top: 10px;
  padding-bottom: 10px;
}
.u-padding--3y {
  padding-top: 15px;
  padding-bottom: 15px;
}
.u-padding--4y {
  padding-top: 30px;
  padding-bottom: 30px;
}
.u-padding--5y {
  padding-top: 50px;
  padding-bottom: 50px;
}
.u-padding--6y {
  padding-top: 60px;
  padding-bottom: 60px;
}
.u-padding--7y {
  padding-top: 70px;
  padding-bottom: 70px;
}
.u-paddingLg--8y {
  padding-top: 80px;
  padding-bottom: 80px;
  @include media-breakpoint-down(lg){
    padding-top: 50px;
    padding-bottom: 50px;
  }
}

// Margin Classes // ------
.u-noMargin--x {
  margin-left: 0px;
  margin-right: 0px;
}
.u-noMargin--y {
  margin-top: 0px !important;
  margin-bottom: 0px !important;
}
.u-margin--1 {
  margin: 5px;
}
.u-margin--2 {
  margin: 10px;
}
.u-margin--3 {
  margin: 15px;
}
.u-margin--4 {
  margin: 30px;
}
.u-margin--5 {
  margin: 50px;
}
.u-margin--6 {
  margin: 70px;
}
.u-margin--7 {
  margin: 100px;
}
// Margin Left/Right // ---
.u-margin--1x {
  margin-left: 5px;
  margin-right: 5px;
}
.u-margin--2x {
  margin-left: 10px;
  margin-right: 10px;
}
.u-margin--3x {
  margin-left: 15px;
  margin-right: 15px;
}
.u-margin--4x {
  margin-left: 30px;
  margin-right: 30px;
}
.u-margin--5x {
  margin-left: 50px;
  margin-right: 50px;
}
.u-margin--6x {
  margin-left: 70px;
  margin-right: 70px;
}
.u-margin--7x {
  margin-left: 100px;
  margin-right: 100px;
}
// Margin Top Bottom // ---
.u-margin--1y {
  margin-top: 5px;
  margin-bottom: 5px;
}
.u-margin--2y {
  margin-top: 10px;
  margin-bottom: 10px;
}
.u-margin--3y {
  margin-top: 15px;
  margin-bottom: 15px;
}
.u-margin--4y {
  margin-top: 30px;
  margin-bottom: 30px;
}
.u-margin--5y {
  margin-top: 50px;
  margin-bottom: 50px;
}
.u-margin--6y {
  margin-top: 70px;
  margin-bottom: 70px;
}
.u-margin--7y {
  margin-top: 100px;
  margin-bottom: 100px;
}
// Custom Margins // ------
.u-marginTop--10px {
  margin-top: 10px;
}
.u-marginTop--20px {
  margin-top: 20px;
}
.u-marginTop--40px {
  margin-top: 40px;
}
.u-marginBottom--40px {
  margin-bottom: 40px;
}
// Floats // ------
.u-floatRight {
  float: right;
}
.u-floatLeft {
  float: left;
}
.u-floatNone {
  float: none !important;
}

// Design Utils // ------
.u-bgFilterLight {
  &:before {
    position: absolute;
    content:" ";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0.25);
  }
}
.u-bgFilterDark {
  @extend .u-bgFilterLight;
  &:before {
    background-color: rgba(22,22,22,0.25);
  }
}
.u-bgFilterPurple {
  @extend .u-bgFilterLight;
  &:before {
    background-color: rgba(67,41,115,0.25);
  }
}
// Gradient Filters
.u-bgFilterDarkBottomGradient {
  @extend .u-bgFilterLight;
  &:before {
    background-image: linear-gradient(-180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 55%, rgba(0,0,0,0.35) 90%, rgba(0,0,0,0.5) 100%);
    background-color: rgba(255,255,255,0);
  }
}
.u-bgFilterWhiteBottomGradient {
  @extend .u-bgFilterLight;
  &:before {
    background-image: linear-gradient(-180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 55%, rgba(255,255,255,1) 90%, rgba(255,255,255,1) 100%);
    background-color: rgba(255,255,255,0);
  }
}
// Blue Gradient Filters
.u-bgFilterBlueGradient {
  @extend .u-bgFilterLight;
  &:before {
    background-image: linear-gradient(-180deg, rgba(49,56,60,0.00) 2%, rgba(72,135,181,0.35) 100%);
  }
}
.u-bgFilterBlueGradientLight {
  @extend .u-bgFilterLight;
  &:before {
    background-image: linear-gradient(-180deg, rgba(49,56,60,0.00) 2%, rgba(72,135,181,0.15) 100%);
  }
}
.u-bgFilterBlueGradientTop {
  @extend .u-bgFilterLight;
  &:before {
    background-image: linear-gradient(-180deg, rgba(72,135,181,0.5) 2%, rgba(49,56,60,0.00) 25%);
    background-color: rgba(255,255,255,0);
  }
}
.u-bgFilterBeachGradient {
  @extend .u-bgFilterLight;
  &:before {
    background-image: linear-gradient(-180deg,rgba(64,139,144,.5) 0, rgba(64,139,144,0.45) 50%,  rgba(64,139,144,0.25) 65%,rgba(255,255,255,0) 75%);
    background-color: rgba(255,255,255,0);
  }
}
// Filter on hover
.u-bgFilterLightHover {
  &:before {
    position: absolute;
    content:" ";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-color: rgba(255,255,255,0);
    transition: all .25s ease;
    -webkit-transition: all .25s ease;
  }
}
.u-bgFilterLightHover:hover, .u-bgFilterLightHover:focus {
  &:before {
    background-color: rgba(255,255,255,0.25);
  }
}
.u-transparentBg {
  background-color: transparent !important;
}
.u-grayBg {
  background-color: $cool_very_light_gray;
}