.ce-transaction {
  .ce-incrementer {
    .minus,
    .plus {
      cursor: pointer;
      font-size: 24px;
      z-index: 1;
      color: $cool_gray;
      flex: 0 1 auto;
      &:hover, &:focus, &:active {
        color: $cool_purple;
      }
    }
    .minus {
      order: 1;
    }
    .ce-incrementer_value {
      order: 2;
      font-weight: $cool_font-semi-bold-weight;
      font-size: $mod_font_size_h5;
      text-align: center;
      padding-top: 5px;
      padding-bottom: 5px;
      min-width: 1px;
      border: 2px solid $cool_gray;
      border-radius: 0;
      -webkit-appearance: none;
      -moz-appearance: none;
      appearance: none;
      &:focus,
      &:active,
      &:hover {
        border-color: $cool_purple;
      }
    }
    .plus {
      order: 3;
    }
    @include media-breakpoint-up(lg) {
      .ce-incrementer_value {
        font-size: $mod_font_size_h5_lg;
      }
    }
  }

  .ce-modal_footer {
    .btn-secondary {
      margin-top: 20px;
    }
  }

  .exceeds-inventory {
    font-size: 13px;
    font-weight: $cool_font-semi-bold-weight;
    color: $cool_red;
    margin-top: 4px;
    margin-bottom: 0;
    line-height: 1.2;
    [class^="ce-icon-"],
    [class*="ce-icon-"] {
      font-size: 14px;
      vertical-align: middle;
    }
  }

  .price {
    font-weight: $cool_font-semi-bold-weight;
    color: $cool_dark_gray;
    line-height: 1;
    margin-top: 4px;
    &.price-project {
      margin-top: 1rem;
    }
  }

  .exceeds-inventory ~ .price.price-project {
    margin-top: 4px;
  }

  &.ce-sidebar {
    .cta {
      margin-top: 1.5rem;
    }
  }
}

.addtocart.single {
  .price,
  .ce-button-primary {
    position: relative;
    display: inline-block;
    float: left;
    height: 48px;
  }
  .price {
    padding: 7px;
    margin-right: 7px;
    height: 48px;
    color: $cool_purple;
    text-transform: uppercase;
    background-color: $cool_white;
    border: 2px solid $cool_purple;
    font-weight: $cool_font-bold-weight;

    display: flex;
    align-items: center;
    justify-content: center;

    .price_cluster {
      * {
        position: relative;
        display: block;
        vertical-align: top;
        white-space: nowrap;
        line-height: 1;
        float: left;
      }
      .price_currency {
        font-size: 16px;
        font-weight: $cool_font-regular-weight;
      }
      .price_whole {
        font-size: 36px;
        line-height: 28px;
      }
      .price_decimal {
        font-size: 16px;
      }
      .price_amount {
        font-size: 12px;
        white-space: nowrap;
        clear: left;
        font-weight: $cool_font-semi-bold-weight;
      }
      .price_right {
        margin-left: 1px;
      }
    }
  }
  .ce-button-primary {
    display: flex;
    justify-content: center;
    align-items: center;
    line-height: 1;
  }
}

.ce-incrementer {
  .minus,
  .plus {
    cursor: pointer;
    color: $cool_gray;
    &:hover, &:focus, &:active {
      color: $cool_purple;
    }
  }
}


