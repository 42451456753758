.lightbox--overlay {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: none;
  background-color: rgba(0,0,0,0.55);
  z-index: 2000;
}
.lightbox--overlay.is-visible {
  display: block;
}
.lightbox {
  display: -webkit-box;
  display: -webkit-flex;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -webkit-align-items: center;
  align-items: center;
  min-height: 100vh;
  -webkit-box-pack: center;
  -ms-flex-pack: center;
  -webkit-justify-content: center;
  justify-content: center;
  z-index: 2001; // TODO: Implement reasonable 1-9 z-index scale. https://medium.com/media/d2ea84fa40689d84cd2fddf9f7800916 - ae: 11.8.16
}
.lightbox .lightbox--content {
  position: relative;
  -webkit-box-flex: 0;
  -webkit-flex: none;
  -ms-flex: none;
  flex: none;
  width: 100vw;
  max-width: 350px;
  @include media-breakpoint-down(sm) {
    max-width: 90%;
  }
}
.lightbox--close {
  position: absolute;
  top: 0px;
  right: -45px;
  font-size: 30px;
  color: rgba(255,255,255,0.75);
  cursor: pointer;
  z-index: 2002;
  -webkit-transition: color .25s;
  transition: color .25s;
  &:hover {
    color: rgba(255,255,255,1);
  }
}
.lightbox--video {
  position: relative;
  width: 100%;
  height: 0;
  padding-bottom: 56.25%;
}
.lightbox--video iframe {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
}