//@import url("//hello.myfonts.net/count/391419");
@font-face {
  font-family: 'Veneer';
  src: url('/app/fonts/veneer/391419_0_0.eot');
  src:    url('/app/fonts/veneer/391419_0_0.eot?#iefix') format('embedded-opentype'),
  url('/app/fonts/veneer/391419_0_0.woff2') format('woff2'),
  url('/app/fonts/veneer/391419_0_0.woff') format('woff'),
  url('/app/fonts/veneer/391419_0_0.ttf') format('truetype');
}

.ce-module.earth-day-2019 {
  &.homepage-hero {
    .ce-banner-content-overlay {
      &.copy {
        text-align: left;
        padding-top: 20px;
        padding-bottom: 20px;
        margin-bottom: 0;
      }
    }
    h1 {
      font-family: Veneer;
      font-weight: normal;
      font-style: normal;
      font-size: 40px;
      line-height: .9;
    }
    @include media-breakpoint-up(sm) {
      h1 {
        font-size: 54px;
      }
    }
    @include media-breakpoint-up(md) {
      .ce-banner-content-overlay {
        &.copy {
          padding-left: $grid-gutter-width/2;
          padding-right: $grid-gutter-width/2;
        }
      }
      h1 {
        font-size: 54px;
      }
    }
    @include media-breakpoint-up(lg) {
      h1 {
        font-size: 72px;
      }
    }
    @include media-breakpoint-up(xl) {
      h1 {
        font-size: 80px;
      }
    }
  }
  &.donate-page {
    .header-title.primary {
      font-family: Veneer;
      font-weight: normal;
      font-style: normal;
      font-size: 34px;
    }
    @include media-breakpoint-up(sm) {
      .header-title.primary {
        font-size: 40px;
      }
    }
    @include media-breakpoint-up(md) {
      .header-title.primary {
        font-size: 40px;
      }
    }
    @include media-breakpoint-up(lg) {
      .header-title.primary {
        font-size: 40px;
      }
    }
    @include media-breakpoint-up(xl) {
      .header-title.primary {
        font-size: 48px;
      }
    }
  }
}
.earth-day-2019.promo-card {
  .ce-card-header {
    margin-bottom: 10px;
  }
  .card-title {
    font-family: Veneer;
    font-weight: normal;
    font-style: normal;
    line-height: 1.15;
    white-space: pre;
    font-size: 36px;
  }
  @include media-breakpoint-up(md) {
    .card-title {
      font-size: 40px;
    }
  }
  @include media-breakpoint-up(lg) {
    .card-title {
      font-size: 40px;
    }
  }
  @include media-breakpoint-up(xl) {
    .card-title {
      font-size: 40px;
    }
  }
}