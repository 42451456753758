// TODO - Rename this to masthead, makes more sense since we're routinely using it multiple times per page - ae 4.11.17

// Header Splash
.page-intro {
  position: relative;
  height: auto;
  padding: 0;
  overflow: hidden;
  @include media-breakpoint-up(md) {
    max-height: 560px;
  }
  @include media-breakpoint-up(lg) {
    max-height: 720px;
  }
}
.page-intro--90Height {
  height: 100vw;
  max-height: 90vh;
}
.page-intro--content {
  position: relative;
  width: 100%;
  @include media-breakpoint-down(md) {
    padding: 0 15px;
  }
}

// Intro Splash
.page-intro--content h1 {
  color: rgb(255,255,255); // WHY is there a color attribute on h elements by default? -ae 11.20.16
  @include media-breakpoint-up(lg) {
    font-size: 46px;
  }
}
.page-intro--content h3 {
  color: rgb(255,255,255);
}