.ce-module.stillin {
  &.broken-grid > .container > .row {
    @include media-breakpoint-down(sm) {
      display: block;
    }

    display: grid;
    display: -ms-grid;
    grid-template-columns: repeat(12, 1fr);
    grid-template-rows: repeat(10, auto);
    -ms-grid-columns: 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr 1fr;
    -ms-grid-rows: auto auto auto auto auto auto auto auto auto auto;

    .broken-grid-text-column-1 {
      padding: 70px;
      grid-column: 1 / span 6;
      grid-row: 6 / span 5;
      -ms-grid-column: 1;
      -ms-grid-column-span: 6;
      -ms-grid-row: 6;
      -ms-grid-row-span: 5;
    }
    .broken-grid-text-column-2 {
      padding: 70px;
      grid-row: 4 / span 4;
      grid-column: 7 / span 5;
      -ms-grid-column: 7;
      -ms-grid-column-span: 5;
      -ms-grid-row: 4;
      -ms-grid-row-span: 4;
      background-color: #f1f1e7;
    }
    .broken-grid-image-column {
      grid-column: 7 / span 5;
      grid-row: 8 / span 3;
      -ms-grid-column: 7;
      -ms-grid-column-span: 5;
      -ms-grid-row: 8;
      -ms-grid-row-span: 3;
      padding: 0;
    }
    .video-trigger-column {
      position: relative;
      padding-bottom: 56.25%;
      grid-column: 1 / span 12;
      grid-row: 1 / span 5;
      -ms-grid-column: 1;
      -ms-grid-column-span: 12;
      -ms-grid-row: 1;
      -ms-grid-row-span: 5;
      .ce-video-poster-trigger {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
      }
    }

  }
}
