// Local variables
$stat_slider_spacing: 1rem;
// Divider styles
@mixin create_ce-stat-slider-divider( $left_offset) {
  position: relative;
  &:before {
    background: $cool_gray;
    content: "";
    display: block;
    height: 100%;
    left: $left_offset;
    opacity: 0.4;
    position: absolute;
    width: 2px;
  }
}


/*
 * Stat Slider - Controls
*/

.ce-stat-slider_control-wrapper {
  position: relative; // For positioning the controls, this is a parent element to the slider
}

.ce-stat-slider_control_prev,
.ce-stat-slider_control_next {
  background: transparent;
  border: none;
  bottom: $stat_slider_spacing;
  margin: 0;
  outline: none;
  padding: $stat_slider_spacing;
  position: absolute;
  z-index: z-layer( "content-upper" );
  [class*="ce-icon-chevron-"] {
    color: $cool_white;
    display: block;
  }
}

.ce-stat-slider_control_prev {
  left: 0;
  .ce-icon-chevron-right {
    transform: rotate(180deg);
  }
}

.ce-stat-slider_control_next {
  right: 0;
}


/*
 * Stat Slider - Outer elements
*/

.ce-stat-slider_container {
  display: flex;
  align-items: flex-end;
  padding: $stat_slider_spacing 0 ( $mod_space_lg/2 );
  @include media-breakpoint-up( md ) {
    padding-bottom: $mod_space_lg;
  }
}

.ce-stat-slider {
  max-height: 110px; // Prevent loading error from pushing up content on mobile
  overflow: hidden;
  width: 100%;
  z-index: z-layer( "content" );
  @include media-breakpoint-up( md ) {
    max-height: initial;
    overflow: initial;
  }
  @include media-breakpoint-up(lg) {
    .slick-track,
    &.slick-initialized .slick-slide { // Override Slick style setting item to display as block
      display: flex;
    }
    .slick-track {
      align-items: stretch;
    }
    .slick-slide {
      height: auto;
    }
  }
}


/*
 * Stat Slider - Inner content
*/

.ce-stat-slider_slide {
  padding: 0 $stat_slider_spacing;
  &:focus {
    outline: 0;
  }
  //@include media-breakpoint-only( md ) {
  //  &:nth-child(odd) .ce-stat-slider_inner {
  //    align-items: flex-end;
  //    padding-right: ( $stat_slider_spacing * 2);
  //  }
  //  &:nth-child(even) .ce-stat-slider_inner {
  //    align-items: flex-start;
  //    padding-left: ( $stat_slider_spacing * 2);
  //    @include create_ce-stat-slider-divider(( -1 * $stat_slider_spacing));
  //  }
  //}
  @include media-breakpoint-up( lg ) {
    &:first-child .ce-stat-slider_inner {
      padding-right: $stat_slider_spacing;
    }
    &:not(:first-child) .ce-stat-slider_inner {
      padding: 0 $stat_slider_spacing;
      @include create_ce-stat-slider-divider(( -1 * $stat_slider_spacing));
    }
    &.drawdown {
      max-width: 225px;
    }
  }
  @include media-breakpoint-up( xl ) {
    &.drawdown {
      max-width: 380px;
    }
  }
}

.ce-stat-slider_inner {
  align-items: center;
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  width: 100%;
  @include media-breakpoint-up( lg ) {
    align-items: flex-start;
  }
}

.ce-stat-slider_header,
.ce-stat-slider_footer {
  text-align: center;

  br {
    display: none;
  }
  //@include media-breakpoint-up( md ) {
  //  text-align: left;
  //}
  @include media-breakpoint-up( lg ) {
    text-align: left;
    br {
      display: block;
    }
  }
}

.ce-stat-slider_header {
  flex: 1 0 auto;
  margin-bottom: $mod_space_sm;
  width: 100%;

  //@include media-breakpoint-up( md ) {
  //  margin-bottom: $stat_slider_spacing;
  //}
  @include media-breakpoint-up( lg ) {
    margin-bottom: 0;
  }
  .ce-stat-slider_label:only-child {
    font-weight: $cool_font-semi-bold-weight;
    display: block;
  }
}

.ce-stat-slider_label,
.ce-stat-slider_slide [class*="ce-icon-"] {
  color: $cool_gray;
}
.ce-stat-slider_label {
  //text-transform: capitalize;
  line-height: 1.3;
  //a {
  //  color: $cool_yellow;
  //  &:hover,
  //  &:focus {
  //    color: $cool_gray;
  //  }
  //}
}

.ce-stat-slider_stat {
  color: $cool_white;
  margin-bottom: 0;
}

h2.ce-stat-slider_stat {
  font-size: $mod_font_size_h1_lg;
}

.ce-stat-slider_icon-text,
.ce-stat-slider_columns {
  display: flex;
}

.ce-stat-slider_icon-text {
  align-items: center;
  [class*="ce-icon-"] {
    font-size: $mod_font_size_h2_lg;
  }
  >*:not(:first-child) {
    margin-left: ( $stat_slider_spacing * 0.25);
  }
  >*:not(:last-child) {
    margin-right: ( $stat_slider_spacing * 0.25);
  }
}

.ce-stat-slider_columns {
  >*:not(:first-child) {
    margin-left: $stat_slider_spacing;
  }
  >*:not(:last-child) {
    margin-right: $stat_slider_spacing;
  }
}
