.ce-certificate-banner {
  > .container > .row {
    justify-content: center;
  }
  @media print {
    padding: 0;
    .container {
      padding: 0;
      min-width: 0 !important;
      min-width: 0 !important;
      max-width: 9999px;
    }
  }
}

.ce-module .ce-certificate-container {
  position: relative;
  .recipient {
    margin-top: $mod_card_content_padding;
    .text-label,
    input {
      width: 100%;
    }
    @include media-breakpoint-up(lg) {
      margin-top: $mod_card_content_padding_lg;
    }
  }
  .actions {
    margin-bottom: $mod_card_content_padding;
    //text-align: left;
    .col-recipient,
    .col-download {
      display: flex;
      align-items: flex-end;
      width: auto;
    }

    .col-recipient {
      flex: 1 0 auto;
      form {
        width: 100%;

        input {
          width: 100%;
          height: 44px;
        }
      }
    }

    .col-download {
      flex: 0 1 auto;
    }
    .error-msg {
      margin-top: 10px;
    }
    .origami-cta {
      white-space: normal;
    }
    @include media-breakpoint-up(lg) {
      //.col-recipient,
      //.col-download,
      //.col-download .ce-button {
      //  width: 100%;
      //}
    }
    @media print {
      display: none;
    }
  }
}

@media print {
  body {
    min-width: 0 !important;
  }
}

.ce-module .ce-certificate {
  position: relative;
  .content {
    width: 11in;
    height: 8.5in;
    border: 1px solid black;
    position: absolute;
    top: 0;
    left: 0;
    transform-origin: top left;
    padding: .5in;
    .overlay {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      .copy {
        display: block;
        position: relative;
        margin-top: 446px;
      }
    }
    .outer-frame {
      position: relative;
      width: 100%;
      height: 100%;
      border: .1in solid $cool_dark_gray;
      display: flex;

      .image-wrapper {
        position: relative;
        width: 3.51in;
        height: 100%;
        flex: 1 0 auto;
        overflow: hidden;
        img {
          width: 100%;
          height: 100%;
        }
        .logo {
          position: absolute;
          left: 50%;
          top: 50%;
          transform: translate(-50%, -50%);
          color: $cool_white;
          font-size: .9in;
        }
      }

      .inner-frame {
        position: relative;
        flex: 0 1 auto;
        margin: .125in;
        width: 9.65in;
        .inner-frame-image {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%;
        }
        .copy {
          > * {
            width: 100%;
          }
          .copy-inner {
            position: relative;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
            width: 100%;
            height: 100%;
          }
          .copy-body {
            flex: 1 0 auto;
            display: flex;
            flex-direction: column;
            justify-content: center;
            > * {
              width: 100%;
            }
          }
          .copy-footer {
            flex: 0 1 auto;
            padding-top: .6in;
            > * {
              width: 100%;
            }
          }
          position: relative;
          width: 100%;
          height: 100%;
          padding: .75in .6in;
          text-align: center;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          color: $cool_dark_gray;
          .title {
            font-size: 30pt;
            margin-bottom: .5rem;
            white-space: pre-line;
          }
          p {
            line-height: 1.5;
            margin-bottom: 0;
            @include media-breakpoint-up(sm) {
              line-height: 1.5;
            }
          }
          .date-label {
            font-size: 12pt;
            line-height: 1.4;
          }
          .date {
            font-size: 12pt;
            line-height: 1.4;
            margin-bottom: .25rem;
          }
          .name {
            font-size: 24pt;
            font-weight: $cool_font-semi-bold-weight;
            white-space: pre-wrap;
            word-break: break-word;
            line-height: 1.2;
            margin-top: 5px;
            margin-bottom: 9px;
          }
          .impact {
            font-size: 12pt;
            line-height: 1.4;
            white-space: pre-line;
          }
          .event-name {
            font-size: 24pt;
            font-weight: $cool_font-semi-bold-weight;
            white-space: pre-wrap;
            word-break: break-word;
            line-height: 1.2;
            margin-top: 5px;
          }
          .quantity-label {
            font-size: 12pt;
            line-height: 1;
          }
          .quantity {
            font-size: 24pt;
            font-weight: $cool_font-semi-bold-weight;
            margin-bottom: .5rem;
          }
          .project-list-label {
            font-size: 9pt;
            font-weight: $cool_font-semi-bold-weight;
            margin-bottom: .25rem;
          }
          .project-list {
            font-size: 9pt;
          }
          .partner-logo-label {
            font-size: 12pt;
            margin-bottom: 8pt;
          }
          .partner-logo {
            img {
              width: 250px;
              &.xxs {
                width: 100px;
              }
              &.xs {
                width: 150px;
              }
              &.sm {
                width: 200px;
              }
              &.md {
                width: 250px;
              }
              &.lg {
                width: 300px;
              }
              &.xl {
                width: 350px;
              }
              &.xxl {
                width: 400px;
              }
            }
          }
          .separator {
            width: .5in;
            height: .033in;
            margin: 1rem auto 1rem auto;
            border-radius: 9999px;
            background: linear-gradient( to right, $cool_red 0, $cool_blue 100% );
          }
          .copy-body.business {
            .title {
              white-space: normal;
              .certification {
                line-height: 1;
              }
              .by {
                font-size: 16pt;
                margin-top: 4px;
                margin-bottom: 4px;
              }
            }
            .separator {
              margin: 48px auto 40px auto;
            }
            .quantity {
              margin-bottom: 2px;
            }
            .name-label {
              font-size: 12pt;
              margin-bottom: 0;
            }
            .name {
              margin-bottom: 2px;
            }
          }
        }
      }
    }
  }

  &.gift {
    .content {
      .outer-frame {
        .inner-frame {
          .copy {
            .title {
              line-height: 1;
              margin-bottom: .25rem;
            }
            .recipient-name {
              font-size: 22pt;
              margin-bottom: .6rem;
            }
            .sender-label {
              font-size: 12pt;
              line-height: 1.4;
              margin-bottom: .75rem;
            }
            .sender-name {
              font-size: 18pt;
            }
            .separator {
              margin: 1.4rem auto 1.4rem auto;
            }
            .about {
              font-size: 12pt;
              line-height: 1.4;
              margin-bottom: .75rem;
            }
            .thank-you {
              font-size: 12pt;
              font-weight: $cool_font-semi-bold-weight;
              line-height: 1.4;
            }
          }
        }
      }
    }
  }

  &.variant-1 {
    .content .outer-frame .image-wrapper .logo {
      color: $cool_purple;
      top: 95px;
      transform: translateX(-50%);
    }
  }
}

@media print {
  .grecaptcha-badge {
    display: none !important;
  }
  .ce-module .ce-certificate-container {
    display: flex;
    align-items: center;
    justify-content: center;
    overflow: hidden;
    .recipient {
      display: none;
    }
  }
  .ce-module .ce-certificate {
    margin: 0;
    width: 100%;
    height: 100%;

    .grid-overlay {
      display: none;
    }
    .content {
      transform: scale(1) !important;
      width: 100%;
      height: 100%;
      border: none;
      .logo {
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
      }
      .separator {
        -webkit-print-color-adjust: exact;
        color-adjust: exact;
      }
    }
  }
  @page {
    size: 11in 8.5in;
    margin: 0 !important;
  }
}
