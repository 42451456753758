.ce-module.salesforce {
  .module-header {
    @include media-breakpoint-down(md) {
      h3.header-title {
        &:before {
          display: none;
        }
      }
      .header-body {
        text-align: center;
      }
    }
  }

  .text-title {
    @include media-breakpoint-down(md) {
      text-align: center;
      &:before {
        display: none !important;
      }
    }
  }
  .text-body {
    @include media-breakpoint-down(md) {
      text-align: center;
    }
  }
}

.ce-module.hero.salesforce {
  $logo-h: 80px;
  height: 400px;
  background-size: cover;
  background-position: right center;

  .logos {
    @extend .col-12;
    display: flex;
    align-items: stretch;
    justify-content: center;
    height: $logo-h;
    .logo-salesforce {
      height: $logo-h;
      display: flex;
    }
  }




  @include media-breakpoint-down(md) {
    height: 300px;
    .module-header {
      display: none;
    }


    > .container {
      height: 100%;
      > .row {
        height: 100%;
        display: flex;
        align-items: center;
      }
    }
  }
}
