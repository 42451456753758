// Splash Intro Content
.ourStory {
  overflow-x: hidden;
}
.ourStory .page-intro--content {
  width: 100%;
  margin-top: -150px; // Match margin-top on Video block
  @include media-breakpoint-up(sm) {
    margin-top: -150px; // Match margin-top on Video block
  }
}
.ourStory .page-intro--content h1 {
  font-size: 26px;
  line-height: 30px;
  @include media-breakpoint-only(sm) {
    font-size: 34px;
    line-height: 38px;
  }
  @include media-breakpoint-only(md) {
    font-size: 34px;
    line-height: 38px;
  }
  @include media-breakpoint-up(lg) {
    font-size: 40px; // This is in conflict to the style elsewhere in the site, bad for globalization. -ae 3.28.17
    line-height: 44px
  }
}

.ourStory .page-intro--content h3 {
  font-size: 16px; // This is also bad, don't make styles for specific pages.
                  // We have a front-end framework for a reason, design needs to learn/respect that. -ae 3.28.17
}

.ourStory .container {
  @include media-breakpoint-down(md) {
    padding-left: 40px;
    padding-right: 40px;
  }
}

// Cloud Styling
.clouds {
  position: absolute;
  width: 100%;
  height: auto;
  overflow-y: visible;
  z-index: 100;
  transform: translateY(-300px);
  visibility: visible;
  opacity: 1;
  transition: visibility 0s, opacity 0.75s linear;
  @include media-breakpoint-down(lg) {
    visibility: hidden;
    opacity: 0;
    transition: visibility 0s, opacity 0.75s linear;
  }
}

.clouds-xMask {
  position: fixed;
  width: 100%;
  pointer-events: none;
}

.cloudLeft {
  float: left;
  width: 50%;
  transform: scale(1.5, 1.5) translateX(10%);
  opacity: 1;
  transition: opacity 2.5s;
}
.cloudLeft img {
  width: 100%;
  height: auto;
  transform: rotate(-129deg);
}
.cloudRight {
  float: right;
  width: 50%;
  transform: rotate(-227deg) translateX(-10%) translateY(50px) scale(1.45, 1.45);
  opacity: 1;
  transition: opacity 2.5s;
}
.cloudRight img {
  width: 100%;
  height: auto
}

.is-hidden {
  visibility: hidden;
}

// Animate Cloud Passive
@keyframes rotatingRight {
  from {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
  to {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
}
.rotatingRight {
  -webkit-animation: rotatingRight 400s linear infinite;
  -moz-animation: rotatingRight 400s linear infinite;
  -ms-animation: rotatingRight 400s linear infinite;
  -o-animation: rotatingRight 400s linear infinite;
  animation: rotatingRight 400s linear infinite;
}
@keyframes rotatingLeft {
  from {
    -ms-transform: rotate(360deg);
    -moz-transform: rotate(360deg);
    -webkit-transform: rotate(360deg);
    -o-transform: rotate(360deg);
    transform: rotate(360deg);
  }
  to {
    -ms-transform: rotate(0deg);
    -moz-transform: rotate(0deg);
    -webkit-transform: rotate(0deg);
    -o-transform: rotate(0deg);
    transform: rotate(0deg);
  }
}
.rotatingLeft {
  -webkit-animation: rotatingLeft 340s linear infinite;
  -moz-animation: rotatingLeft 340s linear infinite;
  -ms-animation: rotatingLeft 340s linear infinite;
  -o-animation: rotatingLeft 340s linear infinite;
  animation: rotatingLeft 340s linear infinite;
}

// Animate Cloud Exit
.plzGo {
  transition: all 3.5s cubic-bezier(0.58, 0.07, 0.43, 0.98);
}
.cloudLeft.plzGo {
  transform: scale(1.5, 1.5) translateX(10%) translate3d(-700px, -250px, -700px) !important;
  opacity: 0;
}
.cloudRight.plzGo {
  transform: rotate(-227deg) scale(1.45, 1.45) translate3d(-500px, -900px, 0px) !important;
  opacity: 0;
}

// Video Styling
.ourStory-video {
  position: relative;
  margin-top: -150px;
  margin-bottom: 95px;
  z-index: 50;
  @include media-breakpoint-down(md) {
    margin-bottom: 20px;
  }
}

.ourStory-video .videoWrapper {
  @include media-breakpoint-down(md) {
    height: auto;
    padding-bottom: 0;
  }
}

.ourStory-videoSplash {
  width: calc(100% - 30px);
  margin-left: 15px;
  z-index: 10;
  @include media-breakpoint-down(md) {
    display: none;
  }
  .play-button-wrapper {
    width: 100%;
    height: 62px;
    margin: 30px 0;
    position: relative;
    .play-button {
      cursor: pointer;
      border: 2px solid $cool_white;
      width: 62px;
      height: 62px;
      display: block;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translate(-50%,0);
      border-radius: 9999px;
      opacity: 1;
      transition: all 0.3s ease;
      &:after {
        content: '';
        display: block;
        position: absolute;
        width: 0;
        height: 0;
        border-top: 10px solid transparent;
        border-bottom: 10px solid transparent;
        border-left: 17px solid $cool_white;
        top: 50%;
        left: 54%;
        transform: translate(-44%,-50%);
      }
      &:hover {
        background-color: $cool_white;
        &:after {
          border-left: 17px solid $cool_blue;
        }
      }
    }
  }
}

.ourStory-videoSplash.bg-imgHolder {
  background: rgba(255,255,255,0);
}

.ourStory-videoContent {
  @include media-breakpoint-down(md) {
    display: none;
  }
}

.ourStory-videoContent h1,
.ourStory-videoContent h3 {
  color: $cool_white;
  margin-bottom: 0;
}

.page-content {
  position: relative;
  z-index: 200;
}

// Pull Quote Styling
.pull-quote:before {
  font-size: 110px;
  font-style: normal;
  content: "\201C";
  line-height: 1;
  position: absolute;
  top: 0;
  left: 0;
  opacity: 0.1;
  @include media-breakpoint-up(lg) {
    font-size: 176px;
    top: -28px;
  }
}
.pull-quote {
  text-align: right;
  @include media-breakpoint-down(lg) {
    text-align: left;
  }
}
.pull-quote h3 {
  font-size: 28px;
  line-height: 40px;
  text-indent: 48px;
  @include media-breakpoint-down(md) {
    text-indent: 0;
    font-size: 18px;
    line-height: 24px;
    padding-left: 30px;
    margin-top: 14px; // This is to make things consistent with design requests and is not a good decision for global consistency. - ae 3.28.17
    margin-bottom: 30px;
  }
}

.pull-title h3 {
  font-size: 24px;
  line-height: 32px;
  text-align: right;
  @include media-breakpoint-down(lg) {
    font-size: 18px;
    text-align: center;
  }
}

.pull-title, .pull-quote {
  @include media-breakpoint-up(lg) {
    padding-right: 30px;
  }
}

// Body Content Styling
.ourStory .page-content {
  margin-bottom: 105px;
  @include media-breakpoint-down(md) {
    margin-bottom: 45px;
  }
}
.ourStory .row {
  margin-bottom: 40px;
  @include media-breakpoint-down(md) {
    margin-bottom: 0;
  }
}

.ourStory .row:last-child {
  margin-bottom: 0;
}

// Images
.img-lg {
  height: 425px;
}

.img-md {
  height: 325px;
}

.img-lg, .img-md {
  margin-bottom: 10px;
  @include media-breakpoint-down(xs) {
    height: 200px;
  }
}

.captionPhoto {
  margin-bottom: 40px;
  @include media-breakpoint-down(md) {
    margin-bottom: 28px;
  }
}

.img-caption {
  font-size: .875em;
  line-height: 20px;
}

// Signature Styling
.signature {
  display: inline-block;
  max-width: 190px;
  max-height: 100px;
  width: 100%;
  height: auto;
  @include media-breakpoint-down(md) {
    max-width: 150px;
  }
}
.signature:first-child {
  margin-right: 30px;
  @include media-breakpoint-down(md) {
    margin-right: 15px;
  }
}

// Bold Link
.bold-link {
  font-weight: 600;
  text-transform: uppercase;
  display: block;
}