@mixin center_ce-list-item_content {
  align-items: center;
  display: flex;
  justify-content: center;
  position: relative;
}
@mixin set_ce-list-item_gutter() {
  padding-left: $grid-gutter-width/2;
  padding-right: $grid-gutter-width/2;
}
.ce-image-list {
  align-items: center;
  display: flex;
  flex-wrap: wrap;
  flex-direction: row;

  margin-left: (-1 * ( $grid-gutter-width/2 ));
  margin-right: (-1 * ( $grid-gutter-width/2 ));
  margin-bottom: $mod_space_lg;

  > .ce-image-list-item {
    @include center_ce-list-item_content();
    @include set_ce-list-item_gutter();
    padding-bottom: ( $mod_space_lg / 2 );
  }
}
.ce-image-list-item {
  > * {
    width: 100%;
  }
  &.xs > * {
    width: percentage(1/6);
  }
  &.sm > * {
    width: percentage(2/6);
  }
  &.md > * {
    width: percentage(3/6);
  }
  &.lg > * {
    width: percentage(4/6);
  }
  &.xl > * {
    width: percentage(5/6);
  }
}

.ce-image-list-image {
  display: block;
  width: 100%;
  height: auto;
}
