.ce-module .offset-calculator {
  .col-inputs {
    display: flex;
    align-items: center;
    .actions {
      text-align: center;
      margin-top: $mod_space_xs;
    }
  }
  .row.inputs {
    width: auto;
  }
  .purchase {
    .tonnes-calculated {
      .circle {
        .inner {
          background-color: $cool-white;
        }
      }
    }
  }

  .purchase {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    background-color: $cool_white;
    width: 100%;
    //height: 100%;
    padding: 30px 30px;

    .summary {
      margin-bottom: 40px;
      font-weight: $cool_font-semi-bold-weight;
      color: $cool_dark_gray;
      .per {
        color: $cool_medium_gray;
        text-transform: capitalize;

      }
      &.tonnes-summary {
        .per {
          font-size: 13px;
        }
        .total {
          font-size: 27px;
        }
      }
      &.price-summary {
        .total {
          font-size: 36px;
        }
        .per {
          font-size: $mod_font_size_small;
        }
      }
    }
    .title {
      margin-bottom: 0;
      text-transform: uppercase;
      font-size: 18px;
    }
    .price {
      .total {
        font-size: 27px;
      }
    }
  }

  .input-tonnes-col {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    .text-label {
      text-transform: capitalize;
      justify-content: center;
    }
    .text-label,
    input {
      max-width: 125px;
      display: block;
      text-align: center;
      font-size: 1rem;
      font-weight: $cool_font-semi-bold-weight;
    }
  }
  .switch-mode {
    text-align: center;
    align-self: stretch;
  }

  .flipcard-inner a {
    text-decoration: underline;
  }

  @include media-breakpoint-up(lg) {
    .col-inputs {
      .actions {
        text-align: left;
        margin-top: $mod_space_lg;
      }
    }
    .purchase {
      .summary {
        &.price-summary {
          .title {
            font-size: 1rem;
          }
          .total {
            font-size: 36px;
          }
          .per {
            font-size: $mod_font_size_small;
          }
        }
      }
    }
  }
  &.eyot {
    .col-inputs {
      .flip-card-back {
        display: flex;
        flex-direction: column;
        align-items: center;
        text-align: center;
        .title {
          text-transform: uppercase;
          font-weight: $cool_font-semi-bold-weight;
          font-size: 18px;
          color: $cool_dark_gray;
        }
        form {
          flex: 1 0 auto;
          > .row {
            height: 100%;
          }
        }
      }
      .actions {
        text-align: center;
        margin-top: $mod_space_xs;
      }
    }
    @include media-breakpoint-up(lg) {
      &.manual {
        .col-inputs {
          // disable calculator
          pointer-events: none;
          opacity: .35;
          filter: grayscale(100%);
        }
      }
    }
  }
}
