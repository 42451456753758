.ce-module.hero {
  background-repeat: no-repeat;
  background-size: cover;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: stretch;
  > .container {
    width: 100%;
  }
  .module-header {
    .header-title:before {
      display: none;
    }
  }
}

.ce-module.ce-video-banner.hero-video {
  display: block;
  text-align: center;
  height: auto;
  @include media-breakpoint-down(md) {
    padding-top: 0;
  }
  video {
    position: relative;
    width: 100%;
    height: auto;
    min-height: 0;
    margin-bottom: $mod_space_xl;
    top: 0;
    left: 0;
    transform: none;
  }

  .module-header {
    .header-title {
      color: $cool_purple;
    }
    .header-body {
      color: $cool_dark_gray;
    }
  }

  .see-more {
    display: none;
  }

  @include media-breakpoint-up(lg) {
    height: 55vw;
    max-height: 70vh;
    display: flex;
    align-items: center;
    video {
      position: absolute;
      width: 100%;
      height: auto;
      margin-bottom: 0;
      top: auto;
      bottom: 0;
      transform: none;
    }
    > .container {
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      justify-content: center;

      .see-more {
        display: block;
        position: absolute;
        bottom: 0;
        .ce-icon-chevron-right {
          display: block;
          transform: rotate(90deg);
          color: $cool_white;
          font-size: 26px;
        }
      }
      .module-header {
        .pre-title,
        .header-title {
          color: $cool_white;
        }
        .header-body {
          color: $cool_white;
        }
      }
      .ce-button-tertiary {
        color: $cool_white;
        padding-left: 0;
        padding-right: 0;
        border-bottom: 1px solid transparent;
        transition: border 0.15s ease;
        &:hover {
          border-color: $cool_white;
        }
      }
    }
  }
  @include media-breakpoint-up(xxl) {
    max-height: 80vh;
  }
}

.ce-module.ce-hero {
  padding: 0 0 $mod_card_content_padding 0;
  > .bg-img-wrapper {
    position: relative;
    padding-top: 42%;
    width: 100%;
    height: auto;
    margin-bottom: $mod_card_content_padding;
    .bg-img {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      background-position: center;
    }
  }
  > .container > .row {
    justify-content: center;
  }
  .module-header {
    @extend .col-md-auto;
    @extend .offset-md-0;
  }
  @include media-breakpoint-up(md) {
    padding: 0;
    &.vertical-size-sm {
      height: 300px;
    }
    &.vertical-size-md {
      height: 450px;
    }
    &.vertical-size-lg {
      height: 600px;
    }
    > .bg-img-wrapper {
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      left: 0;
      padding: 0;
      margin-bottom: 0;
    }
    > .container > .row {
      height: 100%;
      flex-direction: column;
      align-items: center;
    }
    .module-header {
      background-color: $cool_white;
      padding: 20px 60px;
    }
  }
  @include media-breakpoint-up(lg) {
    .module-header {
      padding: 20px 90px;
    }
  }
}