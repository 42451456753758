/*
 * Title: Video Modal
 * Type: Element
 * Dependencies: (Bootstrap) modal.scss, (CoolEffect) modal.scss
*/

.ce-modal.ce-modal-video {

	&[role="dialog"] > .modal-dialog {
		@include media-breakpoint-up(sm) {
			max-width: 90%;
		}
  	}
	.ce-modal_content {
		padding: 0px;
		// Override padding between content
		// because there's only one child (video)
		> * {
			padding: 0;
		}
		margin-bottom: 0 !important;

		@include media-breakpoint-up(sm) {
        	padding: 0;
    	}
	}
	.ce-modal_body {
		height: 0; // Reset height for Vimeo videos
		overflow: hidden;
		padding: 0 0 56.25% 0;
		position: relative;

		iframe,
		object,
		embed {
			border: 0;
			height: 100%;
			left: 0;
			position: absolute;
			top: 0;
			width: 100%; 
		}
	}

	.ce-modal_close {
	    top: -32px;
	    right: 0px;
	    color: $cool_white;
		&:hover, &:active, &:focus,
		:hover, :active, :focus {
			color: $cool_light_gray;
		}

	    @include media-breakpoint-up(md) {
	        top: 0px;
	        right: -25px;
	    }
	}
}