.ce-module {
  &.holiday-2019,
  .holiday-2019 {
    $holidayOrange: #ffaa19;
    .promo-heading,
    &.promo-card .holiday-heading {
      text-align: center;
      font-size: 22px;
      font-weight: $cool_font-bold-weight;
      text-shadow: 2px 2px 2px rgba(0,0,0,.5);
      line-height: 1;
      .hashtag {
        color: $holidayOrange;
      }
    }
    .promo-body,
    &.promo-card .holiday-body {
      display: flex;
      flex-direction: column;
      align-items: flex-start;
      .highlight {
        font-size: 17px;
        color: $cool_purple;
        background-color: $cool_white;
        font-weight: $cool_font-bold-weight;
        line-height: 1;
        padding: 3px 5px;
      }
      .highlight + .highlight {
        margin-top: 5px;
      }
    }
    &.homepage-hero {
      .promo-col {
        padding-bottom: $grid-gutter-width;
        .promo-col-inner {
          > a {
            display: block;
            padding: 15px;
            width: 100%;
            height: 325px;
            display: flex;
            flex-direction: column;
            justify-content: space-between;
          }
        }
      }
      @include media-breakpoint-up(sm) {
        .promo-col {
          .promo-col-inner {
            > a {
              padding: 20px;
            }
          }
        }
      }
      @include media-breakpoint-up(md) {
        padding-bottom: $mod_padding_v;
        .promo-col {
          .promo-col-inner {
            > a {
              padding: 20px;
              height: 375px;
            }
            .promo-heading {
              font-size: 26px;
            }
          }
        }
      }
      @include media-breakpoint-up(lg) {
        padding-bottom: 0;
        .promo-col {
          padding-bottom: 0;
          padding-right: 0;
          .promo-col-inner {
            //margin-right: -$grid-gutter-width/2;
            height: 100%;
            > a {
              padding: 20px;
              height: 100%;
            }
            .promo-heading {
              font-size: 26px;
            }
            .promo-body {
              .highlight {
                font-size: 24px;
                padding: 3px 5px;
              }
              .highlight + .highlight {
                margin-top: 8px;
              }
            }
          }
        }
      }
      @include media-breakpoint-up(xl) {
        .promo-col {
          .promo-col-inner {
            .promo-heading {
              font-size: 30px;
            }
            .promo-body {
              .highlight {

              }
              .highlight + .highlight {
              }
            }
          }
        }
      }
    }
    &.promo-card {
      .ce-card-content {
        height: 100%;
        padding: 18px;
        .ce-card-header {
          display: none;
        }
        .ce-card-body {
          margin-top: 0;
          display: flex;
          flex-direction: column;
          justify-content: space-between;
          flex: 1 0 auto;
          .holiday-heading {
            flex: 1 0 auto;
            font-size: 30px;
          }
          .holiday-body {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            margin-bottom: 1rem;
          }
        }
      }
      .ce-button {
        background-color: $holidayOrange;
        border-color: $holidayOrange;
        color: $cool_purple;
        &:hover,
        &:active,
        &:focus {
          background-color: $cool_yellow;
          border-color: $cool_yellow;
        }
      }
      @include media-breakpoint-up(lg) {
        .ce-card-content {
          .ce-card-body {
            .holiday-heading {
              font-size: 26px;
            }
            .holiday-body {
              .highlight {
                font-size: 16px;
              }
            }
          }
        }
      }
      @include media-breakpoint-up(xl) {
        .ce-card-content {
          .ce-card-body {
            .holiday-heading {
              font-size: 30px;
            }
            .holiday-body {
              .highlight {
                font-size: 18px;
              }
            }
          }
        }
      }
    }
  }
}

