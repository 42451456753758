.ce-module {
  .title {
    margin-bottom: 1.2rem;
  }
  .title.primary {
    &:before {
      content: '';
      display: block;
      border-top: 2px solid;
      width: 50px;
      margin: 0 auto 13px auto;
    }
  }
  .title.featured {
    &:after {
      content: '';
      display: block;
      width: 100px;
      height: 4px;
      margin: 15px auto 0 auto;
      border-radius: 9999px;
      background: linear-gradient(to right, $cool_red 0, $cool_blue 100%);
    }
  }
  .title.icon-title {
    display: flex;
    align-items: center;
    .ce-icon-circle {
      margin-right: 20px;
    }
    h1, h2, h3, h4, h4, h5 {
      margin-bottom: 0;
    }
  }
  .title-left {
    @extend .title;
    &:before {
      margin-left: 0;
    }
  }

  .pre-title {
    font-size: $mod_font_size_small;
    font-weight: $cool_font-semi-bold-weight;
    &:before {
      content: '\2014   ';
      display: inline-block;
      vertical-align: middle;
      margin-top: -5px;
      margin-right: 8px;
    }
  }

  .byline {
    font-size: $mod_font_size_small;
    font-weight: $cool_font-semi-bold-weight;
  }
}

