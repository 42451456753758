.ce-module.header-nav-module {
  // animation
  @include media-breakpoint-up(lg) {
    height: $header-nav-height-desktop;
    transition: height $heading-duration/2 ease $heading-duration/2;
    nav.navbar.desktop {
      height: $header-nav-height-desktop;
      transition: height $heading-duration/2 ease $heading-duration/2;
      .logo {
        width: 52px;
        height: 70px;
        transition: width $heading-duration/2 ease,
        height $heading-duration/2 ease $heading-duration/2;
        .ce-icon-logo {
          font-size: 70px;
          opacity: 1;
          width: 52px;
          transition: opacity $heading-duration/2 ease,
          font-size $heading-duration/2 ease $heading-duration/2;
        }
        .ce-copy-logo {
          opacity: 0;
          transition: opacity $heading-duration/2 ease;
        }
      }
    }
    &.minimized {
      height: $header-nav-height-minimized-desktop;
      transition: height $heading-duration/2 ease;
      nav.navbar.desktop {
        height: $header-nav-height-minimized-desktop;
        transition: height $heading-duration/2 ease;
        .logo {
          width: 157px;
          height: 18px;
          transition: width $heading-duration/2 ease $heading-duration/2,
          height $heading-duration ease;
          .ce-icon-logo {
            font-size: 50px;
            opacity: 0;
            transition: opacity $heading-duration/2 ease $heading-duration/2,
            font-size $heading-duration/2 ease;
          }
          .ce-copy-logo {
            opacity: 1;
            transition: opacity $heading-duration/2 ease $heading-duration/2;
          }
        }
      }
    }
    // end animation
    .logo {
      overflow: hidden;
      .ce-icon-logo,
      .ce-copy-logo {
        position: absolute;
        top: 0;
        left: 2px;
        &:before {
          position: absolute;
          top: 0;
          left: 0px;
        }
      }
      .ce-copy-logo {
        font-size: 24px;
        overflow: hidden;
        line-height: .75;
      }
    }
  }
  //
  nav.navbar.desktop {
    @include media-breakpoint-down(md) {
      display: none;
    }
    a:hover, a:focus, a:active {
      text-decoration: none;
      outline: none;
    }
    border-radius: 0;
    padding: 0 $grid-gutter-width/2;
    width: 100%;
    height: $header-nav-height-desktop;
    display: flex;
    justify-content: space-between;
    align-items: center;
    .navbar-dropdown {
      display: flex;
      flex: 1 0 auto;
      height: 100%;
      .navbar-main-menu {
        display: flex;
        align-items: stretch;
        padding: 0;
        margin: 0 0 0 15px;
        list-style: none;
        .nav-item {
          position: relative;
          padding: 0;
          text-transform: uppercase;
          font-weight: $cool_font-semi-bold-weight;
          display: flex;
          align-items: stretch;
          .item-action {
            padding: 8px 10px;
            color: $cool_dark_gray;
            position: relative;
            display: flex;
            align-items: center;
            font-size: 15px;
          }
          .item-action:hover,
          .ce-dropdown-toggle[aria-expanded="true"] {
            &:after {
              content: '';
              position: absolute;
              display: block;
              width: 100%;
              height: 3px;
              left: 0;
              bottom: 0;
              background: linear-gradient(to right, $cool_red 0, $cool_blue 100%);
            }
          }
          .ce-dropdown-menu {
            .item-action:hover:after {
              display: none;
            }
          }
        }
        .nav-item.gift-center {
          .item-action:before {
            @extend .ce-icon-gift-bow:before;
            font-family: 'cooleffect' !important;
            font-weight: normal;
            line-height: 1;
            text-align: center;
            font-size: 10px;
            height: 11px;
            width: calc(100% - 40px);
            position: absolute;
            top: 50%;
            left: 20px;
            transform: translateY(-24px);
            border-bottom: 2px solid $cool_dark_gray;
          }
        }
      }
      .navbar-main-menu + .navbar-main-menu {
        margin-left: 0;
      }
      .navbar-cta {
        flex: 1 0 auto;
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        align-items: center;
        .ce-button + .ce-button {
          margin-left: $grid-gutter-width/2;
        }
        .ng-animate.ce-button {
          transition: 0s none;
        }
      }
    }
    .navbar-dropdown.checkout {
      display: none;
    }
    .logo {
      position: relative;
      display: flex;
      align-items: center;
      justify-content: center;
      text-transform: uppercase;
    }
    @include media-breakpoint-up(xl) {
      .navbar-dropdown {
        .navbar-main-menu {
          margin: 0 0 0 50px;
          .nav-item {
            .item-action {
              padding: 8px 18px;
            }
          }
        }
      }
    }
  }
}
