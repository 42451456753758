/*
 * Title: Overview Card
 * Type: Element
 * Dependencies: card.scss, overlay.scss, card-overlay.scss
*/

.ce-module {
  .ce-card.ce-card-overview {
    @include ce-card_overlay();

    @include media-breakpoint-up(lg) {
      .ce-card-inner {
        min-height: 240px !important;
      }

      .ce-card-inner:hover {
        figure {
          .bg-image {
            transform: scale(1.05);
            transform-origin: 50%;
          }
        }
      }
    }

    figure {
      .bg-image {
        position: absolute;
        top: 0;
        transform: scale(1);
        transition: transform 0.75s;

        @include ce-overlay_base();

        &:after {
          background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.85));
          background-color: rgba(255, 255, 255, 0);
        }
      }
    }

    &.no-overlay {
      figure .bg-image:after {
        display: none;
      }
    }

    .ce-card-header {
      margin-bottom: .5rem;

      .card-title {
        font-size: $mod_font_size_h3;
        color: $cool_white;
        @include media-breakpoint-up(md) {
          font-size: $mod_font_size_h3_md;
        }
        @include media-breakpoint-up(lg) {
          font-size: $mod_font_size_h3_lg;
        }
      }
    }

    .ce-card-content {
      flex: 0 1 auto;
      pointer-events: none;
      cursor: pointer;
    }

    .ce-card-body {
      color: $cool_white;
      h1, h2, h3, h4, h5, h6 {
        color: $cool_white;
      }
    }

    .ce-card-body + .ce-card-footer {
      margin-top: .75rem;
    }

    .ce-card-footer {
      .ce-button {
        pointer-events: all;
      }
    }

    &.featured {
      justify-content: flex-start;
      @include media-breakpoint-up(lg) {
        .ce-card-content {
          padding: $mod_card_content_padding_featured;
          max-width: 400px;
        }
        .card-title {
          font-size: $mod_font_size_h2_lg;
        }
      }
    }
  }

  .gift-grid {
    .ce-card.ce-card-overview {
      .ce-card-inner {
        min-height: 300px;
        @include media-breakpoint-up(md) {
          min-height: 470px !important;
        }
      }

      figure {
        .bg-image {
          &:after {
            background-image: linear-gradient(transparent, rgba(0, 0, 0, 0.75));
          }
        }
      }

      .ce-card-content {
        padding: $mod_space_xl $mod_space_sm;

        @include media-breakpoint-up(lg) {
          padding: $mod_card_content_padding_featured;
        }
      }
    }
  }
}
