.ways-to-help {
  // modals
  &.ce-modal {
    .ce-modal_close {
      color: $cool_white;
      font-weight: $cool_font-bold-weight;
      &:hover, &:active, &:focus,
      :hover, :active, :focus {
        color: $cool_light_gray;
      }
    }
    // SCSS compiling bug in build:prod, so leave this next duplicate block
    .ce-modal_close {
      color: $cool_white;
      font-weight: $cool_font-bold-weight;
      &:hover, &:active, &:focus,
      :hover, :active, :focus{
        color: $cool_light_gray;
      }
    }
    .ce-modal_wrapper {
      background-color: transparent;
      .ce-modal_content {
        text-align: left;
        padding: 0;
        .ce-modal_body {
          padding-bottom: 0;
          > .row {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
          }
          > .header,
          > .footer {
            display: none;
          }
          > .header {
            color: #ffffff;
            text-transform: uppercase;
            font-size: 20px;
            font-weight: 600;
            line-height: 1.2;
            align-items: flex-start;
            flex: 1 0 auto;
            > .col {
              display: flex;
              justify-content: center;
              align-items: center;
            }
            [class^="ce-icon-"], [class*=" ce-icon-"] {
              padding: $mod_space_xl 0;
              margin-right: $mod_space_md;
              &.xs {
                font-size: 28px;
              }
              &.sm {
                font-size: 31px;
              }
              &.md {
                font-size: 34px;
              }
              &.lg {
                font-size: 37px;
              }
              &.xl {
                font-size: 39px;
              }
              &.xxl {
                font-size: 42px;
              }
            }
            .text-block {
              padding: 0;
            }
          }
          > .main {
            flex: 0 1 auto;
          }
          > .footer {
            align-items: flex-end;
            flex: 1 0 auto;
            > .col {
              .text-block {
                padding: $mod_space_sm 0;
                color: $cool_gray;
              }
            }
          }
        }
      }
    }

    @include media-breakpoint-down(md) {
      background-image: none !important;
    }
    @include media-breakpoint-up(lg) {
      top: $header-height-desktop;
      .ce-modal_close {
        right: $grid-gutter-width/2;
      }
      .ce-modal_wrapper {
        .ce-modal_content {
          .ce-modal_body {
            > .header,
            > .footer {
              display: flex;
            }
          }
        }
      }
    }

    .text-block {
      padding: $mod_space_xl;
      @include media-breakpoint-up(lg) {
        background-image: none !important;
        &.theme-transparent-dark {
          padding: 0;
        }
        &.theme-light {
          padding: $mod_space_xxl;
        }
      }
    }
    .text-block {
      &.consumption,
      &.emission {
        .amt {
          line-height: 1;
          display: flex;
          align-items: flex-end;
        }
        .unit, .type {
          font-weight: $cool_font-semi-bold-weight;
        }
        .unit {
          font-size: 24px;
          line-height: 1;
        }
        p {
          line-height: 1.5;
        }
      }
      &.consumption {
        .amt {

        }
        .value {
          font-size: 57px;
          margin-bottom: -5px;
        }
        .unit {
          font-size: 20px;
          margin-left: 8px;
        }
        p {
          line-height: 1.2;
        }
        .top-bottom-border {
          border-top: 1px solid #FFF;
          border-bottom: 1px solid #FFF;
          padding: $mod_space_xs 0;
        }
      }
      &.emission {
        .amt {
          color: $headings-color;
        }
        .value {
          font-size: 57px;
          margin-bottom: -7px;
          font-weight: $cool_font-semi-bold-weight;
        }
        .unit {
          font-size: 18px;
          margin-left: 8px;
        }
        h4 {
          margin-top: 1.75rem;
        }
      }

      @include media-breakpoint-up(lg) {
        &.consumption {
          .amt {
            margin-bottom: 1.5rem;
          }
          .value {
            margin-top: -10px;
            margin-bottom: -16px;
            letter-spacing: -5px;
          }
          .unit {
            font-size: 28px;
          }
          .top-bottom-border {
            border-top: 2px solid #FFF;
            border-bottom: 2px solid #FFF;
            padding: $mod_space_sm 0;
          }
        }
        &.emission {
          .amt {
            margin-top: -7px;
          }
          .unit {
            font-size: 22px;
          }
        }
        &.consumption {
          .value {
            font-size: 148px;
          }
          p {
            font-size: 28px;
          }
        }
        &.emission {
          .value {
            font-size: 72px;
          }
        }
      }
    }
    &.full {
      & > .modal-dialog {
        &:after {
          display: none;
        }
      }
    }
  }
  // icon grid
  .ce-message {
    > a {
      display: flex;
      flex-direction: row;
      .ce-icon-circle {
        margin: 0 auto;
        margin-bottom: $mod_space_sm;
      }

      h4 {
        &:after {
          @extend .ce-icon-arrow-right:before;
          font-family: 'cooleffect';
          font-weight: normal;
          font-size: 13px;
          margin-left: 8px;
          color: $cool_purple;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      > a {
        flex-direction: column;
        h4 {
          &:after {
            display: none;
          }
        }
      }
    }
  }
}
