.ce-module.stillin,
.ce-modal.stillin {
  font-family: $stillin_font_family_base;
  font-weight: $stillin_font_weight_base_regular;

  p {
    line-height: 1.75;
  }

  h1, h2, h3, h4, h5, h6,
  .h1, .h2, .h3, .h4, .h5, .h6 {
    font-family: $stillin_font_family_display;
    font-weight: $stillin_font_weight_display_semibold;
    color: $stillin_color_black;
    &.highlight {
      color: $stillin_color_red;
      font-weight: $stillin_font_weight_display_bold;
    }
  }

  h1 {
    font-size: $stillin_font_size_h1;
    line-height: 1.1;
  }
  h2 {
    font-size: $stillin_font_size_h2;
  }
  h3 {
    font-size: $stillin_font_size_h3;
  }
  h4 {
    font-size: $stillin_font_size_h4;
  }
  h5 {
    font-size: $stillin_font_size_h5;
  }

  h2, h3, h4, h5, h6 {
    line-height: 1.4;
  }

  h1, h2, h3 {
    margin-bottom: 1.2rem;
  }

  @include media-breakpoint-up(md) {
    h1 {
      font-size: $stillin_font_size_h1_md;
      line-height: 1.2;
    }
    h2 {
      font-size: $stillin_font_size_h2_md;
    }
    h3 {
      font-size: $stillin_font_size_h3_md;
    }
    h4 {
      font-size: $stillin_font_size_h4_md;
    }
    h5 {
      font-size: $stillin_font_size_h5_md;
    }
  }

  @include media-breakpoint-up(lg) {
    p {
      line-height: 1.75;
    }
    h1 {
      font-size: $stillin_font_size_h1_lg;
      line-height: 1.2;
    }
    h2 {
      font-size: $stillin_font_size_h2_lg;
    }
    h3 {
      font-size: $stillin_font_size_h3_lg;
    }
    h4 {
      font-size: $stillin_font_size_h4_lg;
    }
    h5 {
      font-size: $stillin_font_size_h5_lg;
    }
  }
  h1, h2, h3 {
    &.primary {
      &:before {
        @extend .ce-icon-si-tilde:before;
        font-family: 'cooleffect';
        color: $stillin-color_red;
        font-size: 11px;
        display: block;
        margin: 0 0 20px 0;
      }
    }
  }
}