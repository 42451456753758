/*
 * Title: Incrementer
 * Type: Element
 * Dependencies: 
*/

.ce-incrementer {
  align-items: center;
  display: flex;
  justify-content: center;

  /* Add gutter for incremented value
   * when it's the middle child */
  > *:nth-child(2):nth-last-child(2) {
    &.ce-incrementer_value {
      margin: 0 .5rem;
    }
  }
}

.ce-incrementer_subtract,
.ce-incrementer_add {
  color: $cool_dark_gray;
  cursor: pointer;
  transition: color 0.3s ease;

  &:hover {
    color: $cool_yellow;
  }
}
.ce-incrementer_value {
  color: $cool_dark_gray;
  margin: 0;

  &:focus {
    outline: 1px solid $cool_dark_gray;
  }
}
