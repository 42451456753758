.ce-module {
  &.ce-layered-compare,
  .ce-layered-compare {
    $dur: 1.5s;
    $delay: .5s;
    $readyStartPos: 15%;
    $readyMidPos: 85%;
    $readyEndPos: 15%;

    position: relative;
    pointer-events: all;
    > * {
      pointer-events: none;
      .text-block,
      a {
        pointer-events: auto;
      }
    }
    .compare-layer.back {
      position: relative;
      width: 100%;
      height: auto;
    }
    .compare-layer.front {
      position: absolute;
      top: 0;
      left: 0;
      overflow: hidden;
    }
    &.compare-x .compare-layer.front {
      width: $readyStartPos;
      height: 100%;
    }
    &.compare-y .compare-layer.front {
      width: 100%;
      height: $readyStartPos;
    }
    img {
      -webkit-user-select: none;
      user-select: none;
    }
    > .indicator {
      display: block;
      background-color: white;
      position: absolute;
      .grabber {
        pointer-events: all;
        touch-action: auto;
        width: 40px;
        height: 40px;
        border-radius: 9999px;
        background-color: $cool_white;
        position: absolute;
        @include centerer(50%, 50%);
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-between;
        .arrows {
          padding: 10px 0;
          width: 100%;
          height: 100%;
          position: relative;
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: space-between;
          .ce-icon-chevron-down {
            color: black;
            font-size: 8px;
            font-weight: $cool_font-bold-weight;
          }
          .ce-icon-chevron-down:first-of-type {
            transform: rotate(180deg);
          }
        }
        @include media-breakpoint-up(lg) {
          width: 60px;
          height: 60px;
          .arrows {
            padding: 18px 0;
            .ce-icon-chevron-down {
              font-size: 10px;
            }
          }
        }
      }
    }
    $lineSize: 2px;
    &.compare-x > .indicator {
      width: $lineSize;
      height: 100%;
      top: 0;
      left: $readyStartPos;
      .grabber {
        cursor: col-resize;
        .arrows {
          transform: rotate(90deg);
        }
      }
    }

    &.compare-y > .indicator {
      width: 100%;
      height: $lineSize;
      top: $readyStartPos;
      left: 0;
      .grabber {
        cursor: row-resize;
      }
    }
    // animation
    @keyframes indicator-x {
      0% {    left: $readyStartPos; }
      50% {   left: $readyMidPos; }
      100% {  left: $readyEndPos; }
    }
    @keyframes indicator-y {
      0% {    top: $readyStartPos; }
      50% {   top: $readyMidPos; }
      100% {  top: $readyEndPos; }
    }
    @keyframes front-x {
      0% {    width: $readyStartPos; }
      50% {   width: $readyMidPos; }
      100% {  width: $readyEndPos; }
    }
    @keyframes front-y {
      0% {    height: $readyStartPos; }
      50% {   height: $readyMidPos; }
      100% {  height: $readyEndPos; }
    }
    &.in-first-view {
      &.compare-x {
        > .indicator {
          animation: indicator-x $dur ease-in-out $delay;
        }
        > .compare-layer.front {
          animation: front-x $dur ease-in-out $delay;
        }
      }
      &.compare-y {
        > .indicator {
          animation: indicator-y $dur ease-in-out $delay;
        }
        > .compare-layer.front {
          animation: front-y $dur ease-in-out $delay;
        }
      }
    }
  }
  // content specific
  &.ce-layered-compare,
  .ce-layered-compare {
    .compare-layer {
      .overlay {
        position: absolute;
        top: 0;
        left: 0;
        padding-top: $grid-gutter-width;
      }
      .banner-overlay {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
      }
      video {
        display: block;
      }
    }

    .compare-layer.back {
      img, video {
        width: 100%;
        height: auto;
      }
    }
    &.compare-x .compare-layer.front {
      img,
      video {
        height: 100%;
        width: auto;
      }
    }
    &.compare-y .compare-layer.front {
      img,
      video {
        height: auto;
        width: 100%;
      }
    }
  }
}
