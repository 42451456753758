.ce-module {
  &.checkout-module.complete {
    padding-top: $mod_padding_v + $mod_space_sm;
    .module-header {
      .header-section {
        .ce-icon-certificate {
          font-size: 60px;
          color: $cool_purple;
          margin-bottom: .75rem;
        }
        .cert-subheading {
          font-size: $mod_font_size_h4;
          @include media-breakpoint-up(md) {
            font-size: $mod_font_size_h4_md;
          }
          @include media-breakpoint-up(lg) {
            font-size: $mod_font_size_h4_lg;
          }
        }
      }
      .header-section + .header-section {
        margin-top: $mod_space_lg;
      }
      .header-title {
        &:before {
          display: none;
        }
      }
    }
    .msg {
      text-align: center;
    }
    .ce-social-share {
      @extend .row;
      display: flex;
      justify-content: center;
      align-items: flex-end;
      margin-top: -$mod_space_md;
      a {
        display: flex;
        align-items: center;
        padding: 0 $grid-gutter-width-md/2;
        transition: all 0.3s ease;
        [class^="ce-icon-"],
        [class*=" ce-icon-"] {
          color: $cool_purple;
          font-size: 20px;
        }
        .ce-icon-facebook {
          font-size: 24px;
        }
        &:hover, &:active, &:focus {
          [class^="ce-icon-"],
          [class*=" ce-icon-"] {
            color: $cool_blue;
          }
        }
      }
    }
    .crm {
      display: flex;
      align-items: stretch;
      height: 280px;
      figure {
        display: none;
        width: 50%;
        margin-bottom: 0;
        .bg-image {
          width: 100%;
          height: 100%;
          background-repeat: no-repeat;
          background-size: cover;
        }
        @include media-breakpoint-up( lg ) {
          display: block;
        }
      }
      .content {
        width: 100%;
        padding: $mod_space_lg;
        background-color: $cool_white;
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        text-align: center;
        @include media-breakpoint-up( lg ) {
          width: 50%;
        }
      }
    }
    .checkout-actions {
      display: flex;
      align-items: center;
      margin-top: 0;
    }
  }
}
