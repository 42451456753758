/*
 * Title: Preview Card
 * Type: Element
 * Dependencies: card.scss, overlay.scss, card-overlay.scss
*/

.ce-card.preview-card {
  //display: block;
  //.ce-card-inner {
  //  position: relative;
  //  width: 100%;
  //  display: flex;
  //  flex-direction: column;
  //  flex: 1 0 auto;
  //  justify-content: flex-end;
  //}
  figure {
    display: none;
    .bg-image {
      position: absolute;
      top: 0;
    }
  }
  .ce-card-header {
    margin-top: $mod_card_padding_v;
  }
  .ce-card-content {
    position: relative;
    border-top: 2px solid $cool_light_gray;
  }

  @include ce-card_overlay(lg);
  @include media-breakpoint-up(lg) {
    .ce-card-inner {
      min-height: 240px !important;
    }
    figure {
      margin-bottom: 0;
      .bg-image {
        @include ce-overlay_dark-bottom-gradient(0, 0.85);
        &::after {
          transition: height 0.5s ease, top 0.5s ease;
        }
      }
    }
    .ce-card-content {
      border: none;
    }
    .ce-card-header {
      margin-bottom: 0;
      margin-top: 0;
      transition: all 0.3s ease;
    }
    .ce-card-body {
      margin-bottom: -1rem;
      max-height: 1rem;
      overflow: hidden;
      opacity: 0;
      transition: max-height 0.3s cubic-bezier(0, 1, 0, 1), opacity 0.5s cubic-bezier(0, 1, 0, 1);
    }
    .ce-card-footer {
      margin: 0;
      max-height: 0;
      overflow: hidden;
      text-align: right;
      transition: max-height 0.3s cubic-bezier(0, 1, 0, 1);

      a {
        color: $cool_white;
      }
    }
    .ce-card-inner:hover {
      figure {
        .bg-image {
          &::after {
            height: 300%;
            top: -200%;
          }
        }
      }
      .ce-card-header {
        margin-bottom: $mod_space_xs;
        transition: all 0.3s ease;
      }
      .ce-card-body {
        max-height: 100px;
        opacity: 1;
        transition: max-height 0.3s ease-in-out, opacity 0.5s ease-in-out;
      }
      .ce-card-footer {
        max-height: 100px;
        transition: max-height 0.3s ease-in-out;

        a {
          color: $cool_white;
        }
      }
    }
  }

  &.featured {
    figure {
      display: block;
    }
    .ce-card-content {
      border: none;
      width: 100%;
    }
    .ce-card-body {
      max-height: initial;
      opacity: 1;
    }
    .ce-card-inner:hover {
      .ce-card-body {
        max-height: initial;
      }
    }
    .ce-card-header {
      margin-top: 0;
    }
    @include media-breakpoint-up(lg) {
      .ce-card-content {
        padding: $mod_card_content_padding_featured;
      }
      .ce-card-header {
        margin-bottom: $mod_space_xs;
        max-width: 300px;
      }
    }
  }

  &.simple {
    .ce-card-content {
      background-color: $cool_white;
      border: none;
      padding: $mod_card_content_padding;
      .ce-card-header {
        margin-top: 0;
        .pre-title,
        .byline {
          color: $cool_medium_gray;
        }
      }
      .ce-card-footer {
        a {
          margin-top: $mod_space_xs;
          color: $cool_purple;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      .ce-card-inner {
        min-height: 180px !important;
      }
      figure a {
        display: block;
        width: 100%;
        height: 100%;
      }
      .ce-card-content {
        pointer-events: none;
        height: 100%;
        color: $color-base;
        border: 3px solid transparent;
        transition: border 0.3s ease;
        .ce-card-header {
          flex: 1 0 auto;
          .card-title {
            color: $cool_purple;
          }
        }
        .ce-card-footer {
          max-height: 100%;
          text-align: left;
        }
      }
      .ce-card-inner:hover {
        .ce-card-content {
          border-color: $cool_purple;
          .ce-card-header {
            margin-bottom: 0;
          }
        }
      }
    }
  }
  &.pagination-card {
    .ce-card-footer {
      margin-top: $mod_space_xs;
      .ce-icon-arrow-right {
        font-size: 16px;
      }
      .previous,
      .next {
        display: flex;
        align-items: center;
      }
      .previous {
        .ce-icon-arrow-right {
          margin-right: $mod_space_xs;
        }
      }
      .next {
        justify-content: flex-end;
        .ce-icon-arrow-right {
          margin-left: $mod_space_xs;
        }
      }
      .previous .ce-icon-arrow-right {
        transform: rotate(180deg);
      }
    }

    @include media-breakpoint-down(md) {
      .ce-card-content {
        background-color: transparent;
        padding: 0;
        .ce-card-header {
          display: none;
        }
      }
    }
    @include media-breakpoint-up(lg) {
      .ce-card-footer {
        margin-top: $mod_space_xs;
      }
    }
  }

}