/*
 * Title: Overlay
 * Type: Template
 * Dependencies: 
*/

@mixin ce-overlay_base() {
  &:after {
    position: absolute;
    content:" ";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
@mixin ce-overlay_dark( $transparency: 0.5 ) {
  @include ce-overlay_base();
  &:after {
    background-color: rgba(0,0,0, $transparency );
  }
}
@mixin ce-overlay_purple( $transparency: 0.25 ) {
  @include ce-overlay_base();
  &:before {
    background-color: rgba(67,41,115, $transparency );
  }
}
// Gradient Filters
@mixin ce-overlay_dark-bottom-gradient( $transparency1: .85, $transparency2: 0 ) {
  @include ce-overlay_base();
  &:after {
    background-image: linear-gradient(rgba(0,0,0, $transparency1 ), rgba(0,0,0, $transparency2 ));
    background-color: rgba(255,255,255,0);
  }
}
@mixin ce-overlay_dark-bottom-gradient_stark( $transparency1: 0, $transparency2: 0, $transparency3: 0,  $transparency4: .85) {
  @include ce-overlay_base();
  &:after {
    background-image: linear-gradient(rgba(0,0,0, $transparency1 ), rgba(0,0,0, $transparency2 ), rgba(0,0,0, $transparency3 ), rgba(0,0,0, $transparency4 ));
    background-color: rgba(255,255,255,0);
  }
}
@mixin ce-overlay_white-bottom-gradient() {
  @include ce-overlay_base();
  &:after {
    background-image: linear-gradient(-180deg, rgba(255,255,255,0) 0%, rgba(255,255,255,0) 55%, rgba(255,255,255,1) 90%, rgba(255,255,255,1) 100%);
    background-color: rgba(255,255,255,0);
  }
}