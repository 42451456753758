@import url('https://fonts.googleapis.com/css?family=Lobster&display=swap');
@font-face {
  font-family: 'akrobat';
  src: url('/app/fonts/certificate/html_versions/Akrobat-ExtraBold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
.ce-module .ce-certificate.ce-certificate-origami-gift.blooming-bundle {
  .content {
    .outer-frame {
      .inner-frame {
        .copy {
          padding-top: 3.27in;
          justify-content: flex-start;
          .recipient-label {
            font-size: 7pt;
            font-weight: $cool_font-bold-weight;
            text-transform: uppercase;
          }
          .recipient-name {
            font-size: 20pt;
            font-family: 'Lobster', cursive;
            margin-bottom: 0;
          }
          .quantity-label {
            margin-bottom: 0;
          }
          .quantity {
            font-size: 16pt;
            font-family: 'akrobat';
            margin-bottom: 14pt;
          }
          .about {
            font-size: 9pt;
            padding: 0 34pt;
            font-weight: $cool_font-semi-bold-weight;
            line-height: 1.4;
          }
          .project-list-label {
            font-size: 9pt;
            font-weight: $cool_font-semi-bold-weight;
            font-style: italic;
          }
          .project-list {
            font-size: 10pt;
            font-weight: $cool_font-semi-bold-weight;
          }
        }
      }
    }
  }
}
