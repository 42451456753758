/*
 * Title: Modal
 * Type: Element/Template
 * Dependencies: (Bootstrap) modal.scss
*/

// Local variables
$modal_padding: $mod_space_md;
$modal_padding_lg: $mod_space_xxl;

$scrollable_modal_content_height: 1000px;

$scrollable_modal_body_height: 300px;
$scrollable_modal_body_height_md: 500px;
$scrollable_modal_body_height_lg: 700px;

// Group together buttons that contain a fill/outline
@mixin ce-modal_child-buttons() {
    > .btn.btn-primary,
    > .btn.btn-primary-outline
    > .btn.btn-secondary,
    > .btn.btn-secondary-outline,
    > .btn.btn-tertiary,
    > .btn.btn-tertiary-outline {
        @content;
    }
}

.ce-modal {
    @extend .modal;
    background-size: cover;
    background-repeat: no-repeat;
    margin: 0 !important;
    &.fade .modal-dialog {
        transform: translate(0,0);
    }
    &[role="dialog"] .modal-dialog {
        width: 100%;
        max-height: 100%;
        margin: 0 auto;
        padding-left: 20px;
        padding-right: 20px;
        @include centerer(50%, 50%);
        @include media-breakpoint-up(md) {
            max-width: 500px;
            margin: 0;
            padding: 0;
        }
        &.modal-md {
            @include media-breakpoint-up(md) {
                max-width: 576px;
            }
            @include media-breakpoint-up(lg) {
                max-width: 576px;
            }
        }
        &.modal-lg {
            @include media-breakpoint-up(md) {
                max-width: 576px;
            }
            @include media-breakpoint-up(lg) {
                max-width: 800px;
            }
        }
    }
    &.full {
        @include media-breakpoint-up(lg) {
            &[role="dialog"] .modal-dialog {
                @include make-container();
                @include make-container-max-widths();
            }
            .ce-modal_body {
                display: flex;
                flex-direction: column;
                flex-wrap: nowrap;
                align-items: stretch;
                justify-content: center;
                padding: 0 $grid-gutter-width/2;
            }
            .modal-dialog,
            .ce-modal_wrapper,
            .ce-modal_content,
            .ce-modal_body {
                position: absolute;
                top: 0;
                left: 50%;
                transform: translateX(-50%);
                width: 100%;
            }
            .modal-dialog,
            .ce-modal_wrapper,
            .ce-modal_content {
                height: 100%;
            }
            .ce-modal_body {
                min-height: 100%;
            }
        }
    }
    &.scrollable {
        ::-webkit-scrollbar-track,
        ::-webkit-scrollbar {
            background-color: $cool_very_light_gray;
        }
        ::-webkit-scrollbar {
            width: 10px;
        }
        ::-webkit-scrollbar-thumb {
            background-color: $cool_gray;
            border: 2px solid $cool_gray;
        }
    }
    form {
        text-align: left;
        .form-actions {
            text-align: center;
        }
    }
}

.ce-modal_wrapper {
    @extend .modal-content;
    border: 0;
    border-radius: 0;
    flex-direction: row;
}

.ce-modal_content {
    @extend .modal-body;
    padding: 30px $modal_padding;
    
    .ce-modal:not(.ce-modal_informative) & {
        text-align: center;
    }

    .ce-modal.scrollable & {
        height: 100%;
        max-height: $scrollable_modal_content_height;
        overflow: hidden;
        padding: 30px 0;
    }

    @include media-breakpoint-up(sm) {
      padding: 50px $modal_padding_lg;
      .ce-modal.scrollable & {
        padding: 50px $modal_padding;
      }
    }

    > *:not(:last-child) {
        padding-bottom: 1rem;
    }
}

.ce-modal .ce-modal_close {
    @extend .close;
    color: $cool_dark_gray;
    position: absolute;
    top: ( $modal_padding * 0.5 );
    right: ( $modal_padding * 0.5 );
    font-size: 36px;
    line-height: 18px;
    font-weight: $cool_font-regular-weight;
    opacity: 1;
    text-shadow: none;

    @include media-breakpoint-up( sm ) {
        top: $modal_padding;
        right: $modal_padding;
    }
    &:hover, &:active, &:focus,
    :hover, :active, :focus {
        color: $cool_blue;
    }
}

.ce-modal_header {
    text-align: center;

    .ce-modal.scrollable & {
        padding: 0 $modal_padding ( $modal_padding * 0.65 );
        @include media-breakpoint-up( sm ) {
            padding: 0 $modal_padding_lg ( $modal_padding_lg * 0.65 );
        }
    }
    > .bg-image:not(:first-child) {
        margin-top: 1rem;
    }
    > .bg-image:not(:last-child) {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        margin-right: auto;
        margin-left: auto;
        margin-bottom: 1.5rem;
        // For images with 3h:7w dimensions
        padding-top: 43%;
        max-width: 100%;
        width: 100%;
    }
    h1, h2, h3, h4, h4, h6 {
        margin-bottom: 0;
    }
  .header-body {
    margin-top: .75rem;
    &:after {
      content: "";
      display: block;
      width: 100px;
      height: 8px;
      margin: 2rem auto 0 auto;
      border-radius: 9999px;
      background: linear-gradient(to right, $cool_blue 0, $cool_red 100%);
    }
  }
}

.ce-modal_body {
    > .ce-transaction_quantity {
        font-size: $mod_font_size_h2_lg;
    }
    .ce-modal_label {
        font-size: $mod_font_size_small;
        font-weight: $cool_font-semi-bold-weight;
        &.quantity {
          display: block;
          text-transform: capitalize;
          margin-bottom: 6px;
        }
    }
    .ce-incrementer .ce-incrementer_value {
      max-width: 125px;
    }
    .price {
        color: $cool_dark_gray;
        //line-height: 1;
      &.price-project {
        margin-top: 1rem;
      }
    }
    .price-explanation {
        margin-top: 2rem;
    }
    form#edititem {
      text-align: center;
      .gift {
        margin: 1.5rem 0 1rem 0;
        display: flex;
        justify-content: center;
        label {
          width: auto;
        }
      }
    }

    .ce-modal.scrollable & {
        max-height: $scrollable_modal_body_height;
        overflow-y: scroll;
        padding: 0 20px;

      @include media-breakpoint-up(sm) {
        padding: 0 40px;
      }

        @media only screen and (min-height: 700px) {
            max-height: $scrollable_modal_body_height_md;
        }

        @media only screen and (min-height: 1000px) {
            max-height: $scrollable_modal_body_height_lg;
        }
    }
}

.ce-modal_footer {
    align-items: center;
    display: flex;
    flex-direction: column;
    flex-wrap: wrap;
    justify-content: center;
    align-items: stretch;
    padding-bottom: 0;
    .row {
        flex: 1 0 auto;
        > [class*="col"] {
            //float: none;
            //padding: 0;

            & + [class*="col"] {
                //margin-top: $mod_space_sm;

                .btn.btn-secondary {
                    margin-top: 0;
                    padding: 0;
                }
            }
            @include media-breakpoint-up( sm ) {
                //float: left;
                //padding-left: 15px;
                //padding-right: 15px;
                //
                //& + [class*="col"]:not([class*="col-12"]) {
                //    margin-top: 0;
                //}
            }
            .btn {
                margin-left: auto;
                margin-right: auto;
                max-width: 165px;
            }
        }
    }
    
    @include ce-modal_child-buttons() {
        max-width: 170px;
    }
    > .ce-modal_link {
        font-weight: $cool_font-semi-bold-weight;
        text-transform: uppercase;
    }
    @include media-breakpoint-up(md) {
        flex-direction: row;
        justify-content: space-around;

        @include ce-modal_child-buttons() {
            max-width: initial;
            
            & + .ce-modal_link:last-child {
                flex-basis: 100%;
            }
        }
        > *:not(:last-child) {
            margin-bottom: 0;
        }
    }
  .ce-grid.stacked-buttons {
    display: flex;
    flex-direction: column;
    align-content: center;
    .grid-item {
      flex: 1 0 auto;
      width: auto;
    }

  }
}
