.ce-module.homepage-hero.see-carbon-and-make-difference {
  padding-bottom: $mod_padding_v_mobile;
  .video-col {
    padding-bottom: $grid-gutter-width;
    .video-col-inner {
      padding: $mod_space_lg;
      width: 100%;
      height: 325px;
      .ce-play-video-button {
        margin-top: $mod_space_sm;
      }
    }
  }
  @include media-breakpoint-up(sm) {
    .video-col {
      .video-col-inner {
        padding: $mod_space_xl;
        height: 325px;
      }
    }
  }
  @include media-breakpoint-up(md) {
    padding-bottom: $mod_padding_v;
    .video-col {
      .video-col-inner {
        padding: 50px;
        height: 375px;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    padding-bottom: 0;
    .video-col {
      padding-bottom: 0;
      padding-right: 0;
      .video-col-inner {
        padding: 80px;
        margin-right: -$grid-gutter-width/2;
        height: 100%;
      }
    }
  }
}
