/*
  ** Split Carousel - Base
  * Sets up the framework for the carousel
*/

#{$ce-split-carousel} {
  // Shortcut local variables
  $wrapper:         $ce-split-carousel-wrapper;
  $inner:           $ce-split-carousel-inner;
  $content-wrapper: $ce-split-carousel-content;
  $item-content:    $ce-split-carousel-item-content;
  $front:           $ce-split-carousel-front;
  $back:            $ce-split-carousel-back;
  $title:           $ce-split-carousel-title;
  
  @include media-breakpoint-up(lg) {
    position: relative;
    padding: 0;
    overflow: hidden;
    max-height: 446px;
    height: 70vh;
  }
  @include media-breakpoint-down(md) {
    display: flex;
    flex-direction: column;
    margin-bottom: 40px;
  }

  #{$wrapper} {
    @include media-breakpoint-up(lg) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
      position: absolute;
      top: 0;
      left: 50%;
      transform: translateX(-50%);
    }
    position: relative;
    z-index: z-layer( "content" );
    width: 100%;
    height: 100%;
    order: 2;
  }

  #{$back} {
    width: 100%;
    height: 100%;
    pointer-events: none;
    @include media-breakpoint-down(lg) {
      order: 1;
    }
    @include media-breakpoint-down(md) {
      height: 300px;
    }
    @include media-breakpoint-down(sm) {
      height: 240px;
    }
  }

  #{$back} .carousel-inner,
  #{$back} .carousel-inner .carousel-item {
    height: 100%;
    background-size: cover;
    background-position: 50% 50%;
  }

  #{$inner} {
    position: relative;
    z-index: z-layer( "content-upper" );
    @extend .col-12;
    @extend .col-lg-6;
  }

  #{$title} {
    color: rgb(255,255,255);
    text-align: center;
    @include media-breakpoint-down(md) {
      margin-top: 40px;
      margin-bottom: 20px;
      color: $cool_purple;
    }
  }

  .halves-carousel #{$content-wrapper} {
    @include media-breakpoint-up(md) {
      display: flex;
      align-items: center;
      justify-content: center;
      flex-direction: row;
    }
  }

  #{$content-wrapper} .carousel-ctrl {
    border: none;
    color: rgba(255,255,255, 0.75);
    transition: color .25s ease;
    &:hover {
      color: rgba(255,255,255, 1);
      background-color: transparent;
    }
  }

  #{$front} {
    float: right;
    border: 3px solid rgb(235,235,235);
    padding: 40px;
    margin: 20px 40px;
    z-index: z-layer( "content-upper" );

    @include media-breakpoint-up(lg) {
      width: 100%;
      margin: 20px;
      border-color: rgb(255,255,255);
    }
  }

  #{$front} .carousel-inner {
    width: 100%;
  }

  #{$item-content} {
    font-weight: $cool_font-semi-bold-weight;
    font-size: 20px;
    width: 100%;
    margin: auto;
    @include media-breakpoint-up(lg) {
      color: rgb(255,255,255);
    }
    > *:last-child {
      margin-bottom: 0;
    }
  }

  .ce-mobile-carousel-controls {
    @include media-breakpoint-up(lg) {
      display: none;
    }
  }
}