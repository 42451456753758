.ce-module.header-nav-module {
  nav.navbar.mobile {
    @include media-breakpoint-up(lg) {
      display: none;
    }
    pointer-events: all;
    -ms-touch-action: manipulation;
    touch-action: manipulation;
    a:hover, a:focus, a:active {
      text-decoration: none;
      outline: none;
    }
    width: 100%;
    height: $header-nav-height-mobile;
    .navbar-collapse {
      transition: transform 0.4s ease;
      position: fixed;
      width: calc(100vw - 70px);
      height: 100vh;
      top: 0;
      right: 0;
      transform: translateX(100%);
      background-color: $cool_white;
      box-shadow: -1px 0px 5px 0px rgba(0,0,0,0.1);
      display: flex;
      flex-direction: column;
      overflow-y: scroll;
      -webkit-overflow-scrolling: touch;

      &.show {
        transform: translateX(0);
      }
      .navbar-main-menu,
      .navbar-admin-menu {
        width: 100%;
        padding-left: 0;
        margin-bottom: 0;
        list-style: none;
        .nav-item {
          width: 100%;
          text-transform: uppercase;
          font-weight: $cool_font-semi-bold-weight;
          .item-action {
            position: relative;
            color: $cool_dark_gray;
            display: flex;
            align-items: center;
            padding: 18px 24px;
            [class^="ce-icon-"] {
              font-size: 24px;
              margin-right: 10px;
            }
            .nav-menu-cart-count {
              top: -4px;
              left: 40px;
            }
          }
          &.minor {
            font-weight: $cool_font-regular-weight;
            .item-action {
              padding-top: 0;
              padding-bottom: 0;
              margin: 18px 0;
            }
          }
        }
      }
      .navbar-admin-menu {

      }
      .navbar-main-menu {
        padding-top: 70px;
        .nav-item {
          .item-action {
            &:before {
              content: '';
              display: none;
              width: 5px;
              height: 100%;
              position: absolute;
              top: 0;
              left: 0;
              background: linear-gradient(to bottom, $cool_red 0, $cool_blue 100%);
            }
            &:hover:before {
              display: block;
            }
            .item-label {
              flex: 1 0 auto;
            }
          }
          .ce-collapse-toggle {
            &:not(.collapsed):before {
              display: block;
            }
            .indicator {
              @extend .ce-icon-chevron-down;
              font-family: 'cooleffect' !important;
              font-weight: normal;
              font-size: 9px;
              transform: rotate(0);
              transition: transform .4s ease;
              line-height: 1;
            }
            &:not(.collapsed) .indicator {
              transform: rotate(180deg);
            }
          }
          .collapse.in {
            display: block;
          }
          .ce-collapse-menu {
            padding: 0;
            border-top: 1px solid $cool_gray;
            //display: none;
            .nav-item {
              display: block;
              border: none;
              padding: 0;
              font-weight: $cool_font-regular-weight;
              .item-action {
                margin: 18px 24px;
                padding: 0;
                &:hover:before {
                  display: none;
                }
              }
            }
          }
        }
        .nav-item {
          border-bottom: 1px solid $cool_gray;
        }
      }
    }
    .logo {
      position: fixed;
      top: 0;
      left: 20px;
      display: flex;
      align-items: center;
      justify-content: center;
      height: $header-nav-height-mobile;
      z-index: 1;
      .ce-icon-logo {
        font-size: 50px;
      }
    }
    .controls {
      // mobile toggle and related
      height: $header-nav-height-mobile;
      width: calc(100vw - 70px);
      position: fixed;
      top: 0;
      right: 0;
      display: flex;
      justify-content: flex-end;
      background-color: $cool_white;
      .navbar-toggle {
        width: $header-nav-height-mobile;
        height: $header-nav-height-mobile;
        padding: 0;
        background-color: transparent;
        border: none;
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        cursor: pointer;
        &:before {
          display: block;
          content: '\002630';
          color: $cool_purple;
          font-family: $font-family-base;
          font-weight: $cool_font-regular-weight;
          font-size: 34px;
          line-height: 1;
          flex: 0 0 100%;
        }
      }
      .checkout {
        position: relative;
        display: flex;
        align-items: center;
        justify-content: center;
        visibility: visible;
        opacity: 1;
        transition: all 0.3s ease;
        .ce-icon-cart {
          font-size: 32px;
        }
        .nav-menu-cart-count {
          top: 16px;
          left: 22px;
          letter-spacing: 0;
        }
      }
      &.show-menu {
        .checkout {
          visibility: hidden;
          opacity: 0;
        }
        .navbar-toggle:before {
          content: '\002715';
          font-size: 20px;
          font-weight: $cool_font-semi-bold-weight;
        }
      }
      &.checkout {
        display: none;
      }
    }
  }
}
#navbarNavMobile {
  > *:last-child {
    padding-bottom: 90px;
  }
}
