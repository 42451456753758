//NEEDED for WP VideoPlayer
.arve-wrapper, .ce-vimeo-embed-wrapper {
	margin-bottom: 20px;
	width: 100%;
	/* Background image is applied with inline CSS */
	background-position: center center;
	background-size: cover;
	position: relative;
	behavior: url(backgroundsize.min.htc);
	/* IE polyfill for background size */
}

.arve-wrapper.alignright {
	margin-left: 20px;
}

.arve-wrapper.alignleft {
	margin-right: 20px;
}
//NEEDED for WP VideoPlayer

// Video Wrapper for Vimeo
.videoWrapper {
	position: relative;
	padding-bottom: 56.25%;
	height: 0;
	overflow: hidden;
	max-width: 100%;
  @include media-breakpoint-down(xs) {
    height: 100vw;
  }
}
.videoWrapper iframe, .videoWrapper object, .videoWrapper embed {
	position: absolute;
	top: 0;
	left: 0;
	width: 100%;
	height: 100%;
  @include media-breakpoint-up(xl) {
    height: 720px;
  }
	// Uncomment to make sure the whole video is visible.
	// height: 90vh;
}

// Fancy Play Button Styling
.playButton {
	cursor: pointer;
  height: 100%;
  width: 100%;
  max-height: 70px;
  max-width: 70px;
  transition: opacity 0.3s;
  &:hover{
    opacity: 0.75;
  }
  @include media-breakpoint-up(md) {
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

@keyframes spin {
  to { transform: rotate(360deg); }
}

.icon {
  transform-origin: 50% 50%;
  transition: transform 200ms ease-out;
}

// Hover
#play:hover {
  .icon {
    transform: scale(1.05);
  }
}

