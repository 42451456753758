.ce-module, .ce-modal {
  .faq-list {
    dt {
      font-weight: $cool_font-semi-bold-weight;
      font-size: $mod_font_size_h5_lg;
      color: $cool_dark_gray;
      padding-left: $grid-gutter-width;
      position: relative;
      cursor: pointer;
      .indicator {
        font-weight: $cool_font-regular-weight;
        color: $cool_purple;
        position: absolute;
        top: 10px;
        left: 0;
        display: block;
        .ce-icon-chevron-down {
          font-size: 11px;
          transform: rotate(180deg);
          transition: transform 0.4s ease;
          display: block;
        }
      }
      &.collapsed {
        .indicator {
          .ce-icon-chevron-down {
            transform: rotate(0);
          }
        }
      }
    }
    dd {
      padding-left: $grid-gutter-width;
      margin-bottom: 0;
      &.show {
        display: block;
      }
      :last-child {
        margin-bottom: 0;
      }
    }

    dd + dt {
      margin-top: $grid-gutter-width;
    }
  }
  h2 + .faq-list {
    margin-top: 18px;
  }
  .text-block + .faq-list {
    margin-top: 10px;
  }
}