.ce-module .ce-stat-box {
  display: flex;
  flex-direction: column;
  align-items: center;
  .stat-icon {
    display: flex;
    justify-content: center;
    width: 100%;
  }
  .stat-content {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    text-align: center;
    width: 100%;
    .amount {
      font-weight: $cool_font-semi-bold-weight;
      line-height: 1.1;
      &.h1 {
        font-size: $mod_font_size_h1;
      }
      &.h2 {
        font-size: $mod_font_size_h2;
      }
      &.h3 {
        font-size: $mod_font_size_h3;
      }
      &.h4 {
        font-size: $mod_font_size_h4;
      }
    }

    .description {
      width: 100%;
      font-size: $mod_font_size_h6;
      font-weight: $cool_font-semi-bold-weight;
      line-height: 1.1;
    }
  }

  &.layout-horizontal {
    .stat-icon {
      margin-bottom: $mod_space_xs;
      .ce-icon-circle {
        width: 80px;
        height: 80px;
        min-width: 80px;
        min-height: 80px;
        &.xxs {
          font-size: 28px;
        }
        &.xs {
          font-size: 32px;
        }
        &.sm {
          font-size: 37px;
        }
        &.md {
          font-size: 41px;
        }
        &.lg {
          font-size: 47px;
        }
        &.xl {
          font-size: 50px;
        }
        &.xxl {
          font-size: 53px;
        }
      }
    }
    .stat-content {
      justify-content: center;
      text-align: center;
      .amount {
        &.h1 {
          font-size: 56px;
        }
        &.h2 {
          font-size: 50px;
        }
        &.h3 {
          font-size: 30px;
        }
        &.h4 {
          font-size: 24px;
        }
      }
      .description {
        width: 100%;
        font-size: 22px;
      }
    }
  }

  &.theme-transparent-blue {
    color: $cool_dark_blue;
    .stat-icon {
      .ce-icon-circle {
        color: $cool_dark_blue;
        border-color: $cool_dark_blue;
      }
    }
    .stat-content {
      .h1, .h2, .h3, .h4, .h5, .h6 {
        color: $cool_dark_blue;
        margin-bottom: 0;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .stat-content {
      .amount {
        &.h1 {
          font-size: 66px;
        }
        &.h2 {
          font-size: 56px;
        }
        &.h3 {
          font-size: $mod_font_size_h3_md;
        }
        &.h4 {
          font-size: $mod_font_size_h4_md;
        }
      }
      .description {
        font-size: $mod_font_size_h6_md;
      }
    }
    &.layout-horizontal {
      flex-direction: row;
      .stat-icon {
        margin-right: $mod_space_sm;
        margin-bottom: 0;
        width: auto;
      }
      .stat-content {
        justify-content: flex-start;
        text-align: left;
        width: auto;
        .amount {
          &.h1 {
            font-size: 52px;
          }
          &.h2 {
            font-size: 50px;
          }
          &.h3 {
            font-size: 32px;
          }
          &.h4 {
            font-size: 26px;
          }
        }
        .description {
          font-size: 22px;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .stat-content {
      .amount {
        &.h1 {
          font-size: $mod_font_size_h1_lg;
        }
        &.h2 {
          font-size: $mod_font_size_h2_lg;
        }
        &.h3 {
          font-size: $mod_font_size_h3_lg;
        }
        &.h4 {
          font-size: $mod_font_size_h4_lg;
          line-height: 1.3;
        }
      }
      .description {
        font-size: $mod_font_size_h6_md;
      }
    }
    &.layout-horizontal {
      .stat-icon {
        .ce-icon-circle {
          width: 125px;
          height: 125px;
          min-width: 125px;
          min-height: 125px;
          border-width: 3px;
          &.xxs {
            font-size: 42px;
          }
          &.xs {
            font-size: 47px;
          }
          &.sm {
            font-size: 51px;
          }
          &.md {
            font-size: 56px;
          }
          &.lg {
            font-size: 61px;
          }
          &.xl {
            font-size: 64px;
          }
          &.xxl {
            font-size: 69px;
          }
        }
      }
      .stat-content {
        .amount {
          &.h1 {
            font-size: 66px;
          }
          &.h2 {
            font-size: 50px;
          }
          &.h3 {
            font-size: 40px;
          }
          &.h4 {
            font-size: 30px;
          }
        }
        .description {
          font-size: 22px;
        }
      }

    }
  }
}

.ce-stats-impact {
  &.loading {
    .ce-stat-box {
      opacity: 0;
    }
  }
  &:not(.loading) {
    .ce-stat-box {
      opacity: 1;
      transition: .25s;
    }
  }
}
