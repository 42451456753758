.ce-module.stillin,
.ce-modal.stillin {
  .ce-card.checkout-card {
    .cart-items {
      .item {
        .product-type {
          font-weight: $stillin_font_weight_display_medium;
          font-family: $stillin_font_family_display;
        }
        //h4 {
        //  font-size: 18px;
        //  @include media-breakpoint-up(lg) {
        //    font-size: 20px;
        //  }
        //}
      }
    }
  }
}