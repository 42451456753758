.ce-module {
  .module-footer {
    @extend .col-12;

    @extend .col-md-8;
    @extend .offset-md-2;

    @extend .col-lg-8;
    @extend .offset-lg-2;

    text-align: center;
    > :last-child {
      margin-bottom: 0;
    }
    .header-body {
      > *:last-child {
        margin-bottom: 0;
      }
      @include media-breakpoint-down(sm) {
        text-align: left;
      }
    }

    @include media-breakpoint-down(md) {
      .header-title {
        font-size: 28px;
      }
    }
  }
}
