.ce-module {
    .ce-radio-button-grid-wrapper {
        margin-bottom: -$grid-gutter-width-md;
        .row {
            display: flex;
            flex-wrap: wrap;
            margin-left: -$grid-gutter-width-md/2;
            margin-right: -$grid-gutter-width-md/2;
            .col {
                padding-left: $grid-gutter-width-md/2;
                padding-right: $grid-gutter-width-md/2;
                padding-bottom: $grid-gutter-width-md;
            }
        }
    }
}

.ce-radio-button {
    .ce-radio-button_inner {
        display: flex;
        position: relative;
    }
    input.ce-radio-button_input[type="radio"] {
        cursor: pointer;
        display: block;
        height: 100%;
        margin: 0;
        opacity: 0;
        overflow: hidden;
        padding: 0;
        position: absolute;
        width: 100%;
    }
    label.ce-radio-button_label {
        border: 2px solid $cool_dark_gray;
        color: $cool_dark_gray;
        display: block;
        font-weight: $cool_font-bold-weight;
        line-height: 1.33333;
        margin: 0;
        max-width: 100%;
        padding: ( $mod_space_xs/2 ) 0;
        transition: background-color 0.3s ease, color 0.3s ease, border 0.3s ease;
        width: 100%;
        font-size: $font-size-base;

        &:before {
            display: none;
        }
    }
    input.ce-radio-button_input[type="radio"]:checked + label.ce-radio-button_label {
        background-color: $cool_blue;
        border-color: $cool_blue;
        color: $cool_white;
    }
}