.carousel.slide {
  .carousel-item {
    .carousel-caption {
      background-color: $cool_very_light_gray;
      box-sizing: border-box;
      color: $cool_medium_gray;
      padding: ($mod_space_lg / 2);
      position: static;
      text-align: left;
      text-shadow: initial;
      > p {
        margin-bottom: 0;
      }
    }
  }
}

.square {
  @include media-breakpoint-down(sm) {
    padding-top: 0;
  }
  .carousel-projects,
  .carousel-inner,
  .carousel-item,
  .carousel-item > a,
  .projects-slide {
    width: 100%;
    height: 100%;
  }

  @include media-breakpoint-down(sm) {
    .carousel-projects,
    .carousel-inner,
    .carousel-item,
    .carousel-item > a,
    .projects-slide {
      height: 400px;
    }
  }

  .carousel-projects {
    position: absolute;
    @include media-breakpoint-down(sm) {
      position: relative;
    }
    top: 0;
    left: 0;
    .projects-slide {
      padding: 40px 30px;
      width: 100%;
      background-size: 105% auto;
      background-position: center bottom;
      background-repeat: no-repeat;
      color: $cool_white;
      .row {
        .info {
          @extend .col-12;
        }
      }

      @include media-breakpoint-down(sm) {
        padding: 14px;
        position: relative;
        background-size: 100% auto;
      }

      h2,
      h3 {
        color: rgba(0, 0, 0, 0.65);
      }

      h2 {
        margin-bottom: 0.25rem;
      }

      h3 {
        font-weight: $cool_font-regular-weight;
      }

      .ce-icon-map-marker {
        display: inline-block;
        vertical-align: bottom;
        margin-right: 10px;
        line-height: 1;
      }

      a {
        color: $cool_white;
      }
    }

    .carousel-control {
      width: 100px;
      height: 73px;
      opacity: 0.9;
      @include centerer(null, 50%);
      &.left,
      &.right {
        @include media-breakpoint-down(sm) {
          display: none;
        }
        .indicator {
          @include centerer(null, 50%);
          .arrow,
          .endcap {
            display: inline-block;
            vertical-align: middle;
            float: left;
          }
          .endcap {
            width: 8px;
            transition: width 250ms ease-out;
            height: 73px;
            background-color: $cool_white;
          }
        }
      }

      &.left {
        left: -1px; // -1 instead of 0 to fix rendering issue
        .indicator {
          left: 0;
          .endcap {
            border-radius: 0 8px 8px 0;
          }
        }
      }
      &.right {
        right: -1px; // -1 instead of 0 to fix rendering issue
        .indicator {
          right: 0;
          .endcap {
            border-radius: 8px 0 0 8px;
          }
        }
      }
      &:hover {
        .indicator {
          .endcap {
            width: 48px;
            transition: width 250ms ease-out;
          }
        }
      }
      //
    }
  }
}

// Styles for carousel on individual projects

// vars
$carousel-control-icon-width: 20px !default;

$carousel-control-prev-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"), "#", "%23") !default;
$carousel-control-next-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"), "#", "%23") !default;

// Left/right controls for nav
//

.carousel-control-prev,
.carousel-control-next {
  position: absolute;
  top: 0;
  bottom: 0;
  // Use flex for alignment (1-3)
  display: flex; // 1. allow flex styles
  align-items: center; // 2. vertically center contents
  justify-content: center; // 3. horizontally center contents
  width: calc(#{$carousel-control-width} / 2);
  color: $carousel-control-color;
  text-align: center;
  opacity: $carousel-control-opacity;
  // We can't have a transition here because WebKit cancels the carousel
  // animation if you trip this while in the middle of another animation.

  // Hover/focus state
  @include hover-focus {
    color: $carousel-control-color;
    text-decoration: none;
    outline: 0;
    opacity: .9;
  }
  // For prev/next ce-icons
  [class*="ce-icon-chevron-"] {
    display: block;
  }
}

.carousel-control-prev {
  left: 0;
  .ce-icon-chevron-right {
    transform: rotate(180deg);
  }
}

.carousel-control-next {
  right: 0;
}

// Icons for within
.carousel-control-prev-icon,
.carousel-control-next-icon {
  display: inline-block;
  width: $carousel-control-icon-width;
  height: $carousel-control-icon-width;
  background: transparent no-repeat center center;
  background-size: 100% 100%;
}

.carousel-control-prev-icon {
  background-image: $carousel-control-prev-icon-bg;
}

.carousel-control-next-icon {
  background-image: $carousel-control-next-icon-bg;
}