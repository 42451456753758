/*
 * Name: Z-index Function
 * Purpose: Consolidate usage of z-index for SCSS modules
*/

/* Default Bootstrap values
 * * * * * * * * * * * * * 
 * $zindex-dropdown-backdrop: 990
 * $zindex-navbar : 1000
 * $zindex-dropdown : 1000
 * $zindex-fixed : 1030
 * $zindex-sticky : 1030
 * $zindex-modal-backdrop : 1040
 * $zindex-modal : 1050
 * $zindex-popover : 1060
 * $zindex-tooltip : 1070
 */

$zindex_layers: (
				"absolute-top": 9999,
				"overlay-top": 1050,
				"overlay-upper": 1040,
				"overlay": 1030,
				"overlay-lower": 1000,
				"overlay-bottom": 990,
				"sticky-header": 600,
				"header": 500,
				"sticky-footer": 400,
				"content-upper": 2,
				"content": 1,
				"content-lower": -1,
				"absolute-bottom": -9999
);

@function z-layer( $layer ) {
	@if not map-has-key( $zindex_layers, $layer ) {
		@warn "No layer found for `#{$layer}` in $zindex_layers. Property omitted.";
	}
	@return map-get( $zindex_layers, $layer );
}