.ce-module .crawler {
  background-color: $cool_dark_gray;
  color: $cool_white;
  font-weight: $cool-font-bold-weight;
  font-style: italic;
  letter-spacing: 1px;
  padding: .5rem 0;
  overflow: hidden;
  display: flex;
  flex-wrap: nowrap;
  @keyframes crawl {
    from {
      transform: translate3D(0, 0, 0);
    }
    to {
      transform: translate3D(-100%, 0, 0);
    }
  }
  .crawler-item {
    white-space: nowrap;
    transform: translate3D(0, 0, 0);
    animation-name: crawl;
    animation-duration: 15s;
    animation-iteration-count: infinite;
    animation-timing-function: linear;
    .separator {
      padding: 0 20px;
    }
  }
  @include media-breakpoint-up(md) {
    font-size: 20px;
    padding: .7rem 0;
  }

  &.loading {
    .crawler-item {
      opacity: 0;
    }
  }
  &:not(.loading) {
    .crawler-item {
      opacity: 1;
      transition: .25s;
    }
  }
}