.ce-module.stillin {
  .ce-grid {
    .grid-item {
      &.centered {
        text-align: center;
      }
      img.round {
        border-radius: 50%;
        width: 100%;
        margin-bottom: 20px;
      }
      .text-block > * {
        margin-bottom: 0;
      }
    }
  }
}
