.ce-module .ce-link-box {
  > a {
    border-radius: 8px;
    border: 2px solid $cool_dark_blue;
    padding: $mod_card_padding_v;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    &:hover {
      border-color: $cool_blue;
      .icon .circle {
        &:before {
          bottom: 0;
        }
        .ce-icon {
          color: $cool_blue;
          transition: color 0.75s ease;
        }
      }
      .copy {
        .heading {
          .ce-icon-arrow-right {
            color: $cool_blue;
            transition: color 0.75s ease;
          }
        }
      }
    }
  }
  .icon {
    margin-bottom: 1rem;
    .circle {
      $circleSizeSm: 80px;
      width: $circleSizeSm;
      height: $circleSizeSm;
      min-width: $circleSizeSm;
      min-height: $circleSizeSm;
      border-radius: 9999px;
      position: relative;
      display: flex;
      justify-content: center;
      align-items: center;
      overflow: hidden;
      &:before {
        content: "";
        display: block;
        position: absolute;
        //z-index: z-layer("content-lower");

        background: linear-gradient(to bottom, $cool_red 0, $cool_blue 50%);
        bottom: -100%;
        height: 200%;
        left: 0;
        right: 0;
        transition: bottom 0.75s ease;

      }
      .inner {
        width: $circleSizeSm - 6px;
        height: $circleSizeSm - 6px;
        min-width: $circleSizeSm - 6px;
        min-height: $circleSizeSm - 6px;
        border-radius: 9999px;
        background-color: $cool_white;
        display: flex;
        justify-content: center;
        align-items: center;
        position: relative;
        .ce-icon {
          color: $cool_dark_blue;
          &.xxs {
            font-size: 24px;
          }
          &.xs {
            font-size: 28px;
          }
          &.sm {
            font-size: 34px;
          }
          &.md {
            font-size: 37px;
          }
          &.lg {
            font-size: 40px;
          }
          &.xl {
            font-size: 40px;
          }
          &.xxl {
            font-size: 46px;
          }
        }
      }
    }
  }
  .copy {
    flex: 0 1 auto;
    min-width: 1px;
    display: flex;
    justify-content: center;
    .heading {
      margin-bottom: 0;
      flex: 0 1 auto;
      text-align: center;
      .ce-icon-arrow-right {
        font-size: 1rem;
        margin-left: 10px;
        color: $cool_dark_blue;
      }
    }
  }
  &.theme-white-light {
    background-color: $cool_white;
  }
  &.layout-horizontal {
    .icon {
      margin-bottom: $mod_space_xs;
    }
  }
  @include media-breakpoint-up(md) {
    &.layout-horizontal {
      > a {
        flex-direction: row;
        justify-content: flex-start;
        .icon {
          margin-right: $mod_space_sm;
          margin-bottom: 0;
          width: auto;
        }
        .copy {
          display: block;
          .heading {
            text-align: left;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &.layout-horizontal {
      .icon {
        .circle {
          $circleSizeLg: 125px;
          width: $circleSizeLg;
          height: $circleSizeLg;
          min-width: $circleSizeLg;
          min-height: $circleSizeLg;
          .inner {
            width: $circleSizeLg - 6px;
            height: $circleSizeLg - 6px;
            min-width: $circleSizeLg - 6px;
            min-height: $circleSizeLg - 6px;
            .ce-icon {
              &.xxs {
                font-size: 42px;
              }
              &.xs {
                font-size: 47px;
              }
              &.sm {
                font-size: 51px;
              }
              &.md {
                font-size: 56px;
              }
              &.lg {
                font-size: 61px;
              }
              &.xl {
                font-size: 64px;
              }
              &.xxl {
                font-size: 69px;
              }
            }
          }
        }
      }
    }
  }

}
