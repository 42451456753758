.ce-module {
  .flipcard {
    position: relative;
    perspective: 1000px;
    -webkit-perspective: 1000px;
    .flipcard-inner {
      position: relative;
      width: 100%;
      height: 100%;
      transition: 0.6s;
      transform-style: preserve-3d;

      .flip-card-front,
      .flip-card-back {
        backface-visibility: hidden;
        -webkit-backface-visibility: hidden;
        transition: 0.6s;
        transform-style: preserve-3d;
      }
      .flip-card-front {
        z-index: 2;
        transform: rotateY(0deg);
      }
      .flip-card-back {
        transform: rotateY(-180deg);
      }
    }
    &.flip .flipcard-inner  {
      .flip-card-front {
        transform: rotateY(180deg);
      }
      .flip-card-back {
        transform: rotateY(0deg);
      }
    }
  }
  .flipcard.flipcard-flex {
    .flipcard-inner {
      display: flex;
      flex-wrap: nowrap;
      align-items: stretch;
      width: 100%;
      height: 100%;
      .flip-card-front,
      .flip-card-back {
        box-sizing: border-box;
        width: 100%;
        height: auto;
        flex: none;
      }
      .flip-card-front {
        margin-left: -100%;
      }
    }
  }
}
