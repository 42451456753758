@font-face {
  font-family: 'cooleffect';
  src:  url('/app/fonts/cooleffect/cooleffect.eot?2u20f1');
  src:  url('/app/fonts/cooleffect/cooleffect.eot?2u20f1#iefix') format('embedded-opentype'),
  url('/app/fonts/cooleffect/cooleffect.ttf?2u20f1') format('truetype'),
  url('/app/fonts/cooleffect/cooleffect.woff?2u20f1') format('woff'),
  url('/app/fonts/cooleffect/cooleffect.svg?2u20f1#icomoon') format('svg');
  font-weight: normal;
  font-style: normal;
}

[class^="ce-icon-"], [class*=" ce-icon-"] {
  /* use !important to prevent issues with browser extensions that change fonts */
  font-family: 'cooleffect' !important;
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;

  font-size: 22px;

  /* Better Font Rendering =========== */
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

.ce-icon-home:before {
  content: "\e900";
}
.ce-icon-travel-car:before {
  content: "\e901";
}
.ce-icon-travel-rail:before {
  content: "\e902";
}
.ce-icon-travel-air:before {
  content: "\e903";
}
.ce-icon-trash:before {
  content: "\e904";
}
.ce-icon-shipping:before {
  content: "\e905";
}
.ce-icon-meat:before {
  content: "\e906";
}
.ce-icon-fashion:before {
  content: "\e907";
}
.ce-icon-electricity:before {
  content: "\e908";
}
.ce-icon-air:before {
  content: "\e909";
}
.ce-icon-undo:before {
  content: "\e90a";
}
.ce-icon-printer:before {
  content: "\e90b";
}
.ce-icon-paper-plane:before {
  content: "\e90c";
}
.ce-icon-credit-card:before {
  content: "\e90d";
}
.ce-icon-check:before {
  content: "\e90e";
}
.ce-icon-ton:before {
  content: "\e90f";
}
.ce-icon-award:before {
  content: "\e910";
}
.ce-icon-earth:before {
  content: "\e911";
}
.ce-icon-gift:before {
  content: "\e912";
}
.ce-icon-gift-bow:before {
  content: "\e913";
}
.ce-icon-heart:before {
  content: "\e914";
}
.ce-icon-heart-dollar:before {
  content: "\e915";
}
.ce-icon-plus-outline:before {
  content: "\e916";
}
.ce-icon-minus-outline:before {
  content: "\e917";
}
.ce-icon-arrow-right:before {
  content: "\e918";
}
.ce-icon-arrow-down:before {
  content: "\e919";
}
.ce-icon-facebook:before {
  content: "\e91a";
}
.ce-icon-google-plus:before {
  content: "\e91b";
}
.ce-icon-instagram:before {
  content: "\e91c";
}
.ce-icon-linkedin:before {
  content: "\e91d";
}
.ce-icon-twitter:before {
  content: "\e91e";
}
.ce-icon-youtube:before {
  content: "\e91f";
}
.ce-icon-question:before {
  content: "\e920";
}
.ce-icon-close:before {
  content: "\e921";
}
.ce-icon-map-marker:before {
  content: "\e922";
}
.ce-icon-spinner:before {
  content: "\e923";
}
.ce-icon-cart:before {
  content: "\e924";
}
.ce-icon-logo:before {
  content: "\e925";
}
.ce-icon-search:before {
  content: "\e926";
}
.ce-icon-search-alt:before {
  content: "\e927";
}
.ce-icon-logo-alt:before {
  content: "\e928";
}
.ce-icon-user:before {
  content: "\e929";
}
.ce-icon-calendar-day:before {
  content: "\e92a";
}
.ce-icon-calendar-month:before {
  content: "\e92b";
}
.ce-icon-plus:before {
  content: "\e92c";
}
.ce-icon-minus:before {
  content: "\e92d";
}
.ce-icon-pencil:before {
  content: "\e92e";
}
.ce-icon-chevron-right:before {
  content: "\e92f";
}
.ce-icon-chevron-down:before {
  content: "\e930";
}
.ce-icon-envelope:before {
  content: "\e931";
}
.ce-icon-calculator:before {
  content: "\e932";
}
.ce-icon-download:before {
  content: "\e933";
}
.ce-icon-question-outline:before {
  content: "\e934";
}
.ce-icon-exclamation:before {
  content: "\e935";
}
.ce-icon-carbon:before {
  content: "\e936";
}
.ce-icon-person:before {
  content: "\e937";
}
.ce-icon-binoculars:before {
  content: "\e938";
}
.ce-icon-authenticate:before {
  content: "\e939";
}
.ce-icon-select:before {
  content: "\e93a";
}
.ce-icon-x:before {
  content: "\e93b";
}
.ce-icon-si-tilde:before {
  content: "\e93c";
}
.ce-icon-refresh:before {
  content: "\e93d";
}
.ce-icon-exclamation-outline:before {
  content: "\e93e";
}
.ce-icon-info:before {
  content: "\e93f";
}
.ce-icon-verify:before {
  content: "\e940";
}
.ce-icon-science-clipboard:before {
  content: "\e941";
}
.ce-icon-team:before {
  content: "\e942";
}
.ce-icon-globe-hand:before {
  content: "\e943";
}
.ce-icon-community:before {
  content: "\e944";
}
.ce-icon-certificate:before {
  content: "\e945";
}
.ce-icon-carbon-reduce:before {
  content: "\e946";
}
.ce-icon-play-outline:before {
  content: "\e948";
}
.ce-icon-sun:before {
  content: "\e949";
}
.ce-icon-footprint:before {
  content: "\e947";
}
.ce-icon-footprint-outline:before {
  content: "\e94a";
}
.ce-icon-office:before {
  content: "\e94b";
}
.ce-icon-double-quote-open:before {
  content: "\e94c";
}
.ce-icon-double-quote-close:before {
  content: "\e94d";
}
