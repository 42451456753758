.ce-module,
.ce-modal {
  .ce-icon-circle {
    display: flex;
    align-items: center;
    justify-content: center;
    width: 60px;
    height: 60px;
    min-width: 60px;
    min-height: 60px;
    color: $cool_purple;
    background-color: transparent;
    border: 2px solid $cool_purple;
    border-radius: 9999px;
    &.xxs {
      font-size: 18px;
    }
    &.xs {
      font-size: 21px;
    }
    &.sm {
      font-size: 24px;
    }
    &.md {
      font-size: 26px;
    }
    &.lg {
      font-size: 30px;
    }
    &.xl {
      font-size: 32px;
    }
    &.xxl {
      font-size: 34px;
    }

    @include media-breakpoint-up(lg) {
      width: 80px;
      height: 80px;
      min-width: 80px;
      min-height: 80px;
      &.xxs {
        font-size: 27px;
      }
      &.xs {
        font-size: 30px;
      }
      &.sm {
        font-size: 33px;
      }
      &.md {
        font-size: 36px;
      }
      &.lg {
        font-size: 39px;
      }
      &.xl {
        font-size: 41px;
      }
      &.xxl {
        font-size: 44px;
      }
    }
  }
  a {
    .ce-icon-circle {
      color: $cool_white;
      background-color: $cool_purple;
    }
    &:hover,
    &:active,
    &:focus {
      .ce-icon-circle {
        color: $cool_purple;
        background-color: transparent;
        transition: all .3s ease;
      }
    }
  }
}
