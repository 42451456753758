.ce-module.header-topbar-module {
  height: $header-topbar-height;
  background-color: $cool_purple;
  color: $cool_white;
  text-transform: uppercase;
  display: none;
  @include media-breakpoint-up(lg) {
    display: block;
  }

  .navbar-admin {
    display: flex;
    justify-content: flex-end;
    text-transform: uppercase;
    font-weight: $cool_font-semi-bold-weight;
    font-size: $mod_font_size_small;
    margin-left: -$grid-gutter-width-md/2;
    margin-right: -$grid-gutter-width-md/2;
    padding: 0;
    .admin-cta {
      display: flex;
      align-items: center;
      padding: 0 20px;
    }
    .admin-cta,
    .admin-cta:hover,
    .admin-cta:active,
    .admin-cta:focus {
      color: $cool_white;
    }
  }
  .navbar-admin-menu {
    height: $header-topbar-height;
    padding: 0;
    margin: 0;
    list-style: none;
    display: flex;
    align-items: stretch;
    .nav-item {
      text-transform: uppercase;
      font-weight: $cool_font-semi-bold-weight;
      font-size: $mod_font_size_small;
      //margin-left: 15px;
      display: flex;
      align-items: center;
      position: relative;
      padding: 0 $grid-gutter-width/2;
      .item-action {
        color: $cool_white;
        line-height: 1;
        position: relative;
        display: flex;
        align-items: center;
        &.login-action {
          .ce-icon-user {
            margin-right: .5rem;
          }
        }
      }
      //.nav-menu-cart-count {
      //  top: 0;
      //  left: 18px;
      //  letter-spacing: 1px;
      //}
    }
    .user-menu {
      .ce-dropdown-toggle {
        .item-label {
          display: none;
        }
        .indicator {
          font-size: 22px;
          margin-left: 0;
          display: flex;
          align-items: center;
          &:before {
            font-size: 22px;
            @extend .ce-icon-user:before;
          }
          &:after {
            @extend .ce-icon-chevron-down:before;
            font-size: 7px;
            margin-left: 10px;
          }
        }
      }
      .ce-dropdown-menu {
        min-width: 215px;
        left: 50%;
        transform: translateX(-50%);
      }
    }
  }
}