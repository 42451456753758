.partner-projects {
  .ce-card.project-preview-card {

    .ce-card-title {
      color: $cool_purple;
    }

    .ce-card-footer {
      .cta.price {
        margin-bottom: 0;

        .price {
          position: relative;
          margin-right: 10px;
          padding: 6px 35px 10px 5px;
          border: 1px solid $cool_purple;
          border-radius: 5px;
          color: $cool_purple;
        }

        .amount{
          display: inline-block;

          &::first-letter {
            font-size: 2rem;
            line-height: 1;
            display: inline-block;
            vertical-align: top;
          }
        }

        sup {
          top: 0px;
          font-size: 100%;
        }

        sub  {
          position: absolute;
          top: 30px;
          right: 5px;
          text-transform: uppercase;
        }
      }
    }
  }
}
