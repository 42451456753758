.ce-module .ce-text-card {
  text-align: center;
  figure {
    padding: 0;
    display: flex;
    justify-content: center;
  }
  .ce-card-inner {
    padding: $mod_card_content_padding_featured $mod_space_xl;
    .ce-card-header {
      margin-bottom: $mod_space_xs;
      [class^="ce-icon-"], [class*=" ce-icon-"] {
        margin: 0 auto $mod_space_sm auto;
      }
    }
  }

  &.theme-gray-light,
  &.theme-primary-gradient-dark,
  &.theme-secondary-gradient-dark,
  &.theme-transparent-outline-dark,
  &.theme-transparent-blue {
    background: none;
  }
  &.theme-transparent-outline-dark {
    border: none;
  }

  &.theme-gray-light .ce-card-inner {
    @extend .theme-gray-light;
  }

  &.theme-primary-gradient-dark .ce-card-inner {
    @extend .theme-primary-gradient-dark;
  }

  &.theme-secondary-gradient-dark .ce-card-inner {
    @extend .theme-secondary-gradient-dark;
  }

  &.theme-transparent-outline-dark .ce-card-inner {
    @extend .theme-transparent-outline-dark;
  }

  &.theme-transparent-outline-dark .ce-card-inner {
    @extend .theme-transparent-outline-dark;
  }

  &.theme-primary-gradient-dark,
  &.theme-secondary-gradient-dark,
  &.theme-transparent-outline-dark {
    .ce-icon-circle {
      border-color: $cool_white;
    }
  }

  &.theme-transparent-blue {
    color: $cool_dark_blue;
    .ce-icon-circle {
      border-color: $cool_dark_blue;
      color: $cool_dark_blue;
    }
    h1, h2, h3, h4, h5, h6 {
      color: $cool_dark_blue;
    }
  }
  &.theme-icon-box {
    figure {
      margin-bottom: -30px;
      z-index: 1;
    }
    .ce-card-inner {
      padding: 0;
      .ce-card-content {
        border: 2px solid $cool_dark_blue;
        border-radius: 8px;
        padding: 50px 30px 30px 30px;
        background-color: $cool_white;
        .ce-card-header {
          margin-bottom: $mod_space_md;
        }
      }
    }
    .ce-icon-circle {
      border-color: $cool_dark_blue;
      color: $cool_dark_blue;
      background-color: $cool_white;
    }
    h1, h2, h3, h4, h5, h6 {
      color: $cool_dark_blue;
      &:after {
        display: block;
        content: '';
        width: 100px;
        height: 8px;
        background: linear-gradient( to right, $cool_red 0, $cool_blue 100% );
        border-radius: 9999px;
        margin: $mod_space_md auto 0 auto;
      }
    }
  }

  @include media-breakpoint-up(lg) {
    &.layout-horizontal {
      .ce-card-content {
        flex-direction: row;
      }
    }
    &.theme-icon-box {
      figure {
        margin-bottom: -40px;
      }
    }
  }
}
