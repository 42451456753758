.ce-module .offset-calculator {
  .tonnes-calculated {
    display: flex;
    justify-content: center;
    flex: 1 0 auto;
    .circle {
      position: relative;
      width: 156px;
      height: 156px;
      border-radius: 9999px;
      padding: 4px;
      background: linear-gradient( to bottom, $cool_red 0, $cool_blue 100% );
      .inner {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        background-color: $cool_very_light_gray;
        border-radius: 9999px;
        width: 100%;
        height: 100%;
      }

      .amt,
      .units {
        font-weight: $cool_font-semi-bold-weight;
        color: $cool_dark_gray;
      }
      .amt {
        font-size: 46px;
        line-height: 1;
      }
      .units {
        font-size: 18px;
        text-transform: capitalize;
      }
    }
    @include media-breakpoint-up(lg) {
      order: 0;
      .circle {
        width: 204px;
        height: 204px;
        .amt {
          font-size: 70px;
        }
      }
    }
    &.md {
      @include media-breakpoint-up(lg) {
        .circle {
          width: 188px;
          height: 188px;
          .amt {
            font-size: 60px;
          }
        }
      }
      @include media-breakpoint-up(xl) {
        .circle {
          width: 204px;
          height: 204px;
          .amt {
            font-size: 60px;
          }
        }
      }

    }
  }
}
