.ce-module.header-nav-module.stillin {
  nav.navbar.desktop {
    .logos {
      .logo {
        &.separator {
          margin: 0 30px;
          font-size: 24px;
        }
        &.stillin-logo {
          font-size: 28px;
          letter-spacing: 1.5px;
          &:after {
            height: 13px;
          }
        }
        &.ce-logo {
          font-size: 28px;
        }
      }
    }
    .navbar-dropdown {
      justify-content: flex-end;
      margin-right: -20px;
      .item-action {
        &:hover:after {
          display: none !important;
        }
        .ce-dropdown-toggle[aria-expanded="true"] {
          &:after {
            background: none;
            width: 0;
            height: 0;
            border-style: solid;
            border-width: 0 10px 10px 10px;
            border-color: transparent transparent $stillin_color_blue_gray transparent;
            left: 50%;
            transform: translateX(-50%);
          }
        }
      }
    }
    .nav-menu-cart-count {
      top: 0 !important;
      left: 0 !important;
      margin-top: -7px;
      margin-left: -7px;
    }
  }
}