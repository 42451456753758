.ce-module,
.ce-modal {
  p {
    line-height: 1.38; // Make 22px
  }
  small {
    font-size: 13px;
    line-height: 1.4;
  }
  h2, h3, h4, h5, h6 {
    line-height: 1.2;
  }


  h1, .h1 {
    font-size: $mod_font_size_h1;
    line-height: 1.1;
  }
  h2, .h2 {
    font-size: $mod_font_size_h2;
  }
  h3, .h3 {
    font-size: $mod_font_size_h3;
  }
  h4, .h4 {
    font-size: $mod_font_size_h4;
  }
  h5, .h5 {
    font-size: $mod_font_size_h5;
  }

  @include media-breakpoint-up(md) {
    h1, .h1 {
      font-size: $mod_font_size_h1_md;
      line-height: 1.2;
    }
    h2, .h2 {
      font-size: $mod_font_size_h2_md;
    }
    h3, .h3 {
      font-size: $mod_font_size_h3_md;
    }
    h4, .h4 {
      font-size: $mod_font_size_h4_md;
    }
    h5, .h5 {
      font-size: $mod_font_size_h5_md;
    }
  }

  @include media-breakpoint-up(lg) {
    p {
      line-height: 1.5;
    }
    h1, .h1 {
      font-size: $mod_font_size_h1_lg;
      line-height: 1.15;
    }
    h2, .h2 {
      font-size: $mod_font_size_h2_lg;
    }
    h3, .h3 {
      font-size: $mod_font_size_h3_lg;
    }
    h4, .h4 {
      font-size: $mod_font_size_h4_lg;
    }
    h5, .h5 {
      font-size: $mod_font_size_h5_lg;
    }
  }

  .legal {
    color: $cool_gray;
    a {
      color: $cool_gray;
      text-decoration: underline;
    }
  }
}

.ce-textblock {
  // to preserve line breaks entered into vc input
  p, blockquote, small {
    white-space: pre-wrap;
  }
}
