.ce-module {
  .ce-card.checkout-card,
  .checkout-section,
  .ce-purchase-inline {
    form#paymentInfo {
      padding-bottom: $mod_space_xl;
      .separator {
        display: block;
        width: 100%;
        height: 1px;
        background-color: $cool_gray;
        margin-top: $mod_space_lg;
        margin-bottom: $mod_space_lg;
      }
      .fieldset.newsletter {
        margin-top: .5rem;
        margin-bottom: 1.25rem;
      }
      .fieldset.is-business {
        margin-bottom: .75rem;
        > .col {
          display: flex;
          flex-wrap: wrap;
          flex-direction: column;
          .text-label {
            width: 100%;
          }
          input[type="radio"].ce-radio + label {
            font-size: 1rem;
          }
          .field + .field {
            margin-top: .5rem;
          }
          .radio-with-tooltip {
            display: flex;
            justify-content: flex-start;
            label {
              flex: 0 1 auto;
              width: auto;
            }
            .tooltip-trigger {
              color: $cool_blue;
              font-size: 1rem;
              margin-left: $mod_space_xs;
              line-height: inherit;
            }
          }
        }
        @include media-breakpoint-up(sm) {
          > .col {
            flex-direction: row;
            .field + .field {
              margin-top: 0;
              margin-left: $mod_space_lg;
            }
          }
        }
      }
      .ce-radio-tabs.payment-methods {
        margin-bottom: $grid-gutter-width/2;
        input[type="radio"] + label {
          @include media-breakpoint-only(sm) {
            font-size: $font-size-base;
          }
          @include media-breakpoint-up(md) {
            font-size: 1rem;
          }
          [class^="ce-icon-"],
          [class*=" ce-icon-"] {
            font-size: 16px;
            margin-bottom: 0;
            @include media-breakpoint-up( md ) {
              margin-right: $grid-gutter-width-sm;
            }
          }
          .ce-icon-credit-card {
            font-size: 20px;
          }
          img {
            height: auto;
            max-width: 75%;
            width: auto;
            pointer-events: none;
            @include media-breakpoint-up( md ) {
              max-width: unset;
              height: 26px;
            }
          }
          .google-pay-logo {
            width: auto;
            height: 26px;
          }
          .apple-pay-logo {
            width: 100%;
            height: 26px;
          }
          .name {
            line-height: 1.2;
            text-align: center;
          }
        }
        input[type="radio"]:checked + label {
          img {
            filter: none;
            opacity: 1;
          }
        }
        input[type="radio"]:disabled + label {
          img {
            filter: grayscale(100%);
            opacity: .25;
          }
          .apple-pay-logo {
            filter: grayscale(100%);
            opacity: .25;
          }
          .google-pay-logo {
            filter: grayscale(100%);
            opacity: .5;
          }
        }
      }

      .fieldset.braintree {
        width: 100%;
        &.disabled {
          label {
            color: $cool_gray;
          }
          .braintree-input {
            border-color: $cool_light_gray;
          }
        }
      }
      label {
        .ce-icon-question-outline {
          font-size: 16px;
          color: $cool_blue;
          float: right;
          margin-left: 10px;
          order: 2;
          &:hover {
            color: $cool_dark_blue;
          }
        }
      }

      .guidestar {
        margin-top: $mod_space_xs;
        display: flex;
        justify-content: center;
        width: 100%;
        img {
          width: 120px;
          height: 120px;
        }
      }
    }
    form#crmInfo,
    form#paymentInfo {
      .ce-checkbox + label {
        font-weight: $cool_font-regular-weight;
      }
      .checkbox-with-info {
        display: flex;
        justify-content: flex-start;
        margin-top: $mod_space_xs;
        label {
          margin-right: 10px;
          margin-bottom: 0;
          width: auto;
        }
        .ce-icon-question-outline {
          font-size: 18px;
          color: $cool_blue;
          display: flex;
          align-items: center;
          &:hover {
            color: $cool_dark_blue;
          }
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .ce-card.checkout-card {
      form {
        .radio-tabs.payment-methods {
          input[type="radio"] + label {
            font-size: $mod_font_size_h4_lg;
            .ce-icon-credit-card {
              font-size: 20px;
            }
          }
        }
      }
    }
  }

  .checkout-actions {
    button {
      position: relative;
    }
    .recaptcha {
      margin-bottom: $mod_space_lg;
      max-width: 100%;
      @include media-breakpoint-up(lg) {
        margin-bottom: $mod_space_xl;
      }
    }
  }

  .iframe-disabled {
    filter: grayscale(100%) brightness(105%);
    pointer-events: none;
  }
}
