.ce-map {
  padding-top: 67%;
  position: relative;

  .mapboxgl-map {
    font-size: $mod_font_size_small;
    font-family: $font-family-base;
  }
  .mapboxgl-popup {
    max-width: 200px;
  }
  .mapboxgl-popup-close-button {
    display: none;
  }
  .mapboxgl-popup-content {
    padding: 10px;
  }
  .info-window-content {
    display: flex;
    figure {
      position: relative;
      display: none;
      width: 100%;
      height: 80px;
      min-width: 80px;
      background-size: cover;
      background-position: center;
      background-repeat: no-repeat;
      //margin-right: 10px;
      margin-bottom: 10px;
      .overlay {
        position: absolute;
        width: 100%;
        top: 0;
        bottom: 0;
        left: 0;
        background-color: rgba(0,0,0,.25);
      }
      .ce-icon-award {
        font-size: 47px;
        color: $cool_yellow;
        z-index: 1;
      }
    }
    .copy > *:last-child {
      margin-bottom: 0;
    }
    .pre-title {
      font-size: 12px;
      line-height: 1.1;
      margin-bottom: 3px;
    }
    .info-title {
      font-size: $mod_font_size_small;
      margin-bottom: .5rem;
      line-height: 1.4;
    }
  }
  @include media-breakpoint-up(sm) {
    .mapboxgl-popup {
      max-width: 250px;
    }
  }
  @include media-breakpoint-up(md) {
    &.project-map {
      padding-top: 50%;
    }
    .mapboxgl-map {
      font-size: $font-size-base;
    }
    .mapboxgl-popup {
      max-width: 160px;
    }
    .mapboxgl-popup-content {
      padding: 10px;
    }
    .info-window-content {
      display: flex;
      flex-direction: column;
      figure {
        display: flex;
        align-items: center;
        justify-content: center;
      }
      font-size: $mod_font_size_small;
      .pre-title {
        font-size: 12px;
      }
      .info-title {
        font-size: $mod_font_size_small;
      }
    }
  }
  @include media-breakpoint-up(lg) {
    &.project-map {
      padding-top: 40%;
    }
  }

  .map {
    position: absolute;
    width: 100%;
    top: 0;
    bottom: 0;
    left: 0;

    .marker {
      background-size: contain;
      width: 22px;
      height: 32px;
    }
  }
}
