.ce-social-media_wrapper_mobile {
  padding: 0 1rem;
  @include media-breakpoint-down(md) {
    & + *.vertically-spaced:not(.theme-gray-light) {
      padding-top: 1rem;
    }
  }
  @include media-breakpoint-up(lg) {
    display: none;
  }
  .ce-social-media {
    margin-top: 20px;
    width: 100%;
  }
}

.ce-social-media {
  align-items: center;
  display: flex;
  justify-content: flex-end;

  > * {
    margin: 0 ( $mod_space_xs / 2 );
  }
  > *:first-child {
    margin-left: 0;
  }
  > *:last-child {
    margin-right: 0;
  }

  .ce-media_link {
    border-radius: 5px;
    display: flex;
    align-items: center;
    text-transform: lowercase;
    overflow: hidden;

    * {
      pointer-events: none;
    }

    > [class*="ce-icon-"] {
      color: $cool_white;
      height: 35px;
      padding: 7px;
      width: 35px;
      min-width: 35px;
      display: flex;
      align-items: center;
    }
    .ce-icon-twitter {
      font-size: 18px;
    }
    .ce-media_label {
      color: $cool_white;
      padding: 0 0.5em 0;
      overflow: hidden;
      display: none;
      align-items: center;
    }

    // Animate on larger screen sizes only
    @include media-breakpoint-up(lg) {
      max-width: 35px;
      transition: padding .25s ease-in-out, max-width 0.25s ease-in-out;
      &:hover {
        max-width: 600px;
        transition: padding 0.25s ease-in-out, max-width 0.5s ease-in-out;
      }
      > * {
        transition: inherit;
      }
      .ce-media_label {
        padding-left: 0;
        padding-right: 0;
        display: flex;
      }
      &:hover .ce-media_label {
        padding-left: 0.5em;
        padding-right: 0.5em;
      }
    }
  }

  .ce-media_facebook .ce-media_link {
    background-color: #496CB8;
    .ce-icon-facebook {
      background-color: #39589B;
    }
  }
  .ce-media_twitter .ce-media_link {
    background-color: #3b8ece;
    .ce-icon-twitter {
      background-color: #4FABF0;
    }
  }
}
