/*
  ** mobile Carousel Controls
  * Reusable component for creating controls aligned
  * at the bottom of a carousel
*/

// Selector
$ce-mobile-carousel-ctrl: ".ce-mobile-carousel-controls";
$ce-mobile-carousel-ctrl-attr: "data-respond-at";
$grid-breakpoint-keys: map-keys($grid-breakpoints);

// Set styles for component
@mixin set-ce_mobile_carousel-controls {
  display: flex;
  width: 100%;
  margin: $mod_space_lg auto 0;
  justify-content: center;
  align-items: center;

  // Control Reset
  .carousel-control-next,
  .carousel-control-prev,
  .carousel-indicators {
    position: static;
    width: auto;
  }
  .carousel-control-next,
  .carousel-control-prev {
    align-items: initial;
    bottom: initial;
    display: initial;
    opacity: 1;
    top: initial;
    padding: 0 10px;

    [class*="ce-icon-chevron-"] {
      display: block;
      color: $cool_gray;
      font-size: 15px;
    }
  }
  .carousel-control-prev {
    .ce-icon-chevron-right {
      transform: rotate(180deg);
    }
  }
  .carousel-control-next-icon {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$cool_light_gray}' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"), "#", "%23");
  }
  .carousel-control-prev-icon {
    background-image: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$cool_light_gray}' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"), "#", "%23");
  }
  .carousel-indicators {
    bottom: 0;
    display: flex;
    left: initial;
    margin-bottom: 0;
    margin-left: 5px;
    margin-right: 5px;

    li {
      border: 2px solid $cool_gray;
      display: block;
      height: 15px;
      margin: 5px;
      width: 15px;
      margin-top: 0 !important;
      margin-bottom: 0 !important;
    }
    li.active {
      background-color: transparent;
      border-color: $cool-purple;
      margin: 5px;
    }
  }
}

/** Base **/
#{$ce-mobile-carousel-ctrl}:not([#{$ce-mobile-carousel-ctrl-attr}]) {
  @include set-ce_mobile_carousel-controls;
}

// Set styles for specific breakpoints
@mixin set-ce_mobile_carousel-controls-breakpoint($breakpoint) {
  /** Breakpoint: #{$breakpoint} **/
  #{$ce-mobile-carousel-ctrl}[#{$ce-mobile-carousel-ctrl-attr}="#{$breakpoint}"] {
    @include media-breakpoint-down(#{$breakpoint}) {
      @include set-ce_mobile_carousel-controls;
    }
  }
}

// Iterate over each breakpoint
@for $i from 1 through length($grid-breakpoint-keys) {
  $breakpoint: nth($grid-breakpoint-keys, $i);
  @include set-ce_mobile_carousel-controls-breakpoint($breakpoint);
}
