.ce-module,
.ce-modal {
  select {
    -webkit-appearance: none;
    -moz-appearance: none;
    appearance: none;
    width: 100%;
    height: 40px;
    padding: 0 10px;
    background-color: $cool_very_light_gray;
    border-color: $cool_very_light_gray;
    font-weight: $cool_font-semi-bold-weight;

  }
}

.ce-select {
  display: block;
  padding: 0;
  outline: transparent solid 0px;
  background-color: $cool_very_light_gray;
  width: 100%;
  cursor: pointer;

  label {
    cursor: pointer;
  }

  .items {
    max-height: 160px;
    overflow-y: auto;
    -ms-overflow-style: none;
    .item {
      width: 100%;
      background-color: $cool_very_light_gray;
      cursor: pointer;
      white-space: nowrap;

      label {
        margin: 0;
        width: 100%;
        padding-top: 6px;
        padding-bottom: 6px;
      }
      &:first-of-type {
        label {
          padding-top: 10px;
        }
      }
      &:last-of-type {
        label {
          padding-bottom: 12px;
        }
      }
    }
  }
  .selected {
    height: 40px;
  }
  .selected,
  .item label {
    padding-left: 40px;
    padding-right: 20px;
  }
  .selected label {
    margin-bottom: 0;
    white-space: nowrap;
  }
  .selected label,
  .items .item label {
    font-size: $font-size-base;
    line-height: 1;
  }
  .selected label,
  .selected .indicator,
  .items .item label {
    color: $cool_dark_gray;
  }

  .items .item label {
    font-weight: $cool_font-regular-weight;
  }
  .selected label,
  .items .item label:hover {
    font-weight: $cool_font-semi-bold-weight;
  }
  &.theme-light-gray {
    background-color: $cool_very_light_gray;
    .item {
      background-color: $cool_very_light_gray;
    }
    .selected label,
    .selected .indicator,
    .items .item label {
      color: $cool_dark_gray;
    }
  }
  &.theme-dark-gray {
    background-color: $cool_dark_gray;
    .item {
      background-color: $cool_dark_gray;
    }
    .selected label,
    .selected .indicator,
    .items .item label {
      color: $cool_white;
    }
  }
  &.theme-white {
    background-color: $cool_white;
    .item {
      background-color: $cool_white;
    }
    .selected label,
    .selected .indicator,
    .items .item label {
      color: $cool_dark_gray;
    }
  }
  &.theme-white-with-border {
    .selected {
      height: 42px;
    }
    background-color: $cool_white;
    .item {
      background-color: $cool_white;
    }
    .selected label,
    .selected .indicator,
    .items .item label {
      color: $cool_dark_gray;
    }
    .ce-collapsible-trigger,
    .ce-collapsible-target {
      border: 2px solid $cool_gray;
    }
    .ce-collapsible-target {
      border-top: none;
    }
  }
  
  .selected {
    display: flex;
    align-items: center;
    justify-content: space-between;
    .indicator {
      display: flex;
      align-items: center;
      margin-left: 40px;
      .ce-icon-chevron-right {
        display: block;
        font-weight: $cool_font-regular-weight;
        transition: transform 250ms;
        transform: rotate(-90deg);
        font-size: 12px;
        line-height: 1;
      }
    }
  }

  .hide .indicator .ce-icon-chevron-right {
    transform: rotate(90deg);
  }
  [ce-collapsible-target] {
    display: none;
  }
  [ce-collapsible-trigger] {
  }
  .ce-collapsible-target {
    z-index: z-layer( "content-upper" );
  }
  input[type="radio"] {
    display: none;
  }
  &.disabled {
    .selected label,
    .selected .indicator {
      color: $cool_gray;
    }
    cursor: not-allowed;
    * {
      pointer-events: none;
    }
  }
}
