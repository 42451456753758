.ce-module .ce-tabs {
  width: 100%;
  .nav-tabs {
    @extend .row;
    border: none;
    &:after {
      content: '';
      display: block;
      width: 100%;
      height: 10px;
      background-color: $cool_very_light_gray;
      margin: 0 $grid-gutter-width-lg/2;
    }
    .nav-item {
      @extend .col;
      display: flex;
      justify-content: center;
      margin-bottom: -10px;
      .nav-link {
        flex: 0 1 auto;
        padding: 0 $mod_space_sm $grid-gutter-width-lg $mod_space_sm;
        display: flex;
        flex-direction: column;
        align-items: center;
        background: none;
        border: none;
        h4 {
          color: $cool_purple;
          margin-bottom: 0;
        }
        .ce-icon-circle {
          margin-bottom: $grid-gutter-width-lg/2;
        }
        &.active {
          background: none;
          border-image: linear-gradient(to right, $cool_blue, $cool_dark_blue);
          border-width: 0 0 10px 0;
          border-style: solid;
          border-image-slice: 1;
        }
        &.active,
        &:hover, &:focus {
          h4 {
            color: $cool_dark_blue;
          }
          .ce-icon-circle {
            color: $cool_dark_blue;
            border-color: $cool_dark_blue;
            background-color: $cool_white;
          }
        }
      }

    }
  }
  .tab-content {
    padding-top: $mod_card_content_padding_featured;
  }
}
