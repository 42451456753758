.header-link {
  font-weight: $cool_font-semi-bold-weight;
  font-size: $font-size-h3;
  text-transform: uppercase;
  &:hover:after {
    margin-left: 16px;
    transition: margin-left 175ms ease-out;
  }
  &:after {
    content: '\203a';
    display: inline-block;
    font-size: 40px;
    font-weight: $cool_font-regular-weight;
    line-height: 0;
    vertical-align: top;
    margin-top: 11px;
    margin-left: 6px;
    transition: margin-left 175ms ease-out;
  }
}


.carousel-control {
  text-shadow: none;
  &.carousel-control.right,
  &.carousel-control.left {
    background-image: none;
  }
}

.carousel {
  article.project {
    height: 100%;
    padding: 30px 15px;
  }
}

.universal {
  height:0;
  width:0;
  //display:none;
}

.square {
  position: relative;
  padding-top: 100%;
}

// BG Images
.bg-imgHolder {
  position: absolute;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  background: $cool-purple;
  background-size: cover;
  background-position: 50% 50%;
  transition: all 0.5s ease;
  -webkit-transition: all 0.5s ease;
  -moz-transition: all 0.5s ease;
  opacity: 1;
}