.ce-module.checkout-module .ce-modal {
	.ce-modal_body {
		padding-bottom: $mod_space_md;
	}
	form:not(.ce-radio-button-grid) {
		margin-left: 0;
		margin-right: 0;
	}
	form {
		.fieldset {
			justify-content: center;
		}
		.field {
			display: flex;
			padding-left: $mod_space_xs/2;
			padding-right: $mod_space_xs/2;
			&.frequency {
				padding-bottom: $mod_space_sm;
				width: 190px; // Prevent dynamic width changes
				.ce-collapsible-target {
					.item {
						text-align: left;
					}
				}
			}
			&.quantity {
        flex-direction: column;
				align-items: center;
				flex-basis: 100%;
				justify-content: center;
				padding-bottom: $mod_space_md;
				font-weight: $cool_font-semi-bold-weight;
				.minus,
				.plus {
					font-size: 24px;
					color: $cool_gray;
					cursor: pointer;
				}
				.ce-incrementer_value {
					color: $cool_dark_gray;
					font-weight: $cool_font-semi-bold-weight;
					padding: 6px 10px;
					text-align: center;
					text-transform: capitalize;
				}
        label {
          text-transform: capitalize;
          margin-top: 4px;
        }
			}
			&.gift,
			&.price {
				//flex-basis: 50%;
			}
			&.gift {
				align-items: center;
        flex: 1 0 auto;
			}
			&.price {
				justify-content: flex-end;
				font-size: $mod_font_size_h6_md;
				label {
					align-self: center;
					margin-bottom: 0;
					font-size: $mod_font_size_h6_lg;
				}
        label.currency {
          margin-right: $mod_space_xs;
        }
				input {
					background-color: $cool_very_light_gray;
					color: $cool_purple;
					font-weight: $cool_font-semi-bold-weight;
					border: none;
					width: 100%;
					height: 40px;
					padding: 0 $mod_space_xs;
				}
			}
		}
    &.edit-cart-item {
      .price {
        margin-top: 0;
      }
    }
	}
}
