.ce-module {
  &.partner-impact {
    .tonnes {
      color: $cool_blue;
    }
  }
  .tonnes-per-year {
    text-align: center;
    h3 {
      font-size: 14px;
      font-weight: $cool_font-semi-bold-weight;
      color: $cool_medium_gray;
      text-transform: uppercase;
    }
    .thermometer {
      padding: 0;
      border: $cool_medium_gray solid 2px;
      width: 100%;
      height: 24px;
      border-radius: 12px;
      overflow: hidden;
      background: linear-gradient(to right, $cool_red 0%, $cool_blue 100%);
    }
    .graph {
      position: relative;
      margin-top: -5px;
      margin-bottom: 0;
      width: 100%;
      height: 2rem;
      font-size: 0;

      .ton {
        display: inline-block;
        position: absolute;
        top: 54px;
        height: 30px;
        width: 30px;
        top: 0;
        transform: translate(-50%, 0);
        p {
          font-size: $font-size-root;
          margin-left: 0;
        }
      }

      .arrow-up {
        width: 0;
        height: 0;
        border-left: 5px solid transparent;
        border-right: 5px solid transparent;
        border-bottom: 5px solid $cool_gray;
        margin: 0 auto;
      }

      .ton_12 {
        left: 50%;
      }
      .ton_17 {
        left: 70%;
        p {
          color: $cool_blue;
          font-color: $cool_font-semi-bold-weight;
        }
      }

      .ton_24 {
        left: 100%;
        .arrow-up {
          visibility: hidden;
        }
      }

    }
  }
}