$button_border_size: 2px;

.ce-module,
.ce-modal {
  .ce-button {
    border: none; // Removes preset border from <button>
    cursor: pointer;
    display: inline-block;
    font-weight: $cool_font-semi-bold-weight; // Ensures font is semibold for non-<a> buttons
    text-align: center;
    text-decoration: none;
    text-transform: uppercase;
    vertical-align: middle;
    white-space: nowrap;

    &:disabled {
      cursor: not-allowed;
    }

    * {
      pointer-events: inherit;
      cursor: inherit;
    }
    label {
      margin-bottom: 0;
    }
    .progress-indicator {
      display: none;
      //animation: rotate 1.5s linear infinite;
      &:before {
        display: block;
      }
    }
  }

  /* Block buttons */
  .ce-button.ce-button-block {
    display: block;
    width: 100%;
  }

  /* Icon buttons */
  .ce-button.ce-button-icon {
    /* For buttons with padding, borders */
    &.ce-button-primary,
    &.ce-button-secondary,
    &.ce-button-secondary-alt {
      border-radius: 100%;

      [class*="ce-icon-"] {
        font-size: $font-size-root;
        left: 50%;
        position: absolute;
        transform: translate(-50%, -50%);
        top: 50%;
      }
    }
  }

  /* Primary buttons */
  .ce-button.ce-button-primary,
  .ce-button.ce-button-primary-alt {
    background: $cool_purple;
    color: $cool_white;
    overflow: hidden;
    padding: $mod_space_xs $mod_space_lg;
    position: relative;
    z-index: z-layer("content");

    &:after {
      content: "";
      display: block;
      position: absolute;
      z-index: z-layer("content-lower");

      /* This sets up the gradient animation */
      background-image: linear-gradient(to top, $cool_purple 10%, $cool_blue 50%);
      bottom: 100%;
      height: 200%;
      left: 0;
      right: 0;
      transition: bottom 0.75s ease;
    }

    &:hover {
      color: $cool_white;
    }

    &:hover:after {
      bottom: -100%;
    }

    &:disabled {
      background: $cool_gray !important;

      &:after {
        display: none;
      }
    }

    &.ce-button-icon {
      padding: ($mod_space_lg / 2);

      &:after {
        display: none;
      }

      &:hover, &:focus, &:active {
        background-color: $cool_blue;
      }
    }
  }

  .ce-button.ce-button-primary-alt {
    background: $cool_orange;

    &:after {
      background-image: linear-gradient(to top, $cool_orange 10%, $cool_danger 50%);
    }
  }

  /* Secondary buttons */
  .ce-button.ce-button-secondary,
  .ce-button.ce-button-secondary-alt {
    padding: ($mod_space_xs - $button_border_size) ($mod_space_lg - $button_border_size); // This compensates for primary buttons not having borders (borders can't have the gradient animation)
  }
  .ce-button.ce-button-secondary,
  .ce-button.ce-button-secondary-dark,
  .ce-button.ce-button-secondary-alt {
    background: transparent;
    transition: all 0.3s ease;

    &:hover,
    &:hover * {
      color: $cool_blue;
    }

    &:disabled {
      border-color: $cool_gray !important;
      color: $cool_gray !important;
    }

    &.ce-button-icon {
      padding: (( $mod_space_lg / 2 ) - $button_border_size);
      position: relative;
    }
  }

  .ce-button.ce-button-secondary {
    border: $button_border_size solid $cool_purple;
    color: $cool_purple;

    &:hover {
      border-color: $cool_blue;
    }
  }

  .ce-button.ce-button-secondary-dark {
    background: $cool_white;
    color: $cool_purple;
    padding: $mod_space_xs $mod_space_lg;
  }

  .ce-button.ce-button-secondary-alt {
    border: $button_border_size solid $cool_white;
    color: $cool_white;

    &:hover {
      background: $cool_white;
    }

    &:disabled {
      background: transparent !important;
    }
  }

  /* Tertiary buttons */
  .ce-button.ce-button-tertiary {
    color: $cool_purple;
    background-color: transparent;
    transition: color 0.3s ease;
    font-weight: $cool_font-bold-weight;

    &:hover {
      color: $cool_blue;
    }

    &:disabled {
      color: $cool_gray !important;
    }
  }

  .ce-button.ce-button-tertiary-danger {
    color: $cool_danger;

    &:disabled {
      color: $cool_gray !important;
    }
  }

  .ce-button.ce-button-yellow {
    padding: $mod_space_xs $mod_space_lg;
    background-color: $cool_yellow;
    border-radius: 5px;
    color: $cool_white;

    &:hover {
      background-color: darken($cool_yellow, 10%);
    }
  }

  .ce-button.ce-play-video-button {
    .inner {
      display: flex;
      align-items: center;

      .ce-icon-play-outline {
        margin-right: $mod_space_xs;
      }
    }
  }
  .ce-button.ce-button-secondary-dark.ce-play-video-button {
    * {
      color: $cool_purple;
    }
    &:hover,
    &:hover * {
      color: $cool_blue;
    }
  }
  .ce-button.in-progress {
    display: flex;
    justify-content: center;
    align-items: center;
    .progress-indicator {
      animation: rotate 1.5s linear infinite;
      display: block;
      position: absolute;
    }
    label {
      visibility: hidden;
    }
  }
  .ce-button.in-progress-label {
    display: flex;
    justify-content: center;
    align-items: center;
    .progress-indicator {
      animation: rotate 1.5s linear infinite;
      display: block;
      margin-right: 10px;
      font-size: 15px;
    }
  }
}
