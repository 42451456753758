/*
 * Title: Modal
 * Type: Element
 * Dependencies: (Bootstrap) modal.scss
*/

// Local variables
$modal_padding: $mod_space_lg;
$modal_padding_lg: $mod_child_padding_v;

.ce-modal.ce-modal-image {

  &#modalNewsletter {
    .ce-modal_body {
      input {
        margin-left: auto;
        margin-right: auto;
      }
    }
  }

  &[role="dialog"] > .modal-dialog {
    @include media-breakpoint-only(md) {
      max-width: 680px;
    }
    @include media-breakpoint-up(lg) {
      max-width: 788px;
    }
  }

  .ce-modal_content {
    position: static;
    text-align: center;
    @include media-breakpoint-down(xs) {
      padding-top: 20px;
    }

    @include media-breakpoint-up(md) {
      margin-bottom: -1rem !important;
      position: relative;
    }
  }

  .ce-modal_wrapper {
    @extend .row;
    flex-direction: column;
    margin-left: 0;
    margin-right: 0;
    @include media-breakpoint-up(md) {
      flex-direction: row;
      width: auto;
    }
  }

  .ce-modal_image {
    background-size: cover;
    background-repeat: no-repeat;
    background-position: center;
    padding-top: 50%;

    @include media-breakpoint-up(md) {
      padding-top: 0;
    }
  }

  .ce-modal_close {
    color: $cool_white;

    @include media-breakpoint-up(md) {
      color: #9B9B9B; // Revert to default on larger screens
    }
  }
}



