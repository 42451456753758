$project_preview_card_padding_v_mobile: 20px;

[ce-product] {
  display: none;
}

.project-preview-card {
  @extend .stacked-card;
  &:not(.is-completed-goal) {
    figure {
      .ribbon-new {
        position: absolute;
        top: $mod_space_sm;
        right: 0;
      }
      .ribbon {

        position: absolute;
        top: $mod_space_sm;
        right: 0;
        height: 52px;
        background-color: $cool_yellow;
        color: $cool_purple;
        font-size: 20px;
        font-weight: $cool_font-semi-bold-weight;
        text-transform: uppercase;
        display: flex;
        align-items: center;
        justify-content: center;
        line-height: 1;
        padding: 0 22px;
        &:before,
        &:after {
          content: '';
          display: block;
          position: absolute;
          width: 15px;
          height: 26px;
          left: -15px;

        }
        &:before {
          top: 0;
          background: linear-gradient(to right top,
            transparent 0%,
            transparent 48%,
            $cool_yellow 50%);
        }
        &:after {
          bottom: 0;
          background: linear-gradient(to right bottom,
            transparent 0%,
            transparent 48%,
            $cool_yellow 50%);
        }
      }
    }
  }
  &.is-completed-goal {
    figure {
      @include ce-overlay_dark();
      &:after {
        z-index: z-layer( "content" );
      }
      .ce-card-goal {
        align-content: center;
        display: flex;
        justify-content: center;
        left: 50%;
        max-width: 100%;
        position: absolute;
        top: 50%;
        transform: translate(-50%, -50%);
        z-index: z-layer( "content-upper" );
        width: 100%;

        .ce-icon-award {
          color: $cool_yellow;
          font-size: 47px;
          margin-right: 0.4rem;
          
          @include media-breakpoint-between( md, lg ) {
            font-size: 36px;
          }
        }
        ._label,
        ._label:hover {
          color: $cool_white;
        }
        ._label {
          font-size: $font-size-h2;
          font-weight: $cool_font-semi-bold-weight;
          margin-left: 0.4rem;
          
          @include media-breakpoint-between( md, lg ) {
            font-size: $mod_font_size_h3_md;
          }
        }
      }
    }
  }
  .ce-card-footer {
    .price {
      color: $cool_dark_gray;
      font-weight: $cool_font-semi-bold-weight;
    }
    .cta.price {
      margin-bottom: $project_preview_card_padding_v_mobile;

      @include media-breakpoint-up( md ) {
        margin-bottom: $mod_card_padding_v;
      }
      // Make icon buttons have a right margin
      .ce-button[class*="icon"] {
        margin-right: 0.5rem;
      }
    }
  }
  &.is-catalog-item {
    figure .bg-image {
      padding-top: 45%;
    }
  }
  @include media-breakpoint-up(md) {
    &.is-catalog-item {
      figure .bg-image {
        padding-top: 70%;
      }
    }
  }
  &.featured {
    flex: 1 0 100% !important;
    max-width: 100% !important;
    figure {
      padding-top: 0;
      .bg-image {
        padding-top: 50%;
      }
    }
    .ce-card-content {
      @extend .col-12;
      @extend .col-lg-4;
      @extend .offset-lg-7;
      flex: 1 0 auto;
      padding: $mod_space_sm 0 0 0;
    }

    @include media-breakpoint-up(lg) {
      .ce-card-inner {
        height: 400px;
        justify-content: center;
      }

      figure {
        position: absolute;
        top: 0;
        width: 100%;
        height: 100%;
        padding-top: 0;
        .bg-image {
          height: 100%;
          padding-top: 0;
        }
      }
      .ce-card-content {
        flex: 0 1 auto;
        padding: $mod_card_content_padding;
        .ce-card-body {
          margin-top: $mod_space_xs;
        }
      }
    }
  }
  &.featured.is-completed-goal {
    @include media-breakpoint-up(lg) {
      .ce-card-goal {
        width: percentage(7/12);
        left: 0;
        transform: translate(0, -50%);
      }
      .ce-card-content {
        z-index: 2;
      }
      .bg-image {
        position: absolute;
      }
    }
  }
}
