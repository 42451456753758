$gallery-gutter-width: 10px;

.ce-module {
  // controls module
  &.ce-gallery-controls {
    padding-bottom: 0;
    .controls-header {
      display: flex;
      justify-content: space-between;
      align-items: center;
      .count-label {
        font-weight: $cool_font-semi-bold-weight;
      }
      h2.ce-collapsible-trigger {
        flex: 1 0 auto;
        color: $cool_purple;
        text-transform: uppercase;
        font-size: $font-size-base;
        text-align: right;
        margin-bottom: 0;
        .indicator {
          font-size: 12px;
          margin-left: 4px;
        }
      }
      .sort-label {
        flex: 0 1 auto;
        margin-right: 10px;
      }
      .sort-form {
        flex: 0 1 auto;
        .ce-select {
          width: 120px;
          .selected {
            padding-left: 20px;
            .indicator {
              margin-left: 20px;
            }
          }
          .item {
            padding-left: 20px;
          }
        }
      }
      .sort-label,
      .sort-form {
        display: none;
      }
    }
    z-index: z-layer( "content-upper" );
    .shelf {
      width: 100%;
      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    #filters-shelf {
      position: relative;
      overflow: hidden;
      z-index: z-layer( "content-upper" );
      width: 100%;
      padding-top: 25px;
      padding-bottom: 25px;
      .filters-actions {
        display: flex;
        justify-content: space-between;
        align-items: center;
        margin-bottom: $mod_padding_v_mobile;
        .filter-action {
          text-transform: uppercase;
        }
      }
      @include media-breakpoint-down(sm) {
        > .container {
          padding-left: $page_padding;
          padding-right: $page_padding;
        }
      }

      @include media-breakpoint-up(lg) {
        position: absolute;
        top: 0;
        left: 0;
      }
    }
    .filter-item {
      input {
        display: none;
      }
      label {
        @extend .ce-button;
        @extend .ce-button-secondary;
        text-transform: none;
        display: flex;
        text-align: left;
        white-space: normal;
        font-size: $font-size-base;
      }
    }
    @include media-breakpoint-down(md) {
      legend {
        border-bottom: 2px solid $cool_dark_gray;
        text-transform: uppercase;
        font-size: $font-size-base;
        font-weight: $cool-font-semi-bold-weight;
        color: $cool_dark_gray;
        padding-bottom: 0.75rem;
        margin-bottom: 1.5rem;
      }
      .fieldset + .fieldset {
        margin-top: $mod_card_padding_v;
      }
      .filter-item {
        width: 100%;
        
        label {
          padding: 0;
          background-color: transparent;
          border: none;
          margin-bottom: 0;
          color: $cool_medium_gray;
        }

        input:checked + label,
        label:hover,
        label:active,
        label:focus {
          background-color: transparent;
          color: $cool_medium_gray;
        }

        input[type="radio"].ce-radio:checked + label {
          &:before {
            //margin-top: 2px;
          }
          &:after {
            //top: 7px;
          }
        }
      }
      .filter-item + .filter-item {
        margin-top: 1.25rem;
      }
    }
    @include media-breakpoint-up(lg) {
      padding-top: $mod_padding_v;
      padding-bottom: $mod_padding_v_mobile;
      .controls-header {
        .count-label {
          display: none;
        }
        h2.ce-collapsible-trigger {
          color: $cool_dark_gray;
          text-align: left;
          font-size: $mod_font_size_h2;
          text-transform: none;
          .indicator {
            font-size: 20px;
            margin-left: 18px;
          }
        }
        .sort-label,
        .sort-form {
          display: block;
        }
        .sort-form {
          z-index: z-layer( "content-upper" ) + 1;
        }
      }
      .filter {
        .fieldset {
          legend {
            display: none;
          }
          display: flex;
          flex-wrap: wrap;
        }
        .fieldset-sort {
          display: none;
        }
      }
      .filter-item {
        margin-right: $grid-gutter-width-sm;
        margin-bottom: $grid-gutter-width-sm;
        label {
          //text-align: center;
          //white-space: nowrap;
          &:before {
            display: none;
          }
        }
        label,
        input[type="radio"].ce-radio:checked + label,
        input[type="radio"].ce-radio + label {
          &:before,
          &:after {
            display: none;
          }
        }
        input:checked + label {
          color: $cool_white;
          background-color: $cool_purple;
          border-color: $cool_purple;
        }
      }
      .shelf {
        position: relative;
      }
      #filters-shelf {
        .filters-actions {
          display: none;
        }
      }
    }
  }
  // end controls module
  //
  // content module
  &.ce-gallery-content {
    padding-top: 0;
    padding-bottom: $mod_padding_v_mobile;
    //.gallery-row {
    //  margin-bottom: - $mod_card_padding_v;
    //}
    // grid
    .ce-gallery-grid {
      //padding-left: $grid-gutter-width-sm;
      //padding-right: $grid-gutter-width-sm;
      .ce-card.preview-card {
        padding-left: $grid-gutter-width-sm/2;
        padding-right: $grid-gutter-width-sm/2;
        padding-bottom: $grid-gutter-width-sm;
        figure {
          //width: calc(100% - 10px);
          //height: calc(100% - 10px);
          //width: 100%;
          //height: 100%;
        }
        .ce-card-inner {
          @include media-breakpoint-up(lg) {
            min-height: 290px !important;
          }
        }
      }
    }
    #items.ce-gallery-grid {
      .ce-card.preview-card:first-of-type {
        .ce-card-content {
          border-top: none;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      padding-bottom: $mod_padding_v;
      //.gallery-row {
      //  margin-bottom: - $grid-gutter-width-sm;
      //}
      .ce-gallery-grid {
        .ce-card.preview-card {
          //padding-bottom: $grid-gutter-width-sm;
          .ce-card-content {
            padding: 20px;
          }
        }
      }
    }
  }
  // end content module
}

.ce-gallery-light .shelf .ce-collapsible-target {
  background-color: $cool_very_light_gray;
  &:after {
    content: '';
    display: block;
    position: absolute;
    width: 100%;
    height: 4px;
    bottom: 0;
    left: 0;
    background: linear-gradient(to right, $cool-blue 0%, $cool_red 100%);
  }
}

.project-updates-gallery {
  position: relative;
}