$print-page-size: auto;

@font-face {
    font-family: "proxima-soft";
    src:
            url("/app/fonts/proxima-soft/proxima-soft-regular.woff2") format("woff2"),
            url("/app/fonts/proxima-soft/proxima-soft-regular.woff") format("woff");
    font-weight: 400;
    font-style: normal;
}

@font-face {
    font-family: "proxima-soft";
    src:
            url("/app/fonts/proxima-soft/proxima-soft-regular-italic.woff2") format("woff2"),
            url("/app/fonts/proxima-soft/proxima-soft-regular-italic.woff") format("woff");
    font-weight: 400;
    font-style: italic;
}

@font-face {
    font-family: "proxima-soft";
    src:
            url("/app/fonts/proxima-soft/proxima-soft-semibold.woff2") format("woff2"),
            url("/app/fonts/proxima-soft/proxima-soft-semibold.woff") format("woff");
    font-weight: 600;
    font-style: normal;
}

@font-face {
    font-family: "proxima-soft";
    src:
            url("/app/fonts/proxima-soft/proxima-soft-semibold-italic.woff2") format("woff2"),
            url("/app/fonts/proxima-soft/proxima-soft-semibold-italic.woff") format("woff");
    font-weight: 600;
    font-style: italic;
}

@font-face {
    font-family: "proxima-soft";
    src:
            url("/app/fonts/proxima-soft/proxima-soft-bold.woff2") format("woff2"),
            url("/app/fonts/proxima-soft/proxima-soft-bold.woff") format("woff");
    font-weight: 700;
    font-style: normal;
}

@font-face {
    font-family: "proxima-soft";
    src:
            url("/app/fonts/proxima-soft/proxima-soft-bold-italic.woff2") format("woff2"),
            url("/app/fonts/proxima-soft/proxima-soft-bold-italic.woff") format("woff");
    font-weight: 700;
    font-style: italic;
}

// Cool Effect base values

$cool-blue:                 #005BD7;
$cool-dark-blue:            #003B8D;
$cool-deep-sky-blue:        #00BFFF;
$cool_red:                  #EB0029;
$cool_blue:                 #005BD7;
$cool_danger:               #D9534F;
$cool_cream:                #FFF6E7;
$cool_dark_blue:            #003B8D;
$cool_dark_teal:            #067d8c;
$cool_dark_purple:          #230B4F;
$cool_dark_teal:            #067D8C;
$cool_dark_yellow:          #E59916;
$cool_light_purple:         #815BC5;
$cool_peach:                #fff6e7;
$cool_purple:               #432973;
$cool_orange:               #F2694A;

// gray
$cool_dark_gray:            #303030;
$cool_medium_gray:          #606060;
$cool_gray:                 #C3C3C3;
$cool_light_gray:           #EBECED;
$cool_very_light_gray:      #F7F7F7;

$cool_teal:                 #0099A9;
$cool_transparent_white:    rgba(white, .2);
$cool_white:                #fff;
$cool_yellow:               #FFAA19;
$cool_light_yellow:         #F7E9D1;
$cool_mid_purple:           #8C0064;
$cool_hover_gray:           #909090;

$cool_success:              #449d44;

// Snowmen Only
$cool_sos_blue:             rgb(72,135,181);

// Bootstrap
$yellow:                    $cool_yellow !default;
$primary:                   $cool_purple !default;
$secondary:                 $cool_blue !default;
$warning:                   $cool_yellow !default;
$danger:                    $cool_danger !default;

$cool_font-regular-weight:  400;
$cool_font-semi-bold-weight:600;
$cool_font-bold-weight:     700;

/**********************************
 * Bootstrap overrides – Variables *
 **********************************/
$alert-border-radius:       0 !default;
$alert-bg-level:            -10 !default;
$alert-border-level:        -10 !default;
$alert-color-level:         10 !default;

// Breakpoints: add xxl
$grid-breakpoints: (
        xs: 0,
        sm: 576px,
        md: 768px,
        lg: 992px,
        xl: 1200px,
        xxl: 1440px
) !default;

//$page-width-cap: 1280px;
$input-btn-border-width :   2px;
// Buttons
$btn-border-radius :        0;
$btn-border-radius-sm :     $btn-border-radius;
$btn-border-radius-md :     $btn-border-radius;
$btn-border-radius-lg :     $btn-border-radius;
$btn-font-weight :          $cool_font-bold-weight;
// primary
$btn-primary-color :        $cool_white;
$btn-primary-bg :           $cool_purple;
$btn-primary-border :       $cool_purple;
// secondary
$btn-secondary-color :      $cool_yellow;
$btn-secondary-bg :         $cool_white;
$btn-secondary-border :     $cool_white;;
// info
$btn-info-color:            $cool_white;
$btn-info-bg:               $cool_purple;
$btn-info-border:           $cool_purple;
// danger
$btn-danger-color :         $cool_white;
$btn-danger-bg :            $cool_danger;
$btn-danger-border :        $cool_danger;
// link
.btn-link
{
    color:                  $cool_yellow;
    font-weight:            $cool_font-bold-weight;

    &:hover
    {
        background-color:   $cool_yellow;
        color:              $cool_white;
        text-decoration:    none;
    }
}

$btn-padding-x :            10px !default;
$btn-padding-y :            10px !default;
$btn-padding-y-lg :         $btn-padding-y;
$btn-padding-y-sm :         $btn-padding-y;

// Type
$font-family-sans-serif:    "proxima-soft", Helvetica, Arial, sans-serif !default;
$font-family-base:          $font-family-sans-serif !default;

// Pixel value used to responsively scale all typography. Applied to the `<html>` element.
$font-size-root-unitless:   16;
$font-size-root:            $font-size-root-unitless + px    !default;
$font-size-base:            1rem    !default;
$font-size-lg:              1.25rem !default;
$font-size-sm:              0.875rem !default;
$font-size-xs:              0.75rem !default;

$color-base:                $cool_medium_gray;

// Headings
$headings-color:            $cool_dark_gray;
$headings-font-weight:      $cool_font-semi-bold-weight;
$font-size-h1:              36px !default;
$font-size-h2:              28px !default;
$font-size-h3:              18px !default;
$font-size-h4:              16px !default;
$font-size-h5:              16px !default;
$font-size-h6:              16px !default;

.text-alert {
    color: $cool_danger;
}
.text-danger {
    color: $cool_danger;
    font-weight: $cool_font-semi-bold-weight;
    a {
        text-decoration: underline;
    }
}
