.ce-blurb-carousel {
  // Gallery display
  .carousel-inner {
    display: flex;
  }
  .carousel-item {
    @include media-breakpoint-up( md ) {
      display: block;
    }
  }
  .ce-mobile-carousel-controls {
    @include media-breakpoint-up( md ) {
      display: none;
    }
  }
  .carousel-indicators li {
    border-radius: 9999px;
  }
}

.ce-blurb-carousel.ce-blurb-carousel-stacked {
  .carousel-inner {
    @include media-breakpoint-up( md ) {
      flex-wrap: wrap;
    }
  }
  .carousel-item {
    @include media-breakpoint-up( md ) {
      margin-bottom: ( $mod_space_lg / 2 );
      margin-top: ( $mod_space_lg / 2 );
    }
  }
}

.ce-blurb-carousel:not(.ce-blurb-carousel-stacked) {
  @extend .col-12;
  // Base
  .carousel-item {
    @extend .col-12;
    @extend .col-md-4;
    .blurb-carousel-item__image {
      margin: 0 auto;
      padding: 1em;
      height: 200px;
      width: auto;
      display: block;
      @include media-breakpoint-down(xs) {
        height: auto;
        max-height: 170px;
      }
    }
    .blurb-carousel-item__content {
      > *:last-child {
        margin-bottom: 0;
      }
    }
  }
  
  .carousel-item {
    flex: 1 0 auto;
  }

  // Outline display
  @include media-breakpoint-down(sm) {
    .carousel-inner {
      background-color: $cool_white;
      min-height: 400px;
      padding: 2em;
      border: 5px solid #{$ce-blurb-carousel-color-base};
      margin-bottom: 2em;
      overflow: hidden;
    }
    .carousel-item:not(.active) {
      display: none;
    }
  }
}