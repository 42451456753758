.ce-module.ce-footer-wrapper {
	@media print {
		display: none;
	}
	.ce-footer-main {
		@include media-breakpoint-down(md) {
			text-align: center;
		}

		background-color: $cool_dark_gray;
		//min-height: 345px;
		color: white;
		width: 100%;
		a {
			border-bottom: 1px solid transparent;
			color: $cool_white;
			text-decoration: none;
			transition: border 0.15s ease;

			&:hover,
			&:active,
			&:focus {
				border-color: $cool_white;
				color: $cool_white;
			}
		}

		label,
		.newsletter-heading {
			@include type-footer-subhead;
			@include media-breakpoint-down(md) {
				margin-bottom: 16px;
			}

			font-weight: bold;
			margin-bottom: 26px;
		}

		.wpcf7-form label {
			font-weight: normal;
			margin-bottom: 0;
			text-transform: capitalize;
		}

		ul {
			list-style: none;
			margin: 0;
			padding: 0;

			li + li {
				margin-top: 10px;
			}
		}

		//a {
		//  color: inherit;
		//
		//  cursor: pointer;
		//}

		.footer-row {
			@include media-breakpoint-down(md) {
				padding-bottom: 30px;
				padding-top: 45px;
			}

			display: flex;
			flex-wrap: wrap;
			padding: 55px 0 50px 0;
			> .col {
				@include media-breakpoint-down(sm) {
					width: 100%;
				}

				align-items: flex-end;
				display: flex;
				&.secondary {
					@include media-breakpoint-down(md) {
						padding-top: 43px;
					}

					padding-top: 35px;
					.social-row {
						@include media-breakpoint-down(md) {
							margin-left: 0;
						}

						width: 100%;
					}
				}
			}
			.menu-row {
				display: flex;
				flex-wrap: wrap;
				margin-bottom: -40px;
				.menu-col {
					padding-bottom: 40px;
				}
			}
		}

		input:-webkit-autofill {
			// Chrome auto fill hack
			-webkit-box-shadow: 0 0 0 1000px #595959 inset;
			-webkit-text-fill-color: $cool_white;
		}

		.newsletter-heading {
			margin-bottom: 1rem;
		}

		form {
			input[type='email'],
			input[type='text'] {
				background-color: transparent;
				border: 1px solid $cool_medium_gray !important;
				color: $cool_white !important;
				&:not(.has-danger) {
					margin-bottom: 6px;
				}
				&:-ms-input-placeholder {
					color: $cool_medium_gray !important;
				}
				&::-ms-input-placeholder {
					color: $cool_medium_gray !important;
				}
				&:-moz-placeholder {
					color: $cool_medium_gray !important;
				}
				&::-moz-placeholder {
					color: $cool_medium_gray !important;
				}
				&::-webkit-input-placeholder {
					color: $cool_medium_gray !important;
				}
			}
			.thankyou {
				font-size: 18px;
				line-height: 1.4rem;
				text-transform: none !important;
			}
			.cm-error {
				color: $cool_danger;
				display: block;
				font-size: 13px;
				font-weight: $cool_font-semi-bold-weight;
				line-height: 1.2;
				margin-top: 1rem;
				text-transform: none !important;
			}
		}

		.row {
			@include clearfix;
		}

		.legal {
			@include type-footer-legal;

			margin-bottom: 10px;
			text-transform: none;
			width: 100%;
		}
		.social-media-links {
			@include media-breakpoint-up(lg) {
				justify-content: space-between;
				margin-top: 28px;
			}
			@include media-breakpoint-down(md) {
				margin-bottom: 20px;
			}

			align-items: flex-end;
			display: flex;
			justify-content: center;
			margin-left: 0;
			margin-right: 0;
			[class*=' ce-icon-'] {
				color: $cool_white;
				font-size: 21px;
			}
			.ce-icon-facebook {
				font-size: 25px;
			}
			> a + a {
				@include media-breakpoint-up(lg) {
					margin-left: 0;
				}

				margin-left: 30px;
			}
		}
		.ce-footer-amazon-smile {
			img {
				max-width: 175px;
			}
		}
		ul.footer-simple-menu {
			@include media-breakpoint-up(lg) {
				flex-direction: row;
				justify-content: flex-start;
				margin-bottom: 0.75rem;
				li + li {
					margin-top: 0;
					&:before {
						content: '|';
						display: block;
						left: 0;
						position: absolute;
					}
				}
			}

			align-items: center;
			display: flex;
			flex: 1 0 auto;
			flex-direction: column;
			flex-wrap: wrap;
			justify-content: center;
			margin-bottom: 1.25rem;
			margin-left: -1rem;
			margin-right: -1rem;
			li {
				padding-left: 1rem;
				padding-right: 1rem;
				position: relative;
			}
		}
	}
}


//-----------------------------------------
// Modal
//-----------------------------------------
.ce-modal_body {
	.wpcf7-submit {
		background: #432973 !important;
		color: #FFFFFF !important;
		overflow: hidden;
		padding: 10px 30px;
		position: relative;
		z-index: 1;
	}
}
