.ce-module.stillin {
  form {
    input[type="checkbox"].ce-checkbox:checked + label {
      &:before {
        border-color: $stillin_color_dark_blue;
        background-color: $stillin_color_dark_blue;
      }
    }
    input[type="radio"].ce-radio:checked + label {
      &:before {
        border-color: $stillin_color_dark_blue;
      }
      &:after {
        background-color: $stillin_color_dark_blue;
      }
    }
    .radio-tabs {
      input[type="radio"]:checked + label {
        [class*="ce-icon-"] {
          color: $stillin_color_dark_blue;
        }
        border-color: $stillin_color_dark_blue;
      }
      input[type="radio"] + label:hover {
        border-color: $stillin_color_dark_blue;
      }
    }
  }
}