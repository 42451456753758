.ce-checkbox_wrapper {
    align-items: center;
    display: flex;
    justify-content: center;
}

.ce-checkbox_input {
    $checkbox-size: 14px;
    
    cursor: pointer;
    height: $checkbox-size;
    opacity: 0;
    position: relative;
    width: $checkbox-size;
    z-index: z-layer( "content-upper" );
    
    &+.ce-checkbox_label {
        margin-bottom: 0;
        margin-left: 0.5em;
        position: relative;
    }
    &+.ce-checkbox_label:before {
        border: 2px solid $cool_gray;
        content: "";
        display: block;
        height: $checkbox-size;
        left: -26px;
        position: absolute;
        top: 6px;
        width: $checkbox-size;
    }
    // Box checked
    &:checked+.ce-checkbox_label:after {
        color: $cool_gray;
        content: '\2714';
        display: block;
        left: -23px;
        font-size: 11px;
        position: absolute;
        top: 5px;
    }
}
