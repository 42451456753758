@mixin centerer($horizontal, $vertical) {
  position: absolute;
  @if ($horizontal and $vertical) {
    top: $vertical;
    left: $horizontal;
    -webkit-transform: translate(-$horizontal, -$vertical);
    transform: translate(-$horizontal, -$vertical);
  } @else if ($horizontal) {
    left: $horizontal;
    -webkit-transform: translate(-$horizontal, 0);
    transform: translate(-$horizontal, 0);
  } @else if ($vertical) {
    top: $vertical;
    -webkit-transform: translate(0, -$vertical);
    transform: translate(0, -$vertical);
  }
}