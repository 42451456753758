.row-no-padding {
  @extend .row;
  margin: 0;
}

.text-row {
  @extend .text-left;
  @extend .text-md-center;
  padding-top:              60px;
  padding-bottom:           60px;

  * :last-child {
    margin-bottom:          0;
  }

  @include media-breakpoint-down(sm) {
    padding-top:             35px;
    padding-bottom:          25px;
  }
}

// Halves Layout
.halves {
  position: relative;
  padding: 0;
  overflow: hidden;
  @include media-breakpoint-up(lg) {
    height: 70vw;
    max-height: 570px;
  }
}

.image-half {
  position: relative;
  height: 70vw;
  max-height: 570px;
  padding: 0;
  overflow: hidden;
}

.text-half {
  @include media-breakpoint-up(lg) {
    top: 50%;
    transform: translate3d(0, -50%, 0);
    -webkit-transform: translate3d(0, -50%, 0);
    -moz-transform: translate3d(0, -50%, 0);
  }
}
