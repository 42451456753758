/*
	* Title: Overlay Content
	* Type: Template
	* Dependencies: 
*/

@mixin ce-content-overlay( $overlay_class ) {
	position: relative;
	
	#{ unquote($overlay_class) } {
		left: 50%;
		position: absolute;
		top: 50%;
		transform: translate(-50%, -50%);

		@content;
	}
}
