$mod_space_xs:                  10px;
$mod_space_sm:                  20px;
$mod_space_md:                  20px;
$mod_space_lg:                  30px;
$mod_space_xl:                  40px;
$mod_space_xxl:                 60px;

$page_padding:                  $mod_space_md;

$mod_padding_v:                 $mod_space_xxl;
$mod_padding_v_short:           $mod_padding_v/2;
$mod_padding_v_mobile:          $mod_padding_v/2;
$mod_padding_v_mobile_short:    $mod_padding_v/2;

$mod_child_padding_v:           $mod_space_xl;
$mod_child_padding_v_mobile:    $mod_space_lg;

$mod_card_padding_v:            $mod_space_lg;
$mod_card_padding_v_mobile:     $mod_space_lg;
$mod_card_padding_v_sm:         $mod_space_md;

$mod_card_content_padding:      $mod_space_lg;
$mod_card_content_padding_lg:   $mod_space_xl;
$mod_card_content_padding_featured: 50px;

$grid-gutter-width-sm:          10px;
$grid-gutter-width-md:          20px;
$grid-gutter-width-lg:          30px;
$grid-gutter-width-xl:          40px;
$grid-gutter-width-xxl:         50px;
$grid-gutter-width-xxxl:        60px;

$mod_font_size_h1_lg:           40px;
$mod_font_size_h2_lg:           32px;
$mod_font_size_h3_lg:           28px;
$mod_font_size_h4_lg:           20px;
$mod_font_size_h5_lg:           20px;
$mod_font_size_h6_lg:           20px;
$mod_font_size_small_lg:        13px;

$mod_font_size_h1_md:           30px;
$mod_font_size_h2_md:           26px;
$mod_font_size_h3_md:           22px;
$mod_font_size_h4_md:           18px;
$mod_font_size_h5_md:           18px;
$mod_font_size_h6_md:           18px;
$mod_font_size_small_md:        13px;

$mod_font_size_h1:              34px;
$mod_font_size_h2:              27px;
$mod_font_size_h3:              24px;
$mod_font_size_h4:              18px;
$mod_font_size_h5:              18px;
$mod_font_size_h6:              18px;
$mod_font_size_small:           13px;
