@font-face {
  font-family: 'bongiorno';
  src: url('/app/fonts/bongiorno/bongiorno.eot');
  src: url('/app/fonts/bongiorno/bongiorno.eot?#iefix') format('embedded-opentype'),
       url('/app/fonts/bongiorno/bongiorno.woff2') format('woff2'),
       url('/app/fonts/bongiorno/bongiorno.woff') format('woff'),
       url('/app/fonts/bongiorno/bongiorno.ttf') format('truetype'),
       url('/app/fonts/bongiorno/bongiorno.svg#youworkforthem') format('svg');
}

.bongiorno-n5 {
  font-family: 'bongiorno', sans-serif;
  font-style: normal;
  font-weight: 500;
}