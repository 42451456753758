.ce-module.stillin {
  .cart-summary {
    .item {
      font-family: $stillin_font_family_display;
      .price {
        font-weight: $stillin_font_weight_display_medium;
      }
      .details {
        font-family: $stillin_font_family_base;
        font-weight: $stillin_font_weight_base_regular;
      }
    }
  }
  .sub-total {
    .item {
      line-height: 1.3;
    }
  }
  .total {
    .item {
      color: $stillin_color_dark_blue;
    }
  }
}