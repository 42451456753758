.carousel-item {
  >.ce-carousel-gradient {
    &::after {
      content:" ";
      display: block;
      top: 0;
      right: 0;
      bottom: 0;
      left: 0;
      height: 100%;
      position: absolute;
      width: 100%;
    }
  }
}