.ce-textwrap {
  .ce-textwrap_image {
    display: block;
    float: left;
    padding-right: $grid-gutter-width/2;
  }
}

.ce-dropcap-img {
  display: block;
  float: left;
  padding-right: $grid-gutter-width/2;
  height: 90px;
  width: auto;
  margin-bottom: 0 !important;
  &.sm {
    height: 60px;
  }
}
