.ce-module {
  .ce-card.checkout-card {
    &.checkout-card-empty:not(.is-animating) {
      display: none;
    }
  }
  .ce-card.checkout-card.cart-card {
    .card-section-empty:not(.is-animating) {
      display: none;
    }
    .ce-card-content {
      padding-bottom: 10px;
    }
    ol.cart-items {
      padding-left: 0;
      counter-reset: item;
      list-style-type: none;
      li:before {
        content: counter(item) ".  ";
        counter-increment: item;
      }
    }
    .cart-items {
      padding-left: 0;
      margin-top: -$mod_space_lg;
      //margin-bottom: (-1 * $mod_space_lg);
      @include media-breakpoint-up(lg) {
        margin-top: -$mod_space_xl;
        //margin-bottom: (-1 * $mod_space_xl);
      }
      > .item + .item {
        border-top: 1px solid $cool_light_gray;
      }
      > .item.invalid {
        .indicator {
          color: $cool_danger;
        }
        .invalid-msg.text-danger {
          margin-top: 0;
          display: flex;
          .invalid-msg-label {
            font-weight: $cool_font-semi-bold-weight;
          }
          .indicator {
            margin-top: 3px;
            margin-left: 0;
            margin-right: $mod_space_xs;
            [class^="ce-icon-"],
            [class*="ce-icon-"] {
              font-size: 23px;
            }
          }
          @include media-breakpoint-up(lg) {
            justify-content: space-between;
            .invalid-msg-label {
              order: 1;
            }
            .indicator {
              order: 2;
              margin-top: -12px;
              margin-left: $mod_space_sm;
              margin-right: 0;
              [class^="ce-icon-"],
              [class*="ce-icon-"] {
                font-size: 30px;
              }
            }
          }
        }
      }
      > .item {
        position: relative;
        .cntr {
          &.ng-enter,
          &.ng-leave {
            transition:all 300ms;
          }

          &.ng-leave.ng-leave-active {
          }
        }
        .gift-msg {
          width: 100%;
          height: 100%;
          background-color: $cool_white;
          position: absolute;
          top: 0;
          left: 0;
          display: flex;
          align-items: center;
          color: $cool_gray;
          &.ng-enter,
          &.ng-leave {
            transition:opacity 300ms;
          }

          &.ng-enter,
          &.ng-enter-prepare,
          &.ng-leave.ng-leave-active {
            opacity:0;
          }

          &.ng-leave,
          &.ng-leave-prepare,
          &.ng-enter.ng-enter-active {
            opacity:1;
          }
        }
        .content {
          padding-top: $mod_space_lg;
          padding-bottom: $mod_space_lg;
          @include media-breakpoint-up(lg) {
            padding-top: $mod_space_xl;
            padding-bottom: $mod_space_xl;
          }
        }
        .product-type {
          font-size: 14px;
          text-transform: uppercase;
          font-weight: $cool_font-regular-weight;
          margin-bottom: .4rem;
        }
        h4 {
          color: $cool_dark_gray;
          margin-bottom: 20px;
          li {
            display: inline;
          }
        }
        .fieldset {
          display: flex;
          justify-content: space-between;
          align-items: flex-start;
          width: auto;
          margin-left: -$grid-gutter-width/2;
          margin-right: -$grid-gutter-width/2;
          margin-bottom: -$mod_space_xs;
          .field {
            padding-left: $grid-gutter-width/2;
            padding-right: $grid-gutter-width/2;
            padding-bottom: $mod_space_xs;
            display: flex;
            align-items: center;
            label {
              margin-bottom: 0;
            }
          }
          .field.frequency {
            width: 215px;
          }
          .field.quantity,
          .field.price {
            flex: 1 0 auto;
            width: 50%;
          }
          .field.price.portfolio {
            width: 100%;
          }
          .field.price {
            justify-content: flex-end;
            &.price-project {
              padding-top: 5px;
            }
            label {
              margin-bottom: 0;
              margin-right: 10px;
              font-size: $mod_font_size_h5_lg;
              display: flex;
              align-items: center;
              height: 40px;
            }
          }
          .field.quantity {
            display: flex;
            flex-direction: column;
            align-items: flex-start;
            .incrementer-wrapper {
              label {
                width: 100%;
                text-align: center;
              }
            }
            .minus,
            .plus {
              font-size: 24px;
              color: $cool_gray;
              cursor: pointer;
              &:hover,
              &:active {
                color: $cool_dark_gray;
              }
            }

            .ce-incrementer_value {
              padding: 6px 10px;
              text-transform: capitalize;
              color: $cool_dark_gray;
              font-weight: $cool_font-semi-bold-weight;
              text-align: center;
              min-width: 80px;
              max-width: 80px;
              box-sizing: content-box;
            }
            @include media-breakpoint-down(md) {
              span {
                color: $cool_dark_gray;
                text-transform: capitalize;
              }
            }
            label {
              margin-top: 4px;
              text-transform: capitalize;
              line-height: 1;
            }
            .exceeds-inventory {
              font-size: 13px;
              font-weight: $cool_font-semi-bold-weight;
              color: $cool_red;
              margin-top: 4px;
              margin-bottom: 0;
              line-height: 1.2;
              text-align: center;
              [class^="ce-icon-"],
              [class*="ce-icon-"] {
                font-size: 14px;
                vertical-align: middle;
              }
            }
          }
          .field.delete {
            padding-top: 10px;
            a {
              display: flex;
              color: $cool_gray;
              &:hover,
              &:active {
                color: $cool_danger;
              }
              .ce-icon-trash {
                font-size: 19px;
              }
            }
          }
          .field.edit {
            justify-content: flex-end;
            flex: 1 0 auto;
            @include media-breakpoint-up( lg ) {
              margin-top: 0;
            }
          }
        }
        @include media-breakpoint-up( lg ) {
          .fieldset {
            padding-bottom: 0;
            .field.quantity,
            .field.price {
              width: auto;
            }
            .field.quantity {
              align-items: flex-start;
            }
            .field.gift {
              padding-top: 10px;
            }
          }
        }
      }
      > .item:last-of-type {
        padding-bottom: 0;
      }
    }
  }
}
