.ce-module.prev-next-module {
  .module-header {
    .header-title {
      font-size: $mod_font_size_h4;
    }
    .header-title:before {
      display: none;
    }
  }

  .ce-grid {
    display: flex;
    flex-wrap: wrap;
    align-items: stretch;
    margin-bottom: -$mod_card_padding_v_sm !important;
    > * {
      padding-bottom: $mod_card_padding_v_sm;
    }
  }

  .ce-collapsible-target {
    &#sort-items {
      padding-bottom: 1rem;
      .item {
        margin-top: 0.5rem;
        .sort-item-label {
          font-size: $font-size-base;
        }
      }
    }
  }

  @include media-breakpoint-up(lg) {
    .module-header {
      .header-title {
        font-size: $mod_font_size_h4_lg;
      }
    }
    .ce-grid {
      margin-bottom: -$mod_card_padding_v !important;
      > * {
        padding-bottom: $mod_card_padding_v;
      }
    }
  }
}