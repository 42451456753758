/*
 * Title: Flavor Card
 * Type: Element
 * Dependencies: card.scss, overlay.scss, card-overlay.scss, card-preview.scss
*/

.ce-module {
    .ce-card.preview-card.team-member-card {
        // Set amount of padding used to create bottom card gutter

        @include ce-card_overlay();
        height: (232px + $grid-gutter-width) !important;
        @include media-breakpoint-only( sm ) {
            height: ( 350px + $grid-gutter-width ) !important;
        }
        @include media-breakpoint-only( md ) {
            height: ( 320px + $grid-gutter-width ) !important;
        }
        @include media-breakpoint-up( lg ) {
            height: ( 300px + $grid-gutter-width ) !important;
        }
        
        figure {
            .bg-image {
                position: absolute;
                top: 0;
                
                &::after {
                    background: rgba( 0, 0, 0, 0.15 );
                    content: "";
                    display: block;
                    height: 100%;
                    transition: background 0.3s ease;
                }
            }
        }

        .ce-card-content {
            border: none;
        }

        .ce-card-header {
            .card-title {
                color: $cool_white;
            }
        }

        .ce-card-footer {
            display: none;
        }

        // Adds hover effect for smaller screens
        .ce-card-header {
            margin-bottom: 0;
            margin-top: 0;
            transition: all 0.3s ease;
        }
        
        .ce-card-body {
            margin-bottom: -1rem;
            margin-top: initial;
            max-height: 1rem;
            overflow: hidden;
            opacity: 0;
            transition: max-height 0.3s cubic-bezier(0, 1, 0, 1), opacity 0.5s cubic-bezier(0, 1, 0, 1);
        }
        
        .ce-card-inner:hover {
            figure {
                display: block;
                
                .bg-image {
                    &::after {
                        background: rgba( 0, 0, 0, 0.35 );
                        height: 100%;
                        top: 0;
                    }
                }
            }

            .ce-card-header {
                margin-bottom: $mod_space_xs;
                transition: all 0.3s ease;
            }

            .ce-card-body {
                max-height: 100px;
                opacity: 1;
                transition: max-height 0.3s ease-in-out, opacity 0.5s ease-in-out;
            }
        }
    }
}