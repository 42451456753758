.ce-curtain {
    position: absolute;
    top: 0;
    right: 0;
    left: 0;
    bottom: 0;
    width: 100%;
    z-index: 102;
    height: calc(55vw + 82px);
    display: flex;
    flex-direction: column;

    h2, h3 {
        font-size: $mod_font_size_h4;
    }

    .curtain-scroll-msg {
        width: 100%;
        text-align: center;
        position: absolute;
        bottom: $mod_space_md;
        display: none;
        h4 {
            color: $cool_white;
        }
        .ce-icon-chevron-down {
            font-size: 15px;
        }
    }

    @include media-breakpoint-up(sm) {
        h2, h3 {
            font-size: $mod_font_size_h2;
        }
    }

    @include media-breakpoint-up(md) {
        height: calc(55vw + 82px);
        h2, h3 {
            font-size: $mod_font_size_h1_md;
        }
    }
    @include media-breakpoint-up(lg) {
        height: calc(55vw + 160px);
        max-height: calc(70vh + 160px);
        h2, h3 {
            font-size: $mod_font_size_h1_lg;
        }
        .curtain-scroll-msg {
            display: block;
            bottom: $mod_space_lg;
        }
    }
    @include media-breakpoint-up(xxl) {
        max-height: calc(80vh + 160px);
    }
}
.ce-curtain-backdrop {
    background-color: #000; 
    opacity: 0.4;
    display: block;
    z-index: 101;
}
