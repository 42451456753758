@mixin create_ce-banner_base() {
  background-repeat: no-repeat;
  background-size: cover;
}

@mixin create_ce-banner_container-sm( $height, $width:null ) {
  @include media-breakpoint-up( sm ) {
    height: $height;
    @if $width != null {
      width: $width;
    }
  }
}

@mixin create_ce-banner_container_md( $height, $width:null ) {
  @include media-breakpoint-up( md ) {
    height: $height;
    @if $width != null {
      width: $width;
    }
  }
  @include media-breakpoint-down( lg ) {
    max-width: 100%;
  } 
}

@mixin create_ce-banner_container_lg( $max-height ) {
  @include media-breakpoint-up( lg ) {
    max-height: $max-height;
  }
}

// Create a full-width image that keeps its aspect ratio
// for various breakpoints
@mixin ce-banner-img_article( $sm-height, $md-height, $lg-height ) {
  @include create_ce-banner_base();
  padding-top: $sm-height;
  width: 100%;

  @include media-breakpoint-up( md ) {
    padding-top: $md-height;
  }
  @include media-breakpoint-up( lg ) {
    padding-top: $lg-height;
  }
}

.ce-banner-img_article_details {
  $small-screen: percentage( 49/100 );
  $medium-screen: percentage( 54/100 );
  $large-screen: percentage( 56/100 );

  margin-bottom: ( $mod_space_lg / 2 );

  @include ce-banner-img_article($small-screen, $medium-screen, $large-screen);
}

.ce-banner_container_details {
  background-position: center center;
  height: 83vw;
  width: 100%;

  // For stat slider
  > .container,
  > .container > .row {
    height: 100%;
  }

  @include create_ce-banner_base();
  &:not(.no-details):after {
    position: absolute;
    content:" ";
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 100%;
    background-image: linear-gradient(transparent 0%, transparent 55%, rgba(0, 0, 0, 0.55) 70%, rgba(0, 0, 0, 0.75) 85%, rgba(0, 0, 0, 0.85) 100%);;
    @include media-breakpoint-up(lg) {
      background-image: linear-gradient(transparent 0%, transparent 55%, rgba(0, 0, 0, 0.3) 70%, rgba(0, 0, 0, 0.6) 85%, rgba(0, 0, 0, 0.8) 100%);
    }
  }

  @include create_ce-banner_container-sm( 63vw );
  @include create_ce-banner_container-md( 44vw );
  @include create_ce-banner_container-lg( 530px );
}

.ce-banner_catalog {
  &.theme-gray-light {
    @include media-breakpoint-down(lg) {
      background-color: $cool_white;
    }
  }
}

.ce-banner-fullpage {
  display: flex;
  flex: 1 0 auto;
  > .container {
    width: 100%;
  }
}
.ce-banner-fullpage-center {
  align-items: center;
  display: flex;
  flex: 1 0 auto;
  > .container {
    width: 100%;

    > .row {
      display: flex;
      flex-wrap: wrap;
    }
  }
}

.ce-banner-container {
  @extend .container;
  padding-left: 0;
  padding-right: 0;
  @include media-breakpoint-up(sm) {
    padding-left: $page_padding;
    padding-right: $page_padding;
  }
  @include media-breakpoint-up(md) {
    padding-left: $grid-gutter-width/2;
    padding-right: $grid-gutter-width/2;
  }
  @include media-breakpoint-down(xs) {
    &.vertically-padded,
    &.vertically-padded-short {
      padding-top: 0;
      padding-bottom: 0;
    }
  }

  .multi-banner-row {
    margin-bottom: -$mod_card_padding_v;
    > * {
      padding-bottom: $mod_card_padding_v;
      .ce-banner {
        height: 100%;
        .module-header {
          @extend .col-lg-10;
          @extend .offset-lg-1;
        }
        .module-footer {
          @extend .col-lg-10;
          @extend .offset-lg-1;
        }
      }
    }
  }
}

.ce-module.ce-banner.blockquote-banner {
  .ce-blockquote.featured {
    p,
    .ce-blockquote-source {
      color: $cool_dark_blue;
    }
    p:before,
      p:after {
      display: none;
    }
  }
}
