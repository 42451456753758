.ce-module {
  .travel-offset-calculator {
    overflow: visible;
    .col-inputs {
      transition-property: opacity, filter;
      transition-duration: .6s;
      display: block;
      .flipcard {
        width: 100%;
      }
      .switch-mode {
        margin-top: 25px;
      }
      .actions {
        margin-top: $mod_space_sm;
      }
      .how-calculated {
        text-align: center;
        margin-top: $mod_space_sm;
        font-size: $mod_font_size_small;
      }
    }
    .input-tonnes-col {
      margin-bottom: 1rem;
    }
    form {
      .row.inputs {
        //margin-left: -$grid-gutter-width/2;
        //margin-right: -$grid-gutter-width/2;
        .col {
          //padding-left: $grid-gutter-width/2;
          //padding-right: $grid-gutter-width/2;
          padding-bottom: $grid-gutter-width;
        }
        .type,
        .travelers,
        .tonnes {
          > label.text-label {
            width: 100%;
          }
        }
        .type {
          display: flex;
          flex-wrap: wrap;
          .field {
            label {
              font-size: 16px;
              display: flex;
              align-items: center;
              margin-right: 6px;
            }
            .ce-radio {
              & + label {
                font-weight: $cool_font-regular-weight;
              }
              & + label:before {
                margin-top: 0;
              }
              &:checked + label:after {
                top: 7px;
              }
            }
          }
          .field + .field {
            margin-left: 15px;
          }
        }
        .travelers {
          display: flex;
          flex-wrap: wrap;
          align-items: center;
          input[type="text"] {
            //border-color: $cool_white;
            width: 100%;
            font-size: 16px;
            font-weight: $cool_font-semi-bold-weight;
            text-align: center;
          }
        }
        .hours {
          padding-bottom: 0;
          .text-label {
            margin-bottom: 1rem;
          }
        }
      }
    }

    .purchase {
      background-color: $cool_white;
      width: 100%;
      height: 100%;
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      padding: 30px;
      .title {
        margin-bottom: 40px;
        text-transform: uppercase;
        font-weight: $cool_font-semi-bold-weight;
        font-size: 18px;
        color: $cool_dark_gray;
      }
      .price {
        margin-bottom: 40px;
        .total {
          font-weight: $cool_font-semi-bold-weight;
          font-size: 27px;
          color: $cool_dark_gray;
        }
        .per {
          font-weight: $cool_font-semi-bold-weight;
          color: $cool_medium_gray;
          text-transform: capitalize;
        }
      }
      #travel-offset-info {
        margin-top: 15px;
      }
      .purchase-form {
        margin-bottom: 2rem;
        width: 100%;
        .fieldset.center {
          justify-content: center;
          .ce-checkbox + label {
            width: auto;
          }
        }
        .fieldset.gift {
          margin-bottom: 1rem;
        }
      }
    }

    @include media-breakpoint-up(md) {
      form {
        .row.inputs {
          justify-content: space-between;
          .text-label {
            font-size: 1rem;
            font-weight: $cool_font-bold-weight;
            margin-bottom: 1rem;
          }
          .type,
          .travelers,
          .hours {
            padding-top: 10px;
            padding-bottom: 0;
          }
          .type,
          .travelers {
            > label.text-label {
              width: auto;
            }
          }
          .type {
            order: 1;
            align-items: center;
            > label.text-label {
              margin-bottom: 0;
              margin-right: 15px;
            }
          }
          .travelers {
            order: 2;
            > label.text-label {
              margin-bottom: 0;
              margin-right: 15px;
            }
            input[type="text"] {
              width: 78px;
              border-color: $cool_white;
            }
          }
          .hours {
            order: 3;
            margin-top: 0;
          }
        }
      }
    }

    @include media-breakpoint-up(lg) {
      .col-inputs {
        .switch-mode {
          display: none;
        }
        .actions {
          display: none;
        }
        .how-calculated {
          text-align: center;
          margin-top: 1.5rem;
          font-size: $font-size-base;
        }
        .flipcard.flip .flipcard-inner {
          .flip-card-front {
            z-index: 2;
            transform: rotateY(0deg);
          }
          .flip-card-back {
            transform: rotateY(-180deg);
          }
        }
      }

      .purchase {
        .title {
          margin-bottom: 50px;
          font-size: 20px;
        }
        .price {
          margin-bottom: 60px;
          .total {
            font-size: 32px;
          }
        }
      }
      //&.manual {
      //  .col-inputs {
      //    // disable calculator
      //    pointer-events: none;
      //    opacity: .35;
      //    filter: grayscale(100%);
      //  }
      //}
    }
  }
  .travel-offset-calculator-bulk {
    .tonnes-calculated {
      margin-bottom: 40px;
    }
  }
}

