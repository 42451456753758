.ce-module .offset-calculator {
  .results-sheet {
    padding: $mod_space_sm !important;
    background-color: $cool_white;
    top: 100vh;
    right: 0;
    transition: top .4s ease;
    display: block !important;
    overflow-y: scroll;
    z-index: z-layer('overlay');
    &.show {
      top: 0;
    }
    .results-row {
      height: 100%;
      .results-col {
        display: flex;
        flex-direction: column;
        align-items: stretch;
        justify-content: center;
        .purchase {
          justify-content: flex-start;
          flex: 1 0 auto;
          height: auto;
          padding: 0;
          > .title {
            font-size: 1rem;
            font-weight: $cool_font-semi-bold-weight;
            color: $cool_dark_gray;
            margin-bottom: 1rem;
          }
          .summary {
            margin-bottom: 1rem;
          }
          .tonnes-calculated {
            flex: 0 1 auto;
            .circle {
              width: 190px;
              height: 190px;
              .amt {
                font-size: 50px;
              }
            }
          }
          .price-summary {
            margin-top: 1rem;
            .title {
              font-size: 1rem;
              margin-bottom: .5rem;
            }
          }
        }
        .actions {
          flex: 0 1 auto;
          margin-top: $mod_space_sm;
        }
      }
    }
    @include media-breakpoint-up(md) {
      padding: $mod_space_xl !important;
    }
  }
}
