.ce-module.header-nav-module.stillin {
  nav.navbar.mobile {
    padding: 0;
    .logo {
      position: relative;
      text-transform: uppercase;
      left: 0;
    }
    .logos {

      .separator {

      }
    }
    .controls {
      right: 20px;
      .ce-button {
        padding: 0;
      }
      .checkout {
        .ce-icon-cart {
          font-size: 24px;
        }
        .nav-menu-cart-count {
          top: 0;
          left: 0;
          margin-top: -7px;
          margin-left: -7px;
        }
      }
    }
  }
}