.grid-overlay {
  pointer-events: none;
  width: 100%;
  height: 100%;
  display: block;
  position: fixed;
  top: 0;
  left: 0;
  z-index: z-layer("absolute-top");
  .ce-module, .container, .row {
    height: 100%;
  }
  .container > .row {
    background-color: rgba(255,0,0,.05);
  }
  .col {
    &:empty {
      display: block;
    }
    &:before {
      display: block;
      content: '';
      width: 100%;
      height: 100%;
      background-color: rgba(255,0,0,.075);
    }
    box-sizing: border-box;
    $borderColor: rgba(255,0,0,.2);
    border-right: 1px dashed $borderColor;
    &:first-of-type {
      border-left: 1px dashed $borderColor;
    }
  }
  .trigger {
    pointer-events: all;
    cursor: pointer;
    position: absolute;
    top: 15px;
    left: 5px;
    padding: 0;
    color: $cool_danger;
    background-color: transparent;
    border: none;
    font-size: 40px;
    line-height: 1;
    opacity: 0;

    &:hover {
      opacity: 1;
    }
    @include media-breakpoint-up(lg) {
      top: 5px;
      color: #ffe2e3;
    }
  }
  .container {
    display: none;
    &.show {
      display: block;
    }
  }
}