$subnav_height: 70px;

.sub-nav-wrapper {
  background-color: $cool_very_light_gray;
  width: 100%;
  position: relative;
  overflow: hidden; // To make content disappear once .bottom is applied
  height: $subnav_height;
  top: auto;
  margin-top: 0;
  >.container,
  >.container>.row,
  >.container>.row>.sub-nav {
    height: 100%;
  }
  &.stuck-top {
    //top: 155px !important;
    //margin-top: -155px;
    top: 120px !important;
    margin-top: -120px;
    z-index: z-layer('header') - 1;
    transition-property: margin-top;
    transition-duration: $heading-duration/2;
    transition-timing-function: ease-out;
  }
}

.sub-nav {
  align-items: stretch;
  display: flex;
  width: 100%;
  padding: 0 ( $mod_space_lg / 2 );
  position: relative;
  margin-bottom: 0;
  .ce-subnav_aside {
    flex: 1;
    &.ce-social-media {
      display: none;
      @include media-breakpoint-up( lg) {
        display: flex;
      }
    }
  }
  ul.ce-subnav_menu {
    list-style-type: none;
    margin-bottom: 0;
    padding-left: 0;
  }
  .ce-subnav_menu {
    align-items: stretch;
    display: flex;
    >* {
      align-items: center;
      display: flex;
      position: relative;
      margin: 0 ( $mod_space_lg / 2 );
      a {
        color: $cool_dark_gray;
        font-weight: $cool_font-semi-bold-weight;
        text-transform: uppercase;
        &.active:after {
          background-color: $cool_blue;
          background-image: linear-gradient(to right, $cool_red 0, $cool_blue 100%);
          bottom: 0;
          content: "";
          display: block;
          height: 3px;
          left: 0;
          position: absolute;
          width: 100%;
        }
      }
    }
    >*:first-child {
      margin-left: 0;
    }
    >*:last-child {
      margin-right: 0;
    }
  }
}

@include media-breakpoint-down(md) {
  .sub-nav-wrapper {
    display: none;
  }
}

#navbar-example {
  width: 400px;
  overflow: auto;
  .scrollspy-example {
    height: 300px;
    overflow: auto;
  }
}
