.ce-module.about-us-mosaic {
  .grid-item {
    background-color: $cool_white;
    background-clip: content-box;
    overflow: hidden;
  }
  @include media-breakpoint-down(md) {
    background-color: transparent;
  }
  @include media-breakpoint-up(lg) {
    .ce-grid {
      margin: 0;
    }
    .grid-item {
      padding: 0;
    }
  }
}