.counter-digits {
  display: flex;
  $fontSize: 42px;
  $fontSizeSm: 64px;
  $fontSizeLg: 64px;
  $height: 62px;
  $heightSm: 94px;
  $heightLg: 94px;
  $width: 38px;
  $widthSm: 60px;
  $widthLg: 60px;
  $dropShadowSize: 5px;

  color: $cool_dark_gray;
  font-weight: $cool_font-semi-bold-weight;

  &.static {
    .digit {
      display: flex;
      font-size: $fontSize;
      .inner {
        box-shadow: 0px 0px $dropShadowSize 0px rgba(0,0,0,0.35);
        margin: $dropShadowSize;
        display: flex;
        justify-content: center;
        align-items: center;
        width: $width;
        height: $height;
        border-radius: 8px;
        background: linear-gradient($cool_light_gray, $cool_light_gray 50%, $cool_white 50%, $cool_white);
      }
      @include media-breakpoint-up(sm) {
        font-size: $fontSizeSm;
        .inner {
          width: $widthSm;
          height: $heightSm;
        }
      }
      @include media-breakpoint-up(lg) {
        font-size: $fontSizeLg;
        .inner {
          width: $widthLg;
          height: $heightLg;
        }
      }
      // separator
      &:nth-last-child(4n) {
        &:after {
          content: ',';
        }
        margin-right: -$dropShadowSize;
        .inner {
          margin-right: 0;
        }
      }
    }
  }

  &.flip-clock-wrapper {
    // style overrides for flipclock.js
    $flipShadowOpacity: 0.35;
    $flipDuration: 0.25s;
    $flipDelay: 0.125s;
    margin: 0;
    font-family: $font-family-sans-serif;

    ul {
      float: none;
      border-radius: 8px;
      background: transparent;
      width: $width - $dropShadowSize;
      height: $height - $dropShadowSize;
      line-height: $height;

      li {
        line-height: $height;
      }
    }

    ul li a div div.inn {
      color: $cool_purple;
      text-shadow: none;
      font-size: $fontSize;
    }

    ul li a div.up div.inn {
      background-color: $cool_light_gray;
    }

    ul li a div.down div.inn {
      background-color: $cool_very_light_gray;
    }

    ul.play li.flip-clock-before {
      .up {
        animation: turn2 $flipDuration linear both;
        .shadow {
          animation: show $flipDuration linear both;
        }
      }
      .down {
        .shadow {
          animation: show $flipDuration linear both;
        }
      }
    }

    ul.play li.flip-clock-active {
      animation: asd $flipDuration $flipDuration linear both;
      .up {
        .shadow {
          animation: hide $flipDuration $flipDelay linear both;
        }
      }
      .down {
        animation: turn $flipDuration $flipDuration linear both;
        .shadow {
          animation: hide $flipDuration $flipDelay linear both;
        }
      }
    }

    ul.play li.flip-clock-before,
    ul.play li.flip-clock-active {
      .up {
        .shadow {
          background: linear-gradient(rgba(0, 0, 0, 0), rgba(0, 0, 0, $flipShadowOpacity));
        }
      }
      .down {
        .shadow {
          background: linear-gradient(rgba(0, 0, 0, $flipShadowOpacity), rgba(0, 0, 0, 0));
        }
      }
    }

    ul li a div.up:after {
      background-color: transparent;
    }

    .flip {
      box-shadow: 0px 0px $dropShadowSize 0px rgba(0,0,0,0.35);
      margin: $dropShadowSize;
      font-size: $fontSize;
      font-weight: $cool_font-semi-bold-weight;
    }

    .flip:nth-last-child(4n) {
      margin-right: 10px;
      &:after {
        content: ',';
        display: inline-block;
        text-align: center;
        margin-left: 1px;
      }
    }

    @include media-breakpoint-up(sm) {
      ul {
        width: $widthSm - $dropShadowSize;
        height: $heightSm - $dropShadowSize;
        line-height: $heightSm - $dropShadowSize;
      }
      ul li {
        line-height: $heightSm - $dropShadowSize;
      }
      .flip {
        font-size: $fontSizeSm;
      }
      ul li a div div.inn {
        font-size: $fontSizeSm;
      }
      .flip:nth-last-child(4n) {
        margin-right: 12px;
        &:after {
          margin-left: 0;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      ul {
        width: $widthLg - $dropShadowSize;
        height: $heightLg - $dropShadowSize;
        line-height: $heightLg - $dropShadowSize;
      }
      ul li {
        line-height: $heightLg - $dropShadowSize;
      }
      .flip {
        font-size: $fontSizeLg;
      }
      ul li a div div.inn {
        font-size: $fontSizeLg;
      }
      .flip:nth-last-child(4n) {
        margin-right: 12px;
        &:after {
          margin-left: 0;
        }
      }
    }
  }
}
