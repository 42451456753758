.ce-module,
.ce-modal {
  a {
    color: $cool_blue;
    &:hover, &:focus, &:active {
      color: $cool-dark-blue;
    }
    text-decoration: none;
  }
  a.ce-link-alt {
    color: $cool_yellow;
    &:hover, &:focus, &:active {
      color: $cool_dark_yellow;
    }
  }
  a.cta {
    color: $cool_purple;
    &:hover, &:focus, &:active {
      color: $cool_blue;
    }
    &.cta-danger,
    &.cta-danger:hover {
      color: $cool_danger;
    }
    &.cta-success,
    &.cta-success:hover {
      color: $cool_success;
    }
  }
  a.no-decoration {
    &,
    &:hover,
    &:active,
    &:focus {
      text-decoration: none;
      color: inherit;
    }
  }
  h1 a,
  h2 a,
  h3 a,
  h4 a,
  h5 a,
  h6 a {
    text-decoration: none !important;
    color: $cool_purple;
    &:hover, &:focus, &:active {
      color: $cool_blue;
    }
  }
}
