.ce-module {
  // maintains aspect ratio on mobile, and fixed height on desktop
  .figure-adaptive {
    padding-top: 56%;
    margin-bottom: 0;

  }

  @include media-breakpoint-up(lg) {
    .figure-adaptive {
      padding-top: 0;
      height: 300px;
    }
  }
}