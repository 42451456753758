@import url('https://fonts.googleapis.com/css?family=Lobster&display=swap');
@font-face {
  font-family: 'akrobat';
  src: url('/app/fonts/certificate/html_versions/Akrobat-ExtraBold.otf') format('opentype');
  font-weight: normal;
  font-style: normal;
}
.ce-module .ce-certificate.ce-certificate-origami-gift.brewtiful {
  .content {
    .outer-frame {
      .inner-frame {
        .copy {
          padding-top: 2.95in;
          justify-content: flex-start;
          .recipient-label {
            font-size: 11pt;
          }
          .recipient-name {
            font-size: 34pt;
            font-family: 'Lobster', cursive;
            margin-bottom: 6pt;
          }
          .quantity-label {
            font-size: 11pt;
            margin-bottom: -2pt;
          }
          .quantity {
            font-size: 34pt;
            font-family: 'akrobat', 'proxima-soft';
            margin-bottom: 8pt;
          }
          .about {
            font-size: 9pt;
            margin-bottom: 16pt;
          }
        }
      }
    }
  }
}
