//*
//* Split Carousel - Variables
//* Used for organizing current module-specific elements
//*

// Main selector
$ce-split-carousel: unquote("[ce-split-carousel]");

// Containers
$ce-split-carousel-wrapper: ".ce-split-carousel__wrapper";
$ce-split-carousel-inner: ".ce-split-carousel__inner";
$ce-split-carousel-content: ".ce-split-carousel__content";
$ce-split-carousel-item-content: ".ce-split-carousel-item__content";

// Sides
$ce-split-carousel-front: unquote(".carousel[data-split-side='front']");
$ce-split-carousel-back: unquote(".carousel[data-split-side='back']");

// Content
$ce-split-carousel-title: ".ce-split-carousel__title";