#canvas.snow-confetti {
  overflow-y: hidden;
  overflow-x: hidden;
  margin: 0;
  background-color: $cool_very_light_gray;
  width: 400px;
  height: 400px;
}

form.configConfetti {
  .text-label {
    width: 100%;
  }
  input {
    width: 100%;
  }
}