 .documents-search-results {
    position: absolute;
    right: 0px;
    z-index: 99999;
    top: 222px;
    background-color: #F7F7F7;
    box-shadow: 0 0 10px #432973;
    padding: 10px 20px 20px 20px;
    display: none;
    width: 80%;
    border-top: solid 1px #432973;
    border-left: solid 1px #432973;
    border-bottom: solid 1px #432973;

    &.error {
        box-shadow: 0 0 10px #FFAA19;
        border-top: solid 1px #FFAA19;
        border-left: solid 1px #FFAA19;
        border-bottom: solid 1px #FFAA19;
    }

    .close {
        float: right;
        font-size: 34px;
        color: #432973;
    }

    .error-msg {
        clear: both;
        color: #FFAA19;
        font-size: 26px;
    }

    h1 {
        clear: both;
        color: #F7F7F7;
        background-color: #432973;
        padding: 20px;
        margin-bottom: 20px;
    }

    .search-term {
        color: #432973;
    }

    .result {
        border-bottom: solid thin #ccc;
        margin-bottom: 20px;
    }
}



.documents-search-form {
    margin-left: 15px;
    
    h3 {
        font-size: 18px;
    }
    input {
        float:left;
        margin-right: 10px;
    }

    button {
        padding-top: 7px;
        padding-left: 10px;
        padding-right: 10px;
        height: 40px;
        float:left;
        background-color: #432973;

        span {
            color: #fff;
        }
    }

    .clearFloat {
        clear: both;
        display: block;
        height: 20px;
    }
}