/*
  ** Message
  * Small block of information without a CTA
  * that can appear full-width or as a sidebar
*/

.ce-message {
    align-items: flex-start;
    display: flex;

    .ce-message-image {
        display: block;
        &:not([class*="ce-icon-"]) {
            max-width: 16%;
        }
    }
    .ce-message-content {
        margin-left: ( $mod_space_lg / 2 );
        :last-child {
            margin-bottom: 0;
        }
    }
    @include media-breakpoint-up( lg ) {
        display: block;
        margin-bottom: 0;

        .ce-message-image {
            margin-bottom: ( $mod_space_lg / 2 );

            &[class*="ce-icon-"] {
                font-size: 40px;
                text-align: center;
            }
            &:not([class*="ce-icon-"]) {
                margin-left: auto;
                margin-right: auto;
                max-width: unset;
            }
        }

        .ce-message-content {
            margin-left: 0;
            text-align: center;
        }
    }
}

.ce-message-icon-links {
    .ce-message {
        a {
            display: flex;
            .ce-message-content {
                h4 {
                    &:after {
                        @extend .ce-icon-chevron-right:before;
                        font-family: 'cooleffect';
                        font-weight: normal;
                        font-size: 13px;
                        margin-left: 8px;
                        color: #432973;
                    }
                }
            }
        }
    }
}

/*
** Project Updates (Detail page)
*/
.ce-message_project-updates {
    display: flex;
    margin-top: 0;
    padding-left: 0;
    padding-right: 0;
    
    ._inner {
        align-self: center;
        display: flex;
        flex-direction: column;
        flex-wrap: wrap;
        margin-bottom: ( $mod_space_lg / 2 );
    }

    ._header {
        font-size: $font-size-h1;
        margin-bottom: ( $mod_space_xs / 2 );
    }
    
    @extend .col-md-5;

    ._thumb {
        flex: 1;
        overflow: hidden;
        padding: initial;
        width: 100%;
        img {
            float: right;
            width: 135px;
        }
        @include clearfix();
    }

    ._body {
        flex: 1;
        margin: ( $mod_space_lg / 2 ) 0 0 0;
        padding: initial;
        text-align: right;
        width: 300px;
    }
}

@include media-breakpoint-down(md) {
    .detail-map {
        display: none;
    }
}
