.ui-widget.ui-datepicker {
 	font-family: $font-family-base;
 	padding: 9px 8px 3px;
	width: 325px;
	border-top-right-radius: 0;
	border-top-left-radius: 0;
	border-bottom-right-radius: 0;
	border-bottom-left-radius: 0;

	.ui-datepicker-prev, .ui-datepicker-next {
		top: 5px;
	}

	.ui-datepicker-prev {
		left: 2px;
	}

	.ui-datepicker-next {
		right: 2px;
	}

	.ui-datepicker-prev span, .ui-datepicker-next span {
		display: block;
		position: absolute;
		left: 57%;
		margin-left: -8px;
		top: 51%;
		margin-top: -8px;
	}

	.ui-state-default,
	.ui-widget-content .ui-state-default,
	.ui-widget-header .ui-state-default,
	.ui-button,
	.ui-button.ui-state-disabled:hover,
	.ui-button.ui-state-disabled:active {
		border: 2px solid #f6f6f6;
		background: #f6f6f6;
		font-weight: $cool_font-semi-bold-weight;
		color: #606060;
		transition: border 0.5s;
	}

	.ui-datepicker-calendar .ui-state-hover {
		border: 2px solid #432676;
	}

	.ui-datepicker-next-hover.ui-button:hover {
		border: none;
	}

	.ui-widget-header .ui-icon {
		border-style: solid;
		border-width: 2px 2px 0 0;
		border-color: #606060;
		content: '';
		display: inline-block;
		height: 10px;
		left: 0.15em;
		position: relative;
		top: 13px;
		vertical-align: top;
		width: 11px;
		left: 17px;
	}

	.ui-widget-header .ui-icon.ui-icon-circle-triangle-e {
		transform: rotate(45deg);
	}
	.ui-widget-header .ui-icon.ui-icon-circle-triangle-w {
		transform: rotate(-135deg);
	}

	.ui-widget-content {
		border: 1px solid #dddddd;
		background: #ffffff;
		color: #606060;
	}

	.ui-widget-header {
		background: #f7f7f7;
		color: #606060;
		font-weight: $cool_font-semi-bold-weight;
		border: none;
		font-size: 14px;
	}

	.ui-state-hover,
	.ui-widget-content .ui-state-hover,
	.ui-widget-header .ui-state-hover,
	.ui-state-focus,
	.ui-widget-content .ui-state-focus,
	.ui-widget-header .ui-state-focus,
	.ui-button:hover,
	.ui-button:focus {
		border: none;
		background: #f6f6f6;
	}

	.ui-state-highlight {
		border: 2px solid #432676;
		background: #432676;
		color: #fff;
	}

	

	th {
		color: #606060;
		font-weight: $cool_font-semi-bold-weight;
		padding-top: 10px;
		padding-bottom: 5px;
	}

	td {
		border: 0;
		padding: 3px;
	}

	td.ui-state-disabled {
			opacity: 1;

			a, span {
				color: #d3d2d2;
			}
		}

	td a, td span {
		padding: 11px 4px 0px 0px;
	}

	table {
		font-size: 13px;
		font-weight: $cool_font-semi-bold-weight;
	}
}