#spinner
{
    position:           fixed;
    left:               0;
    top:                0;
    height:             100%;
    width:              100%;
    overflow:           hidden;
    font-size:          6rem;
    display:            flex;
    align-items:        center;
    z-index:            1051;   // Bootstrap modal is 1050

    & > div.icon
    {
        display:        flex;
        margin:         0 auto;
        color:          $cool_purple;
        animation:      rotate 1.5s linear infinite;
        z-index:        1052;
    }

    & > div.background
    {
        position:           absolute;
        top:                0;
        bottom:             0;
        left:               0;
        right:              0;
        overflow:           hidden;
        background-color:   rgba($cool_medium_gray, 0.5);
    }
}

@keyframes rotate {
  100% {
    transform: rotateZ(360deg);
  }
}

@keyframes rotateAndCenter {
  100% {
    transform: translate(-50%, -50%) rotateZ(360deg);
  }
}
