.ce-module.stuck-top,
.ce-module .stuck-top {
	position: fixed;
	top: 0;
	bottom: auto;
	width: 100%;
	z-index: z-layer('sticky-header') + 10;
}

.ce-module.stuck-bottom,
.ce-module .stuck-bottom {
	position: fixed;
	top: auto;
	bottom: 0;
	width: 100%;
	z-index: z-layer('sticky-footer');
}

.ce-module.stuck-align-bottom,
.ce-module .stuck-align-bottom {
	justify-content: flex-end;
}

[data-sticky_parent] {
	position: relative;
}