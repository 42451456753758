.ce-modal.purchase-in-place {
  .modal-dialog {
    transform: translate3D(-50%,-50%,0);
  }
  .ce-modal_content {
    > * {
      padding-bottom: 0;
    }
  }
  .payment {
    text-align: left;
    padding-bottom: 0;
    .section {
      padding-bottom: 50px;
      .section-header {
        border-bottom: 1px solid $cool_gray;
        padding-bottom: 20px;
        margin-bottom: 30px;
        .title {
          text-align: left;
          margin-bottom: 0;
        }
      }
      .section-body {
        text-align: left;
      }

      &.summary {
        .total {
          display: flex;
          justify-content: space-between;
          font-size: 20px;
          font-weight: $cool_font-semi-bold-weight;
          .tonnes {
            color: $cool_dark_gray;
            text-transform: capitalize;
          }
          .price {
            color: $cool_purple;
          }
        }
      }
      &.billing {
        padding-bottom: 0;
        form#paymentInfo {
          padding-bottom: 0;
          .ce-radio-tab {
            input[type="radio"] + label {
              .name {
                line-height: 1.1;
                flex: 0 0 0%;
                text-align: center;
              }
            }
          }
          .options {
            .ce-checkbox + label span {
              line-height: 1.5;
              margin-top: -3px;
            }
          }
        }
      }
    }
  }
  .complete {
    .text-block {
      > *:last-child {
        margin-bottom: 0;
      }
    }
    .text-block + .text-block {
      margin-top: $mod_space_lg;
      .ce-icon-certificate {
        color: $cool_purple;
        font-size: 60px;
        margin-bottom: .75rem;
      }
    }
    .cert-subheading {
      font-size: $mod_font_size_h4;
      @include media-breakpoint-up(md) {
        font-size: $mod_font_size_h4_md;
      }
      @include media-breakpoint-up(lg) {
        font-size: $mod_font_size_h4_lg;
      }
    }
    .ce-social-share {
      display: flex;
      justify-content: center;
      align-items: center;
      margin: 40px 0;
      [share] {
        color: $cool_purple;
        background-color: transparent;
      }
      [share] + [share] {
        margin-left: 30px;
      }
      [share]:hover {
        color: $cool_blue;
      }
      .ce-icon-facebook {
        font-size: 25px;
      }
      .ce-icon-twitter {
        font-size: 19px;
      }
      .ce-icon-google-plus {
        font-size: 19px;
      }
    }
  }

  @include media-breakpoint-up(md) {
    .payment {
      .section {
        &.billing {
          form#paymentInfo {
            .ce-radio-tab {
              input[type="radio"] + label {
                .name {
                  text-align: left;
                }
              }
            }
          }
        }
      }
    }
  }
}
