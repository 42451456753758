.ce-module.ce-docs {
  .sidebar {
    margin-bottom: 0;
    .nav-menu-documentation-list,
    .nav-menu-subitem-list {
      position: relative;
      list-style: none;
      padding-left: 0;

      li {
        position: relative;
        margin-left: 15px;
        a {
          color: $cool_dark_gray;
          font-weight: $cool_font-regular-weight;
        }
        a:hover {
          color: $cool_yellow;
        }
        &.current-menu-item > a {
          color: $cool_purple;
          font-weight: $cool_font-semi-bold-weight;
        }
      }
    }
    .nav-menu-documentation-list .ce-collapsible-trigger {
      display: block;
      position: absolute;
      left: -15px;
      top: 0;
      .ce-icon-chevron-right {
        font-size: 10px;
      }
    }

    .ce-collapsible-trigger {
      .indicator.ce-icon-chevron-right {
        transform: rotate(90deg);
      }
      &.hide .indicator.ce-icon-chevron-right {
        transform: rotate(0deg);
      }
    }
  }

  .sidebar,
  .content {
    padding-top: $mod_space_xxl;
    padding-bottom: $mod_space_xxl;
  }

  .content {
    .text-block {
      h1 {
        background-color: #432973;
        color: #fff;
        padding: 20px;
        margin-bottom: 0px;
      }
      p {
        margin-bottom: 20px;
        margin-left: 22px;
      }
      h4 {
        margin-bottom: 0px;
        margin-left: 22px;
      }
      > p:first-of-type {
        border: solid thin #432973;
        padding: 20px;
        margin-left: 0px;

        img {
          margin-right: 10px;
        }
      }
    }
    .comments-area {
      padding-top: $mod_space_md;
      border-top: 1px solid $cool_gray;
    }
  }
}