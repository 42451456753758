.ce-module.ce-footer-wrapper.stillin {
  .ce-footer-main {
    min-height: 0;
    background-color: $stillin_color_blue_gray;
    text-align: center;
    .footer-row {
      padding: 40px 0;
      > .col {
        display: block;
      }
      .ce-grid {
        .col {
          display: flex;
          align-items: center;
          justify-content: center;
        }
      }
      .legal {
        margin: 0;
      }
      a {
        font-size: 12px;
        text-transform: uppercase;
        font-family: $stillin_font_family_display;
        font-weight: $stillin_font_weight_display_bold;
      }
      li {
        display: flex;
      }
      .social-media-links {
        margin: 0;
        [class^="ce-icon-"],
        [class*=" ce-icon-"] {
          font-size: 18px;
        }
        a {
          border: none;
        }
        a + a {
          margin-left: 15px;
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    .ce-footer-main {
      text-align: left;
      .footer-row {
        padding: 80px 0;
        .ce-grid .col {
          align-items: center;
          justify-content: flex-start;
        }
        .social-media-links {
          a + a {
            margin-left: 0;
          }
        }
      }
    }
  }
}