.ce-blockquote {
  font-weight: $cool_font-regular-weight;
  p {
    padding: 0;
    text-indent: 0;
    &:before {
      content: open-quote;
      font-size: inherit;
    }
    &:after {
      content: close-quote;
      font-size: inherit;
    }
    color: $cool_dark_gray;
    margin-bottom: 1rem;
  }
  .ce-blockquote-source {
    color: $cool_medium_gray;
    font-weight: $cool_font-regular-weight;
    text-transform: none;
    text-indent: -10px;
    margin-left: 20px;
    &:before {
      content: '—';
      display: inline-block;
      margin-right: .5rem;
    }
  }

  &.featured {
    font-weight: $cool_font-semi-bold-weight;
    p {
      font-size: $mod_font_size_h4;
      line-height: 1.2;
    }

    .ce-blockquote-source {
      font-size: $font-size-base;
      font-weight: $cool_font-semi-bold-weight;
      line-height: 1.2;
    }
    @include media-breakpoint-up(sm) {
      p {
        font-size: $mod_font_size_h3_md;
      }
      .ce-blockquote-source {
        font-size: $mod_font_size_h6_md;
      }
    }

    @include media-breakpoint-up(lg) {
      p {
        font-size: $mod_font_size_h3_lg;
        position: relative;
        &:before,
        &:after {
          font-size: 120px;
          color: $cool_light_gray;
          line-height: 1;
          position: absolute;
        }
        &:before {
          top: -20px;
          left: -55px;
        }
        &:after {
          bottom: -70px;
          right: -55px;
        }
      }
      .ce-blockquote-source {
        font-size: $mod_font_size_h6_lg;
      }
    }
  }
}