.ce-module {
  .image-text-row {
    flex-direction: column;
    flex-wrap: wrap;

    .image,
    .text {
      flex: 0 0 auto;
    }

    .text {
      display: flex;
      flex-direction: column;
      justify-content: center;
      > *:not(:last-child) {
        margin-bottom: 1rem;
      }
    }

    .image {
      margin-bottom: $mod_space_sm;
      .bg-image {
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center;
        width: 100%;
        padding-top: 56%;
      }
    }

    &.variation-1,
    &.variation-2 {
      .image {
        order: 0;
      }
      .text {
        order: 1;
      }
    }
    &.alternating {
      + .alternating {
        margin-top: $mod_card_padding_v_mobile;
      }
    }

    &.auto {
      .text {
        padding-bottom: $mod_card_padding_v_mobile;
      }
    }

    @include media-breakpoint-up(lg) {
      flex-direction: row;
      .image {
        margin-bottom: 0;
        .bg-image {
          padding-top: 0;
          height: 100%;
        }
      }
      &.auto {
        .text {
          padding-top: $mod_card_padding_v;
          padding-bottom: $mod_card_padding_v;
        }
      }
      .text-content > *:last-child {
        margin-bottom: 0;
      }
      &.sm .bg-image {
        min-height: 270px;
      }
      &.md .bg-image {
        min-height: 285px;
      }
      &.lg .bg-image {
        min-height: 315px;
      }
      &.xl .bg-image {
        min-height: 330px;
      }
      .text-content {
        padding-top: 0;
        padding-bottom: 0;
      }
      &.variation-1 {
        .text > * {
          padding-left: $mod_card_content_padding_featured - $grid-gutter-width;
          padding-right: $mod_card_content_padding_featured;
        }
      }
      &.variation-2 {
        .image {
          order: 1;
        }
        .text {
          order: 0;
        }
        .text > * {
          padding-left: $mod_card_content_padding_featured;
          padding-right: $mod_card_content_padding_featured - $grid-gutter-width;
        }
      }
      &.alternating {
        + .alternating {
          margin-top: 0;
        }
        &:nth-child(odd) {
          .image {
            padding-right: 0;
          }
          .text {
            order: 0;
            padding-left: $grid-gutter-width;
          }
        }
        &:nth-child(even) {
          .image {
            order: 1;
            padding-left: 0;
          }
          .text {
            order: 0;
            padding-right: $grid-gutter-width;
          }
        }
      }
    }
    &.bg-pos-top .bg-image {
      background-position-y: top;
    }
    &.bg-pos-bottom .bg-image {
      background-position-y: bottom;
    }
    &.bg-pos-left .bg-image {
      background-position-x: left;
    }
    &.bg-pos-right .bg-image {
      background-position-x: right;
    }
  }
}
