.ce-modal.stillin {
  .ce-modal_body {
    p {
      font-size: 14px;
    }
  }
  &.ce-transaction {
    .ce-modal_wrapper {
      box-shadow:0px 0px 0px 20px $stillin_color_light_gray inset,0px 0px 0px 22px $stillin_color_red inset;
    }
    .ce-modal_content {
      padding: 50px;
      @include media-breakpoint-up(sm) {
        padding: 80px;
      }
    }
    .ce-modal_close {
      width: 32px;
      height: 32px;
      background-color: $stillin_color_dark_blue;
      border-radius: 9999px;
      color: $stillin_color_white;
      display: flex;
      align-items: center;
      justify-content: center;
      top: -14px;
      right: -14px;
      .ce-icon-x {
        font-size: 9px;
        font-weight: $cool_font-semi-bold-weight;
        width: 100%;
      }
      &:hover {
        opacity: 1;
      }
      :hover {
        color: $stillin_color_white;
      }
    }
    .ce-modal_header {
      &:after {
        @extend .ce-icon-si-tilde:before;
        font-family: 'cooleffect';
        color: $stillin_color_red;
        font-size: 12px;
        display: block;
        margin-top: 40px;
      }
    }
    .ce-modal_body {
      p {
        font-weight: $stillin_font_weight_base_bold;
      }
      .price {
        font-family: $stillin_font_family_display;
        font-size: 26px;
        font-weight: $stillin_font_weight_display_semibold;
        margin-top: 14px;
      }
    }
    .ce-modal_footer {
      align-items: center;
    }
  }
}