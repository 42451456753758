/*
 * Title: Source List
 * Type: Element
 * Dependencies: 
*/

ul.ce-source-list {
  padding-left: 0;
  list-style-type: none;
  li {
    list-style-type: none;
    color: $cool_teal;
    margin-bottom: ( $mod_space_lg / 2 );
    a {
      align-items: center;
      color: $cool_teal;
      display: flex;
      font-weight: $cool_font-regular-weight;
      transition: color 0.3s ease;
      [class*="ce-icon-"] {
        font-size: 15px;
        margin-right: 0.85em;
      }
      &:hover {
        color: $cool_dark_teal;
      }
    }
  }
}
