.ce-module, .ce-modal {
  .ce-grid {
    @extend .row;
    display: flex;
    flex-wrap: wrap;
    overflow: hidden;
    margin-bottom: -$mod_card_padding_v;
    &:after {
      display: none;
    }
    @include media-breakpoint-up(lg) {
      margin-bottom: -$mod_card_padding_v;
    }
    &.xs-1 > * {
      @extend .col-12;
    }
    &.xs-2 > * {
      @extend .col-6;
    }
    &.xs-3 > * {
      @extend .col-4;
    }
    &.xs-4 > * {
      @extend .col-3;
    }
    &.sm-1 > * {
      @extend .col-sm-12;
    }
    &.sm-2 > * {
      @extend .col-sm-6;
    }
    &.sm-3 > * {
      @extend .col-sm-4;
    }
    &.sm-4 > * {
      @extend .col-sm-3;
    }
    &.md-1 > * {
      @extend .col-md-12;
    }
    &.md-2 > * {
      @extend .col-md-6;
    }
    &.md-3 > * {
      @extend .col-md-4;
    }
    &.md-4 > * {
      @extend .col-md-3;
    }
    &.md-6 > * {
      @extend .col-md-2;
    }
    &.lg-1 > * {
      @extend .col-lg-12;
    }
    &.lg-2 > * {
      @extend .col-lg-6;
    }
    &.lg-3 > * {
      @extend .col-lg-4;
    }
    &.lg-4 > * {
      @extend .col-lg-3;
    }
    &.lg-6 > * {
      @extend .col-lg-2;
    }
    &.xl-1 > * {
      @extend .col-xl-12;
    }
    &.xl-2 > * {
      @extend .col-xl-6;
    }
    &.xl-3 > * {
      @extend .col-xl-4;
    }
    &.xl-4 > * {
      @extend .col-xl-3;
    }
    &.xl-6 > * {
      @extend .col-xl-2;
    }
    .grid-item {
      padding-bottom: $mod_card_padding_v;
    }
    .grid-caption {
      width: 100%;
      max-width: 100%;
      flex: 0 0 100%;
      :last-child {
        margin-bottom: 0;
      }
    }
  }
  &.no-gutters,
  .ce-grid.no-gutters,
  .no-gutters {
    width: auto;
    margin-left: 0;
    margin-right: 0;
    margin-bottom: 0;
    @include media-breakpoint-up(lg) {
      margin-bottom: 0;
    }
    > * {
      padding-left: 0;
      padding-right: 0;
      padding-bottom: 0;
    }
  }
  &.small-gutters,
  .ce-grid.small-gutters,
  .small-gutters {
    width: auto;
    margin-left: -$grid-gutter-width-sm/2;
    margin-right: -$grid-gutter-width-sm/2;
    margin-bottom: -$grid-gutter-width-sm;
    @include media-breakpoint-up(lg) {
      margin-bottom: -$grid-gutter-width-sm;
    }
    > * {
      padding-left: $grid-gutter-width-sm/2;
      padding-right: $grid-gutter-width-sm/2;
      padding-bottom: $grid-gutter-width-sm;
    }
  }

  &.medium-gutters,
  .ce-grid.medium-gutters,
  .medium-gutters {
    width: auto;
    margin-left: -$grid-gutter-width-md/2;
    margin-right: -$grid-gutter-width-md/2;
    margin-bottom: -$grid-gutter-width-md;
    @include media-breakpoint-up(lg) {
      margin-bottom: -$grid-gutter-width-md;
    }
    > * {
      padding-left: $grid-gutter-width-md/2;
      padding-right: $grid-gutter-width-md/2;
      padding-bottom: $grid-gutter-width-md;
    }
  }

  &.large-gutters,
  .ce-grid.large-gutters,
  .large-gutters {
    width: auto;
    margin-left: -$grid-gutter-width-lg/2;
    margin-right: -$grid-gutter-width-lg/2;
    margin-bottom: -$grid-gutter-width-lg;
    @include media-breakpoint-up(lg) {
      margin-bottom: -$grid-gutter-width-lg;
    }
    > * {
      padding-left: $grid-gutter-width-lg/2;
      padding-right: $grid-gutter-width-lg/2;
      padding-bottom: $grid-gutter-width-lg;
    }
  }

  &.x-large-gutters,
  .ce-grid.x-large-gutters,
  .x-large-gutters {
    width: auto;
    margin-left: -$grid-gutter-width-xl/2;
    margin-right: -$grid-gutter-width-xl/2;
    margin-bottom: -$grid-gutter-width-xl;
    @include media-breakpoint-up(xl) {
      margin-bottom: -$grid-gutter-width-xl;
    }
    > * {
      padding-left: $grid-gutter-width-xl/2;
      padding-right: $grid-gutter-width-xl/2;
      padding-bottom: $grid-gutter-width-xl;
    }
  }

  &.xx-large-gutters,
  .ce-grid.xx-large-gutters,
  .xx-large-gutters {
    width: auto;
    margin-left: -$grid-gutter-width-xxl/2;
    margin-right: -$grid-gutter-width-xxl/2;
    margin-bottom: -$grid-gutter-width-xxl;
    @include media-breakpoint-up(xl) {
      margin-bottom: -$grid-gutter-width-xxl;
    }
    > * {
      padding-left: $grid-gutter-width-xxl/2;
      padding-right: $grid-gutter-width-xxl/2;
      padding-bottom: $grid-gutter-width-xxl;
    }
  }

  &.xxx-large-gutters,
  .ce-grid.xxx-large-gutters,
  .xxx-large-gutters {
    width: auto;
    margin-left: -$grid-gutter-width-xxxl/2;
    margin-right: -$grid-gutter-width-xxxl/2;
    margin-bottom: -$grid-gutter-width-xxxl;
    @include media-breakpoint-up(xl) {
      margin-bottom: -$grid-gutter-width-xxxl;
    }
    > * {
      padding-left: $grid-gutter-width-xxxl/2;
      padding-right: $grid-gutter-width-xxxl/2;
      padding-bottom: $grid-gutter-width-xxxl;
    }
  }

  .ce-composite-grid {
    @extend .ce-grid;
    margin-bottom: -$grid-gutter-width;
    .col {
      display: flex;
      flex-wrap: wrap;
      padding-bottom: $grid-gutter-width;
    }
    .ce-grid {
      width: 100%;
      flex: 1 0 auto;
      .ce-card {
        flex: 1 0 auto;
      }
    }
  }
  .grid-show-more {
    margin-top: $mod_padding_v_mobile;
    @include media-breakpoint-up(lg) {
      margin-top: $mod_child_padding_v;
    }
    &.inactive {
      display: none;
    }
    .indicator {
      width: 29px;
      height: 29px;
      border: 2px solid $cool_dark_gray;
      border-radius: 9999px;
      color: $cool_dark_gray;
      font-size: 13px;
      line-height: 1;
      transform: rotate(180deg);
      &:before {
        display: flex;
        align-items: center;
        justify-content: center;
        height: 100%;
        width: 100%;
      }
    }
    &.hide .indicator {
      transform: rotate(0deg);
    }
  }
  .ce-grid + .ce-collapsible-target .ce-grid {
    margin-top: $grid-gutter-width;
  }
  .ce-grid.small-gutters + .ce-collapsible-target .ce-grid.small-gutters {
    margin-top: $grid-gutter-width-sm;
  }
}
