.ce-module.promo-blade {
  padding-top: $mod_space_md;
  padding-bottom: $mod_space_md;
  h2, h3, h4, h5 {
    margin-bottom: .25rem;
    line-height: 1.3;
  }
  h4 {
    [class*="ce-icon-"] {
      font-size: 1rem;
    }
  }
  p {
    line-height: 1.3;
    [class*="ce-icon-"] {
      font-size: 14px;
    }
  }
  a {
    position: relative;
    box-shadow: inset 0px -1px 0px 0px $cool_purple;
  }
  &.theme-dark {
    a {
      color: $cool_white;
      box-shadow: inset 0px -1px 0px 0px $cool_white;
    }
  }
}
