.ce-module.gdpr-banner {
  position: fixed;
  width: 100%;
  bottom: 0;
  background-color: $cool_white;
  box-shadow: 0px 1px 10px 0px rgba(0, 0, 0, 0.1);

  .close {
    position: absolute;
    top: 0;
    right: 0;
    font-size: 32px;
  }
}