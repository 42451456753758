// Header topbar
$header-topbar-height: 50px;
// Header nav
$header-nav-height-mobile: 70px;
$header-nav-height-desktop: 105px;
$header-nav-height-minimized-desktop: 70px;
// Header (top bar + nav)
$header-height-mobile: 70px;
$header-height-desktop: $header-topbar-height + $header-nav-height-desktop;
$header-height-minimized-desktop: $header-topbar-height + $header-nav-height-minimized-desktop;
// Partner nav
$header-height-partner-desktop: 175px;
$header-height-partner-mobile: 90px;
// Animation
$heading-duration: 0.6s;