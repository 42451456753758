.ce-module.stillin {
  .carousel.slide {
    background-color: $stillin_color_light_gray;
    padding-bottom: 20px;
    .carousel-item {
      .carousel-caption {
        left: 0;
        right: 0;
        bottom: 0;
        text-align: center;
        background-color: transparent;
        padding: 20px 20px 0 20px;
        margin: 0;
        font-size: 14px;
        .caption-title {
          font-size: 14px;
        }
      }
    }
  }
  .ce-mobile-carousel-controls {
    background-color: $stillin_color_light_gray;
    padding: 20px 20px 0 20px;
    margin: 0;
    .carousel-control-prev,
    .carousel-control-next {
      [class*="ce-icon-chevron-"] {
        color: $stillin_color_dark_blue;
      }
      &:hover {
        [class*="ce-icon-chevron-"] {
          color: $stillin_color_blue_gray;
        }
      }
    }
    .carousel-indicators {
      margin-left: 20px;
      margin-right: 20px;
      li {
        width: 18px;
        height: 18px;
        border: none;
        background-color: $stillin_color_dark_gray;
        margin: 0 10px;
        &.active {
          background-color: $stillin_color_white;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      .carousel-indicators {
        li {
          width: 8px;
          height: 8px;
          margin: 0 5px;
        }
      }
    }
  }
}