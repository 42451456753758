.news-page {
  // Layout // ----
  > .container {
    padding-left: 20px;
    padding-right: 20px;
    @include media-breakpoint-up(md) {
      padding-left: $grid-gutter-width/2;
      padding-right: $grid-gutter-width/2;
    }
  }
  .container:last-child {
    margin-bottom: 50px;
  }
  .basic-row {
    margin-bottom: 32px;
    @include media-breakpoint-down(md){
      margin-bottom: 24px;
    }
    @include media-breakpoint-down(sm) {
      margin-bottom: 16px;
    }
  }
  .basic-col {
    @extend .col-md-10, .offset-md-1;
    @extend .col-12;
  }

  // Components // ----
  .img-banner {
    margin-bottom: 60px;
    @include media-breakpoint-down(sm){
      margin-bottom: 45px;
    }
  }
  .img-banner .basic-row {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .img-banner .text-lg > h1 {
    font-size: 3.5em;
    margin-bottom: 30px;
    @include media-breakpoint-down(sm) {
      font-size: 2.25em;
    }
  }
  .text-lg > p {
    font-size: 22px;
    line-height: 1.2;
    @include media-breakpoint-down(sm) {
      font-size: 20px;
    }
  }
  .img-banner .container:last-child {
    margin-bottom: 0px;
  }
  // Consider this an extend. Not sure what our import pattern is, so we're doing this until I've resolved. -ae|09.29.16
  .page-intro {
    height: 320px; // Fallback to resolve iOS 7 glitch
    height: 75vh;
    @include media-breakpoint-up(md) {
      max-height: 450px;
    }
    @include media-breakpoint-up(lg) {
      max-height: 500px;
      height: 75vh;
    }
  }
  // Typography // ----
  h1, h2, h3 {
    margin-bottom: .5rem;
  }
  p {
    font-size: 16px;
    margin-bottom: 1rem;
  }
  p:last-child {
    margin-bottom: 0rem;
  }
  .color-theme-white {
    h1, h2, h3, h4, h5, h5,
    p {
      color: $cool_white;
    }
  }
  // OLs and ULs
  counter-reset: item;
  ol, ul {
    font-size: 16px;
  }
  ol {
    list-style: none;
  }
  ol > li {
    counter-increment: item;
    margin-bottom: 1rem;
  }
  ol > li:before {
    content: counter(item) '. ';
    color: $cool_purple;
    display: inline-block;
    font-weight: 600;
    margin-left: -25px;
    margin-right: 5px;
    vertical-align: top;
  }
  // Wow this is super specific and I hate writing it. -ae 2.1.17
  ol > li > h1,
  ol > li > h2,
  ol > li > h3,
  ol > li > h4 {
    display: inline-block;
  }
  // Buttons // ----
  .button-wrapper {
    text-align: center;
    margin-top: 2.25rem;
  }

  // Newsletter Signup
  .newsletter {
    text-align: center;
    margin-top: 35px;
    @include media-breakpoint-down(md){
      margin-top: 20px;
    }
    @include media-breakpoint-down(sm) {
      margin-top: 15px;
    }
    label {
      font-size: 18px;
      font-weight: $cool_font-semi-bold-weight;
      text-transform: uppercase;
    }
    .input-group {
      width: 100%;
      input[type='email'] {
        text-align: center;
        max-width: 360px;
        background-color: $cool_very_light_gray;
        border-color: $cool_very_light_gray;
      }
      .form-control {
        float: none;
      }
      .email-error {
        top: 50%;
      }
    }
  }

  // News Desk Archive Styles // ----
  .news-archiveHeader {
    margin-top: 1.5em;
    margin-bottom: 1.5em;
    text-transform: uppercase;
  }
  .type-news {
    background-color: $cool_very_light_gray;
    color: $cool_dark_gray;
    padding: 30px;
    margin-bottom: 45px;
  }
  .type-news a {
    color: $cool_purple;
    &:hover {
      color: $cool_dark_purple;
    }
    &:focus {
      text-decoration: none;
    }
  }
  .basic-row.news-archive{
    margin-top: 0px;
  }
  .container > .basic-row > .basic-col > .button-wrapper > .btn-lg {
    min-width: 0;
    width: 100%;
    @include media-breakpoint-up(sm) {
      min-width: 304px;
      width: auto;
    }
  }
  .ce-banner {
    &.container-px0-xs .container {
      @include media-breakpoint-down(xs) {
        padding-left: 0;
        padding-right: 0;
      }
    }
  }
  .news-travel-offset-card {
    .bg-image {
      background-position-x: left;
    }
    @include media-breakpoint-down(sm) {
      .ce-card-inner {
        min-height: 135px !important;
      }
      .ce-card-content {
        padding: 15px 15px 15px 40% !important;
      }
      .ce-card-header {
        margin-bottom: 0 !important;
        .card-title {
          font-size: 14px !important;
          line-height: 1.1;
        }
      }
      .ce-card-body {
        margin-top: 3px !important;
        h5 {
          font-size: 12px !important;
        }
      }
      .ce-card-footer {
        margin-top: 3px !important;
        .ce-button {
          font-size: 10px;
          padding: 6px 10px !important;
        }
      }
    }
    @include media-breakpoint-only(md) {
      .ce-card-content {
        padding: 15px 15px 15px 35% !important;
      }
      .ce-card-inner {
        min-height: 140px !important;
      }
      .ce-card-header {
        margin-bottom: 0 !important;
        .card-title {
          font-size: 18px !important;
        }
      }
      .ce-card-body {
        margin-top: 5px !important;
        h5 {
          font-size: 16px !important;
        }
      }
      .ce-card-footer {
        margin-top: 5px !important;
        .ce-button {
          font-size: 12px;
        }
      }
    }

    @include media-breakpoint-up(lg) {
      .ce-card-content {
        padding: 30px 30px 30px 35% !important;
      }
    }
  }
}
