.ce-module .grid-logos {
  .grid-item {
    display: flex;
    align-items: center;
    justify-content: center;
  }
  .ce-image-list-item {
    @include center_ce-list-item_content();
  }
}
