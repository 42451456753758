
@font-face {
  font-family: 'toothnail';
  src: url('/app/fonts/toothandnail/tooth-and-nail.eot');
  src: url('/app/fonts/toothandnail/tooth-and-nail.eot?#iefix') format('embedded-opentype'),
       url('/app/fonts/toothandnail/tooth-and-nail.woff2') format('woff2'),
       url('/app/fonts/toothandnail/tooth-and-nail.woff') format('woff'),
       url('/app/fonts/toothandnail/tooth-and-nail.ttf') format('truetype'),
       url('/app/fonts/toothandnail/tooth-and-nail.svg#youworkforthem') format('svg');
}

.toothnail-n5 {
  font-family: 'toothnail', sans-serif;
  font-style: normal;
  font-weight: 500;
}