.ce-module {
  .ce-toggle-button-group {
    display: flex;
    .col {
      flex: 1 1 0;
      padding-left: $grid-gutter-width-sm/2;
      padding-right: $grid-gutter-width-sm/2;
      .ce-toggle-button {
        width: 100%;
        height: 100%;
      }
    }
  }
  .ce-toggle-button {
    input[type="radio"] {
      display: none;
    }
    input[type="radio"] + label {
      display: flex;
      align-items: center;
      justify-content: center;
      padding: 10px;
      cursor: pointer;
      font-size: 1rem;
      text-transform: uppercase;
      color: $cool_dark_blue;
      border: 2px solid $cool_dark_blue;
      border-radius: 4px;
      height: 100%;
    }
    input[type="radio"]:checked + label {
      background-color: $cool_dark_blue;
      color: $cool_white;
    }
  }
}
