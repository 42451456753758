.ce-module,
.ce-modal {
  .ce-quote-carousel {
    @include media-breakpoint-only(xs) {
      margin-left: -30px;
      margin-right: -30px;
    }
  }
  .quote-slide {
    background-color: $cool_white;
    text-align: left;
    padding: $mod_card_content_padding;
    .ce-blockquote {
      margin-bottom: 0;
    }

    @include media-breakpoint-up(lg) {
      text-align: center;
      padding: 80px;
      .ce-blockquote {
        padding: 0 60px;
      }
    }
  }
}