.ce-module.stillin {
  &.ce-sticky-footer-wrapper {
    height: 70px;
    .ce-sticky-footer {
      width: 100%;
      height: 70px;
      .simple-portfolio-purchase {
        width: 100%;
        height: 100%;
        .ce-button {
          width: 100%;
          height: 100%;
          border-radius: 0;
        }
      }
    }
  }
}