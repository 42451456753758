.ce-module {
  .business-offset-calculator {
    .description {
      display: none;
    }
    .radiogroup-inline {
      margin-bottom: 19px;
    }
    @include media-breakpoint-up(lg) {
      .description {
        display: block;
      }
    }
  }
}
