@import url('https://fonts.googleapis.com/css?family=Alegreya+Sans:700i,800&display=swap');
.ce-module .ce-certificate.ce-certificate-origami-gift.poo-package {
  .content {
    .outer-frame {
      .inner-frame {
        .copy {
          padding-top: 3.42in;
          justify-content: flex-start;
          .recipient-label {
            font-size: 7pt;
            font-weight: $cool_font-semi-bold-weight;
            font-style: italic;
            margin-bottom: 4pt;
            span {
              padding: 0 2pt 0pt 2pt;
              border-top: 1px solid black;
              border-bottom: 1px solid black;
            }
          }
          .recipient-name {
            font-size: 30pt;
            font-family: 'Alegreya Sans', sans-serif;
            font-weight: 800;
            margin-bottom: -6pt;
          }
          .quantity-label {
            font-size: 11pt;
            margin-bottom: -2pt;
          }
          .quantity {
            font-size: 24pt;
            font-family: 'Alegreya Sans', sans-serif;
            font-weight: 700;
            font-style: italic;
            margin-bottom: 4pt;
          }
          .about {
            font-size: 9pt;
            margin-bottom: 14pt;
          }
        }
      }
    }
  }
}
