/********
 * Type *
 ********/

@mixin type-nav-subhead {
    @include proxima-soft-semibold;
    font-size: ( 16 / $font-size-root-unitless ) + rem;
    line-height: 1.25em;
    text-transform: uppercase;
}

@mixin type-nav {
    @include proxima-soft-regular;
    font-size: ( 16 / $font-size-root-unitless ) + rem;
    line-height: 1.25em;
    text-transform: uppercase;
}

@mixin type-footer-subhead {
    @include type-nav-subhead;
}

@mixin type-footer-legal {
    @include proxima-soft-regular;
    font-size: ( 12 / $font-size-root-unitless ) + rem;
    line-height: 1.166em;
}
