.ce-module.eco-america {
  .eco-america-logo {
    width: 100%;
    max-width: 250px;
  }
  .project-previews {
    .ce-card-footer .price {
      display: none;
    }
  }
}