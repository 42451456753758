.ce-module.stillin,
.ce-modal.stillin {
  .ce-blockquote {
    margin-bottom: 2rem;
    p,
    .ce-blockquote-source {
      color: $stillin_color_dark_gray;
    }
    .ce-blockquote-source {
      font-weight: $stillin_font_weight_base_bold;
      margin-left: 15px;
      text-indent: -7px;
      &:before {
        margin-right: 2px;
      }
    }
  }
}