/*
 * Title: Sidebar
 * Type: Element
 * Dependencies: 
*/

.transaction {
  display: flex;
  flex-direction: column;
  position: relative;
  flex: 1 0 auto;
  .stuck-top {
    top: 100px !important;
    z-index: z-layer('sticky-header') - 1;
  }
  .trigger-wrapper,
  #money-distribution-sidebar {
    position: relative;
  }
}

@include media-breakpoint-down(md) {
  .transaction {
      display: none;
  }
}

.ce-sidebar {
  padding: $mod_space_lg;
  text-align: center;

  h3, h4 {
    text-transform: uppercase;
  }
  
  > *:not(:last-child) {
    margin-bottom: 1.5rem;
  }

  &.ce-transaction {
    .gift {
      margin-top: 1.5rem;
    }
    .frequency {
      text-align: left;
      margin-top: 1.5rem;
      .field + .field {
        margin-top: .5rem;
      }
      .info {
        display: flex;
        margin-top: 15px;
      }
    }
    label.text-label {
      .tootip-trigger {
        float: none;
      }
    }
  }
}

.ce-sidebar.theme-border-light {
  $border-size: 2px;
  
  background-color: $cool_white;
  margin: $border-size;
  max-width: 100%;
  position: relative;

  // Border is set using a pseudo class so
  // that it shows up in Safari
  &::after {
    background: linear-gradient( to bottom, $cool_red 0, $cool_blue 100% );
    box-shadow: 0px 0px 2px rgba( 0, 0, 0, 0.36 );
    box-sizing: content-box;  
    content: "";
    display: block;
    height: 100%;
    left: ( -1 * $border-size );
    padding: $border-size;
    position: absolute;
    top: ( -1 * $border-size );
    width: 100%;
    z-index: z-layer( "content-lower" );
  }

  label.text-label {
    color: $color_base;
  }
  .quantity .text-label {
    justify-content: center;
  }
  input[type="radio"].ce-radio + label {
    color: $cool_dark_gray;
  }
}
