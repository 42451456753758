@import "responsive";
@import "type";
@import "tags";
@import "buttons";


// This should go in a functions file eventually -ae 2.27.17
@function str-replace($string, $search, $replace: "") {
  $index: str-index($string, $search);

  @if $index {
    @return str-slice($string, 1, $index - 1) + $replace + str-replace(str-slice($string, $index + str-length($search)), $search, $replace);
  }

  @return $string;
}