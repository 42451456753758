.ce-module {
  .calculator-col {
    .description {
      margin-bottom: 1.5rem;
    }
    @include media-breakpoint-up(lg) {
      padding: $mod_card_content_padding_lg $grid-gutter-width/2 $mod_card_content_padding_lg $mod_card_content_padding_lg;
    }
  }
  .facility-offset-calculator,
  .event-offset-calculator,
  .business-offset-calculator,
  .travel-offset-calculator-bulk {
    padding: $mod_padding_v_mobile_short 0;
    .col-inputs {
      padding-bottom: $grid-gutter-width-lg;
      form.calculator-inputs {
        .description {
          margin-bottom: 2rem;
        }
      }
    }
    .col-purchase {
      .bg-image {
        padding: 40px 40px;
        width: 100%;
        height: 100%;
        background-size: cover;
        background-position: center;
        background-repeat: no-repeat;
        display: flex;
        align-items: center;
        justify-content: center;
      }
    }
    .purchase {
      background-color: $cool_white;
      width: 100%;
      padding: 30px;
    }

    @include media-breakpoint-up(lg) {
      padding: 0;
      .col-inputs {
        form.calculator-inputs {
          padding: 40px 30px 40px 0;
        }
      }
      .col-purchase {
        .bg,
        .bg-image {
          height: 100%;
          display: flex;
          justify-content: center;
          align-items: center;
        }
        .bg {
          padding: 60px 50px;
          background: linear-gradient(to bottom, $cool_dark_blue 0%, $cool_purple 100%);
        }
        .bg-image {
          padding: 60px 100px;
          background-repeat: no-repeat;
          background-size: cover;
        }
        .purchase {
          padding: 50px 30px;
        }
      }

    }
  }
}
