.ce-module .year-offset-calculator {
  .col-inputs {
    flex-direction: column;
    justify-content: center;
    .flipcard {
      width: 100%;
    }
    .switch-mode {
      margin-top: 25px;
    }
  }
  .purchase {
    padding: $mod_space_lg $mod_space_sm;
    height: 100%;
    .purchase-form {
      width: 100%;
    }
    .title,
    .total {
      color: black;
      font-weight: $cool_font-semi-bold-weight;
    }
    .title {
      font-size: $font-size-base;
      margin-bottom: .5rem;
    }
    .total {
      font-size: $mod_font_size_h2_lg;
      margin-bottom: .25rem;
    }
    .per {
      font-weight: $cool_font-semi-bold-weight;
      font-size: $mod_font_size_small_lg;
      margin-bottom: 1rem;
    }
    .how {
      text-align: center;
      font-size: $mod_font_size_small_lg;
      margin-top: 1.25rem;
    }

    @include media-breakpoint-up(lg) {
      .ce-button {
        padding-left: $mod_space_md;
        padding-right: $mod_space_md;
      }
      .title {
        margin-bottom: 1.25rem;
      }
      .total {
        margin-bottom: .5rem;
      }
      .per {
        margin-bottom: 4rem;
      }
    }
  }
  &.eyot {
    overflow: visible;
    .purchase {
      padding: $mod_space_sm;
      .input-tonnes-col {
        margin-bottom: .75rem;
      }
    }
    @include media-breakpoint-up(lg) {
      .col-inputs {
        .flipcard.flip .flipcard-inner {
          .flip-card-front {
            z-index: 2;
            transform: rotateY(0deg);
          }
          .flip-card-back {
            transform: rotateY(-180deg);
          }
        }
      }
      .purchase {
        .title {
          font-size: 14px;
          margin-bottom: .75rem;
        }
        .total {
          margin-bottom: 0;
        }
        .per {
          margin-bottom: 1.25rem;
        }
        .switch-mode {
          font-size: 13px;
          margin-top: .75rem;
        }
      }
    }
  }
  &.has-results-sheet {
    .col-inputs {
      .flip-card-front {
        display: flex;
        flex-direction: column;
        justify-content: flex-end;
      }
      .tonnes-calculated {
        display: none;
      }
    }
    .col-purchase {
      display: none;
    }
    @include media-breakpoint-up(lg) {
      .col-inputs {
        .tonnes-calculated {
          display: flex;
          margin-bottom: 1.5rem;
        }
        .actions {
          display: none;
        }
      }
      .col-purchase {
        display: block;
      }
    }
  }
}
