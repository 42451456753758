.ce-video-trigger {
	position: relative;
	text-align: center;
	height: 50vw;
	max-height: 70vh;

	@include media-breakpoint-down(sm) {
		height: auto;
	}

	.background-content {
		background-position: top center;
		background-size: cover;
		position: absolute;
		width: 100%;
		height: 100%;
		z-index: 9;

		@include media-breakpoint-down(sm) {
			position: static;
			height: 300px;
		}
	}

	.body-content {
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		z-index: 99;

		@include media-breakpoint-down(sm) {
			position: static;
			transform: none;
			padding: 50px 30px;
			background-color: #f2f2f2;
		}

		h1 {
			color: #fff;
			font-size: 40px;
			margin-bottom: 25px;

			@include media-breakpoint-down(sm) {
				color: #432675;
				font-size: 30px;
			}
		}

		p {
			color: #fff;
			margin-bottom: 35px;
			
			@include media-breakpoint-down(sm) {
				color: #606060;
			}
		}
	}

	.play-icon {
		position: absolute;
  		top: 50%; 
  		left: 50%;
  		transform: translate(-50%,-50%);
	}

	.play-button-circle {
		border: 5px solid #fff;
		width: 70px;
		height: 70px; 
		display: inline-block;
		border-radius: 50%;
		position: relative;
		opacity: 0.5;
	}

	.play-button-triangle {
		width: 0; 
		height: 0; 
		border-top: 20px solid transparent;
		border-bottom: 20px solid transparent;
		border-left: 20px solid white;
		position: absolute;
  		top: 50%; 
  		left: 54%;
  		transform: translate(-44%,-50%);
	}
}

.wpb_raw_code.wpb_content_element.wpb_raw_html.ce-video-poster-trigger {
	padding-top: 0px;
}

.ce-video-poster-trigger {
	width: 100%;
	height: 100%;
	background-size: cover;
	background-repeat: no-repeat;
	display: block;
	position: relative;
	cursor: pointer;
	padding-top: 56%;

	.play-button {
		border: 2px solid $cool_white;
		width: 62px;
		height: 62px;
		display: block;
		position: absolute;
		top: 50%;
		left: 50%;
		transform: translate(-50%,-50%);
		border-radius: 9999px;
		opacity: 1;
		transition: all 0.3s ease;
		&:after {
			content: '';
			display: block;
			position: absolute;
			width: 0;
			height: 0;
			border-top: 10px solid transparent;
			border-bottom: 10px solid transparent;
			border-left: 17px solid $cool_white;
			top: 50%;
			left: 54%;
			transform: translate(-44%,-50%);
		}
	}
	&:hover {
		.play-button {
			background-color: $cool_white;
			&:after {
				border-left: 17px solid $cool_blue;
			}
		}
	}

	@include media-breakpoint-up(lg) {
		&:not(.aspect-16-9) {
			padding-top: 0;
		}
	}
	.caption {
		text-align: center;
		color: $cool_white;
		position: absolute;
		bottom: $mod_space_xs;
		width: 100%;
		padding: 0 $mod_card_content_padding;
		line-height: 1;
		small {
			line-height: 1;
		}
	}

	&.racing-extinction {
		// this is a last minute one-off, need to standardize
		@include media-breakpoint-down(sm) {
			margin-top: $mod_space_xl;
		}
	}

	&.aspect-16-9 {

	}
}
