/*
  * Mosaic Base
*/

.ce-mosaic {
  $row: $ce-mosaic_row;
  $column: $ce-mosaic_column;
  $cell: $ce-mosaic_cell;
  
  box-sizing: border-box;

  * {
    background-size: cover;
    background-position: center;
  }
  /* Layout */
  &, #{$row}, #{$column} {
    display: flex;
    overflow: hidden;
  }
  #{$row}, #{$column}, #{$cell} {
    box-sizing: inherit;
    flex: $ce-mosaic-default-flex; // Default
    max-height: 100%;
    max-width: 100%;
  }
  #{$row} {
    flex-direction: row;
  }
  #{$column} {
    flex-direction: column;
  }

  .ce-flex {
    flex-basis: 0;
  }
}

.ce-mosaic-copy {
  padding: $mod_child_padding_v;

  .header {
    margin: 0;
  }
}

// Flex layout
.ce-mosaic-flex {
  display: flex;
  margin-left: 0;
  margin-right: 0;

  * {
    background-size: cover;
    background-repeat: no-repeat;
  }

  .ce-flex-row,
  .ce-flex-row-reverse,
  .ce-flex-col,
  .ce-flex-col-reverse,
  .ce-flex-item {
    max-width: 100%;
    max-height: 100%;
  }

  &.mosaic-home {
    height: 50vw;
    max-height: 1000px;
    min-height: 650px;
    @include media-breakpoint-down(lg) {
      height: 50vh
    }
    @include media-breakpoint-down(md) {
      min-height: initial;
    }
  }
}