.ce-module {
  position: relative;
  background-position: center;
  @extend .row;
  margin-left: 0;
  margin-right: 0;
  &.cache-test {
    background-color: yellow;
  }
  .col:empty {
    display: none;
  }
  > .container {
    position: relative;
    padding-left: $page_padding;
    padding-right: $page_padding;
  }
  > .container > .row {
    position: relative;
  }
  @include media-breakpoint-up(md) {
    > .container {
      padding-left: $grid-gutter-width/2;
      padding-right: $grid-gutter-width/2;
    }
  }
  @include media-breakpoint-up(lg) {

  }
  &.full-width {
    > .container {
      padding-left: 0;
      padding-right: 0;
      width: 100%;
      max-width: 100%;
    }
  }
}

.ce-banner-section {
  position: relative;
  width: 100%;
  &:after {
    content: "";
    display: table;
    clear: both;
  }
}

.ce-module.full-height {
  flex: 1 0 auto;
}


.vertically-padded-t {
  padding-top: $mod_padding_v;
  @include media-breakpoint-down(sm) {
    padding-top: $mod_padding_v_mobile;
  }
}

.vertically-padded-b {
  padding-bottom: $mod_padding_v;
  @include media-breakpoint-down(sm) {
    padding-top: $mod_padding_v_mobile;
    padding-bottom: $mod_padding_v_mobile;
  }
}

.vertically-padded {
  padding-top: $mod_padding_v;
  padding-bottom: $mod_padding_v;
  @include media-breakpoint-down(sm) {
    padding-top: $mod_padding_v_mobile;
    padding-bottom: $mod_padding_v_mobile;
  }
  min-height: 1px; // ie-fix
}

.vertically-padded-short {
  padding-top: $mod_padding_v_short;
  padding-bottom: $mod_padding_v_short;

  @include media-breakpoint-down(sm) {
    padding-top: $mod_padding_v_mobile_short;
    padding-bottom: $mod_padding_v_mobile_short;
  }
}

.vertically-spaced {
  @extend .vertically-padded;
  //&.ie-fix {
  //  min-height: 1px;
  //}
  //&.ce-banner-container > .container,
  > .container > .row {
    > *,
    > .ce-banner-section > * {
      margin-bottom: $mod_child_padding_v;
    }
    > *:last-child,
    > .ce-banner-section > *:last-child {
      margin-bottom: 0;
    }
  }
  @include media-breakpoint-down(sm) {
    //&.ce-banner-container > .container,
    > .container > .row {
      > *,
      > .ce-banner-section > * {
        margin-bottom: $mod_child_padding_v_mobile;
      }
    }
  }
}

.vertically-spaced-short {
  @extend .vertically-padded;
  padding-top: $mod_padding_v_short;
  padding-bottom: $mod_padding_v_short;
  &.ce-banner-container > .container,
  > .container > .row {
    > * {
      margin-bottom: $mod_child_padding_v;
    }
    > *:last-child {
      margin-bottom: 0;
    }
  }
  @include media-breakpoint-down(sm) {
    //&.ce-banner-container > .container,
    > .container > .row {
      > * {
        margin-bottom: $mod_padding_v_mobile;
      }
    }
  }
}

.vertically-spaced-paragraphs {
  // for stacked text blocks to appear as one text flow
  > * {
    margin-bottom: 1rem;
  }
  > *:last-child {
    margin-bottom: 0;
  }
}

.ce-section_offset {
  @include media-breakpoint-up(lg) {
    > .row {
      //display: flex;
      justify-content: flex-end;
    }
  }
}

.ce-module, .ce-modal {
  &.bg-pos-top,
  .bg-pos-top {
    background-position-y: top !important;
  }
  &.bg-pos-bottom,
  .bg-pos-bottom {
    background-position-y: bottom !important;
  }
  &.bg-pos-left,
  .bg-pos-left {
    background-position-x: left !important;
  }
  &.bg-pos-right,
  .bg-pos-right {
    background-position-x: right !important;
  }
  &.bg-pos-center,
  .bg-pos-center {
    background-position: center !important;
  }
}

.news-page .ce-module.ce-hero {
  margin-left: -15px;
  margin-right: -15px;
}
.news-page .ce-module.ce-hero .container:last-child,
.news-page .ce-module.ce-banner .container:last-child {
  margin-bottom: 0;
}
