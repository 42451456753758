.col-video {
  padding-bottom: 56.25%;
  margin: 0 $grid-gutter-width/2;
  @include media-breakpoint-down(sm) {
    margin: 0;
  }
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

.ce-youtube-embed-wrapper {
  position: relative;
  width: 100%;
  padding-top: 56.25%;
  iframe {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}
