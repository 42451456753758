/*
 * Title: Overlay Card
 * Type: Template
 * Dependencies: card.scss
*/

// Separate what makes it an 'overlay' card
// in case it only appears as such at a 
// certain breakpoint - J.D.P.
@mixin ce-card_overlay_base() {
	figure {
		display: block;
		height: 100%;
		padding-top: 0;
		position: absolute;
		top: 0;
		width: 100%;
		margin-bottom: 0;
	}
	.ce-card-content {
		color: $cool_white;
		flex: 0 1 auto;
		padding: $grid-gutter-width;

		pointer-events: none;

		a, button {
			pointer-events: auto;
		}

		.card-title {
			color: $cool_white;
		}
		.ce-card-body {
			flex: 0 1 auto;
		}
	}
}

@mixin ce-card_overlay( $breakpoint: null ) {
	display: flex;
	justify-content: flex-end;

	figure {
		overflow: hidden;
		.bg-image {
			background-size: cover;
		}
	}
	.ce-card-content {
		.ce-card-header,
		.ce-card-footer {
			flex: 0 1 auto;
		}
	}

	// If a breakpoint is not specified, the
	// overlay styles affect the element at
	// every breakpoint - J.D.P.
	@if $breakpoint == null {
		@include ce-card_overlay_base();
	}
	@else {
		@include media-breakpoint-up( $breakpoint ) {
			@include ce-card_overlay_base();
		}
	}
}