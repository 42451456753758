.ce-video-banner.ce-module {
  height: 360px;
  overflow: hidden;

  video {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translateX(-50%) translateY(-50%);
    min-width: 100%;
    min-height: 100%;
    width: auto;
    height: auto;
    overflow: hidden;
  }

  .header-title {
    color: $cool_white;
    &:before {
      display: none;
    }
  }

  @include media-breakpoint-up(lg) {
    height: 400px;
  }
}

.ce-video-banner.ce-module.reason {
  > .container {
    display: flex;
    flex-direction: column;
    justify-content: center;
    height: 100%;
  }
  .header-title {
    margin-bottom: 0;
    .reason {
      //@extend .toothnail-n5;
      text-transform: uppercase;
      display: block;
      font-size: 115px;
      line-height: .8;
      margin-top: 20px;
    }
  }
  .header-body {
    text-align: center;
  }
  .module-footer {
    margin-top: 30px;
  }

  @include media-breakpoint-up(lg) {
    .header-title {
      .reason {
        font-size: 160px;
        line-height: .7;
        margin-top: 30px;
      }
    }
    .module-footer {
      margin-top: $mod_padding_v_mobile;
    }
  }
}