.ce-module.stillin,
.ce-modal.stillin {
  .pledged-grid-item {
    &.ng-enter {
      transition: opacity 400ms ease-out;
      opacity: 0;
    }

    &.ng-enter.ng-enter-active {
      opacity: 1;
    }
    figure {
      width: 100%;
      margin-bottom: 20px;
      .bg-image {
        background-position: center;
        background-size: cover;
        background-repeat: no-repeat;
        width: 100%;
        padding-top: 100%;
        border-radius: 9999px;
      }
    }
    text-align: center;
    font-size: 14px;
    .title,
    .sub-title {
      line-height: 1.3;
      color: $stillin_color_dark_gray;
      margin-bottom: 0;
    }
    .title {
      font-weight: $stillin_font_weight_base_bold;
    }
  }
  .see-more {
    display: flex;
    align-items: center;
    justify-content: flex-end;

    &:before {
      @extend .ce-icon-refresh;
      font-family: 'cooleffect';
      font-weight: normal;
      font-size: 16px;
      margin-right: 10px;
    }
  }
}