/*
    Pure CSS solution for an on/off toggle switch.
    Example markup:

        <fieldset class="form-group">
            <label class="form-control-label">Subscription Preference</label>
            <div class="form-control switch">
                <input  id="subscribed" name="subscribed" ng-model="user.subscribed"
                        type="checkbox" class="toggle toggle-round" />
                <label for="subscribed"></label>
                <span>{{user.subscribed ? 'On' : 'Off'}}</span>
            </div>
        </fieldset>
*/
.switch
{
    background-color:                           inherit !important;

    .toggle
    {
        position:                               absolute;
        margin-left:                            -9999px;
        visibility:                             hidden;
    }
    .toggle + label
    {
        display:                                inline-block;
        position:                               relative;
        cursor:                                 pointer;
        outline:                                none;
        user-select:                            none;
    }
    input.toggle-round + label
    {
        padding:                                1px;  //   2px;
        width:                                  60px; // 120px;
        height:                                 30px; //  60px;
        background-color:                       darken($cool_light_gray, 10%);
        border-radius:                          30px; //  60px;
    }
    input.toggle-round + label:before,
    input.toggle-round + label:after
    {
        display:                                block;
        position:                               absolute;
        top:                                    1px;
        left:                                   1px;
        bottom:                                 1px;
        content:                                '';
    }
    input.toggle-round + label:before
    {
        right:                                  1px;
        background-color:                       $cool_light_gray;
        border-radius:                          30px; // 60px;
        transition:                             background 0.4s;
    }
    input.toggle-round + label:after
    {
        width:                                  29px; // 58px;
        background-color:                       $cool_white;
        border-radius:                          100%;
        box-shadow:                             0 2px 5px rgba(0, 0, 0, 0.3);
        transition:                             margin 0.4s;
    }
    input.toggle-round:checked + label:before
    {
        background-color:                       $cool_light_purple;
    }
    input.toggle-round:checked + label:after
    {
        margin-left:                            30px; // 60px;
    }
    span
    {
        position:                               relative;
        top:                                    -18px;
        padding-left:                           0.5em;
        color:                                  $cool_medium_gray;
        -webkit-user-select:                    none;
        -moz-user-select:                       none;
        -ms-user-select:                        none;
        user-select:                            none;

        &.toggle-right
        {
            display:                            inline-block;
            min-width:                          225px;
            text-align:                         right;
        }
    }
}