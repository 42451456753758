#profile.page
{
    .grid, .card {
        position: relative;
    }
    & > nav
    {
        & > a
        {
            display:                            inline-block;
            margin:                             0 0.5em 0 0.5em;

            &:first-child
            {
                margin-left:                    0;
            }

            &:last-child
            {
                margin-right:                   0;
            }
        }

        button
        {
            box-sizing:                         border-box;
            min-width:                          210px;
            font-size:                          16px;
            padding:                            8px 0;
            text-transform:                     uppercase;
            background-color:                   transparent;

            &:hover
            {
                background-color:               transparent;
                color:                          $cool_blue;
                border-color:                   $cool_blue;
            }

            &.active
            {
                background-color:               $cool_purple;
                border-color:                   $cool_purple;
                color:                          $cool_white;
                outline:                        none;
                cursor:                         not-allowed;
            }
        }
    }

    h1
    {
        margin-bottom:                          0;
    }

    @include form-base {
        @include form-base-fields;
    };

    .btn-md
    {
        border-radius:                          $btn-border-radius;
    }

    .divider
    {
        display:                                block;
        line-height:                            1em;
        margin-top:                             0.4em;
        border-top:                             solid 0.2em $cool_purple;
        color:                                  $cool_purple;
        text-align:                             center;

        & > span
        {
            position:                           relative;
            top:                                -0.5em;
            padding:                            0 0.5em 0 0.5em;
            background-color:                   $cool_white;
            text-transform:                     uppercase;
        }
    }

    .table-sm
    {
        td:not(.has-button)
        {
            padding-top:                        0.6em;
            line-height:                        1.1em;
        }
    }

    #history footer > button
    {
        min-width:                              125px;
    }

    .alert > span {
        white-space: normal;
    }
    .has-sort .ce-icon-gift,
    th .ce-icon-gift {
        font-size: 14px;
    }
    #details .ce-icon-undo {
        font-size: 16px;
    }
  #edit-submit {
    text-transform: uppercase;
  }
}

@include media-breakpoint-up(md)
{
    #profile.page
    {
        .form-group.down
        {
            padding-top:                        5px;
        }
        .form-control-static.down
        {
            padding-top:                        0.75em;
            padding-bottom:                     0;
        }
    }
}

#changePassword.page {
    form .form-control.has-danger {
        box-shadow: none;
    }
}
