.ce-module .carbon-cant-hide-cta {
  display: flex;
  flex-direction: column;
  justify-content: center;
  .text-block {
    margin-bottom: $grid-gutter-width;
  }
  .actions {
    :not(:first-child) {
      margin-top: $grid-gutter-width;
    }
  }
  @include media-breakpoint-up(md) {
    .actions {
      :not(:first-child) {
        margin-top: 0;
      }
    }
  }
}