//@import "node_modules/slick-carousel/slick/slick.scss";
////if production this should be the css file instead
@import "bootstrap/bootstrap.scss";

// need these first so we can reference them.
@import "bootstrap/bootstrap-overrides/bootstrap-overrides";
//@import "node_modules/bootstrap/scss/_variables";

@import "site/global/global";
@import "site/uikit/uikit";

////styling for third party elemts
@import "site/vendor/visualcomposer";
@import "site/vendor/slickslider";
@import "site/vendor/jquery-ui";

//page specific (legacy pages)
@import "site/pages/info-page";
@import "site/pages/forms";
@import "site/pages/profile";
@import "site/pages/press";
@import "site/pages/our-story";
@import "site/pages/certificate/certificate";
@import "site/pages/news/news";

//New 7-1 CSS files
@import "site/modules/_base/modules";
