/*
 * Title: Alternative Header
 * Type: Element
 * Dependencies: 
*/

h1, h2, h3, h4 {
  &.ce-alt-header,
  &.ce-alt-header_sidebar {
    color: $cool_dark_gray;
    font-weight: $cool_font-semi-bold-weight;
  }
  &.ce-alt-header_sidebar {
    @include media-breakpoint-up( lg ) {
      text-align: right;
    }
  }
}