/*********************
 * Proxima Nova Soft *
 *********************/
@mixin proxima-soft {
    font-family: $font-family-sans-serif;
    font-weight: 400;
    -webkit-font-smoothing: antialiased;
	-moz-osx-font-smoothing: grayscale;
}

@mixin proxima-soft-regular {
    @include proxima-soft;
    font-weight: 400;
}

@mixin proxima-soft-semibold {
    @include proxima-soft;
    font-weight: 600;
}

@mixin proxima-soft-bold {
    @include proxima-soft;
    font-weight: 700;
}
