@import 'fonts/global-fonts';
@import 'mixins/global-mixins';
@import 'functions/global-functions';
@import 'variables';
@import 'spinner';
@import 'switch';
@import 'forms';
@import 'utilities';
@import 'carousel-indicators';
@import 'typography';


// Ensures main content block extends so footer is pushed to bottom of viewport
// if page contents are too short to fill viewport. Fixed for cross-browser. Neil.
body {
	color: $color-base;
	display: flex;
	flex-direction: column;
	//min-height: 100vh;
	height: 100vh;
	position: relative;
}

body > .main-wrapper {
	display: flex;
	flex: 1 0 auto;
	flex-direction: column;
	overflow: hidden;
	position: relative;
	width: 100%;

	& > main {
		display: flex;
		flex-direction: column;
		flex-grow: 1;
		overflow: hidden;
		position: relative;
	}
}

@-moz-document url-prefix() {
	body > .main-wrapper {
		flex-grow: 1;
	}
}

.img-responsive {
	height: auto;
	max-width: 100%;
}

.img-full {
	height: auto;
	width: 100%;
}

a {
	color: $cool_blue;
	cursor: pointer;
	text-decoration: none;

	&:hover {
		color: $cool-dark-blue;
		text-decoration: none;
	}
}

.link-underline {
	color: $color-base;
	cursor: pointer;
	display: inline-block;
	font-weight: 500;
	text-decoration: underline;
	-webkit-transition: color 0.25s ease;
	transition: color 0.25s ease;

	&:hover {
		color: $cool_blue;
		text-decoration: underline;
	}
}

.minor-link {
	color: $color-base;
	text-decoration: underline;

	&:hover {
		color: $cool_dark_gray;
		text-decoration: underline;
	}
}

html {
	box-sizing: border-box;
	-webkit-font-smoothing: antialiased;
	font-weight: $cool_font-regular-weight;
	-ms-touch-action: manipulation;
	touch-action: manipulation;
}

h1 {
	@include media-breakpoint-down(sm) {
		font-size: $font-size-h2;
	}
}

.dark {
	a {
		color: $cool_yellow;
	}
}

a.back {
	font-size: 18px;

	&:hover {
		text-decoration: none;
	}
}

ul,
ol {
	padding-left: 1em;
}

blockquote {
	p {
		margin-bottom: 8px;
		padding-left: 6px;
		text-indent: -6px;
	}

	p::before {
		content: '\201C';
	}

	p::after {
		content: '\201D';
	}

	p::before,
	p::after {
		font-size: 16px;
		line-height: 0.01em;
		vertical-align: 1px;
	}

	footer {
		color: $cool_purple;
		font-weight: $cool_font-semi-bold-weight;
		margin-left: 50px;
		text-indent: -10px;
		text-transform: uppercase;

		&:before {
			content: '- ';
		}
	}
}

nav[ng-controller='mainNavCtrl'] {
	text-transform: uppercase;
}

.alert {
	& > span {
		white-space: nowrap;
	}
}

.caps {
	text-transform: uppercase;
}

.grid {
	& > .card {
		& > .card-header,
		& > .table {
			margin-bottom: 0;
		}

		& > .table > thead > tr > th {
			white-space: nowrap;

			span.faint {
				display: inline-block;
				width: 1.5em;
			}
		}

		& > .table > thead > tr > th:first-child,
		& > .table > tbody > tr > td:first-child {
			padding-left: 1.25rem;
		}

		& > .table > tbody > tr > td:last-child.has-button {
			text-align: left;
		}

		td > .btn.btn-xs {
			padding: 0.05em 0.5em;
			position: relative;
			top: -0.025em;
		}
	}
}

.faint {
	opacity: 0.5;
}

.has-sort {
	cursor: pointer;
}

// not same as display: none;
.hidden {
	visibility: hidden;
}

.lc {
	text-transform: lowercase;
}

.nowrap {
	white-space: nowrap;
}

.relative {
	position: relative;
}

small.required-hint {
	color: #CCCCCC;
	position: absolute;
	top: -0.75em;

	&.bottom {
		bottom: 0;
		top: inherit;
	}
}

.strikethrough {
	color: lighten($color-base, 25%);
	text-decoration: line-through;
}

table.boxed {
	border: solid 1px $cool_light_gray;
	box-shadow: 1px 1px 10px $cool_light_gray;
}

select {
	color: $cool_dark_gray !important;
}

:-ms-input-placeholder {
	color: lighten($color-base, 15%) !important;
}

::-ms-input-placeholder {
	color: lighten($color-base, 15%) !important;
}

:-moz-placeholder {
	color: lighten($color-base, 15%) !important;
}

::-moz-placeholder {
	color: lighten($color-base, 15%) !important;
}

::-webkit-input-placeholder {
	color: lighten($color-base, 15%) !important;
	font-weight: normal;
}

// newsletter modal, triggered from homepage or footer
iframe body {
	background: none;

	& > .container {
		border: none;
	}
}

//-----------------------------------------
// WooCommerce
//-----------------------------------------
.woocommerce-page {
	background-color: #F7F7F7;
	padding-top: 70px;

	.woocommerce {
		@extend .container;

		// Reset theme styles.
		.col2-set {
			.col-1,
			.col-2 {
				max-width: none;
				padding: 0;
			}
		}

		// Checkout
		.form-row {
			display: block;
		}

		.woocommerce-input-wrapper {
			display: block;
		}
	}
}

//-----------------------------------------
// WooCommerce - Cart
//-----------------------------------------
.woocommerce-page {
	.woocommerce {
		.cart-collaterals .cart_totals,
		.woocommerce-page .cart-collaterals .cart_totals {
			float: none;
			width: auto;
		}

		a.remove {
			color: #C3C3C3 !important;

			&:focus,
			&:hover {
				background: transparent;
				color: #D9534F !important;
			}
		}

		.ce-incrementer_value {
			margin: 0 0.5rem;
		}

		table.shop_table {
			border: 0;
			border-bottom: 1px solid #C3C3C3;
			border-radius: 0;

			td {
				text-align: right;
			}
		}

        th.total, td.total {
            color:#432973;
            font-weight:bold;
            font-family:proxima-soft,Helvetica,Arial,sans-serif;
        }

        table.shop_table th.total {
            padding-left:0px;
        }

        table.cart-summary {
            width:100%;
            font-size:13px;
        }

        table.cart-summary td.price {
            text-align:right;
            padding-right:12px;
        }

        table.cart-summary td.item {
            padding-right:20px;
        }

        table.cart-summary td.item .name {
            font-weight:bold;
        }

	}
    .ce-color {
        color: #432973;
    }
}

//-----------------------------------------
// WooCommerce - Checkout
//-----------------------------------------
.woocommerce-page {
	.woocommerce {
		.woocommerce-form-coupon-toggle {
			display: none;
		}

		.woocommerce-checkout {
			width: 100%;
		}

		table.shop_table td.product-name {
			text-align: left;
		}
	}
}


