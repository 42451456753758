.ce-module .offset-calculator {
  form.calculator-inputs {
    width: 100%;
    .tooltip-trigger {
      color: $cool_blue;
      font-size: 14px;
      margin-left: 8px;
    }
    .ce-select .selected,
    .ce-select .items .item label {
      padding-left: $mod_space_xs;
    }
    .section {
      margin-bottom: .25rem;
      .section-title {
        @extend .input-title;
      }
      & + .section {
        margin-top: .25rem;
      }
    }
    .row.inputs {
      justify-content: space-between;
      align-items: flex-start;
      .input-title {
        margin-top: .5rem;
        h4 {
          line-height: 1;
          margin-bottom: .25rem;
        }
      }
      .option-box {
        background-color: $cool_white;
        padding: 15px 15px;
        margin-top: 5px;
        margin-bottom: 16px;
        display: flex;
        flex-wrap: wrap;
        align-items: center;
        > .row {
          width: 100%;
          flex: 1 0 auto;
          & + .row {
            margin-top: 15px;
          }
        }
        .field {
          display: flex;
          align-items: center;
          width: 100%;
        }
        label {
          align-items: center;
          span {
            margin-top: 0;
            font-weight: $cool_font-regular-weight;
          }
          &:after {
            top: 50%;
            transform: translateY(-50%);
          }
        }
      }
      &.equation {
        align-items: flex-end;
        .parameters {
          min-width: 160px;
          .field-hint {
            font-size: 12px;
            color: $cool_medium_gray;
            line-height: 1.2;
            display: block;
            margin-top: .25rem;
          }
          .parameter-group {
            align-items: flex-end;
          }
          .parameter {
            //width: auto;
            //flex: 1 0 auto;
          }
          .operator {
            width: auto;
            flex: 0 1 auto;
            height: 42px;
            color: $cool_purple;
            font-size: 20px;
            font-weight: $cool_font-semi-bold-weight;
            display: flex;
            align-items: center;
            margin-bottom: 18px;
          }
        }
        .result {
          display: flex;
          flex-wrap: wrap;
          justify-content: flex-end;
          align-items: flex-start;
          margin-bottom: 18px;
          height: 42px;
          .equals,
          .amount,
          .units {
            flex: 1 0 auto;
            color: $cool_purple;
            font-weight: $cool_font-semi-bold-weight;
            line-height: 1;
          }
          .equals,
          .amount {
            height: 40px;
            display: flex;
            align-items: center;
          }
          .equals {
            justify-content: flex-start;
            margin-left: 10px;
            font-size: 20px;
          }
          .amount {
            justify-content: flex-end;
            font-size: 34px;
            &.muted {
              color: $cool_gray;
            }
          }
          .units {
            display: flex;
            justify-content: flex-end;
            width: 100%;
            font-size: 1rem;
            padding-top: .25rem;
            text-transform: capitalize;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(sm) {
    form.calculator-inputs {
      .row.inputs {
        &.equation {
          .parameters {
            min-width: 250px;
          }
          .result {
            .equals {
              flex: 0 1 auto;
            }
            .amount {
              flex: 1 1 auto;
              font-size: 40px
            }
            .units {
              flex: 0 1 auto;
              padding-left: 15px;
              align-self: flex-end;
              margin-bottom: 6px;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(md) {
    form.calculator-inputs {
      .row.inputs {
        &.equation {
          .result {
            .units {
              width: auto;
            }
          }
        }
      }
    }
  }
  @include media-breakpoint-up(lg) {
    form.calculator-inputs {
      .section {
        margin-bottom: .75rem;
        & + .section {
          margin-top: 1.5rem;
        }
      }
      .row.inputs {
        .input-title {
          p {
            font-size: 28px;
            margin-bottom: .75rem;
          }
        }
        .option-box {
          padding: 30px 15px;
          margin-top: 10px;
          margin-bottom: 30px;
        }
        &.equation {
          .parameters {
            min-width: 195px;
            max-width: 195px;
          }
        }
      }
    }
  }
  @include media-breakpoint-up(xl) {
    form.calculator-inputs {
      .row.inputs {
        .input-title {
          p {
            font-size: 28px;
            margin-bottom: .75rem;
          }
        }
        &.equation {
          .parameters {
            min-width: 265px;
            max-width: 265px;
          }
        }
      }
    }
  }
}
