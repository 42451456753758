.ce-module {
    .module-header {
        @extend .col-12;

        @extend .col-md-8;
        @extend .offset-md-2;

        @extend .col-lg-8;
        @extend .offset-lg-2;

        &.half-page {
            @extend .col-md-12;
            @extend .offset-md-0;

            @extend .col-lg-10;
            @extend .offset-lg-1;

            padding-top: $mod_padding_v_mobile;
            padding-bottom: $mod_padding_v_mobile;

            @include media-breakpoint-down(sm) {
                margin-left: -$grid-gutter-width/2;
                margin-right: -$grid-gutter-width/2;
            }

            @include media-breakpoint-up(lg) {
                padding: $mod_padding_v 0;
            }
        }

        text-align: center;
        .header-title {
            @extend .title;
            margin-bottom: 10px;
        }
        .header-title.primary {
            @extend .title.primary;
        }
        .header-title.featured {
            @extend .title.featured;
        }


        &[class*="module-header-icon-"],
        &[class^="module-header-icon-"] {
            .header-title.primary {
                &:before {
                    font-family: 'cooleffect';
                    margin: 0 0 $mod_space_xs;
                    border: none;
                    width: auto;
                    font-size: 30px;
                    font-weight: $cool-font-regular-weight;
                }
            }
        }

        &.module-header-icon-gift {
            .header-title.primary {
                &:before {
                    @extend .ce-icon-gift:before;
                }
            }
        }

        .pre-title {
            font-size: $mod_font_size_h6;
            margin-bottom: 2px;
            &:before {
                margin-top: -9px;
                margin-right: 12px;
            }
        }

        .pre-title + .header-title {
            &:before {
                display: none;
            }
        }

        > :last-child {
            margin-bottom: 0;
        }
        .header-body {
            > *:last-child {
                margin-bottom: 0;
            }
        }
        &.module-header-long {
            text-align: left;
        }
    }
    [class*="col-md-"] .module-header:only-child {
        @include media-breakpoint-down( md ) {
            width: 100%;
        }
    }

    @include media-breakpoint-up(lg) {
        .module-header.module-header-long {
            text-align: center;
            .header-body {
                text-align: left;
            }
        }
    }
}
