/**
 * An alternative for bootstrap's button-variant mixin with hover alt behaviors
 */
@mixin button-variant-no-hover($color, $background, $border) {
    $active-background: darken($background, 10%);
    $active-border: darken($border, 12%);
    color: $color;
    background-color: $background;
    border-color: $border;
    cursor: pointer;

    @include box-shadow(inset 0 1px 0 rgba(255,255,255,.15), 0 1px 1px rgba(0,0,0,.075));
    &:focus,
    &.focus {
        color: $color;
        background-color: $active-background;
        border-color: $active-border;
    }
    &:active,
    &.active,
    .open > &.dropdown-toggle {
        color: $color;
        background-color: $active-background;
        border-color: $active-border;
        // Remove the gradient for the pressed/active state
        background-image: none;
        @include box-shadow(inset 0 3px 5px rgba(0,0,0,.125));
        &:focus,
        &.focus {
            color: $color;
            background-color: darken($background, 17%);
            border-color: darken($border, 25%);
        }
    }
    &.disabled,
    &:disabled {
        &:focus,
        &.focus {
            background-color: $background;
            border-color: $border;
        }
    }
}
