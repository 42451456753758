@import url('https://fonts.googleapis.com/css?family=Montserrat:500,600,600i,700,700i,800,900');
@import url('https://fonts.googleapis.com/css?family=Raleway:400,400i,700,700i');

$stillin_font_family_base: 'Raleway', sans-serif;
$stillin_font_weight_base_regular: 400;
$stillin_font_weight_base_bold: 700;

$stillin_font_family_display: 'Montserrat', sans-serif;
$stillin_font_weight_display_medium: 500;
$stillin_font_weight_display_semibold: 600;
$stillin_font_weight_display_bold: 700;
$stillin_font_weight_display_extra_bold: 800;

$stillin_color_black: #000000;
$stillin_color_white: #FFFFFF;
$stillin_color_red: #C84A41;
$stillin_color_light_yellow: #F1F1E7;
$stillin_color_blue: #0F96E0;
$stillin_color_dark_blue: #0050A6;
$stillin_color_blue_gray: #435773;
$stillin_color_light_gray: #EEEEEE;
$stillin_color_dark_gray: #3B3B3B;

$stillin_font_size_h1_lg:           36px;
$stillin_font_size_h2_lg:           26px;
$stillin_font_size_h3_lg:           20px;
$stillin_font_size_h4_lg:           20px;
$stillin_font_size_h5_lg:           16px;
$stillin_font_size_h6_lg:           16px;
$stillin_font_size_small_lg:        13px;

$stillin_font_size_h1_md:           24px;
$stillin_font_size_h2_md:           22px;
$stillin_font_size_h3_md:           22px;
$stillin_font_size_h4_md:           22px;
$stillin_font_size_h5_md:           18px;
$stillin_font_size_h6_md:           18px;
$stillin_font_size_small_md:        13px;

$stillin_font_size_h1:              26px;
$stillin_font_size_h2:              20px;
$stillin_font_size_h3:              20px;
$stillin_font_size_h4:              20px;
$stillin_font_size_h5:              18px;
$stillin_font_size_h6:              18px;
$stillin_font_size_small:           13px;
