.ce-caption-small {
  font-size: $mod_font_size_small;
  margin-bottom: $mod_space_sm;
  
  @include media-breakpoint-up( lg ) {
    margin-bottom: 0;
  }

  &:last-child {
    margin-bottom: 0;
  }
  h4, h5, h6 {
    font-size: $mod_font_size_small;
  }
}