.ce-mosaic-flex.mosaic-home {
  height: 50vw;
  max-height: 1000px;
  min-height: 650px;
  @include media-breakpoint-down(lg) {
    height: 50vh
  }
  @include media-breakpoint-down(md) {
    min-height: initial;
  }
}

.ce-mosaic_homepage {
  $row: $ce-mosaic_row;
  $column: $ce-mosaic_column;
  $cell: $ce-mosaic_cell;

  // Base
  @extend .ce-mosaic;
  height: 50vw;
  max-height: 1000px;
  min-height: 650px;
  @include media-breakpoint-down(lg) {
    height: 50vh
  }
  @include media-breakpoint-down(md) {
    min-height: initial;
  }

  @include media-breakpoint-up(lg) {
    border-bottom: 1px solid $cool_light_gray;
  }

  // Text
  @include ce-content-overlay( ".ce-mosaic-homepage_overlay" ) {
    align-items: center;
    background: $cool_white;
    box-sizing: border-box;
    //display: flex;
    //flex-direction: column;
    //justify-content: center;

    // Bootstrap specific
    &.container {
      background: transparent;
    }
    &.container > .row {
      display: flex;
      justify-content: center;
    }
    &.container > .row > [class*="col-"] {
      background: $cool_white;
      padding: $mod_space_xxl;
    }
  }

  .module-header {
    margin: 0;
    padding: 0;
    width: 100%;

    > *:not(:last-child) {
      //margin-bottom: $mod_card_padding_v;
    }

    .header-title {
      &:before {
        display: none;
      }
      font-size: $mod_font_size_h2;
      @include media-breakpoint-up(lg) {
        font-size: $mod_font_size_h2_lg;
      }
    }

  }

  /*
   * Homepage Mosaic Layout
   */
  /* Column 1 */
  &#{$row} > #{$column}:first-of-type {
    @include media-breakpoint-down(md) {
      display: none;
    }
    @include media-breakpoint-up(lg) {
      #{$cell}:first-of-type {
        flex: 2;
      }
    }
  }
  /* Column 2 */
  &#{$row} > #{$column}:nth-of-type(2) {
    @include media-breakpoint-up(lg) {
      flex: 3;
    }
    /* Column 2, Row 1 */
    > #{$row}:first-of-type {
      > #{$cell}:nth-of-type(2) {
        flex: 2;
      }
      @include media-breakpoint-down(md) {
        > #{$cell}:first-of-type {
          display: none;
        }
      }
    }
    /* Column 2, Row 2 */
    > #{$row}:nth-of-type(2) {
      flex: 2;
      @include media-breakpoint-down(md) {
        display: none;
      }
      /* Column 2, Row 2, Column 1 */
      > #{$column}:first-of-type {
        flex: 2;
      }
    }
  }
  /* Column 3 */
  &#{$row} > #{$column}:nth-of-type(3) {
    @include media-breakpoint-up(lg) {
      flex: 2;
    }
    > #{$cell}:first-of-type {
      flex: 2;
      @include media-breakpoint-down(md) {
        display: none;
      }
    }
  }
}
