.ce-module {
	.car-offset-calculator{
		.inputs input {
			width: 150px;
		}

		.vehicle_class {
			width: auto;
			background-color: #fff;
			border: 2px solid #C3C3C3;
			border-radius: 0;

			&:nth-child(2) {
				display: none;
			}
		}

		.select_wrapper {
			position: relative;

			&:after {
				content: "\e930";
				display: block;
				height: 7px;
				width: 12px;
				position: absolute;
				top: 15px;
				right: 15px;
				font-family: 'cooleffect';
				font-size: 7px;
			}
		}

		form label.text-label {
			position: relative;

			.tooltip-trigger {
				margin-left: 8px;
				font-size: 14px;

				&:hover + .tooltip__item { display: block; }
			}
		}

		.tooltip__item {
			display: none;
			position: absolute;
			right: -91px;
			bottom: 30px;
			width: 200px;
			padding: 5px;
			background: #000;
			border-radius: 5px;
			color: #fff;
			font-size: 13px;
			font-weight: 400;
			text-align: center;
			z-index: 4;

			&::after {
				content: "";
				display: block;
				position: absolute;
				left: 50%;
				bottom: -16px;
				margin-left: -8px;
				border: 8px solid transparent;
				border-top: 8px solid #000;
			}
		}
	}
}
