.ce-module .ce-certificate.ce-certificate-origami-gift {
  .content {
    display: flex;
    justify-content: center;
    align-items: center;

    .outer-frame {
      width: 7.5in;
      height: 7.5in;
      border: none;

      .image-wrapper {
        width: 100%;
        height: 100%;
        position: absolute;
        top: 0;
        left: 0;
      }

      .inner-frame {
        position: relative;
        width: 100%;
        margin: .5in .5in 0 .5in;

        .copy {
          padding: .5in;
          .about {
            padding: 0 60pt;
            margin-bottom: 10pt;
          }
          .project-list-label {
            font-size: 8pt;
            font-weight: $cool_font-semi-bold-weight;
            font-style: italic;
          }
          .project-list {
            font-size: 9pt;
            font-weight: $cool_font-semi-bold-weight;
            .project {
              display: block;
            }
          }
        }
      }
    }
  }
}

.ce-module .ce-certificate.ce-certificate-origami-gift {
  .content {
    display: flex;
    justify-content: center;
    align-items: center;
  }
}
