.fp-section.fp-table,
.fp-slide.fp-table {
  display: table;
  table-layout: fixed;
  width: 100%;
}

.fp-section {
  position: relative;
}

.fp-tableCell {
  display: table-cell;
  width: 100%;
}

.fp-scrollable {
  overflow: hidden;
  position: relative;
}

.fp-scroller {
  overflow: hidden;
}