.form-control {

}

.width-100 {
  width:100%;
}

@include media-breakpoint-down(lg) {
  .input-group {
    width:                    100%;
  }
  label {
    text-align:               left;
  }
  .input-group-addon {
    position:                 absolute;
    top:                      0;
    right:                    4px;
  }
}
