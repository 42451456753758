.ce-module .ce-faceted-card {
  padding-bottom: $mod_card_padding_v;
  .inner {
    position: relative;
  }
  .ce-collapsible-trigger {
    line-height: 0;
    .ce-icon- {
      font-size: 22px;
    }
    .ce-icon- {
      @extend .ce-icon-minus-outline;
    }
    &.hide .ce-icon- {
      @extend .ce-icon-plus-outline;
    }
  }
  .card-facet.hide {
    display: none;
  }
  .card-facet + .card-facet {

  }
  @include media-breakpoint-up(lg) {
    .ce-collapsible-trigger {

    }
  }
}