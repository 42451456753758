.ce-textfield_wrapper {
  align-items: center;
  display: flex;
  justify-content: center;
  margin-left: auto;
  margin-right: auto;

  .ce-textfield_label {
    color: $cool_purple;
    font-size: $font-size-root;
    font-weight: $cool_font-bold-weight;
    margin-bottom: 0;
  }
  .ce-textfield {
    align-items: center;
    display: flex;
    flex: 1 0 auto;
    justify-content: flex-end;
    padding-left: ( $mod_card_padding_v * 0.5 );
    padding-right: ( $mod_card_padding_v * 0.5 );

    .ce-textfield_unit {
      color: $cool_purple;
      font-size: $mod_font_size_h6_lg;
      margin-bottom: 0;
      margin-right: $grid-gutter-width-sm;
    }
    .ce-textfield_input {
      background-color: $cool_very_light_gray;
      border: none;
      color: $cool_medium_gray;
      font-weight: $cool_font-bold-weight;
      height: 40px;
      padding: 0 $grid-gutter-width-sm;
      width: 90px;
    }
  }
}

.ce-banner-content-overlay .ce-textfield_wrapper {
  margin-top: $mod_card_padding_v;
  max-width: 80%;
}

.ce-textfield-hide .ce-textfield_wrapper,
.ce-textfield-hide.ce-textfield_wrapper {
  @include media-breakpoint-down( sm ) {
    display: none;
  }
}