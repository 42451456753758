.email-error {
  min-width: 165px;
  $caret-h: 8px;
  opacity: 0;
  display: block;
  position: absolute;
  top: 100%;
  left: 50%;
  transform: translateX(-50%);
  //width: $popover-w;
  //height: 50px;
  background-color: $cool_white;
  border-radius: 5px;
  z-index: 99999;

  box-shadow: 4px 4px 0px 0px rgba(0,0,0,0.15);

  &::before {
    content: '';
    display: block;
    // triangle
    width: 0;
    height: 0;
    border-style: solid;
    border-width: 0 8px $caret-h 8px;
    border-color: transparent transparent #ffffff transparent;

    position: absolute;
    top: 1-$caret-h;
    left: 20px;
  }

  .copy {
    // font
    position: relative;
    padding: 10px;
    font-size: 1rem !important;
    font-weight: $cool_font-regular-weight;
    line-height: 1.2;
    text-align: left;
    color: black;
    white-space: normal;
    text-transform: none;
  }
}

.email-error.show {
  //opacity: 1;
  animation-name: popover;
  animation-duration: 4s;
  animation-fill-mode: forwards;
}

@keyframes popover {
  0% {opacity:0;}
  5% {opacity:1;}
  95% {opacity:1;}
  0% {opacity:0;}
}